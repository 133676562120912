import {App} from 'antd';
import {CreateErrorHandlingProxy} from '../service/ErrorHandlingProxy';
import {ButtonSettings} from './Common';

export function useErrorHandler<T extends object>(service: T): T {
  const modal = App.useApp().modal;
  const onError = (e: any) => {
    modal.error({
      title: 'Server Error',
      content: e.details?.message ?? e.message,
      okButtonProps: {...ButtonSettings},
    });
  };
  return CreateErrorHandlingProxy<T>(service, onError);
}
