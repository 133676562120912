package com.adaptify.rating.admin.model

actual fun <T> modelToJson(obj: T): String {
  return JSON.stringify(obj)
}

actual fun <T> modelArrayToJson(util : ModelUtil<T>, obj: Array<T>): String {
  return JSON.stringify(obj)
}

actual fun <T> jsonToModelArray(util: ModelUtil<T>, str : String) : Array<T> {
  return JSON.parse(str);
}

actual fun <T> jsonToModel(util: ModelUtil<T>, str: String): T {
  return JSON.parse(str)
}

actual fun jsonToStringArrayArray(str : String) : Array<Array<String?>> {
  return JSON.parse(str);
}

actual fun generateUUID(): String {
  return UUID.v4()
}

@JsModule("uuid")
@JsNonModule
external object UUID{
  fun v4(): String
}
