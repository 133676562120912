export function CreateErrorHandlingProxy<T extends object>(
  service: T,
  onError: (e: any) => void
): T {
  // if this is already proxied, just return the proxy
  if ((service as any).__errorproxy) {
    return service;
  }
  return new Proxy(service, {
    get: (target, prop) => {
      if (prop === '__errorproxy') {
        return true;
      }
      /// if the function returns a promise, assume we can piggy back on a token refresh call to server
      const targetProp = target[prop as keyof T];
      if (typeof targetProp === 'function') {
        return async (...args: any[]) => {
          // binding is necessary to get an instance method to have the right this context
          try {
            const result = (targetProp as Function).bind(target)(...args);
            if (result instanceof Promise) {
              // add an error handler to the promise but don't chain it, so that the error does get through
              result.catch(e => {
                onError(e);
              });
            }
            return result;
          } catch (e) {
            onError(e);
          }
        };
      }
      return targetProp;
    },
  });
}
