import {DatePicker, Form, Input, Select} from 'antd';
import React, {useEffect, useState} from 'react';
import {v4 as uuidv4} from 'uuid';

import * as kot from 'adaptify-multi-module-rating-admin-model';
import {RuleObject} from 'antd/es/form';
import {useForm} from 'antd/es/form/Form';
import dayjs from 'dayjs';
import {
  ButtonSettings,
  DateDataFormats,
} from '../../../../common/control/Common';
import {DraggableModal} from '../../../../common/control/DraggableModal';
import {
  DateIsoStringToDayJs,
  DayJsToIsoString,
} from '../../../../common/model/CommonUtils';
import {IdAndName} from '../../../../product/model/Product';
import {ProductService} from '../../../../product/service/ProductService';
import {PathPickTree} from './PathPickTree';
import {TableBindingControl} from './TableBindingControl';

import CalculationFunction = kot.com.adaptify.rating.admin.model.calculation.CalculationFunction;
import Binding = kot.com.adaptify.rating.admin.model.calculation.binding.Binding;
import BindingDescriptor = kot.com.adaptify.rating.admin.model.calculation.descriptor.BindingDescriptor;
import BindingType = kot.com.adaptify.rating.admin.model.calculation.BindingType;
import MetadataProvider = kot.com.adaptify.rating.admin.model.util.MetadataProvider;
import CalculationEvalContext = kot.com.adaptify.rating.admin.model.calculation.context.CalculationEvalContext;
import PrimitiveDataType = kot.com.adaptify.rating.admin.model.type.PrimitiveDataType;
import VariableType = kot.com.adaptify.rating.admin.model.calculation.context.VariableType;
import ScopedVariable = kot.com.adaptify.rating.admin.model.calculation.context.ScopedVariable;
import TableBinding = kot.com.adaptify.rating.admin.model.calculation.binding.TableBinding;
import GetPreferredBindingPrimitiveDataType = kot.com.adaptify.rating.admin.model.util.GetPreferredBindingPrimitiveDataType;

export interface BindingPickerProps {
  allTableNames: IdAndName[];
  productService: ProductService;
  rootProperties: ScopedVariable[] | undefined;
  calculationFunction: CalculationFunction | undefined;
  existingBindings: Binding[];
  bindingDescriptor: BindingDescriptor;
  evalContext: CalculationEvalContext | undefined;

  onCancel: () => void;
  onFinish: (binding?: Binding) => void;
  reset: () => void;
  metadataProvider: MetadataProvider;
  resetVersion: number;
  setResetVersion: (version: number) => void;
  defaultPath?: string | undefined;
}

function setupBindingForEdit(
  existingBindings: Binding[],
  bindingDescriptor: BindingDescriptor
): Binding {
  const defaultBindingType =
    bindingDescriptor.allowedBindingTypes.length === 1
      ? bindingDescriptor.allowedBindingTypes[0]
      : BindingType.None;
  // there's only one binding type default to that
  return (
    existingBindings.find(b => b.name === bindingDescriptor.name) || {
      name: bindingDescriptor.name,
      bindingType: defaultBindingType.name,
    }
  );
}

function stepNameComparator(a: string, b: string) {
  // break into numeric parts and compare each part
  // so that we can try to meaninfully compare steps
  // step 10 should appear after step 9, step 1a should appear after step 1 and before step 2
  const aScore = stepNameScorerForSorting(a);
  const bScore = stepNameScorerForSorting(b);
  const minLength = Math.min(aScore.length, bScore.length);
  for (let i = 0; i < minLength; i++) {
    if (aScore[i] < bScore[i]) {
      return -1;
    }
    if (aScore[i] > bScore[i]) {
      return 1;
    }
  }
  if (aScore.length < bScore.length) {
    return -1;
  }
  if (aScore.length > bScore.length) {
    return 1;
  }
  return 0;
}
// we can't sort lexically or step 10 will come before step 2
// so sort based on each individual parts
// this will not exaustively handle all cases, but should be good enough for all cases actually used in practice
function stepNameScorerForSorting(name: string): number[] {
  // name should start with Step followed by a whitespace
  if (name.length < 5) {
    return 0;
  }

  let partScore = 0;
  const allScores: number[] = [];
  let inNumber = false;
  let inString = false;
  for (const c of name) {
    if (c >= '0' && c <= '9') {
      if (inString) {
        inString = false;
        allScores.push(partScore);
        partScore = 0;
      }
      inNumber = true;
      partScore = partScore * 10 + parseInt(c);
    } else if (c >= 'a' && c <= 'z') {
      if (inNumber) {
        inNumber = false;
        allScores.push(partScore);
        partScore = 0;
      }
      inString = true;
      partScore = partScore * 26 + (c.charCodeAt(0) - c.charCodeAt('a'));
    }
    // ignore other characters as they shouldn't impact path sorting
  }
  if (partScore > 0) {
    allScores.push(partScore);
  }
  return allScores;
}

interface BindingFormValues {
  bindingType: string;
  bindingDetails: string; // dummy field used for path
  literalValue: string;
  literalValueDate: dayjs.Dayjs;
  literalDataType: string;
  path: string;
  dynamicVariableFunctionId: string;
  transitionName: string;
  tableName: string;
  variableDeclarationName: string;
  variableDeclarationDataType: string;
  configurationValue: string;
}

export function BindingPicker(props: BindingPickerProps) {
  // set the binding outside of the original parent as we should be able to confirm or cancel any changes
  const [rootProperties, setRootProperties] = useState<ScopedVariable[]>([]);
  const [formName, setFormName] = useState<string>(uuidv4());
  const [refreshCount, setRefreshCount] = useState<number>(0);

  const [form] = useForm<BindingFormValues>();
  const [tableBinding, setTableBinding] = useState<TableBinding | undefined>();

  useEffect(() => {
    const newBinding = setupBindingForEdit(
      props.existingBindings,
      props.bindingDescriptor
    );

    let literalDataType = newBinding.literal?.dataType;
    if (
      !literalDataType &&
      props.bindingDescriptor.allowedPrimitiveDataTypes &&
      props.bindingDescriptor.allowedPrimitiveDataTypes.length === 1
    ) {
      literalDataType =
        props.bindingDescriptor.allowedPrimitiveDataTypes[0].name;
    }

    form.setFieldsValue({
      bindingType: newBinding.bindingType,
      bindingDetails: newBinding.path ?? undefined,
      literalValue: newBinding.literal?.value,
      literalValueDate: newBinding.literal?.value
        ? DateIsoStringToDayJs(newBinding.literal.value)
        : undefined,
      literalDataType: literalDataType,

      path: newBinding.path ?? undefined,
      dynamicVariableFunctionId:
        newBinding.dynamicVariableFunctionId ?? undefined,
      transitionName: newBinding.transitionDefinition ?? undefined,
      tableName: newBinding.tableName ?? undefined,
      variableDeclarationName: newBinding.variableDeclaration?.name,
      variableDeclarationDataType: newBinding.variableDeclaration?.dataType,
      configurationValue: newBinding.configurationValue ?? undefined,
    });
    setTableBinding(newBinding.tableBinding ?? undefined);
    // if root scoped variables provided, use them, otherwise look them up
    if (props.rootProperties) {
      setRootProperties(props.rootProperties);
      return;
    }
  }, [
    props.calculationFunction,
    props.bindingDescriptor.name,
    props.resetVersion,
  ]);

  useEffect(() => {
    const eff = async () => {
      if (props.evalContext) {
        const roots = props.evalContext.functionContexts
          .asJsReadonlyMapView()
          .get(props.calculationFunction?.id ?? '')?.variables;
        setRootProperties(roots ?? []);
      }
    };
    eff();
  }, [props.evalContext, props.calculationFunction]);

  useEffect(() => {
    const eff = async () => {
      if (props.evalContext) {
        const preferredBindingType = await GetPreferredBindingPrimitiveDataType(
          props.bindingDescriptor,
          props.calculationFunction,
          props.metadataProvider,
          props.evalContext
        );
        if (
          form.getFieldValue('literalDataType') === undefined &&
          preferredBindingType
        ) {
          form.setFieldValue('literalDataType', preferredBindingType?.name);
        }
      }
    };
    eff();
  }, [props.evalContext, props.calculationFunction]);

  function onBindingValuesChange(
    changedValues: BindingFormValues,
    allValues: BindingFormValues
  ) {
    // force the UI to refresh
    setRefreshCount(refreshCount + 1);
  }

  // TODO, if current property type isn't allowed show it in the combo anyway
  const allowedBindingTypeOptions =
    props.bindingDescriptor.allowedBindingTypes.map(v => ({
      label: v.displayName,
      value: v.code,
    }));
  allowedBindingTypeOptions.unshift({label: 'None', value: ''});

  function onFinishBindingForm(values: BindingFormValues) {
    if (values.bindingType === BindingType.PrimitiveLiteral.name) {
      const literalValue =
        values.literalDataType === PrimitiveDataType.Date.name
          ? DayJsToIsoString(values.literalValueDate)
          : values.literalValue;

      const newBinding = {
        name: props.bindingDescriptor.name,
        bindingType: BindingType.PrimitiveLiteral.name,
        literal: {
          value: literalValue,
          dataType: values.literalDataType,
        },
      } as Binding;
      props.onFinish(newBinding);
      return;
    }
    if (values.bindingType === BindingType.Path.name) {
      const newBinding = {
        name: props.bindingDescriptor.name,
        bindingType: BindingType.Path.name,
        path: values.path,
      } as Binding;
      props.onFinish(newBinding);
    }
    if (values.bindingType === BindingType.VariableDeclaration.name) {
      const newBinding = {
        name: props.bindingDescriptor.name,
        bindingType: BindingType.VariableDeclaration.name,
        variableDeclaration: {
          name: values.variableDeclarationName,
          dataType: values.variableDeclarationDataType,
        },
      } as Binding;
      props.onFinish(newBinding);
    }
    if (values.bindingType === BindingType.DynamicVariableDeclaration.name) {
      const newBinding = {
        name: props.bindingDescriptor.name,
        bindingType: BindingType.DynamicVariableDeclaration.name,
        dynamicVariableFunctionId: values.dynamicVariableFunctionId,
      } as Binding;
      props.onFinish(newBinding);
    }
    if (values.bindingType === BindingType.TableName.name) {
      const newBinding = {
        name: props.bindingDescriptor.name,
        bindingType: BindingType.TableName.name,
        tableName: values.tableName,
      } as Binding;
      props.onFinish(newBinding);
    }
    if (values.bindingType === BindingType.TransitionDefinition.name) {
      const newBinding = {
        name: props.bindingDescriptor.name,
        bindingType: BindingType.TransitionDefinition.name,
        transitionDefinition: values.transitionName,
      } as Binding;
      props.onFinish(newBinding);
    }
    if (values.bindingType === BindingType.DynamicVariable.name) {
      const newBinding = {
        name: props.bindingDescriptor.name,
        bindingType: BindingType.DynamicVariable.name,
        dynamicVariableFunctionId: values.dynamicVariableFunctionId,
      } as Binding;
      props.onFinish(newBinding);
    }
    if (values.bindingType === BindingType.ConfigurationValue.name) {
      const newBinding = {
        name: props.bindingDescriptor.name,
        bindingType: BindingType.ConfigurationValue.name,
        configurationValue: values.configurationValue,
      } as Binding;
      props.onFinish(newBinding);
    }
    if (values.bindingType === BindingType.TableBinding.name) {
      const newBinding = {
        name: props.bindingDescriptor.name,
        bindingType: BindingType.TableBinding.name,
        tableBinding: tableBinding,
      } as Binding;
      props.onFinish(newBinding);
    }
  }

  function validatePath(rule: RuleObject, value: string) {
    if (value === '_globals') {
      return Promise.reject('Cannot use global fields in this context');
    }
    return Promise.resolve();
  }

  const primitiveTypeOptions = [
    PrimitiveDataType.Boolean,
    PrimitiveDataType.Date,
    PrimitiveDataType.Number,
    PrimitiveDataType.String,
  ].map(v => ({label: v.name, value: v.name}));

  function createBindingControl() {
    const bindingType =
      form.getFieldsValue().bindingType ?? BindingType.None.name;
    if (BindingType.PrimitiveLiteral.name === bindingType) {
      const currentDataType = form.getFieldValue('literalDataType');
      let valueFormItem = (
        <Form.Item name="literalValue" label="Value" rules={[{required: true}]}>
          <Input />
        </Form.Item>
      );
      if (currentDataType === PrimitiveDataType.Date.name) {
        valueFormItem = (
          <Form.Item
            name="literalValueDate"
            label="Value"
            rules={[{required: true}]}
          >
            <DatePicker format={DateDataFormats} />
          </Form.Item>
        );
      } else if (currentDataType === PrimitiveDataType.Boolean.name) {
        valueFormItem = (
          <Form.Item
            name="literalValue"
            label="Value"
            rules={[{required: true}]}
          >
            <Select>
              <Select.Option value="true">TRUE</Select.Option>
              <Select.Option value="false">FALSE</Select.Option>
            </Select>
          </Form.Item>
        );
      }

      return (
        <>
          <Form.Item
            name="literalDataType"
            label="Data Type"
            rules={[{required: true}]}
          >
            <Select
              showSearch
              optionFilterProp="label"
              placeholder="select a DataType"
              options={primitiveTypeOptions}
            />
          </Form.Item>
          {valueFormItem}
        </>
      );
    }
    if (BindingType.Path.name === bindingType) {
      return (
        <Form.Item
          name="path"
          label=""
          labelCol={{span: 0}}
          wrapperCol={{span: 24}}
          rules={[
            {
              validator: validatePath,
            },
          ]}
        >
          <PathPickTree
            height={400}
            rootProperties={[...rootProperties].sort((a, b) =>
              a.displayName.localeCompare(b.displayName)
            )}
            allowedDataTypes={
              props.bindingDescriptor.allowedPrimitiveDataTypes?.map(
                v => v.name
              ) || []
            }
            selectedPath={form.getFieldValue('path') ?? props.defaultPath}
            setSelectedPath={value => {
              form.setFieldValue('path', value);
            }}
            resetVersion={props.resetVersion}
            hideFields={!props.bindingDescriptor.allowPrimitives()}
            onSelectPath={formSubmit}
          />
        </Form.Item>
      );
    }
    if (BindingType.VariableDeclaration.name === bindingType) {
      return (
        <>
          <Form.Item
            name="variableDeclarationName"
            label="Name"
            rules={[{required: true}]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="variableDeclarationDataType"
            label="Data Type"
            rules={[{required: true}]}
          >
            <Select
              showSearch
              optionFilterProp="label"
              placeholder="select a DataType"
              options={primitiveTypeOptions}
            />
          </Form.Item>
        </>
      );
    }
    if (BindingType.TableName.name === bindingType) {
      const tableNames = [...new Set(props.allTableNames.map(t => t.name))];
      const tableNameOptions = tableNames.map(tableName => ({
        label: tableName,
        value: tableName,
      }));
      return (
        <>
          <Form.Item name="tableName" label="Table" rules={[{required: true}]}>
            <Select
              showSearch
              optionFilterProp="label"
              placeholder="select a Table"
              options={tableNameOptions}
            />
          </Form.Item>
        </>
      );
    }
    if (BindingType.TableBinding.name === bindingType) {
      const binding = setupBindingForEdit(
        props.existingBindings,
        props.bindingDescriptor
      );
      return (
        <TableBindingControl
          tableBinding={binding.tableBinding ?? undefined}
          allTableNames={props.allTableNames}
          productService={props.productService}
          setTableBinding={value => {
            setTableBinding(value);
          }}
          resetVersion={props.resetVersion}
        />
      );
    }
    if (BindingType.DynamicVariable.name === bindingType) {
      const variables = props.evalContext?.functionContexts
        .asJsReadonlyMapView()
        .get(props.calculationFunction?.id ?? '')?.variables;

      const stepNames = (variables ?? [])
        .filter(f => f.type === VariableType.DynamicVariable.name)
        .map(f => ({
          label: f.displayName,
          value: f.name,
        }));
      const currentValue = form.getFieldValue('dynamicVariableFunctionId');
      // add a label for steps that cannot be referenced

      stepNames.sort((a, b) => stepNameComparator(a.label, b.label));

      if (currentValue && !stepNames.find(v => v.value === currentValue)) {
        stepNames.push({label: 'Invalid Step', value: currentValue});
      }

      return (
        <>
          <Form.Item
            name="dynamicVariableFunctionId"
            label="Step"
            rules={[{required: true}]}
          >
            <Select
              placeholder="select a step"
              options={stepNames}
              showSearch
              optionFilterProp="label"
            />
          </Form.Item>
        </>
      );
    }
    if (BindingType.TransitionDefinition.name === bindingType) {
      return (
        <>
          <Form.Item
            name="transitionName"
            label="Name"
            rules={[{required: true}]}
          >
            <Input />
          </Form.Item>
        </>
      );
    }
    if (BindingType.ConfigurationValue.name === bindingType) {
      const configOptions =
        props.bindingDescriptor.getAllowedConfigurationValues()?.map(v => ({
          label: v.displayName,
          value: v.name,
        })) ?? [];
      return (
        <>
          <Form.Item
            name="configurationValue"
            label="Value"
            rules={[{required: true}]}
          >
            <Select options={configOptions} />
          </Form.Item>
        </>
      );
    }
    if (BindingType.None.name === bindingType) {
      return <div></div>;
    }
  }

  // only show the dropdown if there are multiple possible options
  // we need to hide the dropdown instead of just leaving it off the control tree, because any forms
  // will only include fields in getFieldsValue that are currently in the DOM
  const bindingTypeDropdown = (
    <Form.Item
      name="bindingType"
      label="Binding Type"
      rules={[{required: true}]}
      hidden={props.bindingDescriptor.allowedBindingTypes.length <= 1}
    >
      <Select
        placeholder="Binding Type"
        options={allowedBindingTypeOptions}
      ></Select>
    </Form.Item>
  );

  function formSubmit() {
    form.submit();
  }

  return (
    <DraggableModal
      className="adaptify-modal"
      width={'clamp(300px, 70svw, 1200px)'}
      open={true}
      title={props.bindingDescriptor?.displayName + ' Property'}
      onCancel={props.onCancel}
      cancelButtonProps={{...ButtonSettings}}
      okButtonProps={{...ButtonSettings, ghost: false, type: 'default'}}
      onOk={formSubmit}
    >
      <Form
        name={formName}
        labelCol={{span: 24}}
        wrapperCol={{span: 24}}
        layout="vertical"
        size="large"
        style={{width: '100%'}}
        autoComplete="off"
        onValuesChange={onBindingValuesChange}
        form={form}
        onFinish={onFinishBindingForm}
        validateTrigger="onSubmit"
      >
        {bindingTypeDropdown}
        {createBindingControl()}
      </Form>
    </DraggableModal>
  );
}
