import {Button, Flex, Form, Input} from 'antd';
import useModal from 'antd/es/modal/useModal';
import Select, {BaseOptionType} from 'antd/es/select';
import React, {useEffect} from 'react';
import {ButtonSettings} from '../../common/control/Common';
import {User, UserRoles} from '../model/User';
import {UserService} from '../service/UserService';

interface CreateUserFormProps {
  userService: UserService;
  tenantId: string;
  userId?: string;
  onFinish: (user: User) => void;
  onCancel: () => void;
}

interface CreateUserFormArgs {
  fullName: string;
  email: string;
  jobTitle: string;
  role: string;
  disabled: boolean;
  status: string;
}

export const RoleOptions: BaseOptionType[] = [
  {label: UserRoles.User, value: UserRoles.User},
  {label: UserRoles.Admin, value: UserRoles.Admin},
];

export function CreateUserForm(props: CreateUserFormProps) {
  const [form] = Form.useForm<CreateUserFormArgs>();

  const [user, setUser] = React.useState<User | undefined>(undefined);

  const [errorModal, errorModalControl] = useModal();

  useEffect(() => {
    if (!props.userId) {
      setUser(undefined);
      form.setFieldsValue({
        status: 'Active',
      });
      return;
    }

    const eff = async () => {
      const user = await props.userService.GetUser(props.userId ?? '');
      setUser(user);
      form.setFieldsValue({
        fullName: user.fullName,
        email: user.email,
        jobTitle: user.title,
        role: user.role,
        disabled: user?.disabled,
        status: user?.disabled ? 'Inactive' : 'Active',
      });
    };
    eff();
  }, [props.userId]);

  async function onSave(value: CreateUserFormArgs) {
    try {
      if (user) {
        // update existing user
        const updatedUser = await props.userService.UpdateUser({
          ...user,
          ...value,
        });
        props.onFinish(updatedUser);
      } else {
        // no existing user, so create a new one
        const user = await props.userService.CreateUser({
          tenantId: props.tenantId,
          ...value,
          disabled: false,
        });
        props.onFinish(user);
      }
    } catch (e) {
      errorModal.error({
        title: 'Failed to save user',
        content: e.details?.message ?? e.message,
        okButtonProps: {...ButtonSettings},
      });
    }
  }

  return (
    <>
      <Form
        name="edit_user"
        labelCol={{span: 8}}
        wrapperCol={{span: 16}}
        style={{maxWidth: 800}}
        size="large"
        onFinish={onSave}
        autoComplete="off"
        form={form}
      >
        <Form.Item name="fullName" label="Name" rules={[{required: true}]}>
          <Input />
        </Form.Item>
        <Form.Item
          name="email"
          label="Username"
          rules={[{required: true, type: 'email'}]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="jobTitle" label="Title" rules={[{required: true}]}>
          <Input />
        </Form.Item>
        <Form.Item name="role" label="Role" rules={[{required: true}]}>
          <Select options={RoleOptions} />
        </Form.Item>
        <Form.Item name="status" label="Status" rules={[{required: true}]}>
          <Input className="adaptify-read-only-field" disabled={true} />
        </Form.Item>
        <Flex justify="end" style={{width: '100%'}} className="gap-2">
          <Button
            {...ButtonSettings}
            htmlType="button"
            onClick={props.onCancel}
          >
            Cancel
          </Button>
          <Button ghost={false} type="default" htmlType="submit">
            Save
          </Button>
        </Flex>
      </Form>
      {errorModalControl}
    </>
  );
}
