import React from 'react';
import {ProductService} from '../../product/service/ProductService';

import {useParams} from 'react-router-dom';
import {AnalysisService} from '../service/AnalysisService';
import {TableComparisonDetailControl} from './TableComparisonDetailControl';

export interface TableComparisonDetailPageProps {
  analysisService: AnalysisService;
  productService: ProductService;
}

export function TableComparisonDetailPage(
  props: TableComparisonDetailPageProps
) {
  const {tableComparisonId} = useParams<string>();
  if (typeof tableComparisonId !== 'string') {
    return <></>;
  }

  return (
    <TableComparisonDetailControl
      analysisService={props.analysisService}
      productService={props.productService}
      studyTableComparisonId={tableComparisonId}
    />
  );
}
