import {Button, Flex, Form, Input} from 'antd';
import React, {useEffect} from 'react';
import {ButtonSettings} from '../../../common/control/Common';

export interface CreateTableFormProps {
  onCreateTable: (name: string) => void;
  onCancel: () => void;
  modifyCount: number;
}

interface CreateFormValues {
  name: string;
}

export function CreateTableForm(props: CreateTableFormProps) {
  const [form] = Form.useForm<CreateFormValues>();

  useEffect(() => {
    form.resetFields();
  }, [props.modifyCount]);

  function onSave(value: CreateFormValues) {
    props.onCreateTable(form.getFieldsValue().name);
  }

  return (
    <Form
      name="edit_column"
      layout="vertical"
      size="large"
      labelCol={{span: 24}}
      wrapperCol={{span: 24}}
      style={{width: '100%'}}
      onFinish={onSave}
      onFinishFailed={props.onCancel}
      autoComplete="off"
      form={form}
    >
      <Form.Item name="name" label="Name" rules={[{required: true}]}>
        <Input />
      </Form.Item>
      <Flex justify="end" style={{width: '100%'}} className="gap-2">
        <Button {...ButtonSettings} htmlType="button" onClick={props.onCancel}>
          Cancel
        </Button>
        <Button ghost={false} type="default" htmlType="submit">
          Save
        </Button>
      </Flex>
    </Form>
  );
}
