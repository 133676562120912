import {Button, Col, Flex, Form, Input, Row, Select} from 'antd';
import {BaseOptionType} from 'antd/es/cascader';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {ButtonSettings} from '../../common/control/Common';
import {CreateTrialAccountRequest} from '../model/TrialAccount';
import {TrialAccountService} from '../service/TrialAccountService';

export interface CreateTrialAccountFormProps {
  trialAccountService: TrialAccountService;
  onFinish?: () => void;
  onCancel?: () => void;
}

const moduleOptions: BaseOptionType[] = [
  {label: 'Comparative Analysis', value: 'Comparative Analysis'},
  {label: 'Book of Business Analysis', value: 'Book of Business Analysis'},
  {label: 'What-If Analysis', value: 'What-If Analysis'},
  {label: 'Rate Program Development', value: 'Rate Program Development'},
  {label: 'Rating-as-a-Service', value: 'Rating-as-a-Service'},
];

export function CreateTrialAccountForm(props: CreateTrialAccountFormProps) {
  const [form] = Form.useForm<CreateTrialAccountRequest>();

  const navigate = useNavigate();

  async function onCreate(values: CreateTrialAccountRequest) {
    await props.trialAccountService.CreateTrialAccount(values);
    if (props.onFinish) {
      props.onFinish();
    }
  }

  return (
    <>
      <Flex vertical>
        <Row>
          <Col span={24}>
            <div className="page-title">Trial Account Details</div>
          </Col>
        </Row>
        <Form
          name="trial_account_detail"
          labelCol={{span: 8}}
          labelWrap={true}
          wrapperCol={{span: 16}}
          style={{maxWidth: 600}}
          autoComplete="off"
          form={form}
          onFinish={onCreate}
        >
          <Form.Item
            name="accountName"
            label="Account Name"
            rules={[{required: true}]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="address" label="Company Address">
            <Input />
          </Form.Item>
          <Form.Item
            name="primaryContactName"
            label="Primary Contact Name"
            rules={[{required: true}]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="primaryContactJobTitle"
            label="Primary Contact Job Title"
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="primaryContactEmail"
            label="Primary Contact Email Address"
            rules={[{required: true}]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="primaryContactPhoneNumber"
            label="Primary Contact Phone Number"
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="description"
            label="What are your current business needs?"
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item
            name="moduleList"
            label="What area of Adaptify's solution suite are you interested in?"
          >
            <Select mode="multiple" options={moduleOptions} />
          </Form.Item>
          <Flex style={{width: '100%'}} justify="end" className="gap-2">
            <Button
              {...ButtonSettings}
              htmlType="button"
              onClick={props.onCancel}
            >
              Cancel
            </Button>
            <Button ghost={false} type="default" htmlType="submit">
              Save
            </Button>
          </Flex>
        </Form>
      </Flex>
    </>
  );
}
