import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import {DataGrid, GridColDef, GridRowSelectionModel} from '@mui/x-data-grid';
import {Button, Col, Flex, Form, Input, Row, Select} from 'antd';
import {BaseOptionType} from 'antd/es/select';
import React, {useEffect} from 'react';
import {ButtonSettings, StateCodeOptions} from '../../common/control/Common';
import {LobHierarchyInfo} from '../../lob/model/LineOfBusiness';
import {ProductQuery, ProductVersionSummary} from '../../product/model/Product';
import {ProductService} from '../../product/service/ProductService';
import {Study} from '../model/ComparativeAnalysis';
import {AnalysisService} from '../service/AnalysisService';

import {DataGridSettings} from '../../common/control/Common';
import {NewTenantAwareEntity} from '../../common/model/Common';
import {DateIsoStringToDisplayString} from '../../common/model/CommonUtils';
import {LobService} from '../../lob/service/LobService';
import {IdAndValue} from '../../valuelist/model/ValueList';
import {ValueListService} from '../../valuelist/service/ValueListService';

export interface EditStudyFormProps {
  analysisService: AnalysisService;
  productService: ProductService;
  lobService: LobService;
  valueListService: ValueListService;
  studyId: string | undefined;
  onSave: (study: Study) => void;
  onCancel: () => void;
}

interface EditStudyFormValues {
  name: string;
  insuranceTypeValueListItemId: string;
  lobNameValueListItemId: string;
  stateCode: string;
  productTypeValueListItemId: string;
  comments: string;
  carrierId: string;
  carrierName: string;
  underwritingCompanyId: string;
  underwritingCompanyName: string;
}

interface EditStudyFormProductVersionValues {
  productVersionId: string;
  carrierName: string;
  underwritingCompany: string;
  productType: string;
  stateCode: string;
  effectiveDate: string;
  versionNumber: number;
  source: string;
  selected: boolean;
}

interface PreviousQueryContext {
  lobNameValueListItemId: string;
  productTypeValueListItemId: string | undefined;
  stateCode: string;
}

export function EditStudyForm(props: EditStudyFormProps) {
  const [form] = Form.useForm<EditStudyFormValues>();
  const [study, setStudy] = React.useState<Study | undefined>(undefined);
  const [lobs, setLobs] = React.useState<LobHierarchyInfo[]>([]);
  const [productTypes, setProductTypes] = React.useState<IdAndValue[]>([]);
  const [carrierValueItems, setCarrierValueItems] = React.useState<
    IdAndValue[]
  >([]);
  const [underwritingCompanyValueItems, setUnderwritingCompanyValueItems] =
    React.useState<IdAndValue[]>([]);
  const [internalRefreshNum, setInternalRefreshNum] = React.useState(0);

  // the requirements are to show an error message if there are selections and the user tries to change
  // the LOB or state code in the search
  const [previousQueryContext, setPreviousQueryContext] = React.useState<
    PreviousQueryContext | undefined
  >(undefined);

  const [productVersionFormValues, setProductVersionFormValues] =
    React.useState<EditStudyFormProductVersionValues[]>([]);

  const [allProductVersions, setAllProductVersions] = React.useState<
    ProductVersionSummary[]
  >([]);

  const [
    currentSelectedProductVersionIds,
    setCurrentSelectedProductVersionIds,
  ] = React.useState<string[]>([]);
  const [
    originalSelectedProductVersionIds,
    setOriginalSelectedProductVersionIds,
  ] = React.useState<string[]>([]);
  const [queryValidationMessage, setQueryValidationMessage] = React.useState<
    string | undefined
  >(undefined);

  useEffect(() => {
    const eff = async () => {
      const configured = await props.lobService.GetConfiguredLobDefInfo();
      setLobs(configured);
    };
    eff();
    form.resetFields();
  }, []);

  useEffect(() => {
    form.resetFields();
    if (props.studyId === undefined) {
      setStudy(undefined);
      setOriginalSelectedProductVersionIds([]);
      setCurrentSelectedProductVersionIds([]);
      return;
    }

    const eff = async () => {
      const studyPromise = props.analysisService.GetStudy(props.studyId ?? '');
      const selectedProductVersionsPromise =
        props.analysisService.GetStudyProductVersionsByStudyId(
          props.studyId ?? ''
        );

      const [study, productVersions] = await Promise.all([
        studyPromise,
        selectedProductVersionsPromise,
      ]);

      setStudy(study);

      // lookup the insurance type id from the lob name since it's normalized
      if (study.lobNameValueListItemId && study.lobNameValueListItemId !== '') {
        const lobValueListItem = await props.valueListService.GetValueListItem(
          study.lobNameValueListItemId
        );
        const insuranceTypeId = lobValueListItem?.parentValueListItemId;
        form.setFieldsValue({
          insuranceTypeValueListItemId: insuranceTypeId,
        });
      }

      const selectedIds = productVersions.map(v => v.productVersionId);
      setCurrentSelectedProductVersionIds(selectedIds);
      setOriginalSelectedProductVersionIds(selectedIds);

      form.setFieldsValue({
        ...form.getFieldsValue(),
        name: study.name,
        lobNameValueListItemId: study.lobNameValueListItemId,
        stateCode: study.stateCode,
        productTypeValueListItemId: study.productTypeValueItemId,
        comments: study.comments,
      });
    };
    eff();
  }, [props.studyId]);

  useEffect(() => {
    const productVersionFormValues: EditStudyFormProductVersionValues[] =
      allProductVersions.map(
        v =>
          ({
            carrierName: v.carrierName,
            underwritingCompany: v.underwritingCompany,
            stateCode: v.stateCode,
            productVersionId: v.id,
            productType: v.productType,
            effectiveDate: v.effectiveDate,
            versionNumber: v.versionNumber,
            source: v.source,
            selected: currentSelectedProductVersionIds.some(id => id === v.id),
          }) as EditStudyFormProductVersionValues
      );
    setProductVersionFormValues(productVersionFormValues);
  }, [allProductVersions, currentSelectedProductVersionIds]);

  useEffect(() => {
    const eff = async () => {
      const formValues = form.getFieldsValue();
      if (
        !formValues.lobNameValueListItemId ||
        formValues.lobNameValueListItemId === ''
      ) {
        setProductTypes([]);
        return;
      }
      const productTypes = await props.valueListService.GetProductTypes(
        formValues.lobNameValueListItemId
      );
      setProductTypes(productTypes);
    };
    eff();
  }, [form.getFieldsValue().lobNameValueListItemId]);

  useEffect(() => {
    const eff = async () => {
      const loaded = await props.valueListService.GetCarriers();
      setCarrierValueItems(loaded);
    };
    eff();
  }, []);

  useEffect(() => {
    const eff = async () => {
      const carrierValueListId = form.getFieldsValue().carrierId;
      if (!carrierValueListId || carrierValueListId === '') {
        setUnderwritingCompanyValueItems([]);
        return;
      }
      // we need to know whether the lob requires the form to be set, so we need to load it here
      const loaded = await props.valueListService.GetUnderwritingCompanies(
        form.getFieldsValue().carrierId
      );
      setUnderwritingCompanyValueItems(loaded);
    };
    eff();
  }, [form.getFieldsValue().carrierId]);

  function getLobCategoryOptions(): BaseOptionType[] {
    // get the unique categories
    const insuranceTypeIds = new Set();
    return lobs
      .map(v => {
        if (insuranceTypeIds.has(v.insuranceTypeValueListItemId)) {
          return;
        }
        insuranceTypeIds.add(v.insuranceTypeValueListItemId);
        return {
          label: v.insuranceType,
          value: v.insuranceTypeValueListItemId,
        };
      })
      .filter(v => v !== undefined) as BaseOptionType[];
  }

  function getLobNameOptions(): BaseOptionType[] {
    const insuranceTypeValueListItemId =
      form.getFieldsValue().insuranceTypeValueListItemId;
    if (insuranceTypeValueListItemId === '') {
      return [];
    }
    const lobNameIds = new Set();
    return lobs
      .filter(
        v => v.insuranceTypeValueListItemId === insuranceTypeValueListItemId
      )
      .map(v => {
        if (lobNameIds.has(v.lobNameValueListItemId)) {
          return;
        }
        lobNameIds.add(v.lobNameValueListItemId);
        return {
          label: v.lobName,
          value: v.lobNameValueListItemId,
        };
      })
      .filter(v => v !== undefined) as BaseOptionType[];
  }

  function getPolicyFormOptions(): BaseOptionType[] | undefined {
    if (productTypes.length === 0) {
      return undefined;
    }
    return productTypes.map(v => ({
      label: v.value,
      value: v.id,
    }));
  }

  function getCarrierNameOptions(): BaseOptionType[] {
    return carrierValueItems.map(v => ({
      label: v.value,
      value: v.id,
    }));
  }

  function getUnderwritingCompanyOptions(): BaseOptionType[] {
    return underwritingCompanyValueItems.map(v => ({
      label: v.value,
      value: v.id,
    }));
  }

  function onFormValuesChange(
    changedValues: EditStudyFormValues,
    allValues: EditStudyFormValues
  ) {
    if (changedValues.insuranceTypeValueListItemId) {
      form.setFieldsValue({
        ...allValues,
        lobNameValueListItemId: undefined,
        productTypeValueListItemId: undefined,
      });
      setInternalRefreshNum(internalRefreshNum + 1);
    }
    if (changedValues.lobNameValueListItemId) {
      form.setFieldsValue({
        ...allValues,
        productTypeValueListItemId: undefined,
      });
      setInternalRefreshNum(internalRefreshNum + 1);
    }
    if (changedValues.stateCode || changedValues.productTypeValueListItemId) {
      setInternalRefreshNum(internalRefreshNum + 1);
    }
    // clear the underwrtiting company if the carrier changes
    if (changedValues.carrierId) {
      form.setFieldsValue({
        ...allValues,
        underwritingCompanyId: '',
      });
      setInternalRefreshNum(internalRefreshNum + 1);
    }
  }

  function onProductSelectionChange(selection: GridRowSelectionModel) {
    // this is unforunately unnecessarily complicated due to the requirements
    // as I might search for carrier and UW company, select something, and then
    // search using a different criteria, retaining the old selection even though it doesn't show on the list

    // remove anything from selection if it's displayed in the list and not selected as we can assume the user deselected it
    const selectedIds = selection as string[];
    const displayedIds = productVersionFormValues.map(v => v.productVersionId);
    const selectionAfterRemoval = currentSelectedProductVersionIds.filter(
      id => {
        const displayed = productVersionFormValues.find(
          v => v.productVersionId === id
        );
        if (!displayed) {
          // if we aren't showing this on the UI currently assume we should retain it's original selection
          return true;
        }
        // otherwise get the selected state from the UI
        return selection.includes(id);
      }
    );

    // add is now the union of the previous selection and displayed items
    const selectionAfterAdd = [
      ...new Set([...selectionAfterRemoval, ...selectedIds]),
    ];
    setCurrentSelectedProductVersionIds(selectionAfterAdd);

    const newValues = productVersionFormValues.map(v => {
      const copy = structuredClone(v);
      copy.selected = selection.includes(v.productVersionId);
      return copy;
    });
    setProductVersionFormValues(newValues);
  }

  async function onFinishForm(values: EditStudyFormValues) {
    const newStudy: Study = {
      ...NewTenantAwareEntity(),
      ...(study ?? {}),
      name: values.name,
      lobNameValueListItemId: values.lobNameValueListItemId,
      stateCode: values.stateCode,
      productTypeValueItemId: values.productTypeValueListItemId,
      comments: values.comments,
    };

    const updated = await props.analysisService.UpdateStudyMergeProductVersion(
      newStudy,
      currentSelectedProductVersionIds
    );
    props.onSave(updated);
  }

  async function queryProuducts() {
    const values = form.getFieldsValue();
    if (!canQueryProducts) {
      return;
    }

    // the requirements here are to show an error message if the user tries to search after changing the LOB or state code
    // if they have selected anything.  It's not the most intuitive way to handle this from a code perspective
    if (previousQueryContext && currentSelectedProductVersionIds.length > 0) {
      if (
        values.lobNameValueListItemId !==
          previousQueryContext.lobNameValueListItemId ||
        values.stateCode !== previousQueryContext.stateCode ||
        values.productTypeValueListItemId !==
          previousQueryContext.productTypeValueListItemId
      ) {
        setQueryValidationMessage(
          'You can only compare rate products with the same Insurance Type/Line of Business/State/Product Type combination. Please unselect the rate products to enter new search criteria.'
        );
        return;
      }
    }

    const productQuery: ProductQuery = {
      insuranceTypeId: values.insuranceTypeValueListItemId,
      lineOfBusinessNameId: values.lobNameValueListItemId,
      productTypeId: values.productTypeValueListItemId,
      carrierId: values.carrierId,
      underwritingCompanyId: values.underwritingCompanyId,
      stateCode: values.stateCode,
    };

    const loaded =
      await props.productService.QueryProductVersionSummaries(productQuery);

    setAllProductVersions(loaded);
    setPreviousQueryContext({
      lobNameValueListItemId: values.lobNameValueListItemId,
      stateCode: values.stateCode,
      productTypeValueListItemId: values.productTypeValueListItemId,
    });
    setQueryValidationMessage(undefined);
  }

  function canQueryProducts() {
    const values = form.getFieldsValue();

    if (
      !values ||
      !values.insuranceTypeValueListItemId ||
      !values.lobNameValueListItemId ||
      !values.stateCode
    ) {
      return false;
    }
    // if product type is defined for the LOB it must be selected
    if (productTypes.length > 0 && !values.productTypeValueListItemId) {
      return false;
    }
    return true;
  }

  const lobReadOnly = props.studyId !== undefined;

  const policyFormOptions = getPolicyFormOptions();

  const policyFormItem = policyFormOptions ? (
    <Form.Item
      name="productTypeValueListItemId"
      label="Product Type"
      rules={[{required: true}]}
    >
      <Select
        showSearch
        optionFilterProp="label"
        placeholder="select a Line of Business"
        options={policyFormOptions}
        disabled={lobReadOnly}
        className={lobReadOnly ? 'adaptify-read-only-field' : ''}
      />
    </Form.Item>
  ) : (
    <></>
  );

  const colDefs: GridColDef<EditStudyFormProductVersionValues>[] = [
    {
      field: 'carrierName',
      headerName: 'Carrier Name',
      flex: 200,
    },
    {
      field: 'underwritingCompany',
      headerName: 'Underwriting Company',
      flex: 200,
    },
    {
      field: 'stateCode',
      headerName: 'State',
      flex: 200,
    },
    {
      field: 'policyForm',
      headerName: 'Policy Form',
      flex: 200,
    },
    {
      field: 'effectiveDate',
      headerName: 'Effective Date',
      flex: 200,
      valueGetter: (params, data) => {
        return DateIsoStringToDisplayString(data.effectiveDate);
      },
    },
    {
      field: 'source',
      headerName: 'Version Source',
      flex: 200,
    },
  ];

  const queryValidationControl = queryValidationMessage ? (
    <Col span={24}>
      <span
        style={{fontSize: 'larger', color: 'red'}}
      >{`** ${queryValidationMessage} **`}</span>
    </Col>
  ) : (
    <></>
  );

  return (
    <Flex vertical style={{width: '100%'}}>
      {queryValidationControl}
      <Form
        name="edit_study"
        layout="vertical"
        size="large"
        labelCol={{span: 24}}
        wrapperCol={{span: 24}}
        labelWrap={true}
        style={{width: '100%'}}
        onValuesChange={onFormValuesChange}
        onFinish={onFinishForm}
        autoComplete="off"
        form={form}
      >
        <Row gutter={[20, 0]}>
          <Col span={12}>
            <Form.Item
              name="name"
              label="Study Name"
              rules={[{required: true}]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}></Col>
          <Col span={12}>
            <Form.Item
              name="comments"
              label="Description"
              rules={[{required: true}]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}></Col>
          <Col span={24}>
            <div className="page-title">
              Select Rate Products for your Carrier Study
            </div>
          </Col>
          <Col span={12}>
            <Form.Item
              name="carrierId"
              label="Carrier Name"
              labelCol={{span: 4}}
              wrapperCol={{span: 20}}
            >
              <Select
                showSearch
                allowClear
                optionFilterProp="label"
                options={getCarrierNameOptions()}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              labelCol={{span: 4}}
              wrapperCol={{span: 20}}
              name="underwritingCompanyId"
              label="Underwriting Company"
            >
              <Select
                showSearch
                allowClear
                optionFilterProp="label"
                options={getUnderwritingCompanyOptions()}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="insuranceTypeValueListItemId"
              label="Insurance Type"
              rules={[{required: true}]}
            >
              <Select
                showSearch
                optionFilterProp="label"
                options={getLobCategoryOptions()}
                disabled={lobReadOnly}
                className={lobReadOnly ? 'adaptify-read-only-field' : ''}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="lobNameValueListItemId"
              label="Line of Business"
              rules={[{required: true}]}
            >
              <Select
                showSearch
                optionFilterProp="label"
                options={getLobNameOptions()}
                disabled={lobReadOnly}
                className={lobReadOnly ? 'adaptify-read-only-field' : ''}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="stateCode"
              label="State"
              rules={[{required: true}]}
            >
              <Select
                showSearch
                optionFilterProp="label"
                options={StateCodeOptions}
                disabled={lobReadOnly}
                className={lobReadOnly ? 'adaptify-read-only-field' : ''}
              />
            </Form.Item>
          </Col>
          <Col span={6}>{policyFormItem}</Col>
        </Row>
        <Row gutter={[0, 10]}>
          <Col span={24}>
            <Flex justify="center" style={{width: '100%'}}>
              <Button
                {...ButtonSettings}
                icon={<SearchOutlinedIcon className="-m-1" />}
                type="primary"
                disabled={!canQueryProducts()}
                onClick={queryProuducts}
              >
                Search
              </Button>
            </Flex>
          </Col>
          <Col span={24}>
            <div style={{height: '100%', width: '100%'}}>
              <DataGrid
                {...DataGridSettings}
                columns={colDefs}
                getRowId={row => row.productVersionId}
                autoHeight
                pageSizeOptions={[5]}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 5,
                    },
                  },
                }}
                filterMode="client"
                checkboxSelection
                rowSelectionModel={productVersionFormValues
                  .filter(v => v.selected)
                  .map(v => v.productVersionId)}
                onRowSelectionModelChange={onProductSelectionChange}
                rows={productVersionFormValues}
                isRowSelectable={params => {
                  // we can't change the selection on edit to remove things
                  return !originalSelectedProductVersionIds.includes(
                    params.id as string
                  );
                }}
                sx={{
                  '&.MuiDataGrid-root': {
                    borderRadius: '8px',
                    overflow: 'hidden',
                    borderColor: '#CCCCCC',
                  },
                }}
              />
            </div>
          </Col>
          <Row gutter={[20, 10]} style={{width: '100%'}}>
            <Col span={24}>
              <Flex justify="end" className="gap-2" style={{width: '100%'}}>
                <Button
                  {...ButtonSettings}
                  htmlType="button"
                  onClick={props.onCancel}
                >
                  Cancel
                </Button>
                <Button
                  htmlType="submit"
                  disabled={currentSelectedProductVersionIds.length < 2}
                  ghost={false}
                  type="default"
                >
                  Choose Table Comparisons
                </Button>
              </Flex>
            </Col>
          </Row>
        </Row>
      </Form>
    </Flex>
  );
}
