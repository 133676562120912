package com.adaptify.rating.admin.model.calculation.context

import com.adaptify.rating.admin.model.calculation.BindingType
import com.adaptify.rating.admin.model.calculation.Calculation
import com.adaptify.rating.admin.model.calculation.CalculationFunction
import com.adaptify.rating.admin.model.calculation.descriptor.BoundDataType
import com.adaptify.rating.admin.model.calculation.descriptor.BoundDataTypeImpl
import com.adaptify.rating.admin.model.calculation.descriptor.FunctionDescriptorMap
import com.adaptify.rating.admin.model.calculation.env.MetadataProvider
import com.adaptify.rating.admin.model.calculation.util.GlobalVariable
import com.adaptify.rating.admin.model.lob.LineOfBusinessHierarchy
import com.adaptify.rating.admin.model.type.PrimitiveDataType
import kotlin.js.JsExport
import com.adaptify.rating.admin.model.util.BindingResolver
import kotlinx.coroutines.GlobalScope

const val DynamicVariableDisplayPrefix = "Step ";

@JsExport
enum class VariableType {
  Input,
  Variable,
  DynamicVariable,
}

// a scoped variable can be a final data type, or pointing to a risk or coverage

@JsExport
class ScopedVariable(val type : String,
                     val name: String,
                     val displayName : String,
                     val functionId: String?, // if the variable is defined in a function fill it in here
                     // either LOB item id or data type (PrimitiveDataType) will be set
                     val boundDataType: BoundDataType)

@JsExport
class FunctionEvalContext(val stepName: String, val variables: Array<ScopedVariable>)

@JsExport
class CalculationEvalContext(val functionContexts: Map<String, FunctionEvalContext>) {
  @JsExport.Ignore
  companion object {
    @JsExport.Ignore
    suspend fun Create(calculation: Calculation,
               lobDef: LineOfBusinessHierarchy,
               metadataProvider: MetadataProvider,
               globalVariables: Array<ScopedVariable> = emptyArray()
    ): CalculationEvalContext {
      val map = mutableMapOf<String, FunctionEvalContext>()
      // add the calculation inputs to the context then process the functions

      val rootVariables = BindingResolver.getRootVariablesForLineOfBusinessItem(
        calculation.lineOfBusinessItemId, lobDef);

      var myGlobals = mutableListOf<ScopedVariable>();

      // collect globals as a first pass as they can be used before their declaration
      // per product requirements
      collectGlobals(calculation.functions ?: emptyArray(), myGlobals);

      val allVars = globalVariables.asList() + rootVariables + myGlobals;

      createInternal(lobDef, calculation.functions ?: emptyArray(),
        metadataProvider, listOf(allVars), myGlobals, DynamicVariableDisplayPrefix, map)

      return CalculationEvalContext(map)
    }

    private fun collectGlobals( calculationFunctions: Array<CalculationFunction>,
                                globals: MutableList<ScopedVariable>) {
      // global Scope are shared across all functions, so you can access them even prior to their declaration
      // product wants any variable that is named other than child scoped (like the variable in a for each loop
      // if we had them) to be global scoped
      // dynamic variables are locally scoped
      // this global scope can lead to more incorrect definitions, but it's implemented as is to meets
      // product requirements
      for (f in calculationFunctions) {
        for (binding in f.bindings ?: emptyArray()) {
          if (binding.bindingType == BindingType.VariableDeclaration.name) {
            val varDec = binding.variableDeclaration
            if (varDec != null) {
              val newVar = ScopedVariable(
                VariableType.Variable.name,
                varDec.name ?: "",
                varDec.name ?: "",
                f.id,
                BoundDataTypeImpl.Primitive(PrimitiveDataType.valueOf(varDec.dataType), false)
              );
              globals.add(newVar);
              /*
              // if product ever wants to bring back for each iterator variables, this
              // is where we'd want to filter them out of the globals list
              if (!bindingDesc.scopedToChildOnly) {
                globals.add(newVar);
              }

               */
            }
          }
          var childFunctions: Array<CalculationFunction>? = null
          if (binding.bindingType == BindingType.Block.name) {
            childFunctions = binding.block
          } else if (binding.bindingType == BindingType.Predicate.name) {
            childFunctions = binding.predicate?.functions
          }
          if (childFunctions != null) {
            collectGlobals(childFunctions, globals)
          }
        }
      }
    }

    @JsExport.Ignore
    private suspend fun createInternal(lobDef: LineOfBusinessHierarchy,
                                       calculationFunctions: Array<CalculationFunction>,
                                       metadataProvider: MetadataProvider,
                                       stack : List<List<ScopedVariable>>,
                                       myGlobals : MutableList<ScopedVariable>,
                                       dynamicVariableDisplayPrefix: String,

                                       returnMap: MutableMap<String, FunctionEvalContext>) {
      val flattenedParents = stack.flatten();

      val scopedVariables = mutableListOf<ScopedVariable>();
      var currentStepNum = 1

      for (f in calculationFunctions) {
        // example: for loop, variable is defined on parent, but only scoped on children
        // we don't support variabled defined on parent but only scoped on one descriptor
        // of the children as there's no need yet
        val childOnlyScopedVariables = mutableListOf<ScopedVariable>();
        val functionStepName = dynamicVariableDisplayPrefix + currentStepNum.toString();
        currentStepNum++;
        // pick the character right before 'a' so we can append at the beginning
        // it makes the code a bit easier to write
        var subPrefixChar = 'a' - 1;

        // create a copy of the current scope for this function
        // compute this in bulk between we generally need this for all functions, and
        // it's much cheaper in aggregate to compute this once than individually per function
        // and trace backwards every time.
        val currentScope = flattenedParents.plus(scopedVariables).toTypedArray()
        returnMap.put(f.id, FunctionEvalContext(functionStepName, currentScope));

        val funcDef = FunctionDescriptorMap.get(f.name)
        if (funcDef != null) {
          val bindingDescs = funcDef.getBindingDescriptors()
          for (bindingDesc in bindingDescs) {
            val binding = f.bindings?.find { b ->
              b.name == bindingDesc.name
            }
            if (bindingDesc.allowedBindingTypes.find {
                it.name == BindingType.Block.name} != null) {
              // all blocks get a prefix allocated regardless of whether there is a current
              // binding or not
              subPrefixChar++;
            }
            if (binding == null) {
              continue;
            }
            if (binding.bindingType == BindingType.VariableDeclaration.name) {
              val varDec = binding.variableDeclaration
              if (varDec != null) {

                val newVar = ScopedVariable(
                  VariableType.Variable.name,
                  varDec.name ?: "",
                  varDec.name ?: "",
                  f.id,
                  BoundDataTypeImpl.Primitive(PrimitiveDataType.valueOf(varDec.dataType), false)
                );
                // see note on collectGlobals, only record global variables in context if we haven't
                // already recorded them, since the product requirement is to have global variables
                // accessible any time, even before their own declaration
                // if these are child scoped, ignore the global since we already collected it
                if (bindingDesc.scopedToChildOnly) {
                  childOnlyScopedVariables.add(newVar)
                }
              }
            }
            if (binding.bindingType == BindingType.DynamicVariableDeclaration.name
            ) {
              val boundType = bindingDesc.getExpectedDataType(
                f,
                funcDef,
                bindingDesc,
                binding,
                metadataProvider,
                currentScope
              )
              if (boundType != null) {
                var newVar =
                  ScopedVariable(
                    VariableType.DynamicVariable.name, f.id,
                    functionStepName, f.id, boundType)

                if (bindingDesc.scopedToChildOnly)
                  childOnlyScopedVariables.add(newVar) else
                  scopedVariables.add(newVar);
              }
            }

            var childFunctions: Array<CalculationFunction>? = null
            var stepPrefix = subPrefixChar.toString()
            if (binding.bindingType == BindingType.Block.name) {
              childFunctions = binding.block
            } else if (binding.bindingType == BindingType.Predicate.name) {
              childFunctions = binding.predicate?.functions
              // predicates don't have referencable steps but giving them a name
              // for consistency
              stepPrefix = "pred";
            }

            if (childFunctions != null) {
              createInternal(lobDef,
                childFunctions, metadataProvider,
                stack.plusElement(scopedVariables).plusElement(childOnlyScopedVariables),
                myGlobals,
                functionStepName + stepPrefix + "_", returnMap
              )
            }
          }
        }
      }
    }

  }
}

