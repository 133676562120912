import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import {DataGrid, GridColDef} from '@mui/x-data-grid';
import {Button, Col, Flex, Row} from 'antd';
import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {ButtonSettings, DataGridSettings} from '../../common/control/Common';
import {DraggableModal} from '../../common/control/DraggableModal';
import {LobService} from '../../lob/service/LobService';
import {ProductVersionSummary} from '../../product/model/Product';
import {FormatVersionNumberDisplayString} from '../../product/model/ProductUtils';
import {ProductService} from '../../product/service/ProductService';
import {ValueListService} from '../../valuelist/service/ValueListService';
import {Study} from '../model/ComparativeAnalysis';
import {AnalysisService} from '../service/AnalysisService';
import {EditStudyForm} from './EditStudyForm';

export interface StudyProductVersionListProps {
  analysisService: AnalysisService;
  productService: ProductService;
  lobService: LobService;
  valueListService: ValueListService;
  studyId: string;
  modifyCount: number;
  setModifyCount: (count: number) => void;
}

export function StudyProductVersionList(props: StudyProductVersionListProps) {
  const [productVersions, setProductVersions] = useState<
    ProductVersionSummary[]
  >([]);

  const [editStudyFormOpen, setEditStudyFormOpen] = useState<boolean>(false);

  useEffect(() => {
    const eff = async () => {
      if (!props.studyId || props.studyId === '') {
        setProductVersions([]);
        return;
      }
      const loaded =
        await props.analysisService.GetProductVersionSummariesByStudyId(
          props.studyId
        );
      setProductVersions(loaded);
    };
    eff();
  }, [props.modifyCount]);

  function onSaveEditStudy(study: Study) {
    setEditStudyFormOpen(false);
    props.setModifyCount(props.modifyCount + 1);
  }
  function onCancelEditStudy() {
    setEditStudyFormOpen(false);
  }

  const colDefs: GridColDef<ProductVersionSummary>[] = [
    {
      field: 'carrierName',
      headerName: 'Carrier Name',
      flex: 300,
    },
    {
      field: 'underwritingCompany',
      headerName: 'Underwiting Company',
      flex: 300,
    },
    {
      field: 'insuranceType',
      headerName: 'Insurance Type',
      flex: 200,
    },
    {
      field: 'lineOfBusinessName',
      headerName: 'Line of Business',
      flex: 300,
    },
    {
      field: 'stateCode',
      headerName: 'State',
      flex: 100,
    },
    {
      field: 'productType',
      headerName: 'Product Type',
      flex: 150,
    },
    {
      field: 'effectiveDate',
      headerName: 'Effective Date',
      flex: 200,
    },
    {
      field: 'versionNumber',
      headerName: 'Version Number',
      flex: 200,
      renderCell: params => {
        return (
          <Link to={`/rating/product/version/${params.row.id}/table`}>
            {FormatVersionNumberDisplayString(params.row.versionNumber)}
          </Link>
        );
      },
    },
  ];

  const editStudyModal = editStudyFormOpen ? (
    <DraggableModal
      className="adaptify-modal"
      title={'Add Carrier Study'}
      open={editStudyFormOpen}
      closable={false}
      okButtonProps={{style: {display: 'none'}}}
      cancelButtonProps={{style: {display: 'none'}}}
      width={'clamp(300px, 70svw, 1200px)'}
      footer={null}
    >
      <EditStudyForm
        analysisService={props.analysisService}
        productService={props.productService}
        lobService={props.lobService}
        valueListService={props.valueListService}
        studyId={props.studyId}
        onSave={onSaveEditStudy}
        onCancel={onCancelEditStudy}
      />
    </DraggableModal>
  ) : (
    <> </>
  );

  return (
    <>
      <Flex vertical>
        <Row>
          <Col span={18}>
            <div className="page-title">Carriers in Comparison Study</div>
          </Col>
          <Col span={6} style={{textAlign: 'right'}}>
            <Button
              {...ButtonSettings}
              icon={<AddOutlinedIcon className="-m-1" />}
              onClick={() => setEditStudyFormOpen(true)}
            >
              Add Carrier
            </Button>
          </Col>
          <Col span={24}>
            <DataGrid
              {...DataGridSettings}
              getRowId={r => r.id}
              columns={colDefs}
              filterMode="client"
              rows={productVersions}
              sx={{
                '&.MuiDataGrid-root': {
                  borderRadius: '8px',
                  overflow: 'hidden',
                  borderColor: '#CCCCCC',
                },
              }}
            />
          </Col>
        </Row>
      </Flex>
      {editStudyModal}
    </>
  );
}
