package com.adaptify.rating.admin.model.util

import com.adaptify.rating.admin.model.calculation.env.MetadataProvider
import com.adaptify.rating.admin.model.table.Table
import com.adaptify.rating.admin.model.table.TableDefinition
import kotlinx.coroutines.Deferred
import kotlinx.coroutines.asDeferred

class MetadataProviderConverter(val provider: MetadataProviderJs) : MetadataProvider {

  override fun getTableDefinitionById(name: String): Deferred<TableDefinition?> {
    return provider.getTableDefinitionById(name).asDeferred();
  }

}
