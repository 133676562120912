import {Button, Col, Flex, Form, Input, Row, Select} from 'antd';
import {BaseOptionType} from 'antd/es/cascader';
import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {ButtonSettings} from '../../common/control/Common';
import {TrialAccountDetails} from '../model/TrialAccount';
import {TrialAccountService} from '../service/TrialAccountService';

export interface TrialAccountDetailFormProps {
  trialAccountService: TrialAccountService;
  trialAccountId: string | undefined;
}

const moduleOptions: BaseOptionType[] = [
  {label: 'Comparative Analysis', value: 'Comparative Analysis'},
  {label: 'Book of Business Analysis', value: 'Book of Business Analysis'},
  {label: 'What-If Analysis', value: 'What-If Analysis'},
  {label: 'Rate Program Development', value: 'Rate Program Development'},
  {label: 'Rating-as-a-Service', value: 'Rating-as-a-Service'},
];

export function TrialAccountDetailForm(props: TrialAccountDetailFormProps) {
  const [trialAccount, setTrialAccount] = useState<
    TrialAccountDetails | undefined
  >(undefined);

  const [form] = Form.useForm<TrialAccountDetails>();

  useEffect(() => {
    if (!props.trialAccountId) {
      setTrialAccount(undefined);
      form.resetFields();
      return;
    }
    const eff = async () => {
      const loaded = await props.trialAccountService.GetTrialAccount(
        props.trialAccountId ?? ''
      );
      setTrialAccount(loaded);
      form.setFieldsValue({
        ...loaded,
      });
    };
    eff();
  }, [props.trialAccountId]);

  return (
    <>
      <Flex vertical>
        <Row>
          <Col span={18}>
            <div className="page-title">Trial Account Details</div>
          </Col>
          <Col span={6}>
            <Flex justify="end">
              <Link to={'/platform/trialaccount/list'}>
                <Button {...ButtonSettings}>Trial Accounts</Button>
              </Link>
            </Flex>
          </Col>
        </Row>
        <Form
          name="trial_account_detail"
          labelCol={{span: 8}}
          labelWrap={true}
          wrapperCol={{span: 16}}
          style={{maxWidth: 600}}
          autoComplete="off"
          form={form}
        >
          <Form.Item name="accountName" label="Account Name">
            <Input readOnly={true} className="adaptify-read-only-field" />
          </Form.Item>
          <Form.Item name="address" label="Company Address">
            <Input readOnly={true} className="adaptify-read-only-field" />
          </Form.Item>
          <Form.Item name="primaryContactName" label="Primary Contact Name">
            <Input readOnly={true} className="adaptify-read-only-field" />
          </Form.Item>
          <Form.Item
            name="primaryContactJobTitle"
            label="Primary Contact Job Title"
          >
            <Input readOnly={true} className="adaptify-read-only-field" />
          </Form.Item>
          <Form.Item
            name="primaryContactEmail"
            label="Primary Contact Email Address"
          >
            <Input readOnly={true} className="adaptify-read-only-field" />
          </Form.Item>
          <Form.Item
            name="primaryContactPhoneNumber"
            label="Primary Contact Phone Number"
          >
            <Input readOnly={true} className="adaptify-read-only-field" />
          </Form.Item>
          <Form.Item
            name="description"
            label="What are your current business needs?"
          >
            <Input.TextArea
              readOnly={true}
              className="adaptify-read-only-field"
            />
          </Form.Item>
          <Form.Item
            name="moduleList"
            label="What area of Adaptify's solution suite are you interested in?"
          >
            <Select
              mode="multiple"
              disabled={true}
              className="adaptify-read-only-field"
              options={moduleOptions}
            />
          </Form.Item>
        </Form>
      </Flex>
    </>
  );
}
