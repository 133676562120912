import {Button, Flex, Menu} from 'antd';
import {ItemType} from 'antd/es/menu/interface';
import React, {useEffect, useState} from 'react';
import {ADAPTIFY_TENANT_ID} from '../../common/service/Constants';
import {DirtyEditorConfirmationModal} from '../../rating/control/DirtyEditorConfirmationModal';
import {ValueListService} from '../../valuelist/service/ValueListService';
import {TenantDetailForm} from '../control/TenantDetailForm';
import {TenantHistoryList} from '../control/TenantHistoryList';
import {TenantModuleList} from '../control/TenantModuleList';
import {TenantSummaryForm} from '../control/TenantSummaryForm';
import {UserList} from '../control/UserList';
import {TenantInfo} from '../model/Tenant';
import {TenantService} from '../service/TenantService';
import {UserService} from '../service/UserService';

export interface TenentDetailControlProps {
  tenantService: TenantService;
  valueListService: ValueListService;
  userService: UserService;
  tenantId: string | undefined;
  userTenantId: string | undefined;
}

export interface TabChangeConfirm {
  open: boolean;
  tab: string;
}

export function TenantDetailControl(props: TenentDetailControlProps) {
  const [selectedTab, setSelectedTab] = useState<string | undefined>(
    'AccountData'
  );
  const [modifyCount, setModifyCount] = React.useState<number>(0);
  const [tenant, setTenant] = React.useState<TenantInfo | undefined>(undefined);
  const [dirty, setDirty] = React.useState<boolean>(false);
  const [confirmOpen, setConfirmOpen] = React.useState<TabChangeConfirm>({
    open: false,
    tab: '',
  });

  useEffect(() => {
    if (!props.tenantId) {
      return;
    }

    const eff = async () => {
      const loaded = await props.tenantService.GetTenantInfo(
        props.tenantId ?? ''
      );
      setTenant(loaded);
    };
    eff();
  }, [props.tenantId, modifyCount]);

  function handleTabChange(key: string) {
    setSelectedTab(key);
  }

  const isAdaptify = ADAPTIFY_TENANT_ID === props.userTenantId;

  function getSelectedDetailControl() {
    if (selectedTab === 'AccountData') {
      return (
        <TenantDetailForm
          tenantService={props.tenantService}
          valueListService={props.valueListService}
          tenantInfo={tenant}
          onSaved={t => {
            setModifyCount(modifyCount + 1);
          }}
          onCancel={() => {}}
          readOnly={!isAdaptify}
          setDirty={setDirty}
        />
      );
    }
    if (selectedTab === 'Module') {
      return (
        <TenantModuleList
          tenantService={props.tenantService}
          valueListService={props.valueListService}
          tenantId={props.tenantId}
          modifyCount={modifyCount}
          onSave={() => {
            setModifyCount(modifyCount + 1);
          }}
          readOnly={!isAdaptify}
        />
      );
    }
    if (selectedTab === 'Users') {
      return (
        <UserList userService={props.userService} tenantId={props.tenantId} />
      );
    }
    if (selectedTab === 'AccountHistory') {
      return (
        <TenantHistoryList
          tenantId={props.tenantId}
          tenantService={props.tenantService}
        />
      );
    }
    return <></>;
  }

  const menuStyle = {color: 'black'};
  const childLists = [
    {
      key: 'AccountData',
      label: (
        <Button
          type="link"
          style={menuStyle}
          onClick={() => {
            setConfirmOpen({open: true, tab: 'AccountData'});
          }}
        >
          Account Data
        </Button>
      ),
    },
    {
      key: 'Module',
      label: (
        <Button
          type="link"
          style={menuStyle}
          onClick={() => setConfirmOpen({open: true, tab: 'Module'})}
        >
          Module Access
        </Button>
      ),
    },
    {
      key: 'Users',
      label: (
        <Button
          type="link"
          style={menuStyle}
          onClick={() => setConfirmOpen({open: true, tab: 'Users'})}
        >
          Users
        </Button>
      ),
    },
    {
      key: 'AccountHistory',
      label: (
        <Button
          type="link"
          style={menuStyle}
          onClick={() => setConfirmOpen({open: true, tab: 'AccountHistory'})}
        >
          Account History
        </Button>
      ),
    },
  ] as ItemType[];

  const tabControl = (
    <Menu
      mode="horizontal"
      selectedKeys={selectedTab ? [selectedTab] : []}
      onSelect={e => {
        setConfirmOpen({
          open: true,
          tab: e.key as string,
        });
      }}
      style={{
        width: '100%',
      }}
      items={childLists}
    ></Menu>
  );

  const dirtyEditorConfirmation = confirmOpen.open ? (
    <DirtyEditorConfirmationModal
      open={confirmOpen.open}
      skipDialog={!dirty}
      onConfirm={async () => {
        handleTabChange(confirmOpen.tab);
        setDirty(false);
        setConfirmOpen({
          open: false,
          tab: '',
        });
      }}
      onCancel={() => {
        setConfirmOpen({
          open: false,
          tab: '',
        });
      }}
    />
  ) : (
    <></>
  );

  return (
    <>
      <Flex vertical gap={24}>
        <TenantSummaryForm
          tenantInfo={tenant}
          isDirty={dirty}
          setDirty={setDirty}
        />

        {tabControl}
        {getSelectedDetailControl()}
      </Flex>
      {dirtyEditorConfirmation}
    </>
  );
}
