import {
  EventSourceMessage,
  fetchEventSource,
} from '@microsoft/fetch-event-source';
import {SERVER_BASE_URL} from '../../common/service/Constants';
import {CreateStandardHeaders} from '../../common/service/Service';
import {
  Conversation,
  Message,
  SendMessageArgs,
  StreamHandler,
} from '../model/Chat';

const baseUrl = new URL(SERVER_BASE_URL);

const GET_CONVERSATIONS_URL = () => `${baseUrl}/chat/conversations`;
const GET_MESSAGES_URL = (conversationId: string) =>
  `${baseUrl}/chat/conversations/${conversationId}/messages`;
const CREATE_CONVERSATION_URL = () => `${baseUrl}/chat/conversations`;
const SEND_MESSAGE_URL = (conversationId: string) =>
  `${baseUrl}/chat/conversations/${conversationId}/messages`;

export interface ChatService {
  GetConversations(): Promise<Conversation[]>;
  GetMessagesForConversation(conversationId: string): Promise<Message[]>;
  SendMessage(
    args: SendMessageArgs,
    handler: StreamHandler,
    controller: AbortController
  ): Promise<void>;
}

export const NewChatService = (authToken: string): ChatService => {
  const createHeaders = () => {
    return CreateStandardHeaders(authToken);
  };
  const GetConversations = async (): Promise<Conversation[]> => {
    console.log('GetConversations');
    const errorMessage =
      'An error ocurred while fetching your conversation history';
    try {
      const response = await fetch(GET_CONVERSATIONS_URL(), {
        method: 'GET',
        headers: createHeaders(),
      });
      if (!response.ok) {
        throw new Error(errorMessage);
      }
      const result = (await response.json()) as Conversation[];
      return result.reverse();
    } catch (e) {
      throw new Error(errorMessage);
    }
  };
  const GetMessagesForConversation = async (
    conversationId: string
  ): Promise<Message[]> => {
    console.log('GetMessagesForConversation');
    const errorMessage =
      'An error ocurred while fetching messages for this conversation';
    try {
      const response = await fetch(GET_MESSAGES_URL(conversationId), {
        method: 'GET',
        headers: createHeaders(),
      });

      if (!response.ok) {
        throw new Error(errorMessage);
      }
      return await response.json();
    } catch (e) {
      throw new Error(errorMessage);
    }
  };
  const SendMessage = async (
    args: SendMessageArgs,
    handler: StreamHandler,
    controller: AbortController
  ): Promise<void> => {
    console.log('SendMessage');
    const url = args.conversationId
      ? SEND_MESSAGE_URL(args.conversationId)
      : CREATE_CONVERSATION_URL();
    return await fetchEventSource(url, {
      method: 'POST',
      headers: createHeaders(),
      body: JSON.stringify({
        message: args.message,
      }),
      signal: controller.signal,
      async onopen(response: Response) {
        if (!response.ok) {
          const errorMessage = 'An error ocurred while sending your message';
          handler.onError(new Error(errorMessage));
          throw new Error(errorMessage);
        }
      },
      onmessage(event: EventSourceMessage) {
        if (event.event && event.data) {
          handler.onMessage(event.event, JSON.parse(event.data));
        }
      },
      onclose() {
        handler.onClose();
        controller.abort();
      },
      onerror(err: Error) {
        handler.onError(err);
        throw err;
      },
    });
  };
  return {
    GetConversations,
    GetMessagesForConversation,
    SendMessage,
  };
};
