package com.adaptify.rating.admin.model.calculation.util

import com.adaptify.rating.admin.model.calculation.BindingType
import com.adaptify.rating.admin.model.calculation.CalculationFunction

object FunctionVisitor {
  fun visit(function: CalculationFunction, acceptor: FunctionAcceptor) {
    acceptor.accept(function)
    for (binding in function.bindings) {
      if (binding.bindingType == BindingType.Block.name && binding.block != null) {
        visit(binding.block!!, acceptor)
      }
      if (binding.bindingType == BindingType.Predicate.name && binding.predicate?.functions != null) {
        visit(binding.predicate!!.functions, acceptor)
      }
    }
  }

  fun visit(functions: Array<CalculationFunction>, acceptor: FunctionAcceptor) {
    for (function in functions) {
      visit(function, acceptor)
    }
  }

}

interface FunctionAcceptor {
  fun accept(function: CalculationFunction): Unit
}
