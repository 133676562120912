import {TenantAwareEntity} from '../../common/model/Common';

export type AuthToken = string;

export interface User extends TenantAwareEntity {
  fullName: string;
  email: string;
  password: string;
  role: string;
  disabled: boolean;
  title: string;
  changePasswordNeeded: boolean;
}

export interface UserQuery {
  tenantId?: string;
  fullName?: string;
  email?: string;
  role?: string;
}

export interface UserInfo {
  email: string;
  fullName: string;
  tenantId: string;
  changePasswordNeeded: boolean;
  authorities: string[];
}

export enum UserRoles {
  User = 'User',
  Admin = 'Admin',
}

export enum UserAuthorities {
  CanEditClientRating = 'Can Edit Client Rating',
}
