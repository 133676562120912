import {Button, Flex, Form, Input, Select} from 'antd';
import React, {useEffect} from 'react';

import {BaseOptionType} from 'antd/es/select';
import {ButtonSettings} from '../../common/control/Common';
import {DateIsoStringToDisplayString} from '../../common/model/CommonUtils';
import {NoteVisibilityType, ProductVersionNote} from '../model/Product';

export interface EditNoteFormProps {
  note: ProductVersionNote;
  onSave: (note: ProductVersionNote) => void;
  onCancel: () => void;
  isAdaptifyUser: boolean;
}

export function EditNoteForm(props: EditNoteFormProps) {
  const [form] = Form.useForm<ProductVersionNote>();
  useEffect(() => {
    form.resetFields();
    form.setFieldsValue({...props.note});
  }, [props.note]);

  function onSave(values: ProductVersionNote) {
    // merge values in the form with the existing object
    props.onSave({
      ...props.note,
      ...{visibility: values.visibility, text: values.text},
    });
  }

  const noteVisibilityOptions: BaseOptionType[] = [
    NoteVisibilityType.External,
    NoteVisibilityType.Internal,
  ].map(v => ({
    label: v,
    value: v,
  }));

  const visibilityFormItem = props.isAdaptifyUser ? (
    <Form.Item name="visibility" label="Type" rules={[{required: true}]}>
      <Select
        showSearch
        allowClear
        optionFilterProp="label"
        options={noteVisibilityOptions}
      />
    </Form.Item>
  ) : (
    <></>
  );

  return (
    <Form
      name="edit_note"
      labelWrap={true}
      labelCol={{span: 24}}
      wrapperCol={{span: 24}}
      layout="vertical"
      size="large"
      style={{width: '100%'}}
      onFinish={onSave}
      autoComplete="off"
      form={form}
    >
      <Form.Item name="createdBy" label="User">
        <Input disabled />
      </Form.Item>
      <Form.Item name="createdAt" label="Date">
        <Input
          disabled
          value={DateIsoStringToDisplayString(form.getFieldsValue().createdAt)}
        />
      </Form.Item>
      {visibilityFormItem}
      <Form.Item name="text" label="Note" rules={[{required: true}]}>
        <Input.TextArea />
      </Form.Item>
      <Flex style={{width: '100%'}} justify="end" className="gap-2">
        <Button {...ButtonSettings} htmlType="button" onClick={props.onCancel}>
          Cancel
        </Button>
        <Button ghost={false} type="default" htmlType="submit">
          Create
        </Button>
      </Flex>
    </Form>
  );
}
