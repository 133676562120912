import {Flex} from 'antd';
import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {UserInfo} from '../model/User';
import {UserService} from '../service/UserService';
import {ChangePasswordForm} from './ChangePasswordForm';

export interface ChangePasswordControlProps {
  userService: UserService;
  onPasswordChanged: () => void;
}

export function ChangePasswordControl(props: ChangePasswordControlProps) {
  const [userInfo, setUserInfo] = React.useState<UserInfo | undefined>(
    undefined
  );
  const navigate = useNavigate();

  useEffect(() => {
    const eff = async () => {
      const user = await props.userService.GetUserDetails();
      setUserInfo(user);
    };
    eff();
  }, []);

  function onSubmit() {
    props.onPasswordChanged();
  }

  const requireOldPassword = !(userInfo?.changePasswordNeeded ?? false);

  return (
    <Flex vertical gap={24} align="center" style={{width: '800px'}}>
      <div className="page-title">Change Password</div>
      <div style={{width: '100%'}}>
        <ChangePasswordForm
          userService={props.userService}
          requireOldPassword={requireOldPassword}
          onSubmit={onSubmit}
          onCancel={onSubmit}
          hideCancel={true}
        />
      </div>
    </Flex>
  );
}
