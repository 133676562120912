import React, {useState} from 'react';
import {ProductService} from '../../product/service/ProductService';

import {Flex} from 'antd';
import {LobService} from '../../lob/service/LobService';
import {ValueListService} from '../../valuelist/service/ValueListService';
import {AnalysisStudyList} from '../control/AnalysisStudyList';
import {StudyQueryForm} from '../control/StudyQueryForm';
import {StudyInfo, StudyInfoQuery} from '../model/ComparativeAnalysis';
import {AnalysisService} from '../service/AnalysisService';

export interface AnalysisStudyListControlProps {
  analysisService: AnalysisService;
  productService: ProductService;
  lobService: LobService;
  valueListService: ValueListService;
}

export function AnalysisStudyListControl(props: AnalysisStudyListControlProps) {
  const [modifyCount, setModifyCount] = useState<number>(0);

  const [selectedStudyId, setSelectedStudyId] = useState<string | undefined>(
    undefined
  );

  const [query, setQuery] = useState<StudyInfoQuery>({} as StudyInfoQuery);

  const [studies, setStudies] = useState<StudyInfo[]>([]);

  async function onQuery() {
    const studies = await props.analysisService.QueryStudyInfo(query);
    setStudies(studies);
  }

  return (
    <Flex vertical gap={24}>
      <StudyQueryForm
        studyInfoQuery={query}
        setStudyInfoQuery={setQuery}
        onQuery={onQuery}
        valueListService={props.valueListService}
      />
      <AnalysisStudyList
        analysisService={props.analysisService}
        productService={props.productService}
        lobService={props.lobService}
        valueListService={props.valueListService}
        modifyCount={modifyCount}
        setModifyCount={setModifyCount}
        studies={studies}
        selectedStudyId={selectedStudyId}
        setSelectedStudyId={setSelectedStudyId}
      />
    </Flex>
  );
}
