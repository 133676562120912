package com.adaptify.rating.admin.model.calculation.validation

import com.adaptify.rating.admin.model.calculation.descriptor.BindingDescriptor
import kotlin.js.JsExport

@JsExport
class FlowValidationResult(val flowId: String,
                           val messages : Array<String>,
                           val nodeResults : Array<NodeValidationResult>) {

  fun isValid() : Boolean {
    return messages.isEmpty() && nodeResults.all { it.isValid() }
  }
}
