package com.adaptify.rating.admin.model.calculation.validation.lib

import com.adaptify.rating.admin.model.calculation.CalculationFunction
import com.adaptify.rating.admin.model.calculation.binding.Binding
import com.adaptify.rating.admin.model.calculation.context.CalculationEvalContext
import com.adaptify.rating.admin.model.calculation.descriptor.BindingDescriptor
import com.adaptify.rating.admin.model.calculation.descriptor.FunctionDescriptor
import com.adaptify.rating.admin.model.calculation.env.MetadataProvider
import com.adaptify.rating.admin.model.calculation.validation.BindingValidator
import com.adaptify.rating.admin.model.calculation.validation.FunctionValidationMessage
import com.adaptify.rating.admin.model.util.BindingResolver

class BindingDataTypeMatchesValidator : BindingValidator {

  override suspend fun validateBinding(
    binding: Binding?,
    bindingDescriptor: BindingDescriptor,
    function: CalculationFunction,
    functionDescriptor: FunctionDescriptor,
    metadataProvider: MetadataProvider,
    evalContext: CalculationEvalContext
  ): Array<FunctionValidationMessage> {
    if (binding == null) {
      return emptyArray()
    }

    val scopeVariables  = evalContext.functionContexts.get(function.id)?.variables ?: emptyArray()
    val expected = bindingDescriptor.getExpectedDataType(
      function, functionDescriptor,
      bindingDescriptor, binding, metadataProvider, scopeVariables
    )

    if (expected == null) {
      return emptyArray()
    }

    val actual = BindingResolver.ResolveDataTypeForBinding(function, binding, metadataProvider, scopeVariables)
    if (actual == null) {
      return emptyArray()
    }
    // ignore isMany now, since the product requirements are to hide whether something is a list or not
    if (expected.primitive != actual.primitive || expected.lobItem != actual.lobItem) {
      // show primitive in the error message as binding to non-primitive is not supported
      val expectedBindingType = expected.lobItem?.name ?: expected.primitive?.name
      val actualBindingType = actual.lobItem?.name ?: actual.primitive?.name
      return arrayOf(
        FunctionValidationMessage("Binding ${bindingDescriptor.displayName} must be of type ${expectedBindingType}, but was ${actualBindingType}",
          function.id, bindingDescriptor.name))
    }
    return emptyArray();
  }
}
