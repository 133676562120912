package com.adaptify.rating.admin.model.calculation.descriptor.lib.predicates

import com.adaptify.rating.admin.model.calculation.CalculationFunction
import com.adaptify.rating.admin.model.calculation.binding.Binding
import com.adaptify.rating.admin.model.calculation.context.ScopedVariable
import com.adaptify.rating.admin.model.calculation.descriptor.*
import com.adaptify.rating.admin.model.calculation.env.MetadataProvider


object EqualsPredicateDescriptor : FunctionDescriptor("EQUALS", "==", FunctionType.Predicate, "Predicate") {

  val leftDescriptor = BindingDescriptor(
    "left", "Left", BindingInputOutputType.Input,
    BindingDescriptor.standardInputBindingTypes,
    null
  )
  val rightDescriptor = object : BindingDescriptor(
    "right", "Right", BindingInputOutputType.Input,
    BindingDescriptor.standardInputBindingTypes,
  ) {
    override suspend fun getExpectedDataType(
      calculationFunction: CalculationFunction,
      functionDescriptor: FunctionDescriptor,
      bindingDescriptor: BindingDescriptor,
      binding: Binding,
      metadataProvider: MetadataProvider,
      scope: Array<ScopedVariable>
    ): BoundDataType? {
      val leftDesc = functionDescriptor.getBindingDescriptors().find {
        it.name == "left"
      }
      if (leftDesc == null) {
        return null
      }
      return leftDescriptor.getExpectedDataType(
        calculationFunction,
        functionDescriptor,
        bindingDescriptor,
        binding,
        metadataProvider,
        scope
      )
    }
  }

  override fun getBindingDescriptors(
  ): Array<BindingDescriptor> = arrayOf(leftDescriptor, rightDescriptor)

  override fun getDisplayTemplate(
  ): String = "\$left == \$right"
}
