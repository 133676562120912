package com.adaptify.rating.admin.model.type

import kotlinx.serialization.Serializable
import kotlin.js.JsExport

@JsExport
@Serializable
enum class PrimitiveDataType {
  String,
  Number,
  Boolean,
  Date
}
