package com.adaptify.rating.admin.model.flow.descriptor

import kotlin.js.JsExport

@JsExport
class NodeDescriptorRegistry {

  val StartNodeDescriptor = NodeDescriptor("Start", false, AllowedEdgeType.One, EmbeddedFunctionType.None);
  val EndNodeDescriptor = NodeDescriptor("End", true, AllowedEdgeType.None, EmbeddedFunctionType.None);
  val CalculationNodeDescriptor = NodeDescriptor("Calculation", true, AllowedEdgeType.One, EmbeddedFunctionType.List);
  val BranchNodeDescriptor = NodeDescriptor("Branch", true, AllowedEdgeType.Many, EmbeddedFunctionType.None);
  val PredicateNodeDescriptor = NodeDescriptor("Predicate", true, AllowedEdgeType.Many, EmbeddedFunctionType.Predicate);
  var TableNodeDescriptor = NodeDescriptor("Table", true, AllowedEdgeType.One, EmbeddedFunctionType.Table);
}
