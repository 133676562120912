package com.adaptify.rating.admin.model.table

import kotlin.js.JsExport

@JsExport
enum class ColumnType {
  Input,
  Output
}

