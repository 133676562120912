import {Button, Col, Flex, Input, Row, Typography} from 'antd';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {ButtonSettings} from '../../common/control/Common';
import {DirtyEditorConfirmationModal} from '../../rating/control/DirtyEditorConfirmationModal';
import {TenantInfo} from '../model/Tenant';

export interface TenantSummaryFormProps {
  tenantInfo: TenantInfo | undefined;
  isDirty: boolean;
  setDirty: (dirty: boolean) => void;
}

export function TenantSummaryForm(props: TenantSummaryFormProps) {
  const navigate = useNavigate();
  const [confirmOpen, setConfirmOpen] = React.useState<boolean>(false);

  const dirtyEditorConfirmation = confirmOpen ? (
    <DirtyEditorConfirmationModal
      open={confirmOpen}
      skipDialog={!props.isDirty}
      onConfirm={async () => {
        setConfirmOpen(false);
        props.setDirty(false);
        navigate('/platform/tenant/summary');
      }}
      onCancel={() => {
        setConfirmOpen(false);
      }}
    />
  ) : (
    <></>
  );

  return (
    <>
      <Flex vertical>
        <Row>
          <Col span={18}>
            <div className="page-title">Account Details</div>
          </Col>
          <Col span={6}>
            <Flex justify="end">
              <Button {...ButtonSettings} onClick={() => setConfirmOpen(true)}>
                Account Lookup
              </Button>
            </Flex>
          </Col>
        </Row>
        <div
          style={{
            border: 'solid',
            borderWidth: '1px',
            borderRadius: '8px',
            borderColor: '#CCCCCC',
            backgroundColor: '#F2F7FE',
          }}
        >
          <div
            style={{
              marginLeft: '16px',
              marginRight: '16px',
              marginTop: '16px',
              marginBottom: '16px',
            }}
          >
            <Row gutter={[10, 16]}>
              <Col span={3} className="adaptify-label-column">
                <Typography.Text>Account Name</Typography.Text>
              </Col>
              <Col span={9}>
                <Input
                  readOnly
                  className="adaptify-read-only-field"
                  value={props.tenantInfo?.name}
                />
              </Col>
              <Col span={3} className="adaptify-label-column">
                <Typography.Text>Account ID:</Typography.Text>
              </Col>
              <Col span={9}>
                <Input
                  readOnly
                  className="adaptify-read-only-field"
                  value={props.tenantInfo?.accountNumber}
                />
              </Col>
              <Col span={3} className="adaptify-label-column">
                <Typography.Text>Account Type:</Typography.Text>
              </Col>
              <Col span={9}>
                <Input
                  readOnly
                  className="adaptify-read-only-field"
                  value={props.tenantInfo?.accountType}
                />
              </Col>
              <Col span={12} />
            </Row>
          </div>
        </div>
      </Flex>
      {dirtyEditorConfirmation}
    </>
  );
}
