import * as kot from 'adaptify-multi-module-rating-admin-model';
import LineOfBusinessHierarchy = kot.com.adaptify.rating.admin.model.lob.LineOfBusinessHierarchy;
import LineOfBusinessHierarchyItem = kot.com.adaptify.rating.admin.model.lob.LineOfBusinessHierarchyItem;
import PrimitiveDataType = kot.com.adaptify.rating.admin.model.type.PrimitiveDataType;
import TestCase = kot.com.adaptify.rating.admin.model.testcase.TestCase;
import TestCaseLobItem = kot.com.adaptify.rating.admin.model.testcase.TestCaseLobItem;
import TestCaseLobItemField = kot.com.adaptify.rating.admin.model.testcase.TestCaseLobItemField;

export interface RootAndNode {
  root: TestCaseTreeNode;
  node: TestCaseTreeNode | undefined;
}
export enum NodeType {
  Item, // we create placeholders for 1-1 types autmomatically, and create nodes in the test case if any property or child is defined
}

export interface TestCaseTreeNode {
  id: string; // tree data models need ids to handle selections and refreshes
  nodeType: NodeType;
  fields: TestCaseTreeNodeField[]; // only populated for item type
  children: TestCaseTreeNode[]; // only populated for multi value risk items
  itemId: string; // only populated for item type
  itemName: string; // only populated for item type
  displayName: string; // only populated for item type
}

export interface TestCaseTreeNodeField {
  id: string;
  fieldId: string;
  fieldName: string;
  displayName: string;
  dataType: string;
  fieldValue: string | undefined;
  // todo list of values
}

export function MergeTestCaseTree(
  tree: TestCaseTreeNode,
  testCase: TestCase
): TestCase {
  const lobItems = createTestCaseLobItems(tree, true);

  return {
    ...testCase,
    rootRiskItem: lobItems.length > 0 ? lobItems[0] : undefined,
  } as TestCase;
}

export function BuildTestCaseTree(
  rootItem: TestCaseLobItem | undefined,
  lobHierarchy: LineOfBusinessHierarchy
): TestCaseTreeNode {
  if (lobHierarchy.risks.length === 0) {
    return {
      id: 'empty',
      nodeType: NodeType.Item,
      fields: [],
      children: [],
      itemId: '',
      itemName: 'Lob Hierarchy not defined',
      displayName: '',
    };
  }

  const rootRiskItem = lobHierarchy.risks[0];
  return buildTestCaseNodes(
    rootRiskItem,
    rootItem ? [rootItem] : [],
    undefined
  )[0];
}

export function CanAddNewTestCaseItem(
  lobHierarchy: LineOfBusinessHierarchy,
  root: TestCaseTreeNode,
  id: string
): boolean {
  const node = FindNodeById(root, id);

  if (lobHierarchy.risks.length === 0 || !node) {
    return false;
  }
  const lobItem = findLobItemById(lobHierarchy.risks[0], node.itemId);
  if (!lobItem?.isMany) {
    return false;
  }

  return true;
}

export function CanRemoveTestCaseItem(
  lobHierarchy: LineOfBusinessHierarchy,
  root: TestCaseTreeNode,
  id: string
): boolean {
  const node = FindNodeById(root, id);
  const parent = FindParentNodeById(root, id);

  if (lobHierarchy.risks.length === 0 || !node || !parent) {
    return false;
  }

  const childAndSiblings = parent.children.filter(
    c => c.itemId === node.itemId
  );

  const lobItem = findLobItemById(lobHierarchy.risks[0], node.itemId);

  // cannot remove the latest test case item
  return (lobItem?.isMany ?? false) && childAndSiblings.length > 1;
}

export function AddNewTestCaseItem(
  lobHierarchy: LineOfBusinessHierarchy,
  root: TestCaseTreeNode,
  siblingId: string
): RootAndNode {
  // this isn't efficient since we are copying even the parts of the tree that remain unchanged,
  // but it's much easier to write this way
  const newTree = structuredClone(root);
  const sibling = FindNodeById(newTree, siblingId);
  const parent = FindParentNodeById(newTree, siblingId);

  if (!parent) {
    return {root: newTree, node: undefined};
  }

  return addNewTestCaseItem(lobHierarchy, newTree, siblingId);
}

export function CopyTestCaseItem(
  lobHierarchy: LineOfBusinessHierarchy,
  root: TestCaseTreeNode,
  itemId: string
): RootAndNode {
  // this isn't efficient since we are copying even the parts of the tree that remain unchanged,
  // but it's much easier to write this way
  const newTree = structuredClone(root);
  const parent = FindParentNodeById(newTree, itemId);
  const source = FindNodeById(newTree, itemId);
  if (!parent || !source) {
    return {
      root: newTree,
      node: undefined,
    };
  }
  const copied = structuredClone(source);
  return addNewTestCaseItem(lobHierarchy, newTree, itemId, copied);
}

export function addNewTestCaseItem(
  lobHierarchy: LineOfBusinessHierarchy,
  root: TestCaseTreeNode,
  siblingId: string,
  newItem?: TestCaseTreeNode
): RootAndNode {
  // this isn't efficient since we are copying even the parts of the tree that remain unchanged,
  // but it's much easier to write this way
  const newTree = structuredClone(root);
  const sibling = FindNodeById(newTree, siblingId);
  const parent = FindParentNodeById(newTree, siblingId);

  if (!parent || !sibling) {
    return {root: newTree, node: undefined};
  }

  const lobItem =
    lobHierarchy.risks.length > 0
      ? findLobItemById(lobHierarchy.risks[0], sibling.itemId)
      : undefined;
  if (!lobItem) {
    // this shouldn't happen
    return {root: newTree, node: undefined};
  }

  // it's 1 indexed, since we are using the index as the display name
  const newIndex =
    parent.children.filter(c => c.itemId === sibling.itemId).length + 1;
  const parentId = siblingId.substring(0, siblingId.lastIndexOf('.'));
  let toAdd: TestCaseTreeNode;
  if (newItem) {
    modifyIds(
      newItem,
      newItem.id,
      parentId + '.' + lobItem.name + '#' + newIndex
    );
    newItem.displayName = lobItem.name + ' ' + newIndex;
    toAdd = newItem;
  } else {
    toAdd = buildSingleTestCaseNode(
      lobItem,
      undefined,
      newIndex,
      parentId + '.' + lobItem.name + '#' + newIndex
    );
  }

  parent.children.push(toAdd);
  return {root: newTree, node: toAdd};
}

function modifyIds(node: TestCaseTreeNode, oldId: string, newId: string): void {
  // replace the id, and then replace the id in the fields and children, which should be the first part of their ids
  node.id = newId + node.id.substring(oldId.length);
  node.fields.forEach(f => {
    f.id = newId + f.id.substring(oldId.length);
  });
  node.children.forEach(c => modifyIds(c, oldId, newId));
}

export function RemoveTestCaseItem(
  lobHierarchy: LineOfBusinessHierarchy,
  root: TestCaseTreeNode,
  id: string
): TestCaseTreeNode {
  // this isn't efficient since we are copying even the parts of the tree that remain unchanged,
  // but it's much easier to write this way
  const newTree = structuredClone(root);
  const parentId = id.substring(0, id.lastIndexOf('.'));
  const parent = FindNodeById(newTree, parentId);
  if (!parent) {
    return newTree;
  }

  parent.children = parent.children.filter(c => c.id !== id);
  return newTree;
}

export function SetTestCaseField(
  lobHierarchy: LineOfBusinessHierarchy,
  root: TestCaseTreeNode,
  fieldId: string,
  fieldValue: string | undefined
): TestCaseTreeNode {
  // this isn't efficient since we are copying even the parts of the tree that remain unchanged,
  // but it's much easier to write this way
  const newTree = structuredClone(root);
  const field = findFieldById(newTree, fieldId);
  if (!field || !fieldId) {
    return newTree;
  }

  field.fieldValue = fieldValue;
  return newTree;
}

export function FindNodeById(
  node: TestCaseTreeNode,
  id: string
): TestCaseTreeNode | undefined {
  // this is an inefficient DFS, we can tune this later if needed because breaking up the past
  if (node.id === id) {
    return node;
  }

  if (!id.startsWith(node.id)) {
    // simple performance optimization to not check the subtree if this node's id isn't part of the id path
    // since we append to the parent id to form the child id
    return undefined;
  }
  for (const child of node.children) {
    const found = FindNodeById(child, id);
    if (found) {
      return found;
    }
  }
  return undefined;
}

export function FindParentNodeById(
  root: TestCaseTreeNode,
  id: string
): TestCaseTreeNode | undefined {
  if (!id.includes('.')) {
    return undefined;
  }
  const parentId = id.substring(0, id.lastIndexOf('.'));
  return FindNodeById(root, parentId);
}

function findLobItemById(
  lobItem: LineOfBusinessHierarchyItem,
  id: string
): LineOfBusinessHierarchyItem | undefined {
  if (lobItem.id === id) {
    return lobItem;
  }
  for (const child of lobItem.children) {
    const found = findLobItemById(child, id);
    if (found) {
      return found;
    }
  }
  return undefined;
}

function findFieldById(
  root: TestCaseTreeNode,
  id: string
): TestCaseTreeNodeField | undefined {
  const node = FindParentNodeById(root, id);
  if (!node) {
    return undefined;
  }

  return node.fields.find(f => f.id === id);
}

function buildTestCaseNodes(
  lobItem: LineOfBusinessHierarchyItem,
  testCaseItems: TestCaseLobItem[],
  parentId: string | undefined
): TestCaseTreeNode[] {
  const relevantTci = testCaseItems.filter(tci => tci.name === lobItem.name);
  const myId = parentId ? parentId + '.' + lobItem.name : lobItem.name;

  if (lobItem.isMany) {
    const testItems =
      relevantTci.length > 0
        ? relevantTci.map((tci, index) => {
            const oneBasedIndex = index + 1;
            return buildSingleTestCaseNode(
              lobItem,
              tci,
              oneBasedIndex,
              myId + '#' + oneBasedIndex
            );
          })
        : // create a dummy item for the list
          [buildSingleTestCaseNode(lobItem, undefined, 1, myId + '#1')];

    return testItems;
  }
  return [
    buildSingleTestCaseNode(
      lobItem,
      relevantTci.length > 0 ? relevantTci[0] : undefined,
      undefined,
      myId
    ),
  ];
}

function buildSingleTestCaseNode(
  lobItem: LineOfBusinessHierarchyItem,
  testCaseItem: TestCaseLobItem | undefined,
  itemNumber: number | undefined,
  id: string
): TestCaseTreeNode {
  const fields = lobItem.fields
    .map(f => {
      return {
        id: id + '.' + f.name,
        fieldId: f.id,
        fieldName: f.name,
        displayName: f.name,
        dataType: f.dataType,
        fieldValue:
          testCaseItem?.fields.find(tf => tf.name === f.name)?.value ?? '',
      } as TestCaseTreeNodeField;
    })
    .sort((a, b) => a.displayName.localeCompare(b.displayName));

  const children = lobItem.children
    .flatMap(c => {
      return buildTestCaseNodes(c, testCaseItem?.childLobItems ?? [], id);
    })
    .sort((a, b) => a.displayName.localeCompare(b.displayName));

  return {
    id: id,
    nodeType: NodeType.Item,
    fields: fields,
    children: children,
    itemId: lobItem.id,
    itemName: lobItem.name,
    displayName: lobItem.name + (itemNumber ? ' ' + itemNumber : ''),
  };
}

function createTestCaseLobItems(
  node: TestCaseTreeNode,
  forceCreation: boolean
): TestCaseLobItem[] {
  if (node.nodeType === NodeType.Item) {
    // only create test case fields that actually have a value
    const fields = node.fields
      .filter(f => f.fieldValue && f.fieldValue !== '')
      .map(f => {
        return {
          id: f.fieldId,
          name: f.fieldName,
          value: f.fieldValue,
        } as TestCaseLobItemField;
      });
    const children = node.children
      .map(c => {
        return createTestCaseLobItems(c, false);
      })
      .flat();

    if (forceCreation || fields.length > 0 || children.length > 0) {
      return [
        {
          id: node.itemId,
          name: node.itemName,
          fields: fields,
          childLobItems: children,
        } as TestCaseLobItem,
      ];
    } else {
      return [];
    }
  }
  return [];
}
