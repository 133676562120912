import {SERVER_BASE_URL} from '../../common/service/Constants';
import {
  ConvertToQueryParams,
  CreateStandardHeaders,
  HandleFetchJson,
  HandleFetchVoid,
} from '../../common/service/Service';
import {
  LineOfBusinessHierarchyDef,
  LineOfBusinessItem,
  LineOfBusinessItemField,
  LobHierarchyInfo,
  LobHierarchyInfoQuery,
  LobItemInfo,
} from '../model/LineOfBusiness';

const SERVER_URL = SERVER_BASE_URL;

const QUERY_LOB_URL = (lobInfoQuery: LobHierarchyInfoQuery) =>
  `${SERVER_URL}/admin/lob/lobhierarchyinfo/query?${ConvertToQueryParams(lobInfoQuery)}`;
const QUERY_LOB_NAME_URL = (lobInfoQuery: LobHierarchyInfoQuery) =>
  `${SERVER_URL}/admin/lob/lobhierarchynames/query?${ConvertToQueryParams(lobInfoQuery)}`;
const GET_LOB_URL = (id: string) =>
  `${SERVER_URL}/admin/lob/lineofbusinesshierarchy/get/${id}`;
const CREATE_LOB_URL = `${SERVER_URL}/admin/lob/lineofbusinesshierarchy/create`;
const SAVE_LOB_URL = `${SERVER_URL}/admin/lob/lineofbusinesshierarchy/update`;
const DELETE_LOB_URL = (id: string) =>
  `${SERVER_URL}/admin/lob/lineofbusinesshierarchy/delete/${id}`;
const PUBLISH_LOB_URL = (id: string) =>
  `${SERVER_URL}/admin/lob/lineofbusinesshierarchy/publish/${id}`;
const COPY_LOB_URL = `${SERVER_URL}/admin/lob/lineofbusinesshierarchy/copy`;

const QUERY_LOB_RISKS_URL = (lobId: string) =>
  `${SERVER_URL}/admin/lob/lineofbusinessitem/query/risks?lobId=${encodeURI(lobId)}`;
const QUERY_LOB_COVERAGES_BY_LOB_URL = (lobId: string) =>
  `${SERVER_URL}/admin/lob/lineofbusinessitem/query/coveragesbylobid?lobId=${encodeURI(lobId)}`;
const QUERY_LOB_COVERAGES_BY_PARENT_URL = (parentRiskId: string) =>
  `${SERVER_URL}/admin/lob/lineofbusinessitem/query/coveragesbyparentriskitemid?parentRiskItemId=${encodeURI(parentRiskId)}`;
const GET_LOB_ITEM_URL = (id: string) =>
  `${SERVER_URL}/admin/lob/lineofbusinessitem/get/${id}`;

const SAVE_LOB_ITEM_URL = `${SERVER_URL}/admin/lob/lineofbusinessitem/update`;
const DELETE_LOB_ITEM_URL = (id: string) =>
  `${SERVER_URL}/admin/lob/lineofbusinessitem/delete/${id}`;
const CREATE_LOB_COVERAGE_URL = `${SERVER_URL}/admin/lob/lineofbusinesscoverage/create`;

const QUERY_LOB_ITEM_FIELDS_URL = (lobItemId: string) =>
  `${SERVER_URL}/admin/lob/lineofbusinessitemfield/query?lobItemId=${encodeURI(lobItemId)}`;
const GET_LOB_ITEM_FIELD_URL = (id: string) =>
  `${SERVER_URL}/admin/lob/lineofbusinessitemfield/get/${id}`;

const SAVE_LOB_ITEM_FIELD_URL = `${SERVER_URL}/admin/lob/lineofbusinessitemfield/update`;
const DELETE_LOB_ITEM_FIELD_URL = (id: string) =>
  `${SERVER_URL}/admin/lob/lineofbusinessitemfield/delete/${id}`;

const QUERY_CONFIGRED_LOB_DEF_INFO_URL = `${SERVER_URL}/admin/lob/lobhierarchyinfo/query`;

export interface LobService {
  QueryLinesOfBusinesses(
    query: LobHierarchyInfoQuery
  ): Promise<LobHierarchyInfo[]>;
  QueryLineOfBusinessNames(query: LobHierarchyInfoQuery): Promise<string[]>;

  GetLineOfBusiness(id: string): Promise<LineOfBusinessHierarchyDef>;
  GetLobDefInfoById(id: string): Promise<LobHierarchyInfo>;
  CreateLineOfBusiness(
    lob: LineOfBusinessHierarchyDef
  ): Promise<LineOfBusinessHierarchyDef>;
  UpdateLineOfBusiness(
    lob: LineOfBusinessHierarchyDef
  ): Promise<LineOfBusinessHierarchyDef>;
  DeleteLineOfBusiness(id: string): Promise<any>;
  PublishLineOfBusiness(id: string): Promise<LineOfBusinessHierarchyDef>;
  CopyLineOfBusiness(
    id: string,
    name: string,
    lineOfBusinessValueListItemId: string
  ): Promise<LineOfBusinessHierarchyDef>;
  GetLineOfBusinessRisks(id: string): Promise<LobItemInfo[]>;
  GetLineOfBusinessCoveragesByLobId(id: string): Promise<LineOfBusinessItem[]>;
  GetLineOfBusinessCoveragesByParentRiskId(id: string): Promise<LobItemInfo[]>;
  GetLineOfBusinessItem(id: string): Promise<LineOfBusinessItem>;
  UpdateLineOfBusinessItem(
    item: LineOfBusinessItem
  ): Promise<LineOfBusinessItem>;
  DeleteLineOfBusinessItem(id: string): Promise<any>;
  CreateLineOfBusinessCoverage(
    coverage: LineOfBusinessItem
  ): Promise<LineOfBusinessItem>;

  GetLineOfBusinessItemFields(
    lobItemId: string
  ): Promise<LineOfBusinessItemField[]>;
  GetLineOfBusinessItemField(id: string): Promise<LineOfBusinessItemField>;
  UpdateLineOfBusinessItemField(
    lob: LineOfBusinessItemField
  ): Promise<LineOfBusinessItemField>;
  DeleteLineOfBusinessItemField(id: string): Promise<any>;
  GetConfiguredLobDefInfo(): Promise<LobHierarchyInfo[]>;
}

export default function NewLobService(authToken: string): LobService {
  function createHeaders() {
    return CreateStandardHeaders(authToken);
  }

  async function QueryLinesOfBusinesses(
    query: LobHierarchyInfoQuery
  ): Promise<LobHierarchyInfo[]> {
    const requestOptions = {
      method: 'GET',
      headers: createHeaders(),
    };

    return HandleFetchJson<LobHierarchyInfo[]>(
      fetch(QUERY_LOB_URL(query), requestOptions)
    );
  }

  async function QueryLineOfBusinessNames(
    query: LobHierarchyInfoQuery
  ): Promise<string[]> {
    const requestOptions = {
      method: 'GET',
      headers: createHeaders(),
    };

    return HandleFetchJson<string[]>(
      fetch(QUERY_LOB_NAME_URL(query), requestOptions)
    );
  }

  async function GetLobDefInfoById(id: string): Promise<LobHierarchyInfo> {
    const requestOptions = {
      method: 'GET',
      headers: createHeaders(),
    };

    return HandleFetchJson<LobHierarchyInfo>(
      fetch(GET_LOB_URL(id), requestOptions)
    );
  }

  async function GetLineOfBusinessRisks(id: string): Promise<LobItemInfo[]> {
    const requestOptions = {
      method: 'GET',
      headers: createHeaders(),
    };

    return HandleFetchJson<LobItemInfo[]>(
      fetch(QUERY_LOB_RISKS_URL(id), requestOptions)
    );
  }

  async function GetLineOfBusinessCoveragesByLobId(
    id: string
  ): Promise<LineOfBusinessItem[]> {
    const requestOptions = {
      method: 'GET',
      headers: createHeaders(),
    };

    return HandleFetchJson<LineOfBusinessItem[]>(
      fetch(QUERY_LOB_COVERAGES_BY_LOB_URL(id), requestOptions)
    );
  }

  async function GetLineOfBusinessCoveragesByParentRiskId(
    id: string
  ): Promise<LobItemInfo[]> {
    const requestOptions = {
      method: 'GET',
      headers: createHeaders(),
    };
    return HandleFetchJson<LobItemInfo[]>(
      fetch(QUERY_LOB_COVERAGES_BY_PARENT_URL(id), requestOptions)
    );
  }

  async function GetLineOfBusiness(
    id: string
  ): Promise<LineOfBusinessHierarchyDef> {
    const requestOptions = {
      method: 'GET',
      headers: createHeaders(),
    };

    return HandleFetchJson<LineOfBusinessHierarchyDef>(
      fetch(GET_LOB_URL(id), requestOptions)
    );
  }

  async function CreateLineOfBusiness(
    lob: LineOfBusinessHierarchyDef
  ): Promise<LineOfBusinessHierarchyDef> {
    const requestOptions = {
      method: 'POST',
      headers: createHeaders(),
      body: JSON.stringify(lob),
    };

    return HandleFetchJson<LineOfBusinessHierarchyDef>(
      fetch(CREATE_LOB_URL, requestOptions)
    );
  }

  async function UpdateLineOfBusiness(
    lob: LineOfBusinessHierarchyDef
  ): Promise<LineOfBusinessHierarchyDef> {
    const requestOptions = {
      method: 'POST',
      headers: createHeaders(),
      body: JSON.stringify(lob),
    };

    return HandleFetchJson<LineOfBusinessHierarchyDef>(
      fetch(SAVE_LOB_URL, requestOptions)
    );
  }

  async function DeleteLineOfBusiness(id: string): Promise<void> {
    const requestOptions = {
      method: 'DELETE',
      headers: createHeaders(),
    };

    return HandleFetchVoid(fetch(DELETE_LOB_URL(id), requestOptions));
  }

  async function PublishLineOfBusiness(
    id: string
  ): Promise<LineOfBusinessHierarchyDef> {
    const requestOptions = {
      method: 'POST',
      headers: createHeaders(),
    };

    return HandleFetchJson(fetch(PUBLISH_LOB_URL(id), requestOptions));
  }

  async function CopyLineOfBusiness(
    id: string,
    name: string,
    lineOfBusinessValueListItemId: string
  ): Promise<LineOfBusinessHierarchyDef> {
    const requestOptions = {
      method: 'POST',
      headers: createHeaders(),
      body: JSON.stringify({
        id,
        name,
        lineOfBusinessValueListItemId,
      }),
    };

    return HandleFetchJson(fetch(COPY_LOB_URL, requestOptions));
  }

  async function GetLineOfBusinessItem(
    id: string
  ): Promise<LineOfBusinessItem> {
    const requestOptions = {
      method: 'GET',
      headers: createHeaders(),
    };

    return HandleFetchJson<LineOfBusinessItem>(
      fetch(GET_LOB_ITEM_URL(id), requestOptions)
    );
  }

  async function UpdateLineOfBusinessItem(
    lobItem: LineOfBusinessItem
  ): Promise<LineOfBusinessItem> {
    const requestOptions = {
      method: 'POST',
      headers: createHeaders(),
      body: JSON.stringify(lobItem),
    };

    return HandleFetchJson<LineOfBusinessItem>(
      fetch(SAVE_LOB_ITEM_URL, requestOptions)
    );
  }

  async function CreateLineOfBusinessCoverage(
    lobItem: LineOfBusinessItem
  ): Promise<LineOfBusinessItem> {
    const requestOptions = {
      method: 'POST',
      headers: createHeaders(),
      body: JSON.stringify(lobItem),
    };

    return HandleFetchJson<LineOfBusinessItem>(
      fetch(CREATE_LOB_COVERAGE_URL, requestOptions)
    );
  }

  async function DeleteLineOfBusinessItem(id: string): Promise<void> {
    const requestOptions = {
      method: 'DELETE',
      headers: createHeaders(),
    };

    return HandleFetchVoid(fetch(DELETE_LOB_ITEM_URL(id), requestOptions));
  }

  async function GetLineOfBusinessItemFields(
    lobItemId: string
  ): Promise<LineOfBusinessItemField[]> {
    const requestOptions = {
      method: 'GET',
      headers: createHeaders(),
    };

    return HandleFetchJson<LineOfBusinessItemField[]>(
      fetch(QUERY_LOB_ITEM_FIELDS_URL(lobItemId), requestOptions)
    );
  }

  async function GetLineOfBusinessItemField(
    id: string
  ): Promise<LineOfBusinessItemField> {
    const requestOptions = {
      method: 'GET',
      headers: createHeaders(),
    };

    return HandleFetchJson<LineOfBusinessItemField>(
      fetch(GET_LOB_ITEM_FIELD_URL(id), requestOptions)
    );
  }

  async function UpdateLineOfBusinessItemField(
    lob: LineOfBusinessItemField
  ): Promise<LineOfBusinessItemField> {
    const requestOptions = {
      method: 'POST',
      headers: createHeaders(),
      body: JSON.stringify(lob),
    };

    return HandleFetchJson<LineOfBusinessItemField>(
      fetch(SAVE_LOB_ITEM_FIELD_URL, requestOptions)
    );
  }

  async function DeleteLineOfBusinessItemField(id: string): Promise<void> {
    const requestOptions = {
      method: 'DELETE',
      headers: createHeaders(),
    };
    return HandleFetchVoid(
      fetch(DELETE_LOB_ITEM_FIELD_URL(id), requestOptions)
    );
  }

  async function GetConfiguredLobDefInfo(): Promise<LobHierarchyInfo[]> {
    const requestOptions = {
      method: 'GET',
      headers: createHeaders(),
    };
    return HandleFetchJson<LobHierarchyInfo[]>(
      fetch(QUERY_CONFIGRED_LOB_DEF_INFO_URL, requestOptions)
    );
  }

  return {
    QueryLinesOfBusinesses,
    QueryLineOfBusinessNames,
    GetLineOfBusiness,
    GetLobDefInfoById,
    CreateLineOfBusiness,
    UpdateLineOfBusiness,
    DeleteLineOfBusiness,
    PublishLineOfBusiness,
    CopyLineOfBusiness,
    GetLineOfBusinessRisks,
    GetLineOfBusinessCoveragesByLobId,
    GetLineOfBusinessCoveragesByParentRiskId,
    GetLineOfBusinessItem,
    UpdateLineOfBusinessItem,
    DeleteLineOfBusinessItem,
    CreateLineOfBusinessCoverage,
    GetLineOfBusinessItemFields,
    GetLineOfBusinessItemField,
    UpdateLineOfBusinessItemField,
    DeleteLineOfBusinessItemField,
    GetConfiguredLobDefInfo,
  };
}
