import '@ag-grid-community/styles/ag-grid.css'; // Core CSS
import '@ag-grid-community/styles/ag-theme-quartz.css';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import {
  DataGrid,
  GridCallbackDetails,
  GridColDef,
  GridRowSelectionModel,
} from '@mui/x-data-grid';
import {Button, Col, Flex, Popconfirm, Row, Space} from 'antd';
import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {ButtonSettings, DataGridSettings} from '../../common/control/Common';
import {DraggableModal} from '../../common/control/DraggableModal';
import {NewTenantAwareEntity} from '../../common/model/Common';
import {ValueListDefinition} from '../model/ValueList';
import {ValueListService} from '../service/ValueListService';
import {EditValueListDefinitionForm} from './EditValueListDefinitionForm';

export interface ValueListDefinitionListProps {
  valueListService: ValueListService;
  readonly?: boolean;
  selectedValueListId: string | undefined;
  setSelectedValueListId: (selection: string | undefined) => void;
  parentValueListDefinitionId: string | undefined;
  childList?: boolean;
}

export interface EditState {
  isNew: boolean;
  open: boolean;
  valueListDefinition: ValueListDefinition;
}

export function ValueListDefinitionList(props: ValueListDefinitionListProps) {
  function createValueListDefinition(): ValueListDefinition {
    return {
      ...NewTenantAwareEntity(),
      name: '',
      code: '',
      parentValueListDefinitionId: props.parentValueListDefinitionId,
    };
  }
  const [editState, setEditState] = useState<EditState>({
    isNew: true,
    open: false,
    valueListDefinition: createValueListDefinition(),
  });
  // used to trigger a refresh on the UI on change
  const [modifyCount, setModifyCount] = useState(0);
  const [valueListDefinitions, setValueListDefinitions] = useState<
    ValueListDefinition[]
  >([]);
  useEffect(() => {
    const eff = async () => {
      if (props.childList && !props.parentValueListDefinitionId) {
        setValueListDefinitions([]);
        return;
      }
      // either get the root list items or the relevant children
      const valueLists =
        await props.valueListService.GetAllValueListDefinitions();
      // TODO have a different service call rot roots vs children
      const filteredValueLists = valueLists.filter(
        l =>
          (l.parentValueListDefinitionId ?? '') ===
          (props.parentValueListDefinitionId ?? '')
      );

      setValueListDefinitions(filteredValueLists);
      if (!props.selectedValueListId) {
        props.setSelectedValueListId(
          filteredValueLists.length > 0 ? filteredValueLists[0].id : undefined
        );
      }
    };
    eff();
  }, [modifyCount, props.parentValueListDefinitionId]);

  const colDefs: GridColDef<ValueListDefinition>[] = [
    {
      field: 'name',
      headerName: 'Name',
      renderCell: params => {
        // chlid definitions don't support navigation, as you define the child values within the UI to edit the parent
        if (props.childList) {
          return params.value;
        }
        return (
          <Link to={`/platform/valuelist/${params.row.id}/item`}>
            {params.value}
          </Link>
        );
      },
      flex: 300,
    },
    {
      field: 'code',
      headerName: 'Internal Code',
      flex: 300,
    },
  ];

  function onOpenCreateDialog() {
    setEditState({
      isNew: true,
      open: true,
      valueListDefinition: createValueListDefinition(),
    });
  }

  function onOpenEditDialog() {
    if (!props.selectedValueListId) {
      return;
    }

    const valueListDefinition = valueListDefinitions.find(
      valListDef => valListDef.id === props.selectedValueListId
    );
    if (!valueListDefinition) {
      return;
    }
    setEditState({
      isNew: false,
      open: true,
      valueListDefinition: valueListDefinition,
    });
  }

  async function onDelete() {
    const sel = props.selectedValueListId;
    if (!props.selectedValueListId) {
      return;
    }
    await props.valueListService.DeleteValueListDefinition(
      props.selectedValueListId
    );
    setModifyCount(modifyCount + 1);
  }

  async function onSaveValueListDef(value: ValueListDefinition) {
    await props.valueListService.SaveValueListDefinition(value);
    setEditState({
      ...editState,
      open: false,
    });
    setModifyCount(modifyCount + 1);
  }

  function onCancelValueListDef() {
    setEditState({
      ...editState,
      open: false,
    });
  }

  const editButtons = (
    <Flex justify="flex-end" align="flex-start" className="gap-2">
      <Button
        {...ButtonSettings}
        onClick={onOpenCreateDialog}
        disabled={props.childList && !props.parentValueListDefinitionId}
      >
        <AddOutlinedIcon className="-ml-1" />
        Create
      </Button>
      <Button
        {...ButtonSettings}
        onClick={onOpenEditDialog}
        disabled={!props.selectedValueListId}
      >
        <DriveFileRenameOutlineOutlinedIcon className="-ml-1" />
        Modify
      </Button>
      <Popconfirm
        title="Confirm deletion"
        description="Are you sure to want to delete?"
        onConfirm={onDelete}
        disabled={!props.selectedValueListId}
        okText="Delete"
        cancelText="Cancel"
      >
        <Button {...ButtonSettings} disabled={!props.selectedValueListId}>
          <DeleteOutlinedIcon className="-ml-1" />
          Delete
        </Button>
      </Popconfirm>
    </Flex>
  );

  const buttons = props.readonly ? <></> : editButtons;

  function onTableSelectionChanged(
    rowSelectionModel: GridRowSelectionModel,
    details: GridCallbackDetails
  ) {
    const selection: ValueListDefinition | undefined = undefined;
    if (rowSelectionModel.length !== 0) {
      props.setSelectedValueListId(rowSelectionModel[0] as string);
    } else {
      props.setSelectedValueListId(undefined);
    }
  }

  return (
    <>
      <Flex vertical style={{width: '100%', height: '100%'}}>
        <Row>
          <Col span={18}>
            <div className="page-title">
              {props.childList ? 'Dependent Value List' : 'Value List'}
            </div>
          </Col>
          <Col span={6}>{buttons}</Col>
        </Row>

        <Space direction="vertical" style={{width: '100%', height: '100%'}}>
          <div style={{height: '100%', width: '100%'}}>
            <DataGrid
              {...DataGridSettings}
              columns={colDefs}
              rows={valueListDefinitions}
              rowSelectionModel={
                props.selectedValueListId ? [props.selectedValueListId] : []
              }
              onRowSelectionModelChange={onTableSelectionChanged}
              sx={{
                '&.MuiDataGrid-root': {
                  borderRadius: '8px',
                  overflow: 'hidden',
                  borderColor: '#CCCCCC',
                },
              }}
            />
          </div>
        </Space>
      </Flex>
      <DraggableModal
        className="adaptify-modal"
        title={editState.isNew ? 'Add Value List' : 'Edit Value List'}
        open={editState.open}
        closable={false}
        okButtonProps={{style: {display: 'none'}}}
        cancelButtonProps={{style: {display: 'none'}}}
        width={'clamp(300px, 70svw, 800px)'}
        footer={null}
      >
        <EditValueListDefinitionForm
          valueListDefinition={editState.valueListDefinition}
          allValueListDefinitions={valueListDefinitions}
          onSave={onSaveValueListDef}
          onCancel={onCancelValueListDef}
        />
      </DraggableModal>
    </>
  );
}
