import {TenantAwareEntity} from '../../common/model/Common';

interface LineOfBusinessName {
  name: string;
  values: string[];
}

export interface CategoryAndName {
  category: string;
  name: string;
}

export interface LobHierarchyInfo {
  name: string;
  insuranceTypeValueListItemId: string;
  insuranceType: string;
  lobNameValueListItemId: string;
  lobName: string;
  hierarchySource: string;
  id: string;
  status: string;
  tenantId: string;
}

export const OrganizationName = {
  Adaptify: 'Adaptify',
  Carrier: 'Carrier',
} as const;

export const LobItemType = {
  Risk: 'Risk',
  Coverage: 'Coverage',
} as const;

export interface ValueWithDisplay {
  name: string;
  displayValue: string;
}

export const LobItemCardinality = {
  Single: {
    name: 'Single',
    displayValue: 'Single',
  } as ValueWithDisplay,
  Many: {
    name: 'Many',
    displayValue: 'Multi',
  } as ValueWithDisplay,
} as const;

export interface LineOfBusinessHierarchyDef extends TenantAwareEntity {
  name: string;
  lobNameValueListItemId: string;
  hierarchySource: string;
  status: string;
}

export interface LineOfBusinessItem extends TenantAwareEntity {
  name: string;
  itemType: string;
  cardinality: string;
  lineOfBusinessId: string;
  parentLineOfBusinessItemId: string;
  copiedToClientFromAdaptify: boolean;
}

export interface LineOfBusinessItemField extends TenantAwareEntity {
  name: string;
  dataType: string;
  ratingInput: boolean;
  lineOfBusinessItemId: string;
  copiedToClientFromAdaptify: boolean;
}

export interface LobItemInfo {
  id: string;
  name: string;
  itemType: string;
  cardinality: string;
  lineOfBusinessId: string;
  parentLineOfBusinessItemId: string;
  parentLineOfBusinessItemName: string;
  copiedToClientFromAdaptify: boolean;
}

export interface LobHierarchyInfoQuery {
  id?: string;
  name?: string;
  insuranceType?: string;
  lobName?: string;
  hierarchySource?: string;
}

export const ReservedCoverageFieldNames = ['Rate', 'Premium', 'Loss Cost'];
export enum LobStatus {
  Published = 'Published',
  Unpublished = 'Unpublished',
}
