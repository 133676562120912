import React from 'react';
import {useParams} from 'react-router-dom';
import {NavigationAware} from '../../NavigationAware';
import {ValueListService} from '../../valuelist/service/ValueListService';
import {TenantService} from '../service/TenantService';
import {UserService} from '../service/UserService';
import {TenantDetailControl} from './TenantDetailControl';

export interface TenantDetailPageProps extends NavigationAware {
  tenantService: TenantService;
  valueListService: ValueListService;
  userService: UserService;
  userTenantId: string | undefined;
}

type TenantDetailPageParams = {
  tenantId: string;
};

export function TenantDetailPage(props: TenantDetailPageProps) {
  const {tenantId} = useParams<TenantDetailPageParams>();
  return (
    <TenantDetailControl
      tenantService={props.tenantService}
      valueListService={props.valueListService}
      userService={props.userService}
      tenantId={tenantId}
      userTenantId={props.userTenantId}
    />
  );
}
