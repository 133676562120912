package com.adaptify.rating.admin.model.calculation.descriptor.lib

import com.adaptify.rating.admin.model.calculation.CalculationFunction
import com.adaptify.rating.admin.model.calculation.descriptor.BindingDescriptor
import com.adaptify.rating.admin.model.calculation.descriptor.BindingInputOutputType
import com.adaptify.rating.admin.model.calculation.descriptor.FunctionDescriptor
import com.adaptify.rating.admin.model.calculation.descriptor.FunctionType


object MinDescriptor : FunctionDescriptor("MIN", "MIN", FunctionType.Function, "Math") {

  val assignToDescriptor = BindingDescriptor(
    "assignTo", "Assign To", BindingInputOutputType.Output, BindingDescriptor.standardOutputBindingTypes,
    BindingDescriptor.numberDataType
  )
  val leftDescriptor = BindingDescriptor(
    "left", "Left", BindingInputOutputType.Input, BindingDescriptor.standardInputBindingTypes,
    BindingDescriptor.numberDataType
  )
  val rightDescriptor = BindingDescriptor(
    "right", "Right", BindingInputOutputType.Input, BindingDescriptor.standardInputBindingTypes,
    BindingDescriptor.numberDataType
  )

  override fun getBindingDescriptors(
  ): Array<BindingDescriptor> = arrayOf(assignToDescriptor, leftDescriptor, rightDescriptor)

  override fun getDisplayTemplate(
  ): String = "\$assignTo = MIN(\$left, \$right)"
}
