import {Handle, Position, useHandleConnections} from '@xyflow/react';
import React from 'react';

interface FlowSourceNodeHandleProps {
  maxConnections: number;
  readOnly?: boolean;
}

export function FlowSourceNodeHandle(props: FlowSourceNodeHandleProps) {
  const connections = useHandleConnections({
    type: 'source',
  });
  return (
    <Handle
      type="source"
      position={Position.Right}
      style={{width: 12, height: 12}}
      isConnectable={
        !props.readOnly && connections.length < props.maxConnections
      }
    />
  );
}
