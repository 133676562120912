import {Flex} from 'antd';
import React, {useEffect, useState} from 'react';
import {LobRiskFieldList} from '../control/LobRiskFieldList';
import {LobSummaryControl} from '../control/LobSummaryControl';
import {LobStatus} from '../model/LineOfBusiness';
import {LobService} from '../service/LobService';

export interface LobRiskFieldControlProps {
  lobHierarchyId: string | undefined;
  lobService: LobService;
  initialLobItemId: string | undefined;
  onSetLobItemId: (lobItemId: string | undefined) => void;
  currentTenantId: string | undefined;
}

export function LobRiskFieldControl(props: LobRiskFieldControlProps) {
  const [selectedLobItemId, setSelectedLobItemId] = useState<
    string | undefined
  >(props.initialLobItemId);
  const [lobTenantId, setLobTenantId] = useState<string | undefined>(undefined);
  const [isPublished, setIsPublished] = useState<boolean>(false);

  useEffect(() => {
    if (!props.lobHierarchyId) {
      return;
    }

    const eff = async () => {
      const lob = await props.lobService.GetLineOfBusiness(
        props.lobHierarchyId ?? ''
      );
      setLobTenantId(lob?.tenantId);
      setIsPublished(lob.status === LobStatus.Published);
    };
    eff();
  }, [props.lobHierarchyId, props.currentTenantId]);

  function onSetLobItemId(lobItemId: string | undefined) {
    setSelectedLobItemId(lobItemId);
    props.onSetLobItemId(lobItemId);
  }
  return (
    <Flex vertical gap={24}>
      <LobSummaryControl
        lobHierarchyId={props.lobHierarchyId}
        lobService={props.lobService}
      />
      <LobRiskFieldList
        lobService={props.lobService}
        lobHierarchyId={props.lobHierarchyId}
        selectedLobItemId={selectedLobItemId}
        setSelectedLobItemId={onSetLobItemId}
        existingItemsReadOnly={isPublished}
        readOnly={lobTenantId !== props.currentTenantId}
      />
    </Flex>
  );
}
