import {Button, Col, Flex, Row} from 'antd';
import React from 'react';
import {DraggableModal} from './DraggableModal';

export interface ErrorModalProps {
  open: boolean;
  errorMessage: string;
  onOk: () => void;
}

export function ErrorModal(props: ErrorModalProps) {
  return (
    <DraggableModal
      className="adaptify-modal"
      title={null}
      destroyOnClose={true}
      closable={false}
      open={props.open}
      okButtonProps={{style: {display: 'none'}}}
      cancelButtonProps={{style: {display: 'none'}}}
      width={'600px'}
      height={'400px'}
      footer={null}
    >
      <Row gutter={[20, 30]} style={{width: '100%', height: '100%'}}>
        <Col span={24}></Col>
        <Col span={24}>
          <Flex justify="center" style={{width: '100%', textAlign: 'center'}}>
            {props.errorMessage}
          </Flex>
        </Col>
        <Col span={24}>
          <Flex justify="center" gap={5}>
            <Button
              ghost={false}
              type="default"
              htmlType="button"
              onClick={props.onOk}
            >
              OK
            </Button>
          </Flex>
        </Col>
      </Row>
    </DraggableModal>
  );
}
