package com.adaptify.rating.admin.model.calculation.descriptor.lib

import com.adaptify.rating.admin.model.calculation.CalculationFunction
import com.adaptify.rating.admin.model.calculation.binding.Binding
import com.adaptify.rating.admin.model.calculation.context.ScopedVariable
import com.adaptify.rating.admin.model.calculation.descriptor.*
import com.adaptify.rating.admin.model.calculation.env.MetadataProvider
import com.adaptify.rating.admin.model.type.PrimitiveDataType
import com.adaptify.rating.admin.model.util.BindingResolver

object AssignDescriptor : FunctionDescriptor("ASSIGN", "=", FunctionType.Function, "Math"){

  val assignToDescriptor = object : BindingDescriptor(
    "assignTo", "Assign To",
    BindingInputOutputType.Output,
    BindingDescriptor.standardOutputBindingTypes, null
  ) {
    override suspend fun getExpectedDataType(
      calculationFunction: CalculationFunction,
      functionDescriptor: FunctionDescriptor,
      bindingDescriptor: BindingDescriptor,
      binding: Binding,
      metadataProvider: MetadataProvider,
      scope: Array<ScopedVariable>
    ): BoundDataType? {
      val valueBinding = calculationFunction.bindings.find { it.name == "value" }
      if (valueBinding == null) {
        return null;
      }

      return BindingResolver.ResolveDataTypeForBinding(
        calculationFunction,
        valueBinding,
        metadataProvider,
        scope
      );
    }
  }

  val valueDescriptor = object:  BindingDescriptor(
    "value", "Value",
    BindingInputOutputType.Input,
    BindingDescriptor.standardInputBindingTypes,
    // limit variables to only point to primitive types
    // I don't think our product team understands variables that don't point to primitives
    // so I have to assume that customers won't either.
    // this will make global variable resolution easier since you can't form a path
    // from a global variables
    arrayOf(PrimitiveDataType.String, PrimitiveDataType.Number,
      PrimitiveDataType.Boolean, PrimitiveDataType.Date)
  ) {
    // preferred binding has to be based on the binding of the LHS
    override suspend fun getPreferredPrimitiveDataType(
      calculationFunction: CalculationFunction,
      metadataProvider: MetadataProvider,
      scope: Array<ScopedVariable>
    ): PrimitiveDataType? {
      val assignToBinding = calculationFunction.bindings.find { it.name == "assignTo" }
      if (assignToBinding == null) {
        return null;
      }
      val assignToDataType = BindingResolver.ResolveDataTypeForBinding(
        calculationFunction,
        assignToBinding,
        metadataProvider,
        scope
      )
      return assignToDataType?.primitive
    }
  }

  override fun getBindingDescriptors(
  ): Array<BindingDescriptor> =  arrayOf(assignToDescriptor, valueDescriptor);

  override fun getDisplayTemplate(
  ): String = "\$assignTo = \$value"
}
