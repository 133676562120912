package com.adaptify.rating.admin.model.flow

import kotlin.js.JsExport

@JsExport
enum class FlowType {
  Workflow,
  Calculation
}


