package com.adaptify.rating.admin.model.flow

import kotlin.js.JsExport

@JsExport
enum class NodeType {
  Start,
  End,
  Branch,
  Calculation,
  Condition,
  Table,
}


