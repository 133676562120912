package com.adaptify.rating.admin.model.calculation.descriptor

import com.adaptify.rating.admin.model.calculation.descriptor.lib.*
import com.adaptify.rating.admin.model.calculation.descriptor.lib.predicates.*
import kotlin.js.JsExport

@JsExport
val FunctionDescriptors = arrayOf<FunctionDescriptor>(
  AssignDescriptor,
  AddDescriptor,

  SubtractDescriptor,
  MultiplyDescriptor,
  DivideDescriptor,
  RoundDescriptor,
  RoundUpDescriptor,
  RoundDownDescriptor,

  MinDescriptor,
  MaxDescriptor,

  DaysBetweenDescriptor,
  MonthsBetweenDescriptor,
  YearsBetweenDescriptor,

  IfStatementDescriptor,
  ForEachDescriptor,

  IfBranchDescriptor,
  TransitionDescriptor,


  // Remove if descriptor that allows you to have separate blocks of value
  // since if statements will be delegated to being used for conditional branching on flows
//  LegacyIfDescriptor(),
  // Remove for each descriptor unless product team comes back and asks for it
  //ForEachDescriptor(),
  // Remove the ability to call tables from calculations, limit it to the flow
  // until product decides how it should work

  // for some reason product fields that table lookups need to be overridable by
  // literal values, and the override cannot be performed in the table node
  // so we need a table lookup function which only supports a single output column
  // just to support overrides
   TableDescriptor,
  EqualsPredicateDescriptor,
  NotEqualsPredicateDescriptor,
  GreaterThanPredicateDescriptor,
  GreaterThanEqualsPredicateDescriptor,
  LessThanPredicateDescriptor,
  LessThanEqualsPredicateDescriptor,
  ConditionDescriptor,
  // switch descriptor is disabled along with it's companion brnach descriptor as the
  // product team has not asked for it
 // SwitchDescriptor(),
  // BranchDescriptor(),
  // CreateTypeDescriptor(),
  // ReturnDescriptor(),
  // CalculationDescriptor(),
  )

@JsExport
val FunctionDescriptorMap = FunctionDescriptors.associateBy { it.name }
