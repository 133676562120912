import React from 'react';
import {useParams} from 'react-router-dom';
import {NavigationAware} from '../../NavigationAware';
import {ValueListService} from '../service/ValueListService';
import {ValueListItemControl} from './ValueListItemControl';

export interface ValueListItemPageProps extends NavigationAware {
  valueListService: ValueListService;
}

type ValueListItemPageParams = {
  valueDefinitionId: string;
};
export function ValueListItemPage(props: ValueListItemPageProps) {
  const {valueDefinitionId} = useParams<ValueListItemPageParams>();
  if (valueDefinitionId === undefined) {
    return <> </>;
  }
  return (
    <ValueListItemControl
      valueListService={props.valueListService}
      valueListDefinitionId={valueDefinitionId}
    />
  );
}
