import {Button, Col, Flex, Form, Input, Row, Select, Typography} from 'antd';
import {BaseOptionType} from 'antd/es/select';
import React, {useEffect, useState} from 'react';

import {useNavigate} from 'react-router-dom';
import {ButtonSettings} from '../../common/control/Common';
import {NewTenantAwareEntity} from '../../common/model/Common';
import {IdAndName, ProductVersionSummary} from '../../product/model/Product';
import {ProductService} from '../../product/service/ProductService';
import {StudyTableComparison} from '../model/ComparativeAnalysis';
import {AnalysisService} from '../service/AnalysisService';

export interface CreateTableComparisonFormProps {
  analysisService: AnalysisService;
  productService: ProductService;
  studyId: string;
  onCancel: () => void;
}

interface CreateTableComparisonFormValues {
  comparisonKey: string;
  name: string;
  comments: string;
  tableMappings: CreateTableComparisonProductTableMapping[];
}

interface CreateTableComparisonProductTableMapping {
  key: string;
  productVersionId: string;
  carrierName: string;
  tableId: string;
}

export function CreateTableComparisonForm(
  props: CreateTableComparisonFormProps
) {
  const [form] = Form.useForm<CreateTableComparisonFormValues>();

  const [productVersionFormValues, setProductVersionFormValues] =
    React.useState<CreateTableComparisonFormValues[]>([]);

  const [tablesByProductVersionId, setTablesByProductVersionId] =
    React.useState<Map<String, IdAndName[]>>(new Map<String, IdAndName[]>());

  const [refreshLocalCount, setRefreshLocalCount] = useState(0);
  const [currentProductTableId, setCurrentProductTableId] =
    useState<string>('');

  const navigate = useNavigate();

  useEffect(() => {
    form.resetFields();
    const eff = async () => {
      // this is a bunch of service calls, it might be possible to reduce network traffic by combining them
      // but just trying to run most of them in parallel as a first pass
      // getting product versions must be done by itself here
      // we need the carrier name as well here
      const studyProductVersions =
        await props.analysisService.GetStudyProductVersionsByStudyId(
          props.studyId
        );
      // but all the table calls can be concurrent, build a promise with all our info, and then await all the promises at the end
      const productVersionIdAndTablePromises = studyProductVersions.map(spv => {
        return props.productService
          .GetProductVersionTableNamesByProductVersionId(spv.productVersionId)
          .then(tables => {
            return {
              productVersionId: spv.productVersionId,
              tables: tables,
            };
          });
      });
      const productVersionSummaryPromises = studyProductVersions.map(spv => {
        return props.productService
          .GetProductVersionSummaryById(spv.productVersionId)
          .then(summary => {
            return {
              productVersionId: spv.productVersionId,
              summary: summary,
            };
          });
      });

      const productVersionIdAndTables = await Promise.all(
        productVersionIdAndTablePromises
      );
      const productVersionSummaries = await Promise.all(
        productVersionSummaryPromises
      );

      const newTableMap = new Map<String, IdAndName[]>();
      productVersionIdAndTables.forEach(pvt => {
        newTableMap.set(pvt.productVersionId, pvt.tables);
      });
      setTablesByProductVersionId(newTableMap);

      const summaryMap = new Map<String, ProductVersionSummary>();
      productVersionSummaries.forEach(pvs => {
        summaryMap.set(pvs.productVersionId, pvs.summary);
      });

      const tableMappings = {};
      // logically it would be great if this was an array, but matching the format ant form list uses
      studyProductVersions.forEach((spv, index) => {
        const mapping = {
          key: spv.productVersionId,
          productVersionId: spv.productVersionId,
          carrierName: summaryMap.get(spv.productVersionId)?.carrierName || '',
          tableId: '',
        };
        tableMappings[index] = mapping;
      });

      form.setFieldValue('tableMappings', tableMappings);
    };
    eff();
  }, [props.studyId]);

  function getTableOptions(productVersionId: string): BaseOptionType[] {
    const tables = tablesByProductVersionId.get(productVersionId);
    if (!tables) {
      return [];
    }
    const tableOptions = tables.map(v => ({
      label: v.name,
      value: v.id,
    }));
    // add empty option
    tableOptions.unshift({label: '', value: ''});
    return tableOptions;
  }

  function okButtonEnabled() {
    // at least 2 tables must be picked for comparison
    return (
      form.getFieldsValue().tableMappings &&
      form.getFieldsValue().tableMappings.filter(tm => tm.tableId !== '')
        .length > 1
    );
  }

  function onFormValuesChange(changedValues: any, allValues: any) {
    if (changedValues.tableMappings) {
      // force a rerender so that we show the table link correctly and the ok button enablement
      setRefreshLocalCount(refreshLocalCount + 1);
    }
  }

  async function onSaveTableComparison(
    values: CreateTableComparisonFormValues
  ) {
    const tableComparison = {
      ...NewTenantAwareEntity(),
      studyId: props.studyId,
      name: values.name,
      comments: values.comments,
    } as StudyTableComparison;

    const tableIds = Object.values(values.tableMappings).map(
      tbl => tbl.tableId
    );
    const updated =
      await props.analysisService.UpdateStudyTableComparisonMergeTables(
        tableComparison,
        tableIds
      );
    // and then we have navigate to the table comparison page, it's not built yet, so go back to study page
    navigate(`/analysis/studytablecomparison/${updated.id}/detail`);
  }

  const tableMappings = form.getFieldValue('tableMappings');

  const readOnlyStyle = {backgroundColor: '#F0F0F0'};

  const tableMappingElements = Object.values(tableMappings || {}).map(
    (mapping, index) => (
      <>
        <Col span={12}>
          <Input
            value={mapping.carrierName}
            readOnly
            style={{...readOnlyStyle}}
          />
        </Col>
        <Col span={12}>
          <Form.Item
            name={['tableMappings', index, 'tableId']}
            wrapperCol={{span: 24}}
          >
            <Select
              placeholder="Select a Table"
              options={getTableOptions(mapping.productVersionId)}
              showSearch
              optionFilterProp="label"
            />
          </Form.Item>
        </Col>
      </>
    )
  );

  return (
    <>
      <Flex vertical>
        <Form
          layout="vertical"
          size="large"
          labelCol={{span: 24}}
          wrapperCol={{span: 24}}
          name="edit_study"
          labelWrap={true}
          style={{width: '100%'}}
          onValuesChange={onFormValuesChange}
          onFinish={onSaveTableComparison}
          autoComplete="off"
          form={form}
        >
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                labelCol={{span: 6}}
                name="name"
                label="Table Comparison Name"
                rules={[{required: true}]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                labelCol={{span: 6}}
                name="comments"
                label="Table Comparison Description"
                rules={[{required: true}]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}></Col>
            <Col span={24}>
              <Flex style={{width: '100%'}} justify="center" align="flex-start">
                <Typography.Title
                  level={5}
                  style={{color: 'black', marginBottom: '20px'}}
                >
                  Select Tables for the Table Comparison
                </Typography.Title>
              </Flex>
            </Col>
          </Row>
          <Row style={{paddingBottom: '20px'}}>
            <Col span={12} style={{textAlign: 'center'}}>
              <Typography.Text strong>Carrier Name</Typography.Text>
            </Col>
            <Col span={12} style={{textAlign: 'center'}}>
              <Typography.Text strong>Table Name</Typography.Text>
            </Col>
          </Row>
          <Row gutter={20}>
            {tableMappingElements}
            <Col span={24}>
              <Flex justify="end" className="gap-2">
                <Button {...ButtonSettings} onClick={props.onCancel}>
                  Cancel
                </Button>
                <Button
                  ghost={false}
                  type="default"
                  disabled={!okButtonEnabled()}
                  htmlType="submit"
                >
                  Generate Comparison
                </Button>
              </Flex>
            </Col>
          </Row>
        </Form>
      </Flex>
    </>
  );
}
