package com.adaptify.rating.admin.model.calculation.validation.lib

import com.adaptify.rating.admin.model.calculation.BindingType
import com.adaptify.rating.admin.model.calculation.CalculationFunction
import com.adaptify.rating.admin.model.calculation.binding.Binding
import com.adaptify.rating.admin.model.calculation.context.CalculationEvalContext
import com.adaptify.rating.admin.model.calculation.descriptor.BindingDescriptor
import com.adaptify.rating.admin.model.calculation.descriptor.FunctionDescriptor
import com.adaptify.rating.admin.model.calculation.descriptor.FunctionDescriptorMap
import com.adaptify.rating.admin.model.calculation.env.MetadataProvider
import com.adaptify.rating.admin.model.calculation.validation.BindingValidator
import com.adaptify.rating.admin.model.calculation.validation.FunctionValidationMessage
import com.adaptify.rating.admin.model.calculation.validation.FunctionValidator
import com.adaptify.rating.admin.model.util.BindingResolver

class RequiredBindingPresentValidator : BindingValidator {

  override suspend fun validateBinding(
    binding: Binding?,
    bindingDescriptor: BindingDescriptor,
    function: CalculationFunction,
    functionDescriptor: FunctionDescriptor,
    metadataProvider: MetadataProvider,
    evalContext: CalculationEvalContext
  ): Array<FunctionValidationMessage> {
    if (binding != null) {
      val isEmptyBlock = (binding.bindingType == BindingType.Block.name && (binding.block?.isEmpty() ?: true)); ;
      val isEmptyPredicate = (binding.bindingType == BindingType.Predicate.name && (binding.predicate?.functions?.isEmpty() ?: true));
      if (!isEmptyBlock && !isEmptyPredicate) {
        return emptyArray()
      }
    }

    if (bindingDescriptor.isOptional()) {
      return emptyArray()
    }
    return arrayOf(FunctionValidationMessage("${bindingDescriptor.displayName} is required but not present",
      function.id, bindingDescriptor.name))
  }
}
