import React from 'react';
import {NavigationAware} from '../../NavigationAware';
import {ValueListService} from '../service/ValueListService';
import {ValueListDefinitionControl} from './ValueListDefinitionControl';

export interface ValueListDefinitionPageProps extends NavigationAware {
  valueListService: ValueListService;
}
export function ValueListDefinitionPage(props: ValueListDefinitionPageProps) {
  return (
    <ValueListDefinitionControl valueListService={props.valueListService} />
  );
}
