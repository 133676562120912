package com.adaptify.rating.admin.model.calculation.util

import com.adaptify.rating.admin.model.calculation.BindingType
import com.adaptify.rating.admin.model.calculation.CalculationFunction
import com.adaptify.rating.admin.model.calculation.descriptor.BoundDataType
import com.adaptify.rating.admin.model.flow.Flow
import com.adaptify.rating.admin.model.flow.Node
import com.adaptify.rating.admin.model.type.PrimitiveDataType
import kotlin.js.JsExport

@JsExport
object GlobalVariableUtil {

  // we aren't declaring variables types, but the only way that the variable type
  // cannot be determined by the function being used right now is if it's assignment
  // which derives the type of the variable from the right side of the assignment
  // we also only support variables on primitive types right now.  What this means is
  // that any variables type is either determinable without global variables
  // or it's the exact type of another global variable.  Global variables can theoretically
  // have circular dependencies, which should be a validation error
  fun getGlobalVariablesForFlow(flow : Flow) : Array<GlobalVariable>{
    return flow.nodes.flatMap { node -> getGlobalVariablesForNode(flow, node).asList() }.toTypedArray();
  }

  private fun getGlobalVariablesForNode(flow : Flow, node : Node) : Array<GlobalVariable>{
    val globalVariables = mutableListOf<GlobalVariable>()
    if (node.functions == null) {
      return globalVariables.toTypedArray()
    }

    node.functions.forEach { function ->
      globalVariables.addAll(getGlobalVariablesForFunction(flow, node, function))
    }
    return globalVariables.toTypedArray()
  }
}

private fun getGlobalVariablesForFunction(flow : Flow, node : Node, function : CalculationFunction) : Array<GlobalVariable>{
  val globalVariables = mutableListOf<GlobalVariable>()
  function.bindings.forEach { binding ->
    val varName = binding.variableDeclaration?.name
    val dataType = binding.variableDeclaration?.dataType
    if (binding.bindingType == BindingType.VariableDeclaration.name &&
      varName != null && dataType != null) {
      globalVariables.add(GlobalVariable(flow.id, node.id, function.id, varName,
        dataType))
    }
    if (binding.bindingType == BindingType.Block.name &&
      binding.block != null) {
      binding.block!!.forEach { globalVariables.addAll(
        getGlobalVariablesForFunction(flow, node, it))
      }
    }
    if (binding.bindingType == BindingType.Predicate.name &&
      binding.predicate?.functions != null) {
      binding.predicate!!.functions.forEach { globalVariables.addAll(
        getGlobalVariablesForFunction(flow, node, it))
      }
    }
  }
  return globalVariables.toTypedArray()
}

@JsExport
class GlobalVariable(val flowId : String,
                     val nodeId : String,
                     val functionId: String,
                     val name : String,
                     val primitiveDataType: String)



