package com.adaptify.rating.admin.model.calculation.validation

import com.adaptify.rating.admin.model.calculation.descriptor.BindingDescriptor
import kotlin.js.JsExport

@JsExport
class NodeValidationResult(val nodeId: String,
                           val messages : Array<String>,
                           val functionMessages : Array<FunctionValidationMessage>) {
  fun isValid() : Boolean {
    return messages.isEmpty() && functionMessages.isEmpty();
  }
}
