import {v4 as uuidv4} from 'uuid';

export interface SystemFieldsEntity {
  id: string;
  new: boolean;
  createdAt: string;
  updatedAt: string;
  version: number;
  createdBy: string;
  updatedBy: string;
}

export interface TenantAwareEntity extends SystemFieldsEntity {
  tenantId: string;
}

export function NewTenantAwareEntity(): TenantAwareEntity {
  return {
    id: uuidv4(),
    tenantId: '',
    new: true,
    createdAt: '',
    updatedAt: '',
    version: 0,
    createdBy: '',
    updatedBy: '',
  };
}

export const TenantQueryMode = {
  TenantOnly: 'TenantOnly',
  AdaptifyOnly: 'AdaptifyOnly',
} as const;
