import * as kot from 'adaptify-multi-module-rating-admin-model';
import {Button, Flex, Form, Input, Select} from 'antd';
import {BaseOptionType} from 'antd/es/select';
import React, {useEffect, useState} from 'react';
import {ButtonSettings} from '../../../common/control/Common';
import {LobItemType} from '../../../lob/model/LineOfBusiness';
import {ProductService} from '../../../product/service/ProductService';
import LineOfBusinessHierarchy = kot.com.adaptify.rating.admin.model.lob.LineOfBusinessHierarchy;
import LineOfBusinessHierarchyItem = kot.com.adaptify.rating.admin.model.lob.LineOfBusinessHierarchyItem;

export interface EditRiskFlowFormProps {
  productService: ProductService;
  productVersionId: string;
  isNew: boolean;
  initialName: string;

  onSave: (
    name: string,
    lobRiskItemId: string,
    lobRiskItemTenantId: string
  ) => void;
  onCancel: () => void;
}

interface EditRiskFlowFormValues {
  name: string;
  lobRiskItemId: string;
}

export function EditRiskFlowForm(props: EditRiskFlowFormProps) {
  const [form] = Form.useForm<EditRiskFlowFormValues>();

  const [lobHierarchy, setLobHierarchy] = useState<
    LineOfBusinessHierarchy | undefined
  >();

  const riskOptions = buildRiskSelectOptionsForHierarhcy(lobHierarchy);

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue({name: props.initialName});
  }, [props.initialName]);

  useEffect(() => {
    const eff = async () => {
      if (!props.productVersionId) {
        setLobHierarchy(undefined);
        return;
      }

      const loaded =
        await props.productService.GetLobHierarchyForProductVersionId(
          props.productVersionId
        );
      setLobHierarchy(loaded);
    };
    eff();
  }, [props.productVersionId]);

  function onSave(values: EditRiskFlowFormValues) {
    // merge values in the form with the existing object
    // all records in the lob hierarchy should have the same tenant id, so use the tenant id of the parent here
    // sinve it's easier to get
    props.onSave(
      values.name,
      values.lobRiskItemId,
      lobHierarchy?.tenantId ?? ''
    );
  }

  return (
    <Form
      name="edit_flow_name"
      labelWrap={true}
      layout="vertical"
      size="large"
      labelCol={{span: 24}}
      wrapperCol={{span: 24}}
      onFinish={onSave}
      autoComplete="off"
      form={form}
    >
      <Form.Item
        name="name"
        label="Risk Item Calculation Name"
        rules={[{required: true}]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="lobRiskItemId"
        label="Risk Item"
        rules={[{required: true}]}
      >
        <Select
          showSearch
          optionFilterProp="label"
          style={{width: '100%'}}
          options={riskOptions}
        />
      </Form.Item>
      <Flex justify="end" className="w-full gap-2">
        <Button {...ButtonSettings} htmlType="button" onClick={props.onCancel}>
          Cancel
        </Button>
        <Button ghost={false} type="default" htmlType="submit">
          OK
        </Button>
      </Flex>
    </Form>
  );
}
function buildRiskSelectOptionsForHierarhcy(
  lobHierarchy: LineOfBusinessHierarchy | undefined
): BaseOptionType[] {
  if (!lobHierarchy) {
    return [];
  }
  const options = [];
  (lobHierarchy.risks || []).forEach(r => {
    buildRiskSelectOptionsForRiskItem(r, options);
  });
  return options;
}

function buildRiskSelectOptionsForRiskItem(
  item: LineOfBusinessHierarchyItem,
  options: BaseOptionType[]
) {
  if (item.itemType !== LobItemType.Risk) {
    return;
  }

  options.push({
    label: item.name,
    value: item.id,
  });

  item?.children?.forEach(c => {
    buildRiskSelectOptionsForRiskItem(c, options);
  });
}
