package com.adaptify.rating.admin.model.calculation.binding

import kotlinx.serialization.Serializable
import kotlin.js.JsExport

@JsExport
@Serializable
enum class ConjunctionType(val displayName : String) {
    And("AND"),
    Or("OR")
}
