import {DependencyList, useEffect, useState} from 'react';

type FetchState<T> = [T | null, Error | null, boolean];

type UseApiOptions<T> = {
  initialLoading?: boolean;
  onSuccess?: (data: T) => void;
  onError?: (error: Error) => void;
};

export const useApi = <T>(
  call: () => Promise<T>,
  deps: DependencyList = [],
  options: UseApiOptions<T> = {}
): FetchState<T> => {
  const {initialLoading = true, onSuccess, onError} = options;
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState<boolean>(initialLoading);

  useEffect(() => {
    const abortController = new AbortController();
    (async () => {
      setLoading(true);
      setError(null);
      try {
        const result = await call();
        if (!abortController.signal.aborted) {
          setData(result);
          onSuccess?.(result);
        }
      } catch (err: unknown) {
        if (!abortController.signal.aborted) {
          const error =
            err instanceof Error ? err : new Error('An unknown error occurred');
          setError(error);
          onError?.(error);
        }
      } finally {
        if (!abortController.signal.aborted) {
          setLoading(false);
        }
      }
    })();

    return () => {
      abortController.abort();
    };
  }, deps);

  return [data, error, loading];
};
