package com.adaptify.rating.admin.model.calculation

import com.adaptify.rating.admin.model.calculation.binding.Binding

@JsExport
external actual interface CalculationFunction {
    actual var id: String
    actual var name: String
    actual var bindings: Array<Binding>
}

actual fun createCalculationFunction(id: String, name: String, bindings: Array<Binding>): CalculationFunction {
  return js("({id: id, name: name, bindings: bindings})")
}


