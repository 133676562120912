import {UserOutlined} from '@ant-design/icons';
import {Avatar, Button, Dropdown, MenuProps, Typography} from 'antd';
import React, {useState} from 'react';
import {DraggableModal} from '../../common/control/DraggableModal';
import NewUserService from '../service/UserService';
import {AuthManager} from '../util/AuthManager';
import {ChangePasswordForm} from './ChangePasswordForm';

interface LoginAvatarProps {
  authToken: string;
  setAuthToken: (token: string) => void;
  logout: () => void;
  authManager: AuthManager;
}

export function LoginAvatar(props: LoginAvatarProps) {
  const [changePasswordOpen, setChangePasswordOpen] = useState(false);

  function closeChangePassswordModal() {
    setChangePasswordOpen(false);
  }

  function openChangePasswordModal() {
    setChangePasswordOpen(true);
  }

  const advancedActionMenu: MenuProps['items'] = [
    {
      label: (
        <div>
          <Typography.Title level={5}>
            Welcome {props.authManager.GetLoginUserId()}
          </Typography.Title>
        </div>
      ),
      key: '0',
      disabled: true,
    },
    {
      label: (
        <a target="_blank" rel="noopener noreferrer" onClick={props.logout}>
          Log out
        </a>
      ),
      key: '1',
    },
    {
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          onClick={openChangePasswordModal}
        >
          Change password
        </a>
      ),
      key: '2',
    },
  ];

  const changePasswordModal = changePasswordOpen ? (
    <DraggableModal
      className="adaptify-modal"
      title={'Change password'}
      open={changePasswordOpen}
      okButtonProps={{style: {display: 'none'}}}
      cancelButtonProps={{style: {display: 'none'}}}
      onCancel={closeChangePassswordModal}
    >
      <ChangePasswordForm
        userService={NewUserService(props.authToken)}
        onSubmit={closeChangePassswordModal}
        onCancel={closeChangePassswordModal}
      />
    </DraggableModal>
  ) : (
    <></>
  );

  return (
    <>
      <Dropdown
        className="opacity-80 hover:opacity-100 focus:opacity-100 rounded-full overflow-hidden border-2 hover:border-white cursor-pointer transition-[border-color]"
        trigger={['click']}
        menu={{items: advancedActionMenu}}
      >
        <Button
          type="link"
          className="px-0 pt-2"
          onClick={e => e.preventDefault()}
        >
          <Avatar onClick={e => e.preventDefault()} icon={<UserOutlined />} />
        </Button>
      </Dropdown>
      {changePasswordModal}
    </>
  );
}
