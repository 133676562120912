import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import {Button, Col, Flex, Row, Select, Typography} from 'antd';
import {BaseOptionType} from 'antd/es/select';
import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {ButtonSettings, StateCodeOptions} from '../../common/control/Common';
import {AuthManager} from '../../user/util/AuthManager';
import {IdAndValue} from '../../valuelist/model/ValueList';
import {ValueListService} from '../../valuelist/service/ValueListService';
import {Product, ProductQuery} from '../model/Product';

export interface ProductQueryFormProps {
  title?: string;
  authManager: AuthManager;
  valueListService: ValueListService;
  productQuery: ProductQuery;
  setProductQuery: (productQuery: ProductQuery) => void;
  onQuery: () => void;
  showTenantTabs?: boolean;
  adaptifyTabSelected?: boolean;
}

export function ProductQueryForm(props: ProductQueryFormProps) {
  const [carrierValueItems, setCarrierValueItems] = useState<IdAndValue[]>([]);
  const [underwritingCompanyValueItems, setUnderwritingCompanyValueItems] =
    useState<IdAndValue[]>([]);
  const [insuranceTypes, setInsuranceTypes] = useState<IdAndValue[]>([]);
  const [linesOfBusiness, setLinesOfBusiness] = useState<IdAndValue[]>([]);
  const [productTypes, setProductTypes] = useState<IdAndValue[]>([]);
  const [selectedCarrierId, setSelectedCarrierId] = useState<
    string | undefined
  >(undefined);
  const [selectedInsuranceTypeId, setSelectedInsuranceTypeId] = useState<
    string | undefined
  >(props.productQuery.insuranceType);
  const [selectedLineOfBusinessId, setSelectedLineOfBusinessId] = useState<
    string | undefined
  >(props.productQuery.insuranceType);
  const [lobName, setLobName] = useState<string | undefined>(
    props.productQuery.lineOfBusinessName
  );
  useEffect(() => {
    const eff = async () => {
      const loaded = await props.valueListService.GetCarriers();
      setCarrierValueItems(loaded);
    };
    eff();
  }, []);

  useEffect(() => {
    const eff = async () => {
      // we need to know whether the lob requires the form to be set, so we need to load it here
      const loaded = await props.valueListService.GetInsuranceTypes();
      setInsuranceTypes(loaded);
    };
    eff();
  }, []);

  useEffect(() => {
    const eff = async () => {
      // we need to know whether the lob requires the form to be set, so we need to load it here
      const loaded =
        await props.valueListService.GetUnderwritingCompanies(
          selectedCarrierId
        );
      setUnderwritingCompanyValueItems(loaded);
    };
    eff();
  }, [selectedCarrierId]);

  useEffect(() => {
    const eff = async () => {
      // we need to know whether the lob requires the form to be set, so we need to load it here
      const loaded = await props.valueListService.GetLinesOfBusiness(
        selectedInsuranceTypeId
      );
      setLinesOfBusiness(loaded);
    };
    eff();
  }, [selectedInsuranceTypeId]);

  useEffect(() => {
    const eff = async () => {
      // we need to know whether the lob requires the form to be set, so we need to load it here
      const loaded = await props.valueListService.GetProductTypes(
        selectedLineOfBusinessId
      );
      setProductTypes(loaded);
    };
    eff();
  }, [selectedLineOfBusinessId]);

  function getCarrierOptions(): BaseOptionType[] | undefined {
    return carrierValueItems.map(v => ({
      label: v.value,
      value: v.id,
    }));
  }

  function getUnderwritingCompanyOptions(): BaseOptionType[] {
    return underwritingCompanyValueItems.map(v => ({
      label: v.value,
      value: v.id,
    }));
  }

  function getInsuranceTypeOptions(): BaseOptionType[] {
    return insuranceTypes.map(v => ({
      label: v.value,
      value: v.id,
    }));
  }

  function getLineOfBusinessOptions(): BaseOptionType[] {
    return linesOfBusiness.map(v => ({
      label: v.value,
      value: v.id,
    }));
  }

  function getProductTypeOptions(): BaseOptionType[] {
    return productTypes.map(v => ({
      label: v.value,
      value: v.id,
    }));
  }

  function onSave(values: Product) {
    // merge values in the form with the existing object
    props.onSave({
      ...props.product,
      ...values,
    });
  }

  function onCarrierNameChange(value: string) {
    setSelectedCarrierId(value as string);
    props.setProductQuery({
      ...props.productQuery,
      carrierName: carrierValueItems.find(v => v.id === value)?.value,
    });
  }

  function onUnderwritingCompanyChange(value: string) {
    props.setProductQuery({
      ...props.productQuery,
      underwritingCompanyName: underwritingCompanyValueItems.find(
        v => v.id === value
      )?.value,
    });
  }

  function onInsuranceTypeChange(value: string) {
    setSelectedInsuranceTypeId(value);
    props.setProductQuery({
      ...props.productQuery,
      insuranceType: insuranceTypes.find(v => v.id === value)?.value,
    });
  }

  function onLineOfBusinessChange(value: string) {
    setSelectedLineOfBusinessId(value);
    props.setProductQuery({
      ...props.productQuery,
      lineOfBusinessName: linesOfBusiness.find(v => v.id === value)?.value,
    });
  }

  function onStateCodeChange(value: string) {
    props.setProductQuery({...props.productQuery, stateCode: value});
  }

  function onProductTypeChange(value: string) {
    props.setProductQuery({
      ...props.productQuery,
      productType: productTypes.find(v => v.id === value)?.value,
    });
  }

  const tenantTabs = props.showTenantTabs ? (
    <>
      <Link to="/platform/product/summary/adaptify">
        <Button
          className="page-title-nav"
          type="link"
          disabled={props.adaptifyTabSelected}
        >
          Adaptify Products
        </Button>
      </Link>
      <Link to="/platform/product/summary/my">
        <Button
          className="page-title-nav"
          type="link"
          disabled={!props.adaptifyTabSelected}
        >
          My Products
        </Button>
      </Link>
    </>
  ) : (
    <></>
  );
  return (
    <Flex vertical>
      <Row>
        <Col span={4}>
          <div className="page-title">
            {props.title ?? 'Rate Product Library'}
          </div>
        </Col>
        <Col span={20}>
          <Flex>{tenantTabs}</Flex>
        </Col>
      </Row>
      <div
        style={{
          border: 'solid',
          borderWidth: '1px',
          borderRadius: '8px',
          borderColor: '#CCCCCC',
          backgroundColor: '#F2F7FE',
        }}
      >
        <div
          style={{
            marginLeft: '16px',
            marginRight: '16px',
            marginTop: '16px',
            marginBottom: '16px',
          }}
        >
          <Row gutter={[10, 16]}>
            <Col span={3} className="adaptify-label-column">
              <Typography.Text>Carrier Name:</Typography.Text>
            </Col>
            <Col span={9}>
              <Select
                showSearch
                optionFilterProp="label"
                options={getCarrierOptions()}
                allowClear
                onChange={value => onCarrierNameChange(value)}
                style={{width: '100%'}}
              ></Select>
            </Col>
            <Col span={3} className="adaptify-label-column">
              <Typography.Text>Underwriting Company:</Typography.Text>
            </Col>
            <Col span={9}>
              <Select
                showSearch
                allowClear
                optionFilterProp="label"
                options={getUnderwritingCompanyOptions()}
                onChange={value => onUnderwritingCompanyChange(value)}
                style={{width: '100%'}}
              ></Select>
            </Col>
            <Col span={3} className="adaptify-label-column">
              <Typography.Text>Insurance Type:</Typography.Text>
            </Col>
            <Col span={3}>
              <Select
                showSearch
                allowClear
                optionFilterProp="label"
                options={getInsuranceTypeOptions()}
                onChange={value => onInsuranceTypeChange(value)}
                style={{width: '100%'}}
              ></Select>
            </Col>
            <Col span={3} className="adaptify-label-column">
              <Typography.Text>Line of Business:</Typography.Text>
            </Col>
            <Col span={3}>
              <Select
                showSearch
                allowClear
                optionFilterProp="label"
                options={getLineOfBusinessOptions()}
                onChange={value => onLineOfBusinessChange(value)}
                style={{width: '100%'}}
              ></Select>
            </Col>
            <Col span={3} className="adaptify-label-column">
              <Typography.Text>State:</Typography.Text>
            </Col>
            <Col span={3}>
              <Select
                showSearch
                allowClear
                optionFilterProp="label"
                options={StateCodeOptions}
                onChange={value => onStateCodeChange(value)}
                style={{width: '100%'}}
              ></Select>
            </Col>
            <Col span={3} className="adaptify-label-column">
              <Typography.Text>Product Type:</Typography.Text>
            </Col>
            <Col span={3}>
              <Select
                showSearch
                allowClear
                optionFilterProp="label"
                options={getProductTypeOptions()}
                onChange={value => onProductTypeChange(value)}
                style={{width: '100%'}}
              ></Select>
            </Col>
            <Col span={24}>
              <Flex justify="flex-end" align="flex-start">
                <Button
                  {...ButtonSettings}
                  icon={<SearchOutlinedIcon className="-m-1" />}
                  onClick={props.onQuery}
                >
                  Search
                </Button>
              </Flex>
            </Col>
          </Row>
        </div>
      </div>
    </Flex>
  );
}
