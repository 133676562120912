import React from 'react';
import {LobService} from '../../lob/service/LobService';
import {ProductService} from '../../product/service/ProductService';
import {ValueListService} from '../../valuelist/service/ValueListService';
import {AnalysisService} from '../service/AnalysisService';
import {AnalysisStudyListControl} from './AnalysisStudyListControl';

export interface AnalysisStudyListPageProps {
  analysisService: AnalysisService;
  productService: ProductService;
  lobService: LobService;
  valueListService: ValueListService;
  authToken: string;
}

export function AnalysisStudyListPage(props: AnalysisStudyListPageProps) {
  return (
    <AnalysisStudyListControl
      analysisService={props.analysisService}
      productService={props.productService}
      lobService={props.lobService}
      valueListService={props.valueListService}
    />
  );
}
