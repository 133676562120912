import '@ag-grid-community/styles/ag-grid.css'; // Core CSS
import '@ag-grid-community/styles/ag-theme-quartz.css';
import * as AntIcons from '@ant-design/icons';
import {DataGrid, GridColDef} from '@mui/x-data-grid';
import * as kot from 'adaptify-multi-module-rating-admin-model';
import {Button, Col, Row} from 'antd';
import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {DataGridSettings} from '../../../common/control/Common';
import {LobService} from '../../../lob/service/LobService';
import {ProductVersionFlowInfo} from '../../../product/model/Product';
import {ProductService} from '../../../product/service/ProductService';
import {RatingService} from '../../service/RatingService';
import LineOfBusinessHierarchyItem = kot.com.adaptify.rating.admin.model.lob.LineOfBusinessHierarchyItem;

export interface PremiumOrchestrationListProps {
  productVersionId: string;

  lobService: LobService;
  productService: ProductService;
  ratingService: RatingService;
  readOnly?: boolean;
}

interface LobRiskHierarchyInfo {
  level: number;
  globalSortOrderPre: number;
  globalSortOrderPost: number;
  lobItem: LineOfBusinessHierarchyItem;
}

interface PremiumListModel {
  isPlaceholder: boolean;
  id: string | undefined; // flowId
  flowVersion: number | undefined;
  isCoverage: boolean;
  parentLobItemId: string | undefined;
  parentLobRiskItemId: string | undefined;
  sign: string;
  riskItemDisplayName: string;
  flowName: string;
  level: number;
  riskSequenceNumber: number;
  flowSequenceNumber: number;
  sequenceAfterChildren: boolean;
  flowActualSequenceNumber: number | undefined;
}

function computeSortOrdersFromHierarchyRecur(
  lobItem: LineOfBusinessHierarchyItem,
  lobInfoMap: Map<string, LobRiskHierarchyInfo>,
  currentSortNumber: number
): number {
  const parentLevel =
    lobInfoMap.get(lobItem.parentLineOfBusinessItemId || '')?.level ?? -1;

  const lobHierarchyInfo = {
    level: parentLevel + 1,
    globalSortOrderPre: currentSortNumber,
    globalSortOrderPost: -1, // we'll come back and fix this after we've processed all the children
    lobItem: lobItem,
  };

  lobInfoMap.set(lobItem.id, lobHierarchyInfo);

  currentSortNumber = currentSortNumber + 1;
  const childLobItems = [
    ...lobItem.children.filter(c => c.itemType === 'Risk'),
  ].sort((a, b) => a.name.localeCompare(b.name));
  for (const child of childLobItems) {
    currentSortNumber = computeSortOrdersFromHierarchyRecur(
      child,
      lobInfoMap,
      currentSortNumber
    );
  }

  lobHierarchyInfo.globalSortOrderPost = currentSortNumber;

  return currentSortNumber + 1;
}

export function PremiumOrchestrationList(props: PremiumOrchestrationListProps) {
  // used to trigger a refresh on the UI on change
  const [flowSummaries, setFlowSummaries] = useState<ProductVersionFlowInfo[]>(
    []
  );
  const [lobHierarchyInfoMap, setLobHierarchyInfoMap] = useState<
    Map<string, LobRiskHierarchyInfo>
  >(new Map<string, LobRiskHierarchyInfo>());
  const [premiumListModels, setPremiumListModels] = useState<
    PremiumListModel[]
  >([]);
  const [modifyCount, setModifyCount] = useState<number>(0);

  useEffect(() => {
    const eff = async () => {
      const lobHierarchy =
        await props.productService.GetLobHierarchyForProductVersionId(
          props.productVersionId
        );
      // figure out the desired sort order of the LOB hierarchy
      // for a given level return flows sorted by name (either risk item name or coverage name)
      let currentSortNumber = 0;
      const newLobHierarchyMap = new Map<string, LobRiskHierarchyInfo>();
      for (const risk of lobHierarchy.risks) {
        currentSortNumber = computeSortOrdersFromHierarchyRecur(
          risk,
          newLobHierarchyMap,
          currentSortNumber
        );
      }

      setLobHierarchyInfoMap(newLobHierarchyMap);
    };
    eff();
  }, [props.productVersionId]);

  useEffect(() => {
    const eff = async () => {
      const productVersionFlowSummaries =
        await props.productService.GetProductVersionFlowSummariesByProductVersionId(
          props.productVersionId
        );

      setFlowSummaries(productVersionFlowSummaries);
    };
    eff();
  }, [props.productVersionId, modifyCount]);

  useEffect(() => {
    // reconstruct the model matching the UI based on the retrieved server data
    setPremiumListModels(buildListModel());
  }, [flowSummaries, lobHierarchyInfoMap]);

  function buildListModel(): PremiumListModel[] {
    // get all the flows in, then sort them, then add the placeholders in between

    const listModel = flowSummaries.map(flow => {
      const lobRiskItem = lobHierarchyInfoMap.get(flow.parentRiskItemId);
      const seqAfterChildren = flow?.sequenceAfterChildren ?? false;

      return {
        isPlaceholder: false,
        id: flow.id,
        flowVersion: flow.flowVersion,
        isCoverage: flow.parentLobItemType === 'Coverage',
        parentLobItemId: flow.parentLobItemId,
        parentLobRiskItemId: flow.parentRiskItemId,
        sign: '+',
        riskItemDisplayName: '',
        flowName: flow.displayName,
        level: lobRiskItem?.level ?? 0,
        riskSequenceNumber:
          (seqAfterChildren
            ? lobRiskItem?.globalSortOrderPost
            : lobRiskItem?.globalSortOrderPre) ?? 0,
        flowSequenceNumber: flow.sequenceNumber ?? 99999999,
        flowActualSequenceNumber: flow.sequenceNumber,
        sequenceAfterChildren: seqAfterChildren,
      };
    });

    // placeholders for the risk name in the list
    const distinctRiskItems = new Map<String, ProductVersionFlowInfo>();
    flowSummaries.forEach(f => {
      // we need a distinct set of risk item ids so that we create one placeholder for each
      distinctRiskItems.set(f.parentRiskItemId, f);
    });

    // this is a completely empty row
    const placeholders = [...distinctRiskItems.values()].map(flow => {
      const lobRiskItem = lobHierarchyInfoMap.get(flow.parentRiskItemId);
      return {
        isPlaceholder: true,
        id: undefined,
        flowVersion: undefined,
        isCoverage: false,
        parentLobItemId: flow.parentLobItemId,
        parentLobRiskItemId: flow.parentRiskItemId,
        sign: '',
        riskItemDisplayName: '',
        flowName: '',
        level: lobRiskItem?.level ?? 0,
        riskSequenceNumber: lobRiskItem?.globalSortOrderPre ?? 0,
        flowSequenceNumber: 0, // it doesn't matter what we put here, since the sort order handles placeholders specially
        flowActualSequenceNumber: undefined,
      } as PremiumListModel;
    });

    // sort the rows, and then we need to add the = sign to the first row overall
    // and the risk name to the first row in a group
    const fullModels = [...listModel, ...placeholders];
    fullModels.sort((a, b) => {
      if (a.riskSequenceNumber === b.riskSequenceNumber) {
        if (a.isPlaceholder === b.isPlaceholder) {
          if (a.riskSequenceNumber === b.riskSequenceNumber) {
            if (a.flowSequenceNumber === b.flowSequenceNumber) {
              // if sequence numebrs are the same sort on name
              return a.flowName.localeCompare(b.flowName);
            }
            return a.flowSequenceNumber - b.flowSequenceNumber;
          }
        }
        return a.isPlaceholder ? 1 : -1;
      }
      return a.riskSequenceNumber - b.riskSequenceNumber;
    });

    if (fullModels.length === 0) {
      return [];
    }

    const firstModel = fullModels[0];
    firstModel.sign = '=';
    firstModel.riskItemDisplayName = getModelDisplayName(firstModel);

    let previousRiskItemId = firstModel.parentLobRiskItemId;
    for (let i = 1; i < fullModels.length; i++) {
      const current = fullModels[i];
      if (current.isPlaceholder) {
        continue;
      }

      if (
        current.parentLobRiskItemId !== previousRiskItemId &&
        !current.sequenceAfterChildren
      ) {
        // whenever there a difference in the parent risk item, we need to add the risk item name
        // product doesn't want the name to show up again for policy flows that are shown after children
        // so make sure it's not included in that scenario
        current.riskItemDisplayName = getModelDisplayName(current);
        previousRiskItemId = current.parentLobRiskItemId;
      }
    }

    return fullModels;
  }

  function getModelDisplayName(model: PremiumListModel): string {
    if (!model.parentLobRiskItemId) {
      return '';
    }
    const lobHierarhcyInfo = lobHierarchyInfoMap.get(model.parentLobRiskItemId);
    const cardinalityString = lobHierarhcyInfo?.lobItem.isMany
      ? '1 to N'
      : '1 to 1';
    return `${lobHierarhcyInfo?.lobItem.name} (${cardinalityString})`;
  }

  function buildColumns(): GridColDef<PremiumListModel>[] {
    const colDefs: GridColDef<PremiumListModel>[] = [];
    const maxLevel = Math.max(
      ...[...lobHierarchyInfoMap.values()].map(v => v.level)
    );
    for (let i = 0; i <= maxLevel; i++) {
      colDefs.push(...buildColumnsPerLevel(i));
    }
    return colDefs;
  }

  function getModelToSwap(
    model: PremiumListModel,
    moveUp: boolean
  ): PremiumListModel | undefined {
    const index = premiumListModels.findIndex(m => m.id === model.id);
    const swapWithIndex = moveUp ? index - 1 : index + 1;
    if (swapWithIndex < 0 || swapWithIndex >= premiumListModels.length) {
      return undefined;
    }

    const swapModel = premiumListModels[swapWithIndex];
    if (
      swapModel.isPlaceholder ||
      swapModel.parentLobRiskItemId !== model.parentLobRiskItemId
    ) {
      return undefined;
    }
    return swapModel;
  }

  function canModelToMoveToDifferentGroup(
    model: PremiumListModel,
    moveUp: boolean
  ): boolean {
    // current product requirements is only the root policy level risk item flows can move
    if (model.level > 0) {
      // only flows based on root lob hierarchy item can be moved
      return false;
    }

    // moving up can only be done if model is run after children and vice versa
    if (model.sequenceAfterChildren !== moveUp) {
      // sequence after children = false means we can potentially move down but never up when changing groups
      // sequence after children = true operates the opposite way
      return false;
    }

    // leverage the can move up or can move down logic
    // if we can move up or down via swapping, we should be doing that, not switching groups
    if (canSwapFlow(model, moveUp)) {
      return false;
    }
    return true;
  }

  function canMoveUpFlow(model: PremiumListModel) {
    return canMoveUpOrDownFlow(model, true);
  }

  function canMoveDownFlow(model: PremiumListModel) {
    return canMoveUpOrDownFlow(model, false);
  }

  function canMoveUpOrDownFlow(model: PremiumListModel, moveUp: boolean) {
    return (
      canSwapFlow(model, moveUp) ||
      canModelToMoveToDifferentGroup(model, moveUp)
    );
  }

  function canSwapFlow(model: PremiumListModel, moveUp: boolean) {
    return getModelToSwap(model, moveUp) !== undefined;
  }

  async function onMoveUpOrDownFlow(model: PremiumListModel, moveUp: boolean) {
    const previousModel = getModelToSwap(model, moveUp);
    if (previousModel) {
      await onSwap(model, previousModel);
      return;
    }

    // we couldn't move within the group, try moving to a different group
    if (canModelToMoveToDifferentGroup(model, moveUp)) {
      await onMoveToDifferentGroup(model, moveUp);
    }
  }

  async function onMoveToDifferentGroup(
    model: PremiumListModel,
    moveUp: boolean
  ) {
    if (!canModelToMoveToDifferentGroup(model, moveUp)) {
      return;
    }

    // set the new model group
    const newSequenceAfterChildren = !moveUp;

    // set the new model to th
    const copyPremiumListModels = structuredClone(premiumListModels);
    const copyModel = copyPremiumListModels.find(m => m.id === model.id);
    if (!copyModel) {
      return;
    }

    copyModel.sequenceAfterChildren = newSequenceAfterChildren;

    // now that we've set the model, find the orders and reset the sequence number
    // it will naturally end up at the right part of the list if we filter based on already sorted order

    const modelsPerRisk = copyPremiumListModels.filter(
      m =>
        !m.isPlaceholder &&
        m.parentLobRiskItemId === model.parentLobRiskItemId &&
        (m.sequenceAfterChildren ?? false) === newSequenceAfterChildren
    );

    // renumber the sequence numbers to keep them clean
    let sequenceNumber = 100;
    for (const m of modelsPerRisk) {
      m.flowSequenceNumber = sequenceNumber;
      sequenceNumber = sequenceNumber + 100;
    }

    // update the flows
    await props.ratingService.UpdatePremiumOrchestration({
      items: modelsPerRisk.map(m => {
        return {
          productVersionFlowId: m.id || '',
          version: m.flowVersion || 0,
          sequenceNumber: m.flowSequenceNumber || 0,
          sequenceAfterChildren: m.sequenceAfterChildren,
        };
      }),
    });

    setModifyCount(modifyCount + 1);
    return;
  }

  async function onSwap(
    model: PremiumListModel,
    previousModel: PremiumListModel
  ) {
    if (
      previousModel.isPlaceholder &&
      previousModel.parentLobRiskItemId !== model.parentLobRiskItemId
    ) {
      return;
    }

    // if the flow sequence numbers are both populated and not the same, swap them
    if (
      model.flowActualSequenceNumber !== null &&
      previousModel.flowActualSequenceNumber !== null &&
      model.flowActualSequenceNumber !== previousModel.flowActualSequenceNumber
    ) {
      await props.ratingService.UpdatePremiumOrchestration({
        items: [
          {
            productVersionFlowId: model.id || '',
            version: model.flowVersion || 0,
            sequenceNumber: previousModel.flowSequenceNumber || 0,
            sequenceAfterChildren: model.sequenceAfterChildren,
          },
          {
            productVersionFlowId: previousModel.id || '',
            version: previousModel.flowVersion || 0,
            sequenceNumber: model.flowSequenceNumber || 0,
            sequenceAfterChildren: previousModel.sequenceAfterChildren,
          },
        ],
      });
      setModifyCount(modifyCount + 1);
      return;
    }
    const copyPremiumListModels = structuredClone(premiumListModels);

    // if either sequence number isn't defined, go define the sequence numbers for everything in the risk/coverage
    // in the same group
    const modelsPerRisk = copyPremiumListModels.filter(
      m =>
        !m.isPlaceholder &&
        m.parentLobRiskItemId === model.parentLobRiskItemId &&
        (m.sequenceAfterChildren ?? false) ===
          (model.sequenceAfterChildren ?? false)
    );
    let sequenceNumber = 100;
    for (const m of modelsPerRisk) {
      m.flowSequenceNumber = sequenceNumber;
      sequenceNumber = sequenceNumber + 100;
    }

    // define sequence numbers for everything based on current order, then swap them
    const copyModel = modelsPerRisk.find(m => m.id === model.id);
    const copyPreviousModel = modelsPerRisk.find(
      m => m.id === previousModel.id
    );

    if (!copyModel || !copyPreviousModel) {
      return;
    }
    const copySequenceNumber = copyModel.flowSequenceNumber;
    copyModel.flowSequenceNumber = copyPreviousModel.flowSequenceNumber;
    copyPreviousModel.flowSequenceNumber = copySequenceNumber;

    await props.ratingService.UpdatePremiumOrchestration({
      items: modelsPerRisk.map(m => {
        return {
          productVersionFlowId: m.id || '',
          version: m.flowVersion || 0,
          sequenceNumber: m.flowSequenceNumber || 0,
          sequenceAfterChildren: m.sequenceAfterChildren,
        };
      }),
    });
    setModifyCount(modifyCount + 1);
    return;
  }

  async function onMoveUpFlow(model: PremiumListModel) {
    return onMoveUpOrDownFlow(model, true);
  }

  async function onMoveDownFlow(model: PremiumListModel) {
    return onMoveUpOrDownFlow(model, false);
  }

  function buildColumnsPerLevel(level: number): GridColDef<PremiumListModel>[] {
    return [
      {
        field: 'riskItemDisplayName' + level,
        headerName: 'Risk Item',
        width: 250,
        valueGetter: (value, row) => {
          if (level !== row.level) {
            return '';
          }
          return row.riskItemDisplayName;
        },
        renderCell: params => {
          return (
            <Row align="middle" style={{height: '100%'}}>
              <Col span="24">{params.value}</Col>
            </Row>
          );
        },
      },
      {
        field: 'sign' + level,
        headerName: 'Sign',
        width: 50,
        valueGetter: (value, row) => {
          if (level !== row.level) {
            return '';
          }
          return row.sign;
        },
        renderCell: params => {
          return (
            <Row align="middle" style={{height: '100%'}}>
              <Col span="24">{params.value}</Col>
            </Row>
          );
        },
      },
      {
        field: 'name' + level,
        headerName: 'Risk Flow/Coverage',
        width: 300,
        valueGetter: (value, row) => {
          if (level !== row.level) {
            return '';
          }
          return row.flowName;
        },
        renderCell: params => {
          if (params.value === '') {
            // make sure that the list has a minimum height even when auto height is enabled
            return <div style={{height: '25px'}}></div>;
          }
          return (
            <Row align="middle" style={{minHeight: '25px'}}>
              <Col span={18}>
                {params.row.isCoverage ? (
                  <Link
                    to={`/rating/product/version/${props.productVersionId}/coverage?riskId=${params.row.parentLobRiskItemId}&coverageId=${params.row.parentLobItemId}`}
                    style={{textWrap: 'wrap'}}
                  >
                    {params.value}
                  </Link>
                ) : (
                  <Link
                    to={`/rating/product/version/${props.productVersionId}/risk?riskId=${params.row.parentLobRiskItemId}&flowId=${params.row.id}`}
                    style={{textWrap: 'wrap'}}
                  >
                    {params.value}
                  </Link>
                )}
              </Col>
              <Col span={3}>
                {props.readOnly ? (
                  <></>
                ) : (
                  <div
                    style={{
                      width: '100%',
                      textAlign: 'right',
                    }}
                  >
                    <Button
                      type="link"
                      disabled={!canMoveUpFlow(params.row)}
                      onClick={event => onMoveUpFlow(params.row)}
                    >
                      <AntIcons.ArrowUpOutlined />
                    </Button>
                  </div>
                )}
              </Col>
              <Col span={3}>
                {props.readOnly ? (
                  <></>
                ) : (
                  <div
                    style={{
                      width: '100%',
                      textAlign: 'right',
                    }}
                  >
                    <Button
                      type="link"
                      disabled={!canMoveDownFlow(params.row)}
                      onClick={event => onMoveDownFlow(params.row)}
                    >
                      <AntIcons.ArrowDownOutlined />
                    </Button>
                  </div>
                )}
              </Col>
            </Row>
          );
        },
      },
    ];
  }

  return (
    <>
      <div style={{height: '100%', width: '100%'}}>
        <div style={{height: '100%', width: '100%', overflow: 'scroll'}}>
          <DataGrid
            {...DataGridSettings}
            getRowId={r => r.id || r.parentLobRiskItemId || ''}
            getRowHeight={() => 'auto'}
            getCellClassName={params => 'GridBoxCell'}
            columns={buildColumns()}
            filterMode="client"
            rows={premiumListModels}
            pageSizeOptions={[30]}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 99,
                },
              },
            }}
            sx={{
              '&.MuiDataGrid-root': {
                borderRadius: '8px',
                overflow: 'hidden',
                borderColor: '#CCCCCC',
              },
            }}
          />
        </div>
      </div>
    </>
  );
}
