package com.adaptify.rating.admin.model.lob

import kotlinx.serialization.Serializable
import kotlin.js.JsExport

@JsExport
@Serializable
enum class Cardinality {
  Single,
  Many,
}
