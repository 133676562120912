import {Button, DatePicker, Flex, Form, Input} from 'antd';
import dayjs from 'dayjs';
import React, {useEffect} from 'react';
import {ButtonSettings, DateDataFormats} from '../../common/control/Common';
import {
  DateIsoStringToDayJs,
  DayJsToIsoString,
} from '../../common/model/CommonUtils';
import {ValueListService} from '../../valuelist/service/ValueListService';
import {TenantModule} from '../model/Tenant';
import {TenantService} from '../service/TenantService';

interface EditTenantModuleFormProps {
  tenantService: TenantService;
  valueListService: ValueListService;
  tenantModuleId?: string;
  onSaved: (tenant: TenantModule) => void;
  onCancel: () => void;
}

interface EditTenantModuleFormArgs {
  moduleName: string;
  endDate: dayjs.Dayjs | undefined;
}

export function EditTenantModuleForm(props: EditTenantModuleFormProps) {
  const [tenantModule, setTenantModule] = React.useState<
    TenantModule | undefined
  >(undefined);
  useEffect(() => {
    if (!props.tenantModuleId) {
      return;
    }

    const eff = async () => {
      const loaded = await props.tenantService.GetTenantModuleById(
        props.tenantModuleId ?? ''
      );
      setTenantModule(loaded);
      form.setFieldsValue({
        moduleName: loaded.moduleName,
        endDate: loaded.endDate
          ? DateIsoStringToDayJs(loaded.endDate)
          : undefined,
      });
    };
    eff();
  }, [props.tenantModuleId]);

  const [form] = Form.useForm<EditTenantModuleFormArgs>();

  async function onSave(value: EditTenantModuleFormArgs) {
    const toUpdate = {
      ...tenantModule,
      endDate: value.endDate ? DayJsToIsoString(value.endDate) : undefined,
    } as TenantModule;

    const updated = await props.tenantService.SaveTenantModule(toUpdate);
    props.onSaved(updated);
  }

  return (
    <Form
      name="edit_tenant_module"
      labelCol={{span: 8}}
      wrapperCol={{span: 16}}
      style={{maxWidth: 800}}
      onFinish={onSave}
      autoComplete="off"
      size="large"
      form={form}
    >
      <Form.Item name="moduleName" label="Module Name">
        <Input className="adaptify-read-only-field" disabled />
      </Form.Item>
      <Form.Item name="endDate" label="End Date">
        <DatePicker className="w-full" format={DateDataFormats} />
      </Form.Item>

      <Flex justify="end" style={{width: '100%'}} className="gap-2">
        <Button {...ButtonSettings} htmlType="button" onClick={props.onCancel}>
          Cancel
        </Button>
        <Button {...ButtonSettings} type="primary" htmlType="submit">
          Save
        </Button>
      </Flex>
    </Form>
  );
}
