package com.adaptify.rating.admin.model.calculation.util

import com.adaptify.rating.admin.model.calculation.BindingType
import com.adaptify.rating.admin.model.calculation.CalculationFunction
import kotlin.js.JsExport

@JsExport
object TransitionUtil {

  fun findAllTransitionNames(roots: Array<CalculationFunction>): Array<String> {
    val transitionNames = mutableListOf<String>()
    CalculationFunctionVisitor.visitArray(roots, { f ->
      for (binding in f.bindings) {
        if (binding.bindingType == BindingType.TransitionDefinition.name) {
          transitionNames.add(binding.transitionDefinition ?: "")
        }
      }
    } );
    return transitionNames.toTypedArray()
  }
}
