package com.adaptify.rating.admin.model.calculation.binding

import com.adaptify.rating.admin.model.calculation.CalculationFunction

@JsExport
external actual interface Predicate {
    actual var conjunctionType: String
    actual var functions: Array<CalculationFunction>
}

actual fun createPredicate(conjunctionType: String, functions: Array<CalculationFunction>): Predicate {
  return js("({conjunctionType: conjunctionType, functions: functions})")
}
