import {Flex} from 'antd';
import React, {useEffect, useState} from 'react';
import {useErrorHandler} from '../../common/control/ErrorHandler';
import {ADAPTIFY_TENANT_ID} from '../../common/service/Constants';
import {LobService} from '../../lob/service/LobService';
import {TenantService} from '../../user/service/TenantService';
import {AuthManager} from '../../user/util/AuthManager';
import {ValueListService} from '../../valuelist/service/ValueListService';
import {ProductList} from '../control/ProductList';
import {ProductQueryForm} from '../control/ProductQueryForm';
import {ProductQuery, ProductSummary} from '../model/Product';
import {ProductService} from '../service/ProductService';

export enum TenantMode {
  Adaptify = 'Adaptify',
  Tenant = 'Tenant',
  All = 'All',
}

export interface ProductOverviewControlProps {
  lobService: LobService;
  productService: ProductService;
  valueListService: ValueListService;
  tenantService: TenantService;
  authToken: string;
  authManager: AuthManager;
  adaptifyTenantMode: TenantMode;
}

export function ProductOverviewControl(props: ProductOverviewControlProps) {
  const [modifyCount, setModifyCount] = useState<number>(0);
  const [productQuery, setProductQuery] = useState<ProductQuery>({});
  const [productList, setProductList] = useState<ProductSummary[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<
    ProductSummary | undefined
  >(undefined);
  const valueListService = useErrorHandler(props.valueListService);
  const productService = useErrorHandler(props.productService);
  const [selectedTenant, setSelectedTenant] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    const tenantId =
      props.adaptifyTenantMode === TenantMode.Adaptify
        ? ADAPTIFY_TENANT_ID
        : props.adaptifyTenantMode === TenantMode.Tenant
          ? props.authManager.GetTenantId()
          : selectedTenant;
    setProductQuery({
      ...productQuery,
      tenantId: tenantId,
    });
    setModifyCount(modifyCount + 1);
  }, [props.adaptifyTenantMode]);

  useEffect(() => {
    const eff = async () => {
      // issue an initial query when the page is loaded
      await onQuery();
    };
    eff();
  }, [modifyCount, selectedTenant]);

  function onProductSelectionChanged(selection: ProductSummary | undefined) {
    setSelectedProduct(selection);
  }

  function onModified() {
    setModifyCount(modifyCount + 1);
  }

  async function onQuery() {
    const copyQuery = {...productQuery};
    if (selectedTenant) {
      copyQuery.tenantId = selectedTenant;
    }
    const products = await productService.QueryProduct(copyQuery);
    setProductList(products);
  }

  const showTenantTabs =
    props.adaptifyTenantMode !== TenantMode.All &&
    props.authManager.GetTenantId() !== ADAPTIFY_TENANT_ID;

  return (
    <Flex vertical gap={24}>
      <ProductQueryForm
        valueListService={props.valueListService}
        productQuery={productQuery}
        setProductQuery={setProductQuery}
        onQuery={onQuery}
        authManager={props.authManager}
        showTenantTabs={showTenantTabs}
        adaptifyTabSelected={props.adaptifyTenantMode === TenantMode.Adaptify}
        title={
          props.adaptifyTenantMode === TenantMode.All
            ? 'Client Product Lookup'
            : 'Rate Product Library'
        }
      />
      <div style={{height: '100%'}}>
        <ProductList
          title={
            props.adaptifyTenantMode === TenantMode.All
              ? 'Client Rate Product Library'
              : 'Rating Products'
          }
          lobService={props.lobService}
          authToken={props.authToken}
          productService={props.productService}
          valueListService={props.valueListService}
          tenantService={props.tenantService}
          productChanged={onModified}
          selection={selectedProduct}
          selectionChanged={onProductSelectionChanged}
          products={productList}
          readOnly={props.adaptifyTenantMode === TenantMode.Adaptify}
          showTenantSelect={props.adaptifyTenantMode === TenantMode.All}
          selectedTenant={selectedTenant}
          setSelectedTenant={setSelectedTenant}
        />
      </div>
    </Flex>
  );
}
