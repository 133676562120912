import {Button, DatePicker, Flex, Form, Input, Select} from 'antd';
import {BaseOptionType} from 'antd/es/select';
import dayjs from 'dayjs';
import React, {useEffect, useState} from 'react';
import {
  ButtonSettings,
  DateDataFormats,
  StateCodeOptions,
} from '../../common/control/Common';
import {DayJsToIsoString} from '../../common/model/CommonUtils';
import {IdAndValue} from '../../valuelist/model/ValueList';
import {ValueListService} from '../../valuelist/service/ValueListService';
import {ProductVersion} from '../model/Product';
import {ProductService} from '../service/ProductService';

export interface CopyProductVersionFormProps {
  productService: ProductService;
  valueListService: ValueListService;
  productVersionId: string;
  refreshFormCount: number;
  onFinish: (productVersion: ProductVersion) => void;
  onCancel: () => void;
  disableSameEffectiveDate?: boolean;
  disableNewEffectiveDate?: boolean;
  disableNewProductSameUWCompany?: boolean;
  disableNewProductNewUwCompany?: boolean;
}

interface CopyProductVersionFormValues {
  copyOption: string;
  carrierValueListItemId: string;
  underwritingCompanyValueListItemId: string;
  effectiveDate: dayjs.Dayjs;
  description: string;
  stateCode: string;
}

const SameEffectiveDate = 'SameEffectiveDate';
const NewEffectiveDate = 'NewEffectiveDate';
const NewProduct = 'NewProduct';
const NewProductUwCompany = 'NewProductUwCompany';

export function CopyProductVersionForm(props: CopyProductVersionFormProps) {
  const [form] = Form.useForm<CopyProductVersionFormValues>();

  const [carrierValueItems, setCarrierValueItems] = useState<IdAndValue[]>([]);
  const [underwritingCompanyValueItems, setUnderwritingCompanyValueItems] =
    useState<IdAndValue[]>([]);

  // using this to refresh form dependencies, since I cannot figure out how to use ant forms shouldUpdate correctly
  const [internalRefreshNum, setInternalRefreshNum] = React.useState(0);

  useEffect(() => {
    const eff = async () => {
      const loaded = await props.valueListService.GetCarriers();
      setCarrierValueItems(loaded);
    };
    eff();
  }, []);

  useEffect(() => {
    const eff = async () => {
      const carrierValueListId = form.getFieldsValue().carrierValueListItemId;
      if (!carrierValueListId || carrierValueListId === '') {
        setUnderwritingCompanyValueItems([]);
        return;
      }
      // we need to know whether the lob requires the form to be set, so we need to load it here
      const loaded = await props.valueListService.GetUnderwritingCompanies(
        form.getFieldsValue().carrierValueListItemId
      );
      setUnderwritingCompanyValueItems(loaded);
    };
    eff();
  }, [form.getFieldValue('carrierValueListItemId'), internalRefreshNum]);

  const copyOptions = [
    {
      label: 'Create new version within same effective Date',
      value: SameEffectiveDate,
    },
    {label: 'Create new effective date', value: NewEffectiveDate},
    {label: 'Create new product under a different state', value: NewProduct},
    {
      label: 'Create new product with a different underwriting company',
      value: NewProductUwCompany,
    },
  ].filter(
    v =>
      (v.value === SameEffectiveDate && !props.disableSameEffectiveDate) ||
      (v.value === NewEffectiveDate && !props.disableNewEffectiveDate) ||
      (v.value === NewProduct && !props.disableNewProductSameUWCompany) ||
      (v.value === NewProductUwCompany && !props.disableNewProductNewUwCompany)
  );

  useEffect(() => {
    //form.resetFields();
    form.setFieldsValue({
      ...form.getFieldsValue(),
      copyOption: copyOptions.length === 1 ? copyOptions[0].value : undefined,
    });
    setInternalRefreshNum(internalRefreshNum + 1);
  }, [
    props.disableNewEffectiveDate,
    props.disableNewProductNewUwCompany,
    props.disableNewProductSameUWCompany,
    props.disableSameEffectiveDate,
    props.refreshFormCount,
  ]);

  async function onCopy(value: CopyProductVersionFormValues) {
    let copyOption = value.copyOption;
    if (!copyOption && copyOptions.length === 1) {
      copyOption = copyOptions[0].value;
    }

    if (copyOption === SameEffectiveDate) {
      const pv = await props.productService.CopyProductVersion(
        props.productVersionId,
        value.description
      );
      props.onFinish(pv);
      return;
    }

    if (copyOption === NewEffectiveDate) {
      const pv = await props.productService.CopyProductVersionNewEffectiveDate(
        props.productVersionId,
        DayJsToIsoString(value.effectiveDate),
        value.description
      );
      props.onFinish(pv);
      return;
    }

    if (copyOption === NewProduct) {
      const pv = await props.productService.CopyProductVersionNewProduct(
        props.productVersionId,
        undefined,
        DayJsToIsoString(value.effectiveDate),
        value.stateCode,
        value.description
      );
      props.onFinish(pv);
      return;
    }
    if (copyOption === NewProductUwCompany) {
      const pv = await props.productService.CopyProductVersionNewProduct(
        props.productVersionId,
        value.underwritingCompanyValueListItemId,
        DayJsToIsoString(value.effectiveDate),
        value.stateCode,
        value.description
      );
      props.onFinish(pv);
      return;
    }
  }

  function onFormValuesChange(
    changedValues: CopyProductVersionFormValues,
    allValues: CopyProductVersionFormValues
  ) {
    if (changedValues.copyOption) {
      setInternalRefreshNum(internalRefreshNum + 1);
    }
    if (changedValues.carrierValueListItemId) {
      setInternalRefreshNum(internalRefreshNum + 1);
    }
  }

  function getCarrierNameOptions(): BaseOptionType[] {
    return carrierValueItems.map(v => ({
      label: v.value,
      value: v.id,
    }));
  }

  function getUnderwritingCompanyOptions(): BaseOptionType[] {
    return underwritingCompanyValueItems.map(v => ({
      label: v.value,
      value: v.id,
    }));
  }

  const currentCopyOption = form.getFieldValue('copyOption');

  const uwCompanyControl =
    [NewProductUwCompany].indexOf(currentCopyOption) >= 0 ? (
      <>
        <Form.Item
          name="carrierValueListItemId"
          label="Carrier Name"
          rules={[{required: true}]}
        >
          <Select
            showSearch
            optionFilterProp="label"
            placeholder="select a Carrier"
            options={getCarrierNameOptions()}
          />
        </Form.Item>
        <Form.Item
          name="underwritingCompanyValueListItemId"
          label="Underwriting Company"
          rules={[{required: true}]}
        >
          <Select
            showSearch
            optionFilterProp="label"
            placeholder="select an Underwriting Company"
            options={getUnderwritingCompanyOptions()}
          />
        </Form.Item>
      </>
    ) : (
      <></>
    );

  const effectiveDateControl =
    [NewEffectiveDate, NewProduct, NewProductUwCompany].indexOf(
      currentCopyOption
    ) >= 0 ? (
      <Form.Item
        name="effectiveDate"
        label="Effective Date"
        shouldUpdate
        rules={[{required: true}]}
      >
        <DatePicker format={DateDataFormats} />
      </Form.Item>
    ) : (
      <></>
    );

  // we must display the state when copying to a new underwrriting company but it cannot be changed for reasons
  // that I do not understand
  // TODO implement display and defaulting logic
  const stateCodeControl =
    [NewProduct].indexOf(currentCopyOption) >= 0 ? (
      <Form.Item
        name="stateCode"
        label="State"
        shouldUpdate
        rules={[{required: true}]}
      >
        <Select
          showSearch
          optionFilterProp="label"
          placeholder="select a State"
          options={StateCodeOptions}
        />
      </Form.Item>
    ) : (
      <></>
    );

  const copyOptionFormItem =
    copyOptions.length > 1 ? (
      <Form.Item
        name="copyOption"
        shouldUpdate
        label="What what you like to do with the copied rate prouct?"
        rules={[{required: true}]}
      >
        <Select
          showSearch
          optionFilterProp="label"
          placeholder="select a option"
          options={copyOptions}
        />
      </Form.Item>
    ) : (
      <></>
    );
  return (
    <Form
      name="copy_product_version"
      layout="vertical"
      size="large"
      labelCol={{span: 24}}
      wrapperCol={{span: 24}}
      labelWrap
      style={{width: '100%'}}
      onValuesChange={onFormValuesChange}
      onFinish={onCopy}
      autoComplete="off"
      form={form}
    >
      {copyOptionFormItem}
      {uwCompanyControl}
      {effectiveDateControl}
      {stateCodeControl}
      <Form.Item
        name="description"
        label="Description"
        shouldUpdate
        rules={[{required: true}]}
      >
        <Input />
      </Form.Item>
      <Flex style={{width: '100%'}} justify="end" className="gap-2">
        <Button {...ButtonSettings} htmlType="button" onClick={props.onCancel}>
          Cancel
        </Button>
        <Button ghost={false} type="default" htmlType="submit">
          Create
        </Button>
      </Flex>
    </Form>
  );
}
