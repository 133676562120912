package com.adaptify.rating.admin.model.calculation.validation

import com.adaptify.rating.admin.model.calculation.Calculation
import com.adaptify.rating.admin.model.calculation.CalculationFunction
import com.adaptify.rating.admin.model.calculation.context.CalculationEvalContext
import com.adaptify.rating.admin.model.calculation.context.ScopedVariable
import com.adaptify.rating.admin.model.calculation.env.MetadataProvider
import com.adaptify.rating.admin.model.calculation.util.CalculationFunctionVisitor
import com.adaptify.rating.admin.model.calculation.util.GlobalVariable
import com.adaptify.rating.admin.model.flow.Flow
import com.adaptify.rating.admin.model.flow.NodeType
import com.adaptify.rating.admin.model.lob.LineOfBusinessHierarchy

object FlowValidator {

  // for first pass, just function validations, will change the signature to reflect the expanded scope
// this validation could presumably run on the client or server, we are going to start with the client for
// now as it should have the optimal response time, but we have the option to move expensive validations to the server
// if needed
  suspend fun validateFlow(flow: Flow,
                           metadataProvider: MetadataProvider,
                           lineOfBusinessHierarchy: LineOfBusinessHierarchy,
                           globalVariables : Array<ScopedVariable>): FlowValidationResult {

    val nodeValidations = mutableListOf<NodeValidationResult>();


    // TODO generic validations on nodes and edges

    for (node in flow.nodes) {
      nodeValidations.add(
        NodeValidator.validateNode(flow, node, metadataProvider, lineOfBusinessHierarchy, globalVariables))
    }
    return FlowValidationResult(flow.id, emptyArray(), nodeValidations.toTypedArray())
  }
}
