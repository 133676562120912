import '@ag-grid-community/styles/ag-grid.css'; // Core CSS
import '@ag-grid-community/styles/ag-theme-quartz.css';
import {Button, Menu} from 'antd';
import {ItemType} from 'antd/es/menu/interface';
import React, {useEffect, useState} from 'react';
import {ButtonSettings} from '../../common/control/Common';
import {ValueListDefinition} from '../model/ValueList';
import {ValueListService} from '../service/ValueListService';

export interface ValueListChildTabControlProps {
  valueListService: ValueListService;
  readonly?: boolean;
  parentValueListDefinitionId: string | undefined;

  valueListDefinitionId: string | undefined;
  setValueListDefinitionId: (selection: string | undefined) => void;
}

export function ValueListChildTabControl(props: ValueListChildTabControlProps) {
  const [valueListDefinitions, setValueListDefinitions] = useState<
    ValueListDefinition[]
  >([]);
  useEffect(() => {
    const eff = async () => {
      const valueLists =
        await props.valueListService.GetAllValueListDefinitions();
      // TODO have a different service call rot roots vs children
      const filteredValueLists = valueLists.filter(
        l =>
          (l.parentValueListDefinitionId ?? '') ===
          (props.parentValueListDefinitionId ?? '')
      );

      setValueListDefinitions(filteredValueLists);
      if (!props.valueListDefinitionId && filteredValueLists.length > 0) {
        props.setValueListDefinitionId(filteredValueLists[0].id);
      } else {
        props.setValueListDefinitionId(undefined);
      }
    };
    eff();
  }, [props.parentValueListDefinitionId]);

  const childLists = valueListDefinitions.map(vld => {
    return {
      key: vld.id,
      label: (
        <Button {...ButtonSettings} type="link">
          {vld.name}
        </Button>
      ),
    } as ItemType;
  });
  // only show the tab if there are multiple children, this way we can configure n levels without cluttering the UI
  if (childLists.length < 2) {
    return <></>;
  }
  return (
    <Menu
      mode="horizontal"
      defaultSelectedKeys={
        props.valueListDefinitionId ? [props.valueListDefinitionId] : []
      }
      onSelect={e => {
        props.setValueListDefinitionId(e.key as string);
      }}
      style={{
        justifyContent: 'center',
        backgroundColor: '#F5F5F5',
        width: '100%',
      }}
      items={childLists}
    ></Menu>
  );
}
