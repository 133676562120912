import {SERVER_BASE_URL} from '../../common/service/Constants';
import {
  CreateStandardHeaders,
  HandleFetchJson,
} from '../../common/service/Service';
import {
  CreateTrialAccountRequest,
  TrialAccount,
  TrialAccountDetails,
  TrialAccountInfo,
} from '../model/TrialAccount';

const CREATE_TRIAL_ACCOUNT = `${SERVER_BASE_URL}/admin/trial/account/create`;
const GET_ALL_TRIAL_ACCOUNTS = `${SERVER_BASE_URL}/admin/trial/account/all`;
const GET_TRIAL_ACCOUNT = (id: string) =>
  `${SERVER_BASE_URL}/admin/trial/account/get/${id}`;

export interface TrialAccountService {
  CreateTrialAccount(request: CreateTrialAccountRequest): Promise<TrialAccount>;
  GetAllTrialAccounts(): Promise<TrialAccountInfo[]>;
  GetTrialAccount(id: string): Promise<TrialAccountDetails>;
}

export default function NewTrialAccountService(
  authToken: string
): TrialAccountService {
  function createHeaders() {
    return CreateStandardHeaders(authToken);
  }

  async function CreateTrialAccount(
    request: CreateTrialAccountRequest
  ): Promise<TrialAccount> {
    const requestOptions = {
      method: 'POST',
      headers: createHeaders(),
      body: JSON.stringify(request),
    };

    return HandleFetchJson(fetch(CREATE_TRIAL_ACCOUNT, requestOptions));
  }

  async function GetAllTrialAccounts(): Promise<TrialAccountInfo[]> {
    const requestOptions = {
      method: 'GET',
      headers: createHeaders(),
    };

    return HandleFetchJson(fetch(GET_ALL_TRIAL_ACCOUNTS, requestOptions));
  }

  async function GetTrialAccount(id: string): Promise<TrialAccountDetails> {
    const requestOptions = {
      method: 'GET',
      headers: createHeaders(),
    };

    return HandleFetchJson(fetch(GET_TRIAL_ACCOUNT(id), requestOptions));
  }

  return {
    CreateTrialAccount,
    GetAllTrialAccounts,
    GetTrialAccount,
  };
}
