import {Button, Col, Flex, Form, Input, Row, Typography} from 'antd';
import React, {useEffect} from 'react';
import {ButtonSettings} from '../../common/control/Common';
import {DateIsoStringToDisplayString} from '../../common/model/CommonUtils';
import {ProductVersionSummary} from '../model/Product';
import {FormatVersionNumberDisplayString} from '../model/ProductUtils';
import {ProductService} from '../service/ProductService';

export interface PublishProductVersionFormProps {
  productService: ProductService;
  productVersionId: string | undefined;
  onPublish: () => void;
  onCancel: () => void;
}

interface PublishProductFormValues {
  notes: string;
}

export function PublishProductVersionForm(
  props: PublishProductVersionFormProps
) {
  const [productVersion, setProductVersion] = React.useState<
    ProductVersionSummary | undefined
  >(undefined);
  const [form] = Form.useForm<PublishProductFormValues>();

  useEffect(() => {
    if (!props.productVersionId) {
      setProductVersion(undefined);
      return;
    }

    const eff = async () => {
      const pv = await props.productService.GetProductVersionSummaryById(
        props.productVersionId ?? ''
      );
      setProductVersion(pv);
    };
    eff();
  }, [props.productVersionId]);

  if (!props.productVersionId) {
    return <></>;
  }

  async function onFinishForm(values: PublishProductFormValues) {
    if (!props.productVersionId) {
      return;
    }

    await props.productService.PublishProductVersion(
      props.productVersionId,
      values.notes
    );
    props.onPublish();
  }

  function cancelDelete() {
    props.onCancel();
  }

  return (
    <Form
      name="publish_product_version"
      labelCol={{span: 4}}
      wrapperCol={{span: 20}}
      labelWrap={true}
      style={{width: '100%'}}
      onFinish={onFinishForm}
      autoComplete="off"
      form={form}
    >
      <Row gutter={[16, 30]} style={{paddingTop: '30px'}}>
        <Col span={24}>
          <Typography.Text style={{color: 'red', fontWeight: 'bold'}}>
            The following Rate Product Version has been selected for permanent
            published:
          </Typography.Text>
        </Col>
        <Col span={6}>
          <Typography.Text strong>Effective Date</Typography.Text>
        </Col>
        <Col span={6}>
          <Typography.Text strong>Product Version</Typography.Text>
        </Col>
        <Col span={6}>
          <Typography.Text strong>Status</Typography.Text>
        </Col>
        <Col span={6}>
          <Typography.Text strong>Description</Typography.Text>
        </Col>
        <Col span={6}>
          <Typography.Text>
            {DateIsoStringToDisplayString(productVersion?.effectiveDate)}
          </Typography.Text>
        </Col>
        <Col span={6}>
          <Typography.Text>
            {FormatVersionNumberDisplayString(productVersion?.versionNumber)}
          </Typography.Text>
        </Col>
        <Col span={6}>
          <Typography.Text>{productVersion?.status}</Typography.Text>
        </Col>
        <Col span={6}>
          <Typography.Text style={{textWrap: 'wrap'}}>
            {productVersion?.description}
          </Typography.Text>
        </Col>
        <Col span={24}>
          <Typography.Text style={{color: 'red', fontWeight: 'bold'}}>
            Selecting the "Publish Rate Product" button will make the Rate
            Product Version available within the platform.
          </Typography.Text>
        </Col>
        <Col span={24}>
          <Typography.Text style={{color: 'red', fontWeight: 'bold'}}>
            Select the "Cancel" button to navigate back to the Rate Product
            Version screen.
          </Typography.Text>
        </Col>
        <Col span={24}>
          <Form.Item name="notes" label="Note" rules={[{required: true}]}>
            <Input.TextArea />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Flex style={{width: '100%'}} justify="end" className="gap-2">
            <Button {...ButtonSettings} onClick={() => cancelDelete()}>
              Cancel
            </Button>
            <Button ghost={false} type="default" onClick={() => form.submit()}>
              Publish Rate Product
            </Button>
          </Flex>
        </Col>
      </Row>
    </Form>
  );
}
