package com.adaptify.rating.admin.model.calculation.descriptor

import com.adaptify.rating.admin.model.calculation.CalculationFunction
import com.adaptify.rating.admin.model.calculation.CalculationFunctionUtil
import com.adaptify.rating.admin.model.generateUUID
import kotlin.js.JsExport

@JsExport
abstract class FunctionDescriptor(val name : String, val displayName: String, val functionType: FunctionType, val category: String) {
    abstract fun getBindingDescriptors (
    ) :  Array<BindingDescriptor>

    // this method used to take in function and binding, but now no functions have dynamic names
    // (previous examples of dynamic names were functions calling other used defined named functions)
    abstract fun getDisplayTemplate  (
    ) : String

    open fun createDefault(roots: Array<CalculationFunction>,
                           parent: CalculationFunction? = null) : CalculationFunction {
      return CalculationFunctionUtil.create(generateUUID(), name, emptyArray());
    }
}

