import {HTTP_FORBIDDEN, SERVER_BASE_URL} from '../../common/service/Constants';
import {
  ConvertToQueryParams,
  CreateStandardHeaders,
  HandleFetchJson,
  HttpError,
} from '../../common/service/Service';
import {User, UserInfo, UserQuery} from '../model/User';

const LOGIN_URL = `${SERVER_BASE_URL}/auth/login`;
const CREATE_USER_URL = `${SERVER_BASE_URL}/user/createUser`;
const GET_USER_URL = (id: string) => `${SERVER_BASE_URL}/user/get/${id}`;
const UPDATE_USER_URL = `${SERVER_BASE_URL}/user/update`;
const CHANGE_PASSWORD_URL = `${SERVER_BASE_URL}/user/changePassword`;
const RESET_PASSWORD_URL = `${SERVER_BASE_URL}/user/resetPassword`;
const INACTIVATE_USER_URL = `${SERVER_BASE_URL}/user/inactivateUser`;
const ACTIVATE_USER_URL = `${SERVER_BASE_URL}/user/activateUser`;
const REFRESH_TOKEN_URL = `${SERVER_BASE_URL}/user/refreshToken`;
const USER_DETAILS_URL = `${SERVER_BASE_URL}/user/me`;

const QUERY_USER = (query: UserQuery) =>
  `${SERVER_BASE_URL}/user/query?${ConvertToQueryParams(query)}`;

export interface JwtToken {
  expiresIn: number;
  token: string;
}

export interface UserService {
  GetUser(userId: string): Promise<User>;
  UpdateUser(user: User): Promise<User>;
  LoginUser(email: string, password: string): Promise<string>;
  CreateUser(user: User): Promise<User>;
  QueryUser(query: UserQuery): Promise<User[]>;
  // change password is always for the current user
  ChangePassword(password: string, newPassword: string): Promise<User>;
  ResetPassword(email: string): Promise<User>;
  InactivateUser(email: string): Promise<User>;
  ActivateUser(email: string): Promise<User>;
  RefreshToken(): Promise<string>;
  GetUserDetails(): Promise<UserInfo>;
}

export default function NewUserService(authToken?: string): UserService {
  function createHeaders() {
    return authToken ? CreateStandardHeaders(authToken) : {};
  }

  async function GetUser(userId: string): Promise<User> {
    const requestOptions = {
      method: 'GET',
      headers: createHeaders(),
    };

    return HandleFetchJson(fetch(GET_USER_URL(userId), requestOptions));
  }

  async function UpdateUser(user: User): Promise<User> {
    const requestOptions = {
      method: 'POST',
      headers: createHeaders(),
      body: JSON.stringify(user),
    };

    return HandleFetchJson(fetch(UPDATE_USER_URL, requestOptions));
  }

  async function LoginUser(email: string, password: string): Promise<string> {
    const requestOptions = {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({email: email, password: password}),
    };

    const response = await fetch(LOGIN_URL, requestOptions);
    if (!response.ok) {
      console.log(response.body);
      return Promise.reject(
        new HttpError(response.status, await response.text())
      );
    }

    const body = (await response.json()) as JwtToken;
    return body.token;
  }

  async function CreateUser(user: User): Promise<User> {
    const requestOptions = {
      method: 'POST',
      headers: createHeaders(),
      body: JSON.stringify(user),
    };

    return HandleFetchJson(fetch(CREATE_USER_URL, requestOptions));
  }

  async function ChangePassword(
    password: string,
    newPassword: string
  ): Promise<any> {
    if (!authToken) {
      return Promise.reject(new HttpError(HTTP_FORBIDDEN, 'Not logged in'));
    }
    const requestOptions = {
      method: 'POST',
      headers: CreateStandardHeaders(authToken),
      body: JSON.stringify({password: password, newPassword: newPassword}),
    };

    const response = await fetch(CHANGE_PASSWORD_URL, requestOptions);
    if (!response.ok) {
      console.log(response.body);
      return Promise.reject(
        new HttpError(response.status, await response.text())
      );
    }

    await response.json();
    return;
  }

  async function ResetPassword(email: string): Promise<User> {
    const requestOptions = {
      method: 'POST',
      headers: createHeaders(),
      body: JSON.stringify({
        email: email,
      }),
    };

    return HandleFetchJson(fetch(RESET_PASSWORD_URL, requestOptions));
  }

  async function InactivateUser(email: string): Promise<User> {
    const requestOptions = {
      method: 'POST',
      headers: createHeaders(),
      body: JSON.stringify({
        email: email,
      }),
    };

    return HandleFetchJson(fetch(INACTIVATE_USER_URL, requestOptions));
  }

  async function ActivateUser(email: string): Promise<User> {
    const requestOptions = {
      method: 'POST',
      headers: createHeaders(),
      body: JSON.stringify({
        email: email,
      }),
    };

    return HandleFetchJson(fetch(ACTIVATE_USER_URL, requestOptions));
  }

  async function RefreshToken(): Promise<string> {
    if (!authToken) {
      return Promise.reject(new HttpError(HTTP_FORBIDDEN, 'Not logged in'));
    }
    const requestOptions = {
      method: 'POST',
      headers: CreateStandardHeaders(authToken),
    };

    const response = await fetch(REFRESH_TOKEN_URL, requestOptions);
    if (!response.ok) {
      console.log(response.body);
      return Promise.reject(
        new HttpError(response.status, await response.text())
      );
    }

    const body = (await response.json()) as JwtToken;
    return body.token;
  }
  async function GetUserDetails(): Promise<UserInfo> {
    if (!authToken) {
      return Promise.reject(new HttpError(HTTP_FORBIDDEN, 'Not logged in'));
    }
    const requestOptions = {
      method: 'GET',
      headers: CreateStandardHeaders(authToken),
    };

    const response = await fetch(USER_DETAILS_URL, requestOptions);
    if (!response.ok) {
      console.log(response.body);
      return Promise.reject(
        new HttpError(response.status, await response.text())
      );
    }

    return (await response.json()) as UserInfo;
  }

  async function QueryUser(query: UserQuery): Promise<User[]> {
    if (!authToken) {
      return Promise.reject(new HttpError(HTTP_FORBIDDEN, 'Not logged in'));
    }

    const requestOptions = {
      method: 'GET',
      headers: CreateStandardHeaders(authToken),
    };

    const response = await fetch(QUERY_USER(query), requestOptions);
    if (!response.ok) {
      console.log(response.body);
      return Promise.reject(
        new HttpError(response.status, await response.text())
      );
    }

    return (await response.json()) as User[];
  }

  return {
    GetUser,
    UpdateUser,
    LoginUser,
    CreateUser,
    ChangePassword,
    ResetPassword,
    InactivateUser,
    ActivateUser,
    RefreshToken,
    GetUserDetails,
    QueryUser,
  };
}
