import {Button, Flex, Form, Input, Select} from 'antd';
import {BaseOptionType} from 'antd/es/select';
import React, {useEffect, useState} from 'react';
import {ButtonSettings} from '../../common/control/Common';
import {NewTenantAwareEntity} from '../../common/model/Common';
import {HttpError} from '../../common/service/Service';
import {
  LineOfBusinessItem,
  LobItemCardinality,
  LobItemInfo,
  LobItemType,
  ValueWithDisplay,
} from '../model/LineOfBusiness';
import {LobService} from '../service/LobService';

export interface EditCoverageFormProps {
  lobService: LobService;
  lobId: string | undefined;
  existingItemId: string | undefined;
  defaultRiskId: string | undefined;
  formOpenCount: number;
  onSave: (lobItem: LineOfBusinessItem) => void;
  onCancel: () => void;
}

export function EditCoverageForm(props: EditCoverageFormProps) {
  const [lineOfBusinessHierarchyItem, setLineOfBusinessHierarchyItem] =
    useState<LineOfBusinessItem | undefined>(undefined);
  // we need all the lob items in order to populate the parent id. This could be just id and names, but the entity is so small right now
  // that it's not worth optimizing this
  const [allLineOfBusinessHierarchyRisks, setAllLineOfBusinessHierarchyRisks] =
    useState<LobItemInfo[] | undefined>(undefined);

  const [form] = Form.useForm<LineOfBusinessItem>();
  useEffect(() => {
    form.resetFields();
    const eff = async () => {
      if (!props.lobId || props.lobId === '') {
        setLineOfBusinessHierarchyItem(undefined);
        setAllLineOfBusinessHierarchyRisks(undefined);
        return;
      }
      const loadedRisks = await props.lobService.GetLineOfBusinessRisks(
        props.lobId
      );
      setAllLineOfBusinessHierarchyRisks(loadedRisks);
      if (props.existingItemId) {
        // load the existing item
        const loaded = await props.lobService.GetLineOfBusinessItem(
          props.existingItemId
        );
        setLineOfBusinessHierarchyItem(loaded);
        form.setFieldsValue(loaded);
      } else {
        form.setFieldsValue({
          parentLineOfBusinessItemId: props.defaultRiskId,
        });
      }
    };
    eff();

    // we need form open count so that if the popup is opened to create then closed and reopened we don't retain values
  }, [props.existingItemId, props.formOpenCount]);

  function getRiskCardinalityOptions(): BaseOptionType[] {
    return Object.keys(LobItemCardinality).map(v => {
      const cardinality = LobItemCardinality[v] as ValueWithDisplay;
      return {
        label: cardinality.displayValue,
        value: cardinality.name,
      };
    });
  }

  function getParentRiskItemOptions(): BaseOptionType[] {
    return (allLineOfBusinessHierarchyRisks ?? [])
      .filter(v => v.id !== props.existingItemId)
      .map(v => ({
        label: v.name,
        value: v.id,
      }));
  }

  function createLobHierarchyCoverage(): LineOfBusinessItem {
    return {
      ...NewTenantAwareEntity(),
      name: '',
      itemType: LobItemType.Coverage,
      cardinality: LobItemCardinality.Single.name,
      lineOfBusinessId: props.lobId ?? '',
      parentLineOfBusinessItemId: '',
    };
  }

  async function onSave(value: LineOfBusinessItem) {
    if (props.existingItemId && !lineOfBusinessHierarchyItem) {
      // we can't save an existing item if we haven't loaded it first
      return;
    }

    const priorToUpdate = props.existingItemId
      ? lineOfBusinessHierarchyItem
      : createLobHierarchyCoverage();
    const toSave = {
      ...priorToUpdate,
      ...value,
    } as LineOfBusinessItem;

    let savePromise: Promise<LineOfBusinessItem>;
    if (props.existingItemId) {
      savePromise = props.lobService.UpdateLineOfBusinessItem(toSave);
    } else {
      savePromise = props.lobService.CreateLineOfBusinessCoverage(toSave);
    }
    const updated = await savePromise.catch((e: HttpError) => {
      form.setFields([
        {
          name: 'name',
          errors: [e.details?.message ?? e.message],
        },
      ]);
      return undefined;
    });
    if (updated) {
      props.onSave(updated);
    }
  }

  return (
    <Form
      name="edit_risk_item"
      layout="vertical"
      size="large"
      labelCol={{span: 24}}
      wrapperCol={{span: 24}}
      labelWrap={true}
      style={{width: '100%'}}
      onFinish={onSave}
      autoComplete="off"
      form={form}
    >
      <Form.Item name="name" label="Coverage Name" rules={[{required: true}]}>
        <Input />
      </Form.Item>
      <Form.Item
        name="parentLineOfBusinessItemId"
        label="Risk Name"
        rules={[{required: true}]}
      >
        <Select
          showSearch
          optionFilterProp="label"
          options={getParentRiskItemOptions()}
        />
      </Form.Item>
      <Form.Item
        name="cardinality"
        label="Cardinality"
        rules={[{required: true}]}
      >
        <Select
          showSearch
          optionFilterProp="label"
          options={getRiskCardinalityOptions()}
        />
      </Form.Item>
      <Flex className="gap-2" style={{width: '100%'}} justify="end">
        <Button {...ButtonSettings} htmlType="button" onClick={props.onCancel}>
          Cancel
        </Button>
        <Button ghost={false} type="default" htmlType="submit">
          OK
        </Button>
      </Flex>
    </Form>
  );
}
