import {
  Button,
  Card,
  Dropdown,
  List,
  MenuProps,
  Space,
  Tooltip,
  Typography,
} from 'antd';
import React, {CSSProperties, useEffect, useState} from 'react';

import * as AntIcons from '@ant-design/icons';
import * as kot from 'adaptify-multi-module-rating-admin-model';
import {ButtonSettings} from '../../../common/control/Common';
import {
  BindingPart,
  ChildFunctionPath,
  ChildPath,
  CopyCalculationReplaceBinding,
  FindBinding,
  FunctionListModel,
  GenerateCalculationFunctionListModel,
  GetIndexInModel,
  HasFunction,
  ProcessFunctionDisplayTemplate,
  VirtualPath,
  VirtualPathType,
} from './CalculationFunctionListModel';
import {BindingPicker} from './binding/BindingPicker';

import DeepEqual from 'deep-equal';
import {IdAndName} from '../../../product/model/Product';
import {ProductService} from '../../../product/service/ProductService';

import Calculation = kot.com.adaptify.rating.admin.model.calculation.Calculation;
import CalculationFunction = kot.com.adaptify.rating.admin.model.calculation.CalculationFunction;
import Binding = kot.com.adaptify.rating.admin.model.calculation.binding.Binding;
import FunctionDescriptor = kot.com.adaptify.rating.admin.model.calculation.descriptor.FunctionDescriptor;
import BindingDescriptor = kot.com.adaptify.rating.admin.model.calculation.descriptor.BindingDescriptor;
import BindingType = kot.com.adaptify.rating.admin.model.calculation.BindingType;
import MetadataProvider = kot.com.adaptify.rating.admin.model.util.MetadataProvider;
import ConjunctionType = kot.com.adaptify.rating.admin.model.calculation.binding.ConjunctionType;
import FunctionDescriptors = kot.com.adaptify.rating.admin.model.calculation.descriptor.FunctionDescriptors;
import FunctionDescriptorMap = kot.com.adaptify.rating.admin.model.calculation.descriptor.FunctionDescriptorMap;
import ValidateCalculation = kot.com.adaptify.rating.admin.model.util.ValidateCalculation;
import FunctionValidationMessage = kot.com.adaptify.rating.admin.model.calculation.validation.FunctionValidationMessage;
import CalculationEvalContext = kot.com.adaptify.rating.admin.model.calculation.context.CalculationEvalContext;
import LineOfBusinessHierarchy = kot.com.adaptify.rating.admin.model.lob.LineOfBusinessHierarchy;
import LineOfBusinessHierarchyItem = kot.com.adaptify.rating.admin.model.lob.LineOfBusinessHierarchyItem;
import FunctionType = kot.com.adaptify.rating.admin.model.calculation.descriptor.FunctionType;
import ScopedVariable = kot.com.adaptify.rating.admin.model.calculation.context.ScopedVariable;
import PrimitiveDataType = kot.com.adaptify.rating.admin.model.type.PrimitiveDataType;

import GetCalculationEvalContext = kot.com.adaptify.rating.admin.model.util.GetCalculationEvalContext;
import BindingHelper = kot.com.adaptify.rating.admin.model.calculation.util.BindingHelper;

//const indent = '\u00A0'.repeat(8);

interface CalculationFunctionListProps {
  productService: ProductService;
  lobHierarchy: LineOfBusinessHierarchy | undefined;
  currentLobItemId: string | undefined;
  calculation: Calculation | undefined;
  allTableNames: IdAndName[];
  onChanged: (calculation: Calculation) => void;
  metadataProvider: MetadataProvider;
  singleFunctionMode: boolean;
  rootAllowedFunctionTypes?: FunctionType[];
  globalVariables: ScopedVariable[];
  readOnly?: boolean;
}

interface BindingEditState {
  isEditing: boolean;
  calculationFunction?: CalculationFunction;
  bindingDescriptor?: BindingDescriptor;
  binding?: Binding;
}

interface StepMapper {
  getStepName: (functionId: string) => string;
}

export function CalculationFunctionList(props: CalculationFunctionListProps) {
  // reset version is a cue for the child controls that they should reset when it changes
  // there's probably a better way to do this, but this is at least a reliable way to indicate to controls
  // they should start afresh
  const [resetVersion, setResetVersion] = useState(0);

  const [bindingEditState, setBindingEditState] = useState<BindingEditState>({
    isEditing: false,
  });
  const [evalContext, setEvalContext] = useState<
    CalculationEvalContext | undefined
  >(undefined);
  const [functionModels, setFunctionModels] = useState<FunctionListModel[]>([]);
  type FunctionMapper = (str: string) => string;

  // abstract out a map to just the getter
  const [mapFunctionToStep, setMapFunctionToStep] = useState<StepMapper>({
    getStepName: (functionId: string) => '',
  });

  const [validationMessages, setValidationMessages] = useState<
    Map<String, FunctionValidationMessage[]>
  >(new Map<String, FunctionValidationMessage[]>());

  const [functionControls, setFunctionControls] = useState<
    Map<FunctionListModel, React.ReactElement>
  >(new Map<FunctionListModel, React.ReactElement>());

  useEffect(() => {
    const eff = async () => {
      if (!props.calculation || !props.lobHierarchy) {
        return;
      }

      const localEvalContext = await GetCalculationEvalContext(
        props.calculation,
        props.lobHierarchy,
        props.metadataProvider,
        props.globalVariables ?? []
      );
      setEvalContext(localEvalContext || undefined);
      if (!localEvalContext) {
        return;
      }

      const newStepMapper = {
        getStepName: (functionId: string) => {
          return (
            localEvalContext?.functionContexts
              .asJsReadonlyMapView()
              .get(functionId)?.stepName || ''
          );
        },
      };

      const validationMessages = await ValidateCalculation(
        props.calculation,
        props.metadataProvider,
        localEvalContext
      );
      const validationMessageMap = new Map<
        String,
        FunctionValidationMessage[]
      >();
      validationMessages.forEach(message => {
        if (validationMessageMap.has(message.functionId)) {
          validationMessageMap.get(message.functionId)?.push(message);
        } else {
          validationMessageMap.set(message.functionId, [message]);
        }
      }),
        [props.calculation, props.lobHierarchy];

      setValidationMessages(validationMessageMap);

      setMapFunctionToStep(newStepMapper);
      const models = props.calculation
        ? await GenerateCalculationFunctionListModel(
            props.calculation,
            newStepMapper.getStepName
          )
        : [];
      setFunctionModels(models);
    };
    eff();
  }, [props.calculation]);

  useEffect(() => {
    if (!functionModels) {
      return;
    }
    const eff = async () => {
      const promiseMap = new Map<
        FunctionListModel,
        Promise<React.ReactElement>
      >();

      functionModels.forEach((model, idx) => {
        promiseMap.set(model, getControlForCalculationModel(model, idx));
      });
      await Promise.all(promiseMap.values());
      const resolvedMap = new Map<FunctionListModel, React.ReactElement>();
      promiseMap.forEach(async (promise, model) => {
        // we already awaited them all together so this should be fast
        resolvedMap.set(model, await promise);
      });
      setFunctionControls(resolvedMap);
    };
    eff();
  }, [functionModels]);

  function getMenuOptions(
    functionModel: FunctionListModel
  ): MenuProps['items'] {
    if (!props.calculation) {
      return [];
    }
    const index = GetIndexInModel(functionModel.path, props.calculation);
    const parents: BindingPart[] | undefined = (functionModel.path as ChildPath)
      .parents;
    const hasFunction =
      (functionModel.path as HasFunction).calculationFunction !== undefined;
    const virtualType = (functionModel.path as VirtualPath).type;
    if (virtualType === VirtualPathType.BlockHeader) {
      return []; // no menu options for block headers at this time
    }
    const isSingleFunctionBlock =
      parents &&
      parents.length > 0 &&
      parents[0].bindingDesc.isSingleFunctionBlock();
    return [
      {
        label: 'Add function',
        key: 0,
        children: createAddMenuProps(-1, parents),
        disabled: hasFunction,
      },
      {
        label: 'Add function above',
        key: 1,
        children: createAddMenuProps(index, parents),
        disabled: !hasFunction || isSingleFunctionBlock,
      },
      {
        label: 'Add function below',
        key: 2,
        children: createAddMenuProps(index + 1, parents),
        disabled: !hasFunction || isSingleFunctionBlock,
      },
      {
        key: 3,
        label: (
          <a
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => onDeleteFunction(index, parents)}
          >
            Delete function
          </a>
        ),
        disabled: !hasFunction,
      },
      {
        key: 4,
        label: (
          <a
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => moveFunctionUp(index, parents)}
          >
            Move up
          </a>
        ),
        disabled: !hasFunction || isSingleFunctionBlock,
      },
      {
        key: 5,
        label: (
          <a
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => moveFunctionDown(index, parents)}
          >
            Move down
          </a>
        ),
        disabled: !hasFunction || isSingleFunctionBlock,
      },
    ];
  }

  function moveFunctionUp(index: number, parents?: BindingPart[]) {
    moveFunctionUpOrDown(index, true, parents);
  }

  function moveFunctionDown(index: number, parents?: BindingPart[]) {
    moveFunctionUpOrDown(index, false, parents);
  }

  function mutateBlockBinding(
    parents: BindingPart,
    mutator: (blockFunctions: CalculationFunction[]) => boolean
  ) {
    if (!props.calculation) {
      return false;
    }

    const binding = FindBinding(
      parents.calculationFunction,
      parents.bindingDesc
    );
    if (
      parents.bindingDesc.allowedBindingTypes.find(b => b === BindingType.Block)
    ) {
      const block = (binding?.block || []).map(f => structuredClone(f));

      const changed = mutator(block);
      if (changed) {
        const updated = CopyCalculationReplaceBinding(
          props.calculation,
          parents.calculationFunction,
          parents.bindingDesc,
          {
            name: parents.bindingDesc.name,
            bindingType: BindingType.Block.name,
            block: block,
          } as Binding
        );
        props.onChanged(updated);
      }
    }
    if (
      parents.bindingDesc.allowedBindingTypes.find(
        b => b === BindingType.Predicate
      )
    ) {
      const predFuncs = (binding?.predicate?.functions || []).map(f =>
        structuredClone(f)
      );

      const changed = mutator(predFuncs);
      if (changed) {
        const updated = CopyCalculationReplaceBinding(
          props.calculation,
          parents.calculationFunction,
          parents.bindingDesc,
          {
            name: parents.bindingDesc.name,
            bindingType: BindingType.Predicate.name,
            predicate: {
              functions: predFuncs,
              conjunctionType:
                binding?.predicate?.conjunctionType || ConjunctionType.And.name,
            },
          } as Binding
        );
        props.onChanged(updated);
      }
    }
  }

  function moveFunctionUpOrDown(
    index: number,
    isUp: boolean,
    parents?: BindingPart[]
  ) {
    if (!props.calculation) {
      return [];
    }
    const newIndex = index + (isUp ? -1 : 1);
    if (newIndex < 0) {
      return;
    }
    if (parents && parents.length > 0) {
      mutateBlockBinding(parents[0], block => {
        if (newIndex < 0 || newIndex >= block.length) {
          return false;
        }
        const tmp = block[index];
        block[index] = block[newIndex];
        block[newIndex] = tmp;
        return true;
      });
      return;
    } else {
      if (
        !props.calculation.functions ||
        newIndex < 0 ||
        newIndex >= props.calculation.functions.length
      ) {
        return;
      }

      const updated = structuredClone(props.calculation);
      if (!updated.functions) {
        updated.functions = [];
      }
      const tmp = updated.functions[index];
      updated.functions[index] = updated.functions[newIndex];
      updated.functions[newIndex] = tmp;
      props.onChanged(updated);
    }
  }

  function processTemplate(
    desc: FunctionDescriptor,
    handleNonBinding: (chunk: string) => void,
    handleBinding: (bindingName: string) => void
  ) {
    // regular expressions API isn't so robust so just iterating through the string
    let currentSectionName = '';
    let isInBinding = false;

    // iterate through the characters one by one, replacing the non-templated pieces of the string
    // with spans and the teplated pieces with the current binding as a hyperlink
    const template = desc.getDisplayTemplate() || '';
    for (let i = 0; i < template.length; i++) {
      const c = template[i];
      if (c === '$') {
        // if we have any characters accumulated add them
        if (currentSectionName.length > 0) {
          handleNonBinding(currentSectionName);
          currentSectionName = '';
        }

        // we are in a binding now
        isInBinding = true;
        continue;
      }
      const isLetter = c >= 'A' && c <= 'z';
      const isDigit = c >= '0' && c <= '9';
      if (isInBinding && !(isLetter || isDigit)) {
        // end the binding
        handleBinding(currentSectionName);
        isInBinding = false;
        currentSectionName = c;
        continue;
      }

      currentSectionName += c;
    }
    if (currentSectionName.length > 0) {
      if (isInBinding) {
        handleBinding(currentSectionName);
      } else {
        handleNonBinding(currentSectionName);
      }
    }
  }
  function onDropdownRender(
    menu: React.ReactNode,
    model: FunctionListModel
  ): React.ReactNode {
    const myMenu = menu as React.ReactElement<MenuProps>;
    const items = getMenuOptions(model);
    // dynamically inject the items into the menu
    return {
      ...myMenu,
      props: {
        ...myMenu.props,
        items: items,
      },
    };
  }

  function getControlForCalculationModel(
    model: FunctionListModel,
    index: number
  ) {
    const path = model.path;
    const parents = (path as ChildFunctionPath).parents || [];
    const level = parents.length;
    const calculationFunction = (path as HasFunction).calculationFunction;
    const validationErrors = (
      validationMessages.get(calculationFunction?.id || '') || []
    ).filter(err => err.bindingDescriptorName === '');
    const virtualType = (path as VirtualPath).type;

    const textStyle = {color: 'black'};

    const controls = [] as React.JSX.Element[];
    let showDropdown = !props.readOnly;
    const indentStyle: CSSProperties = {};

    if (calculationFunction) {
      if (level > 0) {
        indentStyle.paddingLeft = `${level * 20}px`;
      }

      const errors =
        validationErrors.length > 0 ? (
          <Tooltip title={validationErrors.map(e => e.message).join('\n')}>
            <AntIcons.WarningOutlined style={{color: 'red'}} />
          </Tooltip>
        ) : (
          <></>
        );
      controls.push(errors);

      const desc = FunctionDescriptorMap.asJsReadonlyMapView().get(
        calculationFunction.name || ''
      );
      if (!desc) {
        return <></>;
      }

      const handleNonBinding = (chunk: string) => {
        controls.push(<span style={textStyle}>{chunk}</span>);
      };

      const handleBinding = (bindingName: string) => {
        const bindingDesc = model.bindingDescriptors.find(
          pd => pd.name === bindingName
        );
        if (bindingDesc) {
          controls.push(renderBinding(calculationFunction, desc, bindingDesc));
        } else {
          // this should not happen
          controls.push(<span>{bindingName}</span>);
        }
      };
      ProcessFunctionDisplayTemplate(desc, handleNonBinding, handleBinding);

      // handle the predicate which need to display AND or OR based on the parent
      const parents = (model.path as ChildFunctionPath).parents || [];
      if (parents.length > 0) {
        const parentBinding = FindBinding(
          parents[0].calculationFunction,
          parents[0].bindingDesc
        );

        if (
          parentBinding &&
          parentBinding.bindingType === BindingType.Predicate.name
        ) {
          const predIndex =
            parentBinding.predicate?.functions?.indexOf(calculationFunction) ||
            0;
          const predLength = parentBinding.predicate?.functions?.length || 0;
          const isLastFunction = predIndex === predLength - 1;
          if (!isLastFunction) {
            const conjunctionType =
              parentBinding.predicate?.conjunctionType ||
              ConjunctionType.And.name;
            const conjunctionTypeForSwitching =
              conjunctionType === ConjunctionType.And.name
                ? ConjunctionType.Or.name
                : ConjunctionType.And.name;

            const conjunctionMenuItems: MenuProps['items'] = [
              {
                label: (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() =>
                      setConjunctionType(
                        parents[0].calculationFunction,
                        parentBinding,
                        parents[0].bindingDesc,
                        conjunctionTypeForSwitching
                      )
                    }
                  >
                    {conjunctionTypeForSwitching === ConjunctionType.And.name
                      ? 'AND'
                      : ' OR'}
                  </a>
                ),
                key: conjunctionTypeForSwitching,
              },
            ];

            //        controls.push(<span>{indent}</span>);
            controls.push(
              <Dropdown
                trigger={['click']}
                menu={{items: conjunctionMenuItems}}
              >
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{paddingLeft: '20px', textDecoration: 'underline'}}
                >
                  {parentBinding.predicate?.conjunctionType ===
                  ConjunctionType.And.name
                    ? 'AND'
                    : 'OR'}
                </a>
              </Dropdown>
            );
          }
        }
      }
    }
    if (virtualType === VirtualPathType.BlockHeader) {
      const binding = (model.path as ChildPath).parents[0].bindingDesc;
      const blockLevel = level - 1;
      if (blockLevel > 0) {
        //  controls.push(<span>{indent.repeat(blockLevel)}</span>);
      }

      const bindingStr = binding.displayName;
      const blockHeaderStyle: CSSProperties = {
        paddingLeft: `${20 * blockLevel}px`,
        ...textStyle,
      };
      controls.push(<span style={blockHeaderStyle}>{bindingStr}</span>);
      // the block header (THEN, ELSE, etc.) doesn't need actions
      showDropdown = false;
    } else if (
      virtualType === VirtualPathType.FunctionPlaceholder ||
      virtualType === VirtualPathType.PredicatePlaceholder
    ) {
      let binding: BindingDescriptor | undefined = undefined;
      if ((model.path as ChildPath)?.parents) {
        binding = (model.path as ChildPath).parents[0].bindingDesc;
      }

      (model.path as ChildPath).parents[0].bindingDesc;
      const functionName =
        binding
          ?.getAllowedFunctionTypes()
          .map(ft => ft.displayName)
          .join(' or ') || FunctionType.Function.displayName;

      const menuPropsStyle: CSSProperties = {};

      if (level > 0) {
        menuPropsStyle.paddingLeft = `${level * 20}px`;
      }
      if (!props.readOnly) {
        controls.push(
          <Dropdown
            trigger={['click']}
            menu={{items: createAddMenuProps(index, parents)}}
          >
            <a
              target="_blank"
              rel="noopener noreferrer"
              style={{...menuPropsStyle, textDecoration: 'underline'}}
            >
              {`<Add ${functionName} here>`}
            </a>
          </Dropdown>
        );
      }
    }

    const isRoot = !parents || parents.length === 0;

    // skip buttons if the root is single function mode
    // or the child is a single function mode block with only one allowed choice
    const skipButtons = isRoot
      ? props.singleFunctionMode
      : parents[0].bindingDesc.isSingleFunctionBlock() &&
        parents[0].bindingDesc.getAllowedFunctionTypes().length === 1;

    const dd =
      showDropdown && !skipButtons ? (
        <Dropdown
          trigger={['click']}
          menu={{items: []}}
          dropdownRender={menu => onDropdownRender(menu, model)}
        >
          <Button {...ButtonSettings} onClick={e => e.preventDefault()}>
            ...
          </Button>
        </Dropdown>
      ) : (
        <></>
      );
    return (
      <>
        <Typography.Title level={5} style={{...indentStyle, margin: '0px'}}>
          {controls}
        </Typography.Title>
        {dd}
      </>
    );
  }

  function setConjunctionType(
    calculationFunction: CalculationFunction,
    binding: Binding,
    bindingDesc: BindingDescriptor,
    conjunctionType: string
  ) {
    if (!props.calculation) {
      return;
    }
    if (!binding.predicate) {
      return;
    }
    const newBinding = structuredClone(binding);
    if (!newBinding.predicate) {
      return;
    }
    newBinding.predicate.conjunctionType = conjunctionType;
    const updated = CopyCalculationReplaceBinding(
      props.calculation,
      calculationFunction,
      bindingDesc,
      newBinding
    );
    props.onChanged(updated);
  }

  function renderBinding(
    calculationFunction: CalculationFunction,
    functionDesc: FunctionDescriptor,
    bindingDesc: BindingDescriptor
  ) {
    const bindingStr = getBindingStringByDescriptor(
      calculationFunction,
      functionDesc,
      bindingDesc
    );
    const editBinding = () =>
      onEditBinding(calculationFunction, functionDesc, bindingDesc);
    const validationErrorsForBinding =
      validationMessages
        .get(calculationFunction?.id || '')
        ?.filter(err => err.bindingDescriptorName === bindingDesc.name) || [];

    const control = props.readOnly ? (
      <span>{bindingStr}</span>
    ) : (
      <a onClick={editBinding} style={{textDecoration: 'underline'}}>
        {bindingStr}
      </a>
    );

    if (validationErrorsForBinding.length > 0) {
      return (
        <>
          <Tooltip
            title={validationErrorsForBinding.map(e => e.message).join('\n')}
          >
            <AntIcons.WarningOutlined style={{color: 'red'}} />
          </Tooltip>
          {control}
        </>
      );
    } else {
      return control;
    }
  }

  function getBindingStringByDescriptor(
    calculationFunction: CalculationFunction,
    descriptor: FunctionDescriptor,
    bindingDesc: BindingDescriptor
  ) {
    const binding = FindBinding(calculationFunction, bindingDesc);
    if (!binding) {
      return 'Pick ' + bindingDesc.displayName;
    }

    return BindingHelper.GetBindingDisplayString(
      calculationFunction?.id,
      bindingDesc,
      binding,
      {
        getTableNameById: getTableName,
      },
      evalContext
    );
  }

  function getTableName(tableId: string) {
    const table = props.allTableNames.find(t => t.id === tableId);
    return table ? table.name : '';
  }

  function onEditBinding(
    calculationFunction: CalculationFunction,
    functionDesc: FunctionDescriptor,
    bindingDesc: BindingDescriptor
  ) {
    setResetVersion(resetVersion + 1);
    setBindingEditState({
      isEditing: true,
      calculationFunction: calculationFunction,
      bindingDescriptor: bindingDesc,
      binding: FindBinding(calculationFunction, bindingDesc),
    });
  }

  function onDeleteFunction(index: number, parents: BindingPart[] | undefined) {
    if (!props.calculation) {
      return;
    }
    const updated = structuredClone(props.calculation);
    if (!parents || parents.length === 0) {
      updated.functions?.splice(index, 1);
      props.onChanged(updated);
    } else {
      mutateBlockBinding(parents[0], block => {
        block.splice(index, 1);
        return true;
      });
    }
  }

  function onEditBindingModalCancel() {
    setBindingEditState({isEditing: false});
    //Refresh the control state
    setResetVersion(resetVersion + 1);
  }

  function onEditBindingModalFinish(binding: Binding) {
    if (!props.calculation) {
      return;
    }

    if (
      bindingEditState.calculationFunction &&
      bindingEditState.bindingDescriptor
    ) {
      const existingBinding = FindBinding(
        bindingEditState.calculationFunction,
        bindingEditState.bindingDescriptor
      );

      if (DeepEqual(existingBinding, binding, {strict: true})) {
        // no changes
        onEditBindingModalCancel();
        return;
      }

      const updated = CopyCalculationReplaceBinding(
        props.calculation,
        bindingEditState.calculationFunction,
        bindingEditState.bindingDescriptor,
        binding
      );
      props.onChanged(updated);
    }

    //Refresh the control state
    setResetVersion(resetVersion + 1);

    // close the modal
    setBindingEditState({isEditing: false});
  }

  function onBindingChanged(newBinding: Binding) {
    if (bindingEditState.binding !== newBinding) {
      setBindingEditState({
        ...bindingEditState,
        binding: newBinding,
      });
    }
  }

  async function addFunction(
    funcDesc: FunctionDescriptor,
    index: number,
    parentPath?: BindingPart[]
  ) {
    if (!props.calculation) {
      return;
    }
    const newFunction = funcDesc.createDefault(
      props.calculation?.functions || [],
      undefined
    );

    const bindingDescs = funcDesc.getBindingDescriptors();
    // check if there are any bindings that allow dynamicvariabledeclarations and default to them
    // this makes functions start out with Step 1 = , etc.
    const defaultBindings = bindingDescs
      .filter(bd =>
        bd.allowedBindingTypes.includes(BindingType.DynamicVariableDeclaration)
      )
      .map(bd => {
        return {
          name: bd.name,
          bindingType: BindingType.DynamicVariableDeclaration.name,
        } as Binding;
      });
    if (defaultBindings.length > 0) {
      newFunction.bindings = defaultBindings;
    }

    if (parentPath && parentPath.length > 0) {
      mutateBlockBinding(parentPath[0], block => {
        if (index >= 0) {
          block.splice(index, 0, newFunction);
        } else {
          block.push(newFunction);
        }
        return true;
      });
    } else {
      const updated = structuredClone(props.calculation);
      if (!updated.functions) {
        updated.functions = [];
      }
      // base case for root methods
      if (index < 0) {
        updated.functions.push(newFunction);
      } else {
        updated.functions.splice(index, 0, newFunction);
      }
      props.onChanged(updated);
    }
  }

  function createAddMenuProps(
    index: number,
    parentPath?: BindingPart[] | undefined
  ): MenuProps['items'] {
    // filter on whether we are showing predicates or not
    let functionTypes: FunctionType[] = [];

    if (!parentPath || parentPath.length === 0) {
      functionTypes = props.rootAllowedFunctionTypes || [FunctionType.Function];
    } else {
      functionTypes = parentPath[0].bindingDesc.getAllowedFunctionTypes();
    }

    const addOptions: MenuProps['items'] = FunctionDescriptors.filter(fd =>
      functionTypes.includes(fd.functionType)
    ).map(fd => {
      return {
        label: (
          <a
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => addFunction(fd, index, parentPath)}
          >
            {fd.displayName}
          </a>
        ),
        key: fd.name + index,
      };
    });

    return addOptions;
  }

  function getDefaultPath() {
    if (!props.currentLobItemId || !props.lobHierarchy) {
      return undefined;
    }

    const rootRisk =
      props.lobHierarchy.risks.length > 0
        ? props.lobHierarchy.risks[0]
        : undefined;

    if (!rootRisk) {
      return undefined;
    }

    return getLobItemPath(props.currentLobItemId, rootRisk, '');
  }

  function getLobItemPath(
    targetId: string,
    lobItem: LineOfBusinessHierarchyItem,
    currentPath: string
  ) {
    currentPath =
      currentPath.length > 0 ? currentPath + '.' + lobItem.name : lobItem.name;
    if (lobItem.id === targetId) {
      return currentPath;
    }

    for (const child of lobItem.children || []) {
      const childPath = getLobItemPath(targetId, child, currentPath);
      if (childPath) {
        return childPath;
      }
    }

    return undefined;
  }

  const addOptions: MenuProps['items'] = createAddMenuProps(-1);

  const extraButtons =
    props.readOnly || props.singleFunctionMode ? (
      <></>
    ) : (
      <Dropdown trigger={['click']} menu={{items: addOptions}}>
        <Button
          {...ButtonSettings}
          onClick={e => e.preventDefault()}
          type="primary"
        >
          <AntIcons.PlusOutlined />
        </Button>
      </Dropdown>
    );

  const bindingModal = bindingEditState.isEditing ? (
    <BindingPicker
      productService={props.productService}
      existingBindings={bindingEditState.calculationFunction?.bindings || []}
      calculationFunction={bindingEditState.calculationFunction}
      bindingDescriptor={bindingEditState.bindingDescriptor}
      onChanged={onBindingChanged}
      allTableNames={props.allTableNames}
      evalContext={evalContext}
      metadataProvider={props.metadataProvider}
      resetVersion={resetVersion}
      onCancel={onEditBindingModalCancel}
      onFinish={onEditBindingModalFinish}
      defaultPath={getDefaultPath()}
    />
  ) : (
    <></>
  );

  return (
    <>
      <Space direction="vertical" style={{width: '100%'}}>
        <Card bordered={false} title="Functions" extra={extraButtons}>
          <List
            size="small"
            dataSource={functionModels || []}
            renderItem={item => (
              <List.Item>
                {functionControls.get(item) ?? <span>Loading...</span>}
              </List.Item>
            )}
          />
        </Card>
      </Space>
      {bindingModal}
    </>
  );
}
