package com.adaptify.rating.admin.model.calculation.util

import com.adaptify.rating.admin.model.calculation.BindingType
import com.adaptify.rating.admin.model.calculation.CalculationFunction
import com.adaptify.rating.admin.model.calculation.descriptor.FunctionDescriptors
import kotlin.jvm.JvmOverloads
import kotlin.jvm.JvmSuppressWildcards

// TODO add parent path if we need it
object CalculationFunctionVisitor {

  fun visit(function: CalculationFunction, processor: (CalculationFunction) -> Unit) {
    processor(function);
    // run through in binding descriptor order
    val functionDesc = FunctionDescriptors.find { it.name == function.name}
    if (functionDesc == null) {
      return;
    }
    for (bindingDescriptor in functionDesc.getBindingDescriptors()) {
      val binding = function.bindings.find { it.name == bindingDescriptor.name }
      if (binding == null) {
        continue;
      }

      if (binding.bindingType == BindingType.Block.name && binding.block != null) {
        visitArray(binding.block!!, processor)
      }
      if (binding.bindingType == BindingType.Predicate.name && binding.predicate?.functions != null) {
        visitArray(binding.predicate!!.functions, processor)
      }
    }
  }

  // allow this to be called from java, since I can't get
  // the java->kotlin integration working
  fun visitArrayJvm(functions: Array<CalculationFunction>?,
                    processor: Processor) {
    visitArray(functions, processor::processor)
  }

  fun visitArray(functions: Array<CalculationFunction>?,
                  processor: (CalculationFunction) -> Unit) {
    functions?.forEach { visit(it, processor) }
  }

  suspend fun visitAsync(function: CalculationFunction, processor: suspend (CalculationFunction) -> Unit ) {
    processor(function);

    val functionDesc = FunctionDescriptors.find { it.name == function.name}
    if (functionDesc == null) {
      return;
    }
    for (bindingDescriptor in functionDesc.getBindingDescriptors()) {
      val binding = function.bindings.find { it.name == bindingDescriptor.name }
      if (binding == null) {
        continue;
      }

      if (binding.bindingType == BindingType.Block.name && binding.block != null) {
        visitArrayAsync(binding.block!!, processor)
      }
      if (binding.bindingType == BindingType.Predicate.name && binding.predicate?.functions != null) {
        visitArrayAsync(binding.predicate!!.functions, processor)
      }
    }
  }

  suspend fun visitArrayAsync(functions: Array<CalculationFunction>?,
                              processor: suspend (CalculationFunction) -> Unit) {
    functions?.forEach { visitAsync(it, processor) }
  }


}

interface Processor {
  fun processor(function: CalculationFunction)
}
