package com.adaptify.rating.admin.model.calculation.binding

@JsExport
external actual interface Literal {
    actual var dataType: String
    actual var value: String
}

actual fun createLiteral(dataType: String, value: String): Literal {
  return js("({dataType: dataType, value: value})")
}
