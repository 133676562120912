import {Flex} from 'antd';
import React, {useEffect, useState} from 'react';

import {TestCaseLobFieldList} from './TestCaseLobFieldList';
import {TestCaseLobTree} from './TestCaseLobTree';
import {
  FindNodeById,
  SetTestCaseField,
  TestCaseTreeNode,
} from './TestCaseUIModel';

import * as kot from 'adaptify-multi-module-rating-admin-model';
import Table = kot.com.adaptify.rating.admin.model.table.Table;
import TestCase = kot.com.adaptify.rating.admin.model.testcase.TestCase;
import LineOfBusinessHierarchy = kot.com.adaptify.rating.admin.model.lob.LineOfBusinessHierarchy;
import TestCaseLobItem = kot.com.adaptify.rating.admin.model.testcase.TestCaseLobItem;

export interface TestCaseDetailPanelProps {
  rootTreeNode: TestCaseTreeNode | undefined;
  lobHierarchy: LineOfBusinessHierarchy | undefined;
  updateRootTreeNode: (root: TestCaseTreeNode) => void;
  readonly?: boolean;
}

export function TestCaseDetailPanel(props: TestCaseDetailPanelProps) {
  const [selectedTestCaseTreeNodeId, setSelectedTestCaseTreeNodeId] = useState<
    string | undefined
  >(undefined);

  const [selectedTreeNode, setSelectedTreeNode] = useState<
    TestCaseTreeNode | undefined
  >(undefined);

  useEffect(() => {
    if (!props.rootTreeNode || !selectedTestCaseTreeNodeId) {
      setSelectedTreeNode(undefined);
      return;
    }

    const node = FindNodeById(props.rootTreeNode, selectedTestCaseTreeNodeId);
    setSelectedTreeNode(node);
  }, [props.rootTreeNode, selectedTestCaseTreeNodeId]);

  function setSelectedTreeNodeById(
    root: TestCaseTreeNode | undefined,
    nodeId: string | undefined
  ) {
    if (!nodeId) {
      setSelectedTestCaseTreeNodeId(undefined);
      setSelectedTreeNode(undefined);
      return;
    }

    setSelectedTestCaseTreeNodeId(nodeId);
    // also find and set state for the resolved node
    if (root) {
      const node = FindNodeById(root, nodeId);
      setSelectedTreeNode(node);
    }
  }

  function setTestCaseFieldValue(fieldId: string, value: string | undefined) {
    if (!props.lobHierarchy || !props.rootTreeNode) {
      return;
    }
    const updated = SetTestCaseField(
      props.lobHierarchy,
      props.rootTreeNode,
      fieldId,
      value
    );
    props.updateRootTreeNode(updated);
  }

  function updateRoot(root: TestCaseTreeNode, newSelection?: string) {
    props.updateRootTreeNode(root);
    setSelectedTestCaseTreeNodeId(newSelection);
  }

  return (
    <Flex>
      <TestCaseLobTree
        selectedTestCaseTreeNodeId={selectedTestCaseTreeNodeId}
        setSelectedTestCaseTreeNodeId={setSelectedTestCaseTreeNodeId}
        lobHierarchy={props.lobHierarchy}
        root={props.rootTreeNode}
        updateRoot={updateRoot}
        readonly={props.readonly}
      />
      <div style={{width: '100%', height: '100%'}}>
        <TestCaseLobFieldList
          node={selectedTreeNode}
          rootNode={props.rootTreeNode}
          setFieldValue={setTestCaseFieldValue}
          readonly={props.readonly}
        />
      </div>
    </Flex>
  );
}
