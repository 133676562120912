package com.adaptify.rating.admin.model.lob

@JsExport
external actual interface LineOfBusinessHierarchy {
  actual var id : String
  actual var name: String
  actual var version : Int
  actual var tenantId: String
  actual var risks: Array<LineOfBusinessHierarchyItem>
}

actual fun createLineOfBusinessHierarchy(
  id: String,
  name: String,
  version: Int,
  tenantId: String,
  risks: Array<LineOfBusinessHierarchyItem>) : LineOfBusinessHierarchy {
  return js("{id: id, name: name, version: version, tenantId: tenantId, risks: risks}")
}


