import {DataGrid, GridColDef} from '@mui/x-data-grid';
import {Col, Flex, Row, Space} from 'antd';
import React, {useEffect, useState} from 'react';
import {DataGridSettings} from '../../common/control/Common';
import {DateIsoStringToDisplayString} from '../../common/model/CommonUtils';
import {ProductVersionActivityHistory} from '../model/Product';
import {ProductService} from '../service/ProductService';

export interface ProductVersionActivityHistoryListProps {
  productVersionId: string | undefined;
  productService: ProductService;
  tabControl: React.ReactElement;
}

export function ProductVersionActivityHistoryList(
  props: ProductVersionActivityHistoryListProps
) {
  const [history, setHistory] = useState<ProductVersionActivityHistory[]>([]);

  // used to trigger a refresh on the UI on change
  const [modifyCount, setModifyCount] = useState(0);

  useEffect(() => {
    if (!props.productVersionId) {
      setHistory([]);
      return;
    }
    const eff = async () => {
      const history =
        await props.productService.GetProductVersionActivityHistoryByProductVersionId(
          props.productVersionId || ''
        );
      setHistory(history);
    };
    eff();
  }, [props.productVersionId, modifyCount]);

  const colDefs: GridColDef<ProductVersionActivityHistory>[] = [
    {
      field: 'createdBy',
      headerName: 'User',
      flex: 200,
    },
    {
      field: 'createdAt',
      headerName: 'Date',
      flex: 150,
      renderCell: params => {
        return DateIsoStringToDisplayString(params.value);
      },
    },
    {
      field: 'description',
      headerName: 'Activity History',
      flex: 400,
    },
  ];

  return (
    <>
      <Flex vertical style={{width: '100%'}} className="gap-2">
        <Row>
          <Col span={24}>{props.tabControl}</Col>
        </Row>
        <Space direction="vertical" style={{width: '100%'}}>
          <div style={{height: '100%', width: '100%'}}>
            <DataGrid
              {...DataGridSettings}
              rowSelection={false}
              columns={colDefs}
              rows={history}
              sx={{
                '&.MuiDataGrid-root': {
                  borderRadius: '8px',
                  overflow: 'hidden',
                  borderColor: '#CCCCCC',
                },
              }}
            />
          </div>
        </Space>
      </Flex>
    </>
  );
}
