import {Flex} from 'antd';
import React, {useEffect, useState} from 'react';
import {LobCoverageList} from '../control/LobCoverageList';
import {LobRiskList} from '../control/LobRiskList';
import {LobSummaryControl} from '../control/LobSummaryControl';
import {LobStatus} from '../model/LineOfBusiness';
import {LobService} from '../service/LobService';

export interface LobItemControlProps {
  lobDefId: string;
  lobService: LobService;
  currentTenantId: string;
}

export function LobItemControl(props: LobItemControlProps) {
  const [selectedRiskId, setSelectedRiskId] = useState<string | undefined>(
    undefined
  );
  const [selectedCoverageId, setSelectedCoverageId] = useState<
    string | undefined
  >(undefined);
  const [modifyCount, setModifyCount] = useState<number>(0);
  const [lobTenantId, setLobTenantId] = useState<string | undefined>(undefined);
  const [isPublished, setIsPublished] = useState<boolean>(false);

  useEffect(() => {
    const eff = async () => {
      const lob = await props.lobService.GetLineOfBusiness(props.lobDefId);
      setLobTenantId(lob?.tenantId);
      setIsPublished(lob.status === LobStatus.Published);
    };
    eff();
  }, [props.lobDefId, props.currentTenantId]);

  const readOnly = lobTenantId !== props.currentTenantId;

  return (
    <Flex vertical gap={24}>
      <LobSummaryControl
        lobHierarchyId={props.lobDefId}
        lobService={props.lobService}
        supressRiskItemsAndCoverageNavigation={true}
      />
      <LobRiskList
        lobService={props.lobService}
        lobDefId={props.lobDefId}
        selection={selectedRiskId}
        selectionChanged={setSelectedRiskId}
        modifyCount={modifyCount}
        setModifyCount={setModifyCount}
        existingItemsReadOnly={isPublished}
        readOnly={readOnly}
        getDrilldownUrl={(lobItemId: string) =>
          `/platform/lob/${props.lobDefId}/risk/field?lobItemId=${lobItemId}`
        }
      />
      <LobCoverageList
        lobService={props.lobService}
        lobHierarchyId={props.lobDefId}
        parentLobItemId={selectedRiskId ?? ''}
        selection={selectedCoverageId}
        selectionChanged={setSelectedCoverageId}
        modifyCount={modifyCount}
        setModifyCount={setModifyCount}
        existingItemsReadOnly={isPublished}
        readOnly={readOnly}
        getDrilldownUrl={(coverageId: string) =>
          `/platform/lob/${props.lobDefId}/coverage/field?coverageId=${coverageId}`
        }
      />
    </Flex>
  );
}
