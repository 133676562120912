package com.adaptify.rating.admin.model.calculation.validation

import com.adaptify.rating.admin.model.calculation.Calculation
import com.adaptify.rating.admin.model.calculation.CalculationFunction
import com.adaptify.rating.admin.model.calculation.context.CalculationEvalContext
import com.adaptify.rating.admin.model.calculation.env.MetadataProvider
import com.adaptify.rating.admin.model.calculation.util.CalculationFunctionVisitor
import com.adaptify.rating.admin.model.calculation.validation.lib.*
import com.adaptify.rating.admin.model.lob.LineOfBusinessHierarchy
import kotlinx.coroutines.coroutineScope

private val Validators : List<FunctionValidator> = listOf(
  FunctionBindingValidatorAdapter(BindingDataTypeMatchesValidator()),
  FunctionBindingValidatorAdapter(ValidPathValidator()),
  FunctionBindingValidatorAdapter(LiteralValueValidator()),
  FunctionBindingValidatorAdapter(VariableAlreadyDefinedValidator()),
  FunctionBindingValidatorAdapter(RequiredBindingPresentValidator())
)
object CalculationValidator {

  // for first pass, just function validations, will change the signature to reflect the expanded scope
// this validation could presumably run on the client or server, we are going to start with the client for
// now as it should have the optimal response time, but we have the option to move expensive validations to the server
// if needed
  suspend fun validateCalculation(calculation: Calculation,
                                  metadataProvider: MetadataProvider,
                                  lineOfBusinessHierarchy: LineOfBusinessHierarchy?,

                                  evalContext: CalculationEvalContext?): Array<FunctionValidationMessage> {
    if (evalContext == null && lineOfBusinessHierarchy == null) {
      return emptyArray();
    }
    var ctx = evalContext ?: CalculationEvalContext.Create(calculation, lineOfBusinessHierarchy!!, metadataProvider)
    if (ctx == null) {
      return arrayOf(FunctionValidationMessage("Unable to create calculation context", calculation.id, "Calculation"))
    }
    val errors = mutableListOf<FunctionValidationMessage>()
    errors.addAll(validateFunctionList(calculation, calculation.functions, metadataProvider,
       ctx))
    return errors.toTypedArray()
  }

  private suspend fun validateFunctionList(calculation: Calculation,
                                           calculationFunctions: Array<CalculationFunction>,
                                           metadataProvider: MetadataProvider,
                                           evalContext: CalculationEvalContext): Array<FunctionValidationMessage> {

    val errors = mutableListOf<FunctionValidationMessage>()
    CalculationFunctionVisitor.visitArrayAsync(calculationFunctions,
      { function ->
        Validators.forEach { validator ->
          errors.addAll(validator.validateFunction(function, calculation, metadataProvider, evalContext))

        }

      });

    return errors.toTypedArray()
  }
}


