import {DataGrid, GridColDef} from '@mui/x-data-grid';
import {Card, DatePicker, Input, Select} from 'antd';
import React, {useEffect, useState} from 'react';
import {
  DataGridSettings,
  DateDataFormats,
} from '../../../common/control/Common';
import {
  DateIsoStringToDayJs,
  DayJsToIsoString,
} from '../../../common/model/CommonUtils';

import * as kot from 'adaptify-multi-module-rating-admin-model';
import dayjs from 'dayjs';
import {TestCaseTreeNode, TestCaseTreeNodeField} from './TestCaseUIModel';
import PrimitiveDataType = kot.com.adaptify.rating.admin.model.type.PrimitiveDataType;

export interface TestCaseLobFieldListProps {
  node: TestCaseTreeNode | undefined;
  rootNode: TestCaseTreeNode | undefined;
  setFieldValue: (fieldId: string, value: string | undefined) => void;
  readonly?: boolean;
}

export function TestCaseLobFieldList(props: TestCaseLobFieldListProps) {
  const [fieldValueMap, setFieldValueMap] = useState<Map<string, string>>(
    new Map()
  );

  useEffect(() => {
    const fieldValues = new Map<string, string>();
    const fields = props.node?.fields || [];
    fields.forEach(field => {
      fieldValues.set(field.id, field.fieldValue || '');
    });
    setFieldValueMap(fieldValues);
  }, [props.node]);

  function onInputBlur(field: TestCaseTreeNodeField) {
    const value = fieldValueMap.get(field.id);

    if (field.fieldValue === value) {
      return;
    }

    props.setFieldValue(field.id, value);
  }

  function onInputChange(fieldId: string, value: string | undefined) {
    const newFieldValueMap = new Map(fieldValueMap);
    newFieldValueMap.set(fieldId, value || '');
    setFieldValueMap(newFieldValueMap);
  }

  function onSelectChange(fieldId: string, value: string | undefined) {
    const oldValue = fieldValueMap.get(fieldId);

    if (oldValue === value) {
      return;
    }

    props.setFieldValue(fieldId, value);
  }

  function onDateChange(fieldId: string, date: dayjs.Dayjs) {
    const newValue = date ? DayJsToIsoString(date) : undefined;
    const oldValue = fieldValueMap.get(fieldId);
    if (oldValue === newValue) {
      return;
    }
    props.setFieldValue(fieldId, newValue);
  }

  const fields = [...(props.node?.fields || [])];
  fields.sort((a, b) => {
    return a.displayName.localeCompare(b.displayName);
  });

  function onKeyDownSpaceBarSupresser(e: React.KeyboardEvent) {
    if (e.key === ' ') {
      // don't let the space bar get to the datagrid or it will trap it and treat as a navigation
      e.stopPropagation();
    }
  }

  const colDefs: GridColDef<TestCaseTreeNodeField>[] = [
    {
      field: 'fieldName',
      headerName: 'Name',
      flex: 200,
    },
    {
      field: 'dataType',
      headerName: 'Data Type',
      flex: 200,
    },
    {
      field: 'fieldValue',
      headerName: 'Value',
      flex: 200,
      renderCell: params => {
        const field = params.row;
        if (field.dataType === PrimitiveDataType.Boolean.name) {
          return (
            <Select
              showSearch
              optionFilterProp="label"
              disabled={props.readonly}
              allowClear
              value={fieldValueMap.get(params.id as string)}
              onChange={value => {
                onSelectChange(params.id as string, value as string);
              }}
              style={{width: '100%'}}
              onKeyDown={onKeyDownSpaceBarSupresser}
            >
              <Select.Option value="true">True</Select.Option>
              <Select.Option value="false">False</Select.Option>
            </Select>
          );
        } else if (field.dataType === PrimitiveDataType.Date.name) {
          const currentVal = fieldValueMap.get(params.id as string);
          const currentValAsDayjs = currentVal
            ? DateIsoStringToDayJs(currentVal)
            : undefined;
          return (
            <DatePicker
              disabled={props.readonly}
              format={DateDataFormats}
              value={currentValAsDayjs}
              onChange={date => onDateChange(field.id, date)}
              onKeyDown={onKeyDownSpaceBarSupresser}
            />
          );
        } else if (field.dataType === PrimitiveDataType.Number.name) {
          const currentVal = fieldValueMap.get(params.id as string);
          const isValid = currentVal ? !isNaN(Number(currentVal)) : true;

          return (
            <Input
              value={fieldValueMap.get(params.id as string)}
              onChange={e => {
                onInputChange(params.id as string, e.target.value);
              }}
              status={isValid ? '' : 'error'}
              onBlur={e => {
                onInputBlur(params.row);
              }}
              onKeyDown={onKeyDownSpaceBarSupresser}
              disabled={props.readonly}
            />
          );
        } else {
          return (
            <Input
              disabled={props.readonly}
              value={fieldValueMap.get(params.id as string)}
              onChange={e => {
                onInputChange(params.id as string, e.target.value);
              }}
              onBlur={e => {
                onInputBlur(params.row);
              }}
              onKeyDown={onKeyDownSpaceBarSupresser}
            />
          );
        }
      },
    },
  ];

  return (
    <Card bordered={false} title="Fields">
      <DataGrid
        {...DataGridSettings}
        columns={colDefs}
        filterMode="client"
        rows={props.node?.fields ?? []}
        sx={{
          '&.MuiDataGrid-root': {
            borderRadius: '8px',
            overflow: 'hidden',
            borderColor: '#CCCCCC',
          },
        }}
      />
    </Card>
  );
}
