import React from 'react';
import {useParams} from 'react-router-dom';
import {NavigationAware} from '../../NavigationAware';
import {LobService} from '../../lob/service/LobService';
import {ProductService} from '../../product/service/ProductService';
import {AuthManager} from '../../user/util/AuthManager';
import {RatingService} from '../service/RatingService';
import {ProductVersionLobItemControl} from './ProductVersionLobItemControl';

export interface ProductVersionLobItemPageProps extends NavigationAware {
  lobService: LobService;
  productService: ProductService;
  ratingService: RatingService;
  authManager: AuthManager;
}
export function ProductVersionLobItemPage(
  props: ProductVersionLobItemPageProps
) {
  const {productVersionId} = useParams<string>();
  if (typeof productVersionId !== 'string') {
    return <></>;
  }

  return (
    <ProductVersionLobItemControl
      lobService={props.lobService}
      productService={props.productService}
      productVersionId={productVersionId as string}
      authManager={props.authManager}
    />
  );
}
