import {SaveOutlined} from '@ant-design/icons';
import {
  Button,
  Col,
  Flex,
  Form,
  Input,
  Row,
  Select,
  TreeDataNode,
  TreeSelect,
} from 'antd';
import React, {useEffect, useState} from 'react';
import {ButtonSettings} from '../../common/control/Common';
import {NewTenantAwareEntity} from '../../common/model/Common';
import {IdAndValue} from '../../valuelist/model/ValueList';
import {ValueListService} from '../../valuelist/service/ValueListService';
import {
  ClientTypeOptions,
  Tenant,
  TenantInfo,
  WrittenByStateCodeOptions,
} from '../model/Tenant';
import {TenantService} from '../service/TenantService';

interface TenantDetailFormProps {
  tenantService: TenantService;
  valueListService: ValueListService;
  tenantInfo: TenantInfo | undefined;
  onSaved: (tenant: Tenant) => void;
  onCancel: () => void;
  setDirty: (dirty: boolean) => void;
  readOnly?: boolean;
}

interface TenantDetailFormArgs {
  accountType: string;
  name: string;
  address: string;
  url: string;
  description: string;
  lineOfBusinessValueListItemIds: string[];
  statesWrittenIn: string[];
  directWrittenPremium: string;
  primaryContactName: string;
  primaryContactJobTitle: string;
  primaryContactEmail: string;
  primaryContactPhone: string;
}

export function TenantDetailForm(props: TenantDetailFormProps) {
  const [form] = Form.useForm<TenantDetailFormArgs>();

  // using this to refresh form dependencies, since I cannot figure out how to use ant forms shouldUpdate correctly
  const [internalRefreshNum, setInternalRefreshNum] = React.useState(0);
  const [tenant, setTenant] = useState<Tenant | undefined>(undefined);
  const [lobTreeData, setLobTreeData] = useState<TreeDataNode[]>();

  useEffect(() => {
    form.resetFields();
    if (!props.tenantInfo) {
      return;
    }

    form.setFieldsValue({
      accountType: props.tenantInfo.accountType,
      name: props.tenantInfo.name,
      address: props.tenantInfo.address,
      url: props.tenantInfo.url,
      description: props.tenantInfo.description,
      directWrittenPremium: props.tenantInfo.directWrittenPremium,
      primaryContactName: props.tenantInfo.primaryContactName,
      primaryContactJobTitle: props.tenantInfo.primaryContactJobTitle,
      primaryContactEmail: props.tenantInfo.primaryContactEmail,
      primaryContactPhone: props.tenantInfo.primaryContactPhone,
      statesWrittenIn: props.tenantInfo.statesWrittenIn,
      lineOfBusinessValueListItemIds:
        props.tenantInfo.lineOfBusinessValueListItemIds?.map(lob => lob.id),
    });
  }, [props.tenantInfo]);

  useEffect(() => {
    const eff = async () => {
      // get the insurance type and the lines of business and use them to form the tree
      const insuranceTypes = await props.valueListService.GetInsuranceTypes();

      // unfortunately we aren't returning back the parent id when querying children
      // so query for each insurance type separately to build the tree
      // since there shouldn't be more than 5 of these ever (and 2 right now) performance should be managable

      const lobPromiseMap = new Map<String, Promise<IdAndValue[]>>();
      insuranceTypes.forEach(async insuranceType => {
        lobPromiseMap.set(
          insuranceType.id,
          props.valueListService.GetLinesOfBusiness(insuranceType.id)
        );
      });

      // wait for all the promises to finish concurrently
      await Promise.all(lobPromiseMap.values());

      // now build the map of insurance type to lob, await here should be free since we already awaited the promises above
      const insuranceTypeIdToCarrierMap = new Map<String, IdAndValue[]>();
      for (const [insuranceTypeId, lobPromise] of lobPromiseMap.entries()) {
        const lobs = await lobPromise;
        insuranceTypeIdToCarrierMap.set(insuranceTypeId, lobs);
      }

      // now build the tree
      const treeData: TreeDataNode[] = insuranceTypes.map(insuranceType => {
        return {
          title: insuranceType.value,
          value: insuranceType.id,
          checkable: false,
          children: insuranceTypeIdToCarrierMap
            .get(insuranceType.id)
            ?.map(lob => {
              return {
                title: lob.value,
                value: lob.id,
              };
            }),
        };
      });
      setLobTreeData(treeData);
    };
    eff();
  }, [internalRefreshNum]);

  async function onSave(value: TenantDetailFormArgs) {
    const toSave = {
      ...NewTenantAwareEntity(),
      id: props.tenantInfo?.id,
      version: props.tenantInfo?.version,
      name: value.name,
      accountType: value.accountType,
      address: value.address,
      url: value.url,
      description: value.description,
      directWrittenPremium: value.directWrittenPremium,
      primaryContactName: value.primaryContactName,
      primaryContactJobTitle: value.primaryContactJobTitle,
      primaryContactEmail: value.primaryContactEmail,
      primaryContactPhone: value.primaryContactPhone,
    } as Tenant;

    const saved = await props.tenantService.SaveTenant(
      toSave,
      value.statesWrittenIn,
      value.lineOfBusinessValueListItemIds
    );
    props.setDirty(false);
    props.onSaved(saved);
  }

  function onFormValuesChange() {
    props.setDirty(true);
  }

  const modifyButtons = props.readOnly ? (
    <></>
  ) : (
    <Button {...ButtonSettings} htmlType="submit">
      <SaveOutlined />
    </Button>
  );

  return (
    <Form
      name="edit_tenant"
      onFinish={onSave}
      onValuesChange={onFormValuesChange}
      autoComplete="off"
      labelCol={{span: 8}}
      wrapperCol={{span: 16}}
      size="large"
      form={form}
    >
      <Row>
        <Col span={24}>
          <Flex justify="end">{modifyButtons}</Flex>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Form.Item
            name="accountType"
            label="Account Type"
            rules={[{required: true}]}
          >
            <Select options={ClientTypeOptions} disabled={props.readOnly} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="statesWrittenIn" label="States Written In">
            <Select
              options={WrittenByStateCodeOptions}
              placeholder="Please select"
              mode="multiple"
              disabled={props.readOnly}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="name"
            label="Account Name"
            rules={[{required: true}]}
          >
            <Input disabled={props.readOnly} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="directWrittenPremium" label="DWP">
            <Input disabled={props.readOnly} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="address"
            label="Company Address"
            rules={[{required: true}]}
          >
            <Input disabled={props.readOnly} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="primaryContactName"
            label="Primary Contact Name"
            rules={[{required: true}]}
          >
            <Input disabled={props.readOnly} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="url" label="Company URL" rules={[{type: 'url'}]}>
            <Input disabled={props.readOnly} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="primaryContactJobTitle"
            label="Primary Contact Job Title"
            rules={[{required: true}]}
          >
            <Input disabled={props.readOnly} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="description" label="Company Description">
            <Input disabled={props.readOnly} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="primaryContactEmail"
            label="Primary Contact Email Address"
            rules={[{required: true}, {type: 'email'}]}
          >
            <Input disabled={props.readOnly} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="lineOfBusinessValueListItemIds"
            label="Lines of Business"
          >
            <TreeSelect
              treeData={lobTreeData}
              treeCheckable={true}
              showCheckedStrategy={TreeSelect.SHOW_PARENT}
              placeholder="Please select"
              disabled={props.readOnly}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="primaryContactPhone"
            label="Primary Contact Phone Number"
            rules={[{required: true}]}
          >
            <Input disabled={props.readOnly} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}
