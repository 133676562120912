import {App, Col, Flex, Layout, Row} from 'antd';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {LoginForm} from './LoginForm';

type LoginPageProps = {
  storeAuthToken: (token: string) => void;
  redirectToUrl: string;
};

const headerStyle: React.CSSProperties = {
  textAlign: 'center',
  color: '#fff',
  minHeight: 64,
  lineHeight: '1',
  height: 'auto',
  position: 'relative',
  backgroundColor: 'transparent',
};

export default function RuleControlPage(props: LoginPageProps) {
  const navigate = useNavigate();

  function onLogin(token: string) {
    props.storeAuthToken(token);
    navigate(props.redirectToUrl);
  }

  // this layout duplicates the main layout, but the main layout has all sorts of assumptions about there being a user
  return (
    <App>
      <Layout className="login-wrapper overflow-auto">
        <div className="login flex flex-col w-full min-h-svh">
          <Layout className="relative bg-transparent">
            <Layout.Header style={headerStyle}>
              <Row>
                {/* <Col span={4} /> */}
                <Col span={24} className="h-28">
                  <Flex
                    justify="center"
                    align="center"
                    className="mx-auto lg:mt-6 mt-4 hidden"
                  >
                    ADAPTIFY
                    {/* <Typography.Title level={2} style={{marginBlockStart: '10px'}}>
                </Typography.Title> */}
                  </Flex>
                </Col>
                {/* <Col span={4}></Col> */}
              </Row>
            </Layout.Header>
            <Layout.Content
              className="shrink-0 h-full"
              style={{
                width: '100%',
                position: 'relative',
                zIndex: 100,
                minHeight: '0',
              }}
            >
              <div className="w-full flex flex-col shrink-0">
                <img
                  className="mb-8 max-w-64 mx-auto shrink-0"
                  src="/logo.svg"
                  alt="Logo"
                />
                <Flex justify="center" align="end" style={{width: '100%'}}>
                  <LoginForm onLogin={onLogin} />
                </Flex>
              </div>
            </Layout.Content>
          </Layout>
          <Layout.Footer className="shrink-0 bg-transparent">
            <div className="flex items-center justify-center mb-12">
              <p className="mb-0">2024 Adaptify, Inc. All Rights Reserved</p>
              {/* <img src="/logo.svg" alt="Logo" className="max-w-32" /> */}
            </div>
          </Layout.Footer>
        </div>
      </Layout>
    </App>
  );
}
