import {Button, Flex, Form, Input} from 'antd';
import React, {useEffect} from 'react';

import {ButtonSettings} from '../../common/control/Common';
import {ValueListItem} from '../model/ValueList';

export interface EditValueListItemFormProps {
  valueListItem: ValueListItem;
  parentValueItemId: string | undefined;
  onSave: (item: ValueListItem) => void;
  onCancel: () => void;
}

export function EditValueListItemForm(props: EditValueListItemFormProps) {
  const [form] = Form.useForm<ValueListItem>();

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(props.valueListItem);
  }, [props.valueListItem]);

  function onSave(item: ValueListItem) {
    // merge values in the form with the existing object
    props.onSave({
      ...props.valueListItem,
      ...item,
    });
  }

  return (
    <Form
      name="value_item_definition"
      layout="vertical"
      size="large"
      labelCol={{span: 24}}
      wrapperCol={{span: 24}}
      style={{width: '100%'}}
      initialValues={{...props.valueListItem}}
      onFinish={onSave}
      autoComplete="off"
      form={form}
    >
      <Form.Item name="value" label="Value" rules={[{required: true}]}>
        <Input />
      </Form.Item>
      <Flex style={{width: '100%'}} justify="center" className="gap-2">
        <Button {...ButtonSettings} htmlType="button" onClick={props.onCancel}>
          Cancel
        </Button>
        <Button {...ButtonSettings} type="primary" htmlType="submit">
          OK
        </Button>
      </Flex>
    </Form>
  );
}
