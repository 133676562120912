import {Flex} from 'antd';
import React, {useState} from 'react';
import {ValueListDefinitionList} from '../control/ValueListDefinitionList';
import {ValueListService} from '../service/ValueListService';

export interface ValueListDefinitionControlProps {
  valueListService: ValueListService;
}
export function ValueListDefinitionControl(
  props: ValueListDefinitionControlProps
) {
  const [selectedValueListId, setSelectedValueListId] = useState<
    string | undefined
  >(undefined);
  const [selectedChildValueListId, setSelectedChildValueListId] = useState<
    string | undefined
  >(undefined);
  const [selectedGrandchildValueListId, setSelectedGrandchildValueListId] =
    useState<string | undefined>(undefined);

  return (
    <Flex vertical gap={24}>
      <ValueListDefinitionList
        valueListService={props.valueListService}
        selectedValueListId={selectedValueListId}
        setSelectedValueListId={setSelectedValueListId}
        parentValueListDefinitionId={undefined}
      />
      <ValueListDefinitionList
        childList
        valueListService={props.valueListService}
        selectedValueListId={selectedChildValueListId}
        setSelectedValueListId={setSelectedChildValueListId}
        parentValueListDefinitionId={selectedValueListId}
      />
      <ValueListDefinitionList
        childList
        valueListService={props.valueListService}
        selectedValueListId={selectedGrandchildValueListId}
        setSelectedValueListId={setSelectedGrandchildValueListId}
        parentValueListDefinitionId={selectedChildValueListId}
      />
    </Flex>
  );
}
