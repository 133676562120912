import React from 'react';
import {useParams, useSearchParams} from 'react-router-dom';
import {LobService} from '../../lob/service/LobService';
import {ProductService} from '../../product/service/ProductService';
import {ValueListService} from '../../valuelist/service/ValueListService';
import {AnalysisService} from '../service/AnalysisService';
import {AnalysisStudyDetailControl} from './AnalysisStudyDetailControl';

export interface AnalysisStudyDetailPageProps {
  analysisService: AnalysisService;
  productService: ProductService;
  valueListService: ValueListService;
  lobService: LobService;
}

export function AnalysisStudyDetailPage(props: AnalysisStudyDetailPageProps) {
  const {studyId} = useParams<string>();
  if (typeof studyId !== 'string') {
    return <></>;
  }

  const [searchParams, setSearchParams] = useSearchParams();
  const launchAddTableComparisonDialog = searchParams.get(
    'launchAddTableComparisonDialog'
  );

  return (
    <AnalysisStudyDetailControl
      analysisService={props.analysisService}
      productService={props.productService}
      valueListService={props.valueListService}
      lobService={props.lobService}
      studyId={studyId}
      launchAddTableComparisonDialog={'true' === launchAddTableComparisonDialog}
    />
  );
}
