import dayjs from 'dayjs';

export function DateIsoStringToDayJs(date: string): dayjs.Dayjs {
  return dayjs(date, 'YYYY-MM-DD');
}

export function DateIsoStringToDisplayString(date: string | undefined): string {
  if (!date) {
    return '';
  }
  return DateIsoStringToDayJs(date).format('MM/DD/YYYY');
}

export function DayJsToIsoString(date: dayjs.Dayjs): string {
  return date.format('YYYY-MM-DD');
}

export function DayJsToDisplayString(date: dayjs.Dayjs | undefined): string {
  if (!date) {
    return '';
  }
  return date.format('YYYY-MM-DD');
}

export function DateFromISOToDisplay(date: string): string {
  const dateObj = new Date(date);
  return dateObj.toLocaleDateString();
}
