package com.adaptify.rating.admin.model.calculation.binding

import com.adaptify.rating.admin.model.ModelUtil
import com.adaptify.rating.admin.model.impl.util.DeepCopyable
import kotlinx.serialization.DeserializationStrategy
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.JsonContentPolymorphicSerializer
import kotlinx.serialization.json.JsonElement
import kotlin.js.JsExport

expect interface KeyedInputEntry {
  var key: String?
  var value: String?
}

@Serializable
class KeyedInputEntryImpl(override var key: String? = null,
                          override var value: String? = null) : KeyedInputEntry

expect fun createKeyedInputEntry(key: String?, value: String?) : KeyedInputEntry

object KeyedInputEntryUtil : ModelUtil<KeyedInputEntry> {
  fun create(key: String? = null, value: String? = null): KeyedInputEntry {
    return createKeyedInputEntry(key, value);
  }

  override fun deepCopy(obj: KeyedInputEntry): KeyedInputEntry {
    return create(obj.key, obj.value)
  }
}
