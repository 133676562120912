import '@ag-grid-community/styles/ag-grid.css'; // Core CSS
import '@ag-grid-community/styles/ag-theme-quartz.css';
import {Col, Input, Row, Select, Typography} from 'antd';
import React from 'react';
import {
  StudyTableComparison,
  StudyTableComparisonTableSummaryInfo,
} from '../model/ComparativeAnalysis';

export interface TableComparisonSummaryFormProps {
  tableComparison: StudyTableComparison | undefined;
  tableComparisonSummaries: StudyTableComparisonTableSummaryInfo[];
  selectedTableComparisonTableId: string | undefined;
  setSelectedTableComparisonTableId: (id: string | undefined) => void;
}

export function TableComparisonSummaryForm(
  props: TableComparisonSummaryFormProps
) {
  const selectedTableComparisonSummary = props.tableComparisonSummaries.find(
    s => s.tableComparisonTableId === props.selectedTableComparisonTableId
  );

  function getCarrierOptions() {
    return props.tableComparisonSummaries.map(s => {
      return {label: s.carrierName, value: s.tableComparisonTableId};
    });
  }

  return (
    <>
      <div
        style={{
          border: 'solid',
          borderWidth: '1px',
          borderRadius: '8px',
          borderColor: '#CCCCCC',
          backgroundColor: '#F2F7FE',
        }}
      >
        <div
          style={{
            marginLeft: '16px',
            marginRight: '16px',
            marginTop: '16px',
            marginBottom: '16px',
          }}
        >
          <Row gutter={[20, 16]}>
            <Col span={4} style={{textAlign: 'right'}}>
              <Typography.Text>Table Comparison Name</Typography.Text>
            </Col>
            <Col span={8}>
              <Input
                readOnly
                style={{backgroundColor: '#F0F0F0'}}
                value={props.tableComparison?.name}
              />
            </Col>
            <Col span={4} style={{textAlign: 'right'}}>
              <Typography.Text>Table Comparison Description</Typography.Text>
            </Col>
            <Col span={8}>
              <Input
                readOnly
                style={{backgroundColor: '#F0F0F0'}}
                value={props.tableComparison?.comments}
              />
            </Col>
            <Col span={4} style={{textAlign: 'right'}}>
              <Typography.Text>Carrier Name</Typography.Text>
            </Col>
            <Col span={8}>
              <Select
                style={{width: '100%'}}
                options={getCarrierOptions()}
                value={props.selectedTableComparisonTableId}
                onChange={props.setSelectedTableComparisonTableId}
              />
            </Col>
            <Col span={4} style={{textAlign: 'right'}}>
              <Typography.Text>Table Name</Typography.Text>
            </Col>
            <Col span={8}>
              <Input
                readOnly
                style={{backgroundColor: '#F0F0F0'}}
                value={selectedTableComparisonSummary?.carrierName}
              />
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
