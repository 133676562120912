import React from 'react';
import {NavigationAware} from '../../NavigationAware';
import {CreateTrialAccountForm} from '../control/CreateTrialAccountForm';
import NewTrialAccountService, {
  TrialAccountService,
} from '../service/TrialAccountService';

// assumption is that this will be opened as a popup
export interface CreateTrialAccountPage extends NavigationAware {
  trialAccountSerice: TrialAccountService;
}

export function onFinish() {
  window.close();
}
export function TrialAccountPage() {
  // create a new trial acccout service that doesn't know about the underlying token
  const trialAccountService = NewTrialAccountService(undefined);
  return (
    <CreateTrialAccountForm
      trialAccountService={trialAccountService}
      onFinish={onFinish}
      onCancel={onFinish}
    />
  );
}
