import {Button, Card, Col, Row, Typography} from 'antd';
import React, {useEffect} from 'react';
import {ButtonSettings} from '../../common/control/Common';
import {DateIsoStringToDisplayString} from '../../common/model/CommonUtils';
import {ProductVersionSummary} from '../model/Product';
import {FormatVersionNumberDisplayString} from '../model/ProductUtils';
import {ProductService} from '../service/ProductService';

export interface DeleteProductVersionFormProps {
  productService: ProductService;
  productVersionId: string | undefined;
  refreshFormCount: number;
  onDeleted: () => void;
  onCancel: () => void;
}

export function DeleteProductVersionForm(props: DeleteProductVersionFormProps) {
  const [productVersion, setProductVersion] = React.useState<
    ProductVersionSummary | undefined
  >(undefined);

  useEffect(() => {
    if (!props.productVersionId) {
      setProductVersion(undefined);
      return;
    }

    const eff = async () => {
      const pv = await props.productService.GetProductVersionSummaryById(
        props.productVersionId ?? ''
      );
      setProductVersion(pv);
    };
    eff();
  }, [props.productVersionId, props.refreshFormCount]);

  if (!props.productVersionId) {
    return <></>;
  }

  async function deleteProduct() {
    if (!props.productVersionId) {
      return;
    }

    await props.productService.DeleteProductVersion(props.productVersionId);
    props.onDeleted();
  }

  function cancelDelete() {
    props.onCancel();
  }

  return (
    <Card bordered={false} title="Delete Rate Product">
      <Row gutter={[16, 30]}>
        <Col span={24}>
          <Typography.Text style={{color: 'red', fontWeight: 'bold'}}>
            The following Rate Product Version has been selected for permanent
            deletion:
          </Typography.Text>
        </Col>
        <Col span={6}>
          <Typography.Text strong>Effective Date</Typography.Text>
        </Col>
        <Col span={6}>
          <Typography.Text strong>Product Version</Typography.Text>
        </Col>
        <Col span={6}>
          <Typography.Text strong>Status</Typography.Text>
        </Col>
        <Col span={6}>
          <Typography.Text strong>Description</Typography.Text>
        </Col>
        <Col span={6}>
          <Typography.Text>
            {DateIsoStringToDisplayString(productVersion?.effectiveDate)}
          </Typography.Text>
        </Col>
        <Col span={6}>
          <Typography.Text>
            {FormatVersionNumberDisplayString(productVersion?.versionNumber)}
          </Typography.Text>
        </Col>
        <Col span={6}>
          <Typography.Text>{productVersion?.status}</Typography.Text>
        </Col>
        <Col span={6}>
          <Typography.Text style={{textWrap: 'wrap'}}>
            {productVersion?.description}
          </Typography.Text>
        </Col>
        <Col span={24}>
          <Typography.Text style={{color: 'red', fontWeight: 'bold'}}>
            Selecting the "Confirm Rate Product Deletion" button will
            permanently delete the rate product with no ability to restore it.
            Select the "Cancel" button to navigate back to the Rate Product
            Versions screen.
          </Typography.Text>
        </Col>
        <Col span={24}>
          <div className="flex w-full items-center justify-end gap-2">
            <Button {...ButtonSettings} onClick={() => cancelDelete()}>
              Cancel
            </Button>
            <Button
              ghost={false}
              type="default"
              onClick={() => deleteProduct()}
            >
              Confirm Rate Product Deletion
            </Button>
          </div>
        </Col>
      </Row>
    </Card>
  );
}
