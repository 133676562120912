package com.adaptify.rating.admin.model.util

import com.adaptify.rating.admin.model.calculation.BindingType
import com.adaptify.rating.admin.model.calculation.Calculation
import com.adaptify.rating.admin.model.calculation.CalculationFunction
import com.adaptify.rating.admin.model.calculation.binding.Binding
import com.adaptify.rating.admin.model.calculation.binding.BindingUtil
import com.adaptify.rating.admin.model.calculation.context.CalculationEvalContext
import com.adaptify.rating.admin.model.calculation.context.ScopedVariable
import com.adaptify.rating.admin.model.calculation.context.VariableType
import com.adaptify.rating.admin.model.calculation.descriptor.*
import com.adaptify.rating.admin.model.calculation.env.MetadataProvider
import com.adaptify.rating.admin.model.lob.LineOfBusinessHierarchy
import com.adaptify.rating.admin.model.lob.LineOfBusinessHierarchyItem
import com.adaptify.rating.admin.model.type.PrimitiveDataType
import kotlinx.coroutines.coroutineScope
import kotlin.js.JsExport

object BindingResolver {

  suspend fun ResolveDataTypeForBinding(
    calculationFunction: CalculationFunction,
    binding: Binding,
    metadataProvider: MetadataProvider,
    scope: Array<ScopedVariable>
  ): BoundDataType? {
      if (binding.bindingType == BindingType.VariableDeclaration.name) {
        val funcDef = FunctionDescriptorMap.get(calculationFunction.name)
        val bindingDescs = funcDef?.getBindingDescriptors()
        val bindingDesc = bindingDescs?.find { bindingDesc ->
          bindingDesc.name == binding.name
        }

        if (funcDef != null && bindingDesc != null) {
          return bindingDesc.getExpectedDataType(
            calculationFunction,
            funcDef,
            bindingDesc,
            binding,
            metadataProvider,
            scope
          )
        }
        return null
      }
      if (binding.bindingType == BindingType.PrimitiveLiteral.name && binding.literal?.dataType != null) {
        return BoundDataTypeImpl.Primitive(PrimitiveDataType.valueOf(binding.literal?.dataType!!), false)
      }
      // table lookup as a function is disabled for now, espcially if it's not clear if the function
      // will have an output or not
      /*

      if (binding.bindingType == BindingType.TableBinding.name  && binding.tableBinding?.resultColumn != null) {
        // find the data type of the column
        if (binding.tableBinding?.tableName != null) {
          val table = metadataProvider.getTableByName(binding.tableBinding!!.tableName!!).await()
          if (table != null) {
            val column = table.columns.find { it.name == binding?.tableBinding?.resultColumn }

            val boundDataType =  ResolveDataTypeForBinding(
              calculationFunction,
              BindingUtil.fromPath("any", column?.path ?: ""),
              metadataProvider,
              scope
            );
            if (boundDataType == null || boundDataType.primitive == null) {
              return null;
            }
            // even if many cardinality was traversed data type is always one
            return BoundDataTypeImpl.Primitive(boundDataType.primitive ?: PrimitiveDataType.String, false);
          }
        }
        return null
      }
      */
      if (binding.bindingType == BindingType.DynamicVariable.name) {
        val rootVar = scope.find { v -> v.name == binding.dynamicVariableFunctionId }
        return rootVar?.boundDataType
      }
      if (binding.bindingType == BindingType.Path.name) {
        val pathComponents = binding.path?.split('.') ?: emptyList()
        if (pathComponents.isEmpty()) {
          return null
        }

        // root
        // first path component is the root, but resolving the root data type
        // might be recursive
        // don't call GetRootsForPathPicker in this function because GetRootsForPathPicker
        // will eventually call this causing circular infinite recursion

        // if a root property is resolvable, it must be an input or a variable declaration
        // check the inputs first as they are cheapest, then get all the variable decs in scope
        val rootPath = pathComponents[0]
        val rest = pathComponents.drop(1)
        val rootVar = scope.find { v -> v.name == rootPath }
        if (rootVar == null) {
          return null
        }

        // root
        if (rootVar.boundDataType.primitive != null) {
          if (rest.isEmpty()) {
            // if this resolves to a primitive type, it needs to be the final part of the path
            return rootVar.boundDataType;
          }
          return null
        }

        else if (rootVar.boundDataType.lobItem != null) {
          return PathResolver().resolvePath(
            rootVar.boundDataType.lobItem!!,
            rootVar.boundDataType.isMany,
            rest,
          )
        }
      }
      return null
  }

  // originally this set up context vales for the current in scope object,
  // and its parents.  Current product requirements are for all paths to start at the root
  // this makes it ambiguous on how to handle lists of objects in certain contexts
  // but this is jus fact of life of the current reqires
  fun getRootVariablesForLineOfBusinessItem(
    lineOfBusinessItemId: String,
    lobDef: LineOfBusinessHierarchy
  ): List<ScopedVariable> {
    // right now it doesn't matter what the current object is, always just use the root
    // val hierarchy = findLineOfBusinessItemAndParents(lineOfBusinessItemId, lobDef)
    // get the roots (stored as list, but always a single item expected
    val hierarchy = lobDef.risks;
    return hierarchy.map { v -> ScopedVariable(VariableType.Input.name, v.name, v.name, null, BoundDataTypeImpl.LobItem(v, false)) }
  }

  @JsExport.Ignore
  fun findLineOfBusinessItemAndParents(
    idToFind: String,
    lineOfBusinessDef: LineOfBusinessHierarchy
  ): List<LineOfBusinessHierarchyItem> {
    val hierarchy = mutableListOf<LineOfBusinessHierarchyItem>()
    for (risk in lineOfBusinessDef.risks) {
      hierarchy.add(risk)
      if (findLineOfBusinessItemAndParentsRecur(idToFind, risk, hierarchy)) {
        return hierarchy
      }
      hierarchy.remove(risk)
    }
    return hierarchy
  }

  @JsExport.Ignore
  fun findLineOfBusinessItemAndParentsRecur(
    idToFind: String,
    lineOfBusinessItem: LineOfBusinessHierarchyItem,
    hierarchy: MutableList<LineOfBusinessHierarchyItem>
  ): Boolean {
    if (lineOfBusinessItem.id == idToFind) {
      return true
    }

    for (child in lineOfBusinessItem.children) {
      hierarchy.add(child)
      if (findLineOfBusinessItemAndParentsRecur(idToFind, child, hierarchy)) {
        return true
      }
      hierarchy.remove(child)
    }

    return false
  }

  suspend fun GetFunctionStepNameMap(
    calculation: Calculation,
    lobDef: LineOfBusinessHierarchy,
    metadataProvider: MetadataProvider
  ): Map<String, String> {
    return CalculationEvalContext.Create(calculation, lobDef, metadataProvider)
      ?.functionContexts?.mapValues { it.value.stepName }
      ?: emptyMap()
  }

   suspend fun GetScopedVariables(
    calculation: Calculation,
    lobDef: LineOfBusinessHierarchy,
    calculationFunction: CalculationFunction,
    metadataProvider: MetadataProvider
  ): Array<ScopedVariable> {
    return CalculationEvalContext.Create(
      calculation,
      lobDef,
      metadataProvider
    )?.functionContexts?.get(calculationFunction.id)?.variables
      ?: emptyArray<ScopedVariable>()
  }

  fun findPreviousFunctions(
    calculation: Calculation,
    calculationFunction: CalculationFunction
  ): List<CalculationFunction> {
    val previous = mutableListOf<CalculationFunction>()
    var funcs = calculation.functions

    for (f in funcs) {
      if (f.id == calculationFunction.id) {
        return previous
      }
      previous.add(f)
      if (findPreviousFunctionsRecur(f, calculationFunction, previous)) {
        return previous
      }
    }
    return previous
  }

  fun findPreviousFunctionsRecur(
    currentCalculationFunction: CalculationFunction,
    calculationFunctionToMatch: CalculationFunction,
    previousList: MutableList<CalculationFunction>
  ): Boolean {
    for (binding in currentCalculationFunction.bindings) {
      var childFunctions: Array<CalculationFunction>? = null

      if (binding.bindingType == BindingType.Block.name && binding.block != null) {
        childFunctions = binding.block
      }
      if (binding.bindingType == BindingType.Predicate.name && binding.predicate?.functions != null) {
        childFunctions = binding?.predicate?.functions
      }
      if (childFunctions == null) {
        return false
      }
      var pushedCount = 0
      for (childFunction in childFunctions) {
        if (childFunction.id == calculationFunctionToMatch.id) {
          return true
        }

        previousList.add(childFunction)
        pushedCount++
        val result = findPreviousFunctionsRecur(
          childFunction,
          calculationFunctionToMatch,
          previousList
        )
        if (result) {
          return result
        }
      }
      // remove the items we pushed above since we sidn't find the function in the branch
      for (i in IntRange(0, pushedCount - 1)) {
        previousList.removeFirst()
      }
    }
    return false
  }
}


