import {Button, Flex, Form, Select} from 'antd';
import React from 'react';
import {ButtonSettings} from '../../common/control/Common';
import {NewTenantAwareEntity} from '../../common/model/Common';
import {ValueListService} from '../../valuelist/service/ValueListService';
import {TenantModule, TenantModuleNameOptions} from '../model/Tenant';
import {TenantService} from '../service/TenantService';

interface AddTenantModuleFormProps {
  tenantService: TenantService;
  valueListService: ValueListService;
  tenantId?: string;
  onSaved: (tenant: TenantModule) => void;
  onCancel: () => void;
}

interface AddTenantModuleFormArgs {
  moduleName: string;
}

export function AddTenantModuleForm(props: AddTenantModuleFormProps) {
  const [form] = Form.useForm<AddTenantModuleFormArgs>();

  async function onSave(value: AddTenantModuleFormArgs) {
    const tenantModule = {
      ...NewTenantAwareEntity(),
      tenantId: props.tenantId,
      moduleName: value.moduleName,
    } as TenantModule;

    try {
      const updated = await props.tenantService.SaveTenantModule(tenantModule);
      props.onSaved(updated);
    } catch (e: any) {
      form.setFields([
        {
          name: 'moduleName',
          errors: [e.details?.message ?? e.message],
        },
      ]);
      return;
    }
  }

  return (
    <Form
      name="add_tenant_module"
      labelCol={{span: 8}}
      wrapperCol={{span: 16}}
      style={{maxWidth: 800}}
      onFinish={onSave}
      autoComplete="off"
      size="large"
      form={form}
    >
      <Form.Item
        name="moduleName"
        label="Module Name"
        rules={[{required: true}]}
      >
        <Select showSearch allowClear options={TenantModuleNameOptions} />
      </Form.Item>

      <Flex justify="end" style={{width: '100%'}} className="gap-2">
        <Button {...ButtonSettings} htmlType="button" onClick={props.onCancel}>
          Cancel
        </Button>
        <Button {...ButtonSettings} type="primary" htmlType="submit">
          Save
        </Button>
      </Flex>
    </Form>
  );
}
