package com.adaptify.rating.admin.model.flow.descriptor

import kotlin.js.JsExport

@JsExport
enum class AllowedEdgeType {
  None,
  One,
  Many
}
