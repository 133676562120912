import React from 'react';
import {useParams} from 'react-router-dom';
import {NavigationAware} from '../../NavigationAware';
import {LobService} from '../../lob/service/LobService';
import {ProductService} from '../../product/service/ProductService';
import {AuthManager} from '../../user/util/AuthManager';
import {RatingService} from '../service/RatingService';
import {ProductVersionPremiumOrchestrationControl} from './ProductVersionPremiumOrchestrationControl';

export interface ProductVersionPremiumOrchestrationPageProps
  extends NavigationAware {
  lobService: LobService;
  productService: ProductService;
  ratingService: RatingService;
  authManager: AuthManager;
}
export function ProductVersionPremiumOrchestrationPage(
  props: ProductVersionPremiumOrchestrationPageProps
) {
  const {productVersionId} = useParams<string>();
  if (typeof productVersionId !== 'string') {
    return <></>;
  }

  return (
    <ProductVersionPremiumOrchestrationControl
      lobService={props.lobService}
      productService={props.productService}
      ratingService={props.ratingService}
      productVersionId={productVersionId as string}
      authManager={props.authManager}
    />
  );
}
