import {Col, Input, Row, Select} from 'antd';
import React, {useEffect, useState} from 'react';

import * as kot from 'adaptify-multi-module-rating-admin-model';
import {IdAndName} from '../../../../product/model/Product';
import {ProductService} from '../../../../product/service/ProductService';

import Binding = kot.com.adaptify.rating.admin.model.calculation.binding.Binding;
import BindingType = kot.com.adaptify.rating.admin.model.calculation.BindingType;
import TableBinding = kot.com.adaptify.rating.admin.model.calculation.binding.TableBinding;
import Table = kot.com.adaptify.rating.admin.model.table.Table;
import TableUtil = kot.com.adaptify.rating.admin.model.table.TableUtil;
import ColumnType = kot.com.adaptify.rating.admin.model.table.ColumnType;
import ScopedVariable = kot.com.adaptify.rating.admin.model.calculation.context.ScopedVariable;
import BindingDescriptor = kot.com.adaptify.rating.admin.model.calculation.descriptor.BindingDescriptor;
import CalculationEvalContext = kot.com.adaptify.rating.admin.model.calculation.context.CalculationEvalContext;
import MetadataProvider = kot.com.adaptify.rating.admin.model.util.MetadataProvider;
import KeyedInputEntry = kot.com.adaptify.rating.admin.model.calculation.binding.KeyedInputEntry;

export interface TableBindingControlProps {
  allTableNames: IdAndName[];
  productService: ProductService;
  tableBinding?: TableBinding;
  setTableBinding: (tableBinding: TableBinding) => void;
  resetVersion: number;
}

interface ColumnDisplayData {
  columnName: string;
  path: string;
  override: string;
}

interface TableBindingFormValues {
  tableId: string;
  displayInputColumns: ColumnDisplayData[];
  resultColumn?: string;
}

export function TableBindingControl(props: TableBindingControlProps) {
  const [table, setTable] = useState<Table | undefined>(undefined);
  const [internalRefreshNumber, setInternalRefreshNumber] = useState(0);

  const [tableBindingFormValues, setTableBindingFormValues] =
    useState<TableBindingFormValues>({
      tableId: '',
      displayInputColumns: [],
    });

  useEffect(() => {
    setTableBindingFormValues({
      tableId: props.tableBinding?.tableId ?? '',
      resultColumn: props.tableBinding?.resultColumn ?? '',
      displayInputColumns: [],
    });
  }, [props.tableBinding]);

  useEffect(() => {
    const currentTableId = tableBindingFormValues?.tableId;
    if (!currentTableId || currentTableId === table?.id) {
      return;
    }
    const eff = async () => {
      const loaded =
        await props.productService.GetProductVersionTable(currentTableId);
      setTable(loaded);

      const columnDisp = loaded.columns
        ?.filter(
          col => !col.columnType || col.columnType === ColumnType.Input.name
        )
        .map(column => {
          const overrideValue = props.tableBinding?.inputOverrides?.find(
            override => override.key === column.name
          )?.value;
          return {
            columnName: column.name,
            path: column.path ?? '',
            override: overrideValue ?? '',
          } as ColumnDisplayData;
        });
      onFieldChange({
        ...tableBindingFormValues,
        displayInputColumns: columnDisp ?? [],
      });
    };
    eff();
  }, [tableBindingFormValues, props.resetVersion, internalRefreshNumber]);

  function onFieldChange(newValues: TableBindingFormValues) {
    setTableBindingFormValues(newValues);

    const newBinding: TableBinding = {
      tableId: newValues.tableId,
      inputOverrides: newValues.displayInputColumns
        .filter(override => override.override !== '')
        .map(override => {
          return {
            key: override.columnName,
            value: override.override,
          } as KeyedInputEntry;
        }),
      resultColumn: newValues.resultColumn,
    };
    props.setTableBinding(newBinding);
  }

  function buildBindingFromFormValues() {
    // convert the mappings back to the table binding
    const allValues = form.getFieldsValue();
    const inputOverrides = allValues.displayInputColumns || [];
    const resultColumn = allValues.resultColumn;

    const configuredBindings = inputOverrides
      .filter(override => override.override !== '')
      .map(override => {
        return {
          key: override.columnName,
          value: override.override,
        } as KeyedInputEntry;
      });

    const newBinding: TableBinding = {
      tableId: allValues.tableId,
      inputOverrides: configuredBindings,
      resultColumn: resultColumn,
    };

    props.setTableBinding(newBinding);
  }

  const tableNameOptions = props.allTableNames
    .map(t => ({
      label: t.name,
      value: t.id,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
  tableNameOptions.unshift({label: '', value: ''});

  const outputColumnNames =
    table?.columns
      .filter(col => col.columnType === ColumnType.Output.name)
      .map(col => col.name) ?? [];

  // preserve the orders
  const nameSet = new Set<string>();
  const outputColumnsNoDups: string[] = [];
  for (const col of outputColumnNames) {
    if (col && !nameSet.has(col)) {
      outputColumnsNoDups.push(col ?? '');
      nameSet.add(col);
    }
  }

  const outputTableColumnOptions = outputColumnsNoDups.map(column => ({
    label: column,
    value: column,
  }));
  outputTableColumnOptions.unshift({label: '', value: ''});

  // it's really a pain to layout forms with this dynamic of a list so don't use forms for this

  const columnOverrideControls =
    (tableBindingFormValues?.displayInputColumns?.length ?? 0) > 0 ? (
      <>
        <Row gutter={[20, 20]} style={{paddingBottom: '50px'}}>
          <Col span={6}></Col>
          <Col span={6}>Input Column Name</Col>
          <Col span={6}>Path</Col>
          <Col span={6}>Input Value</Col>
          {tableBindingFormValues?.displayInputColumns?.map((column, index) => {
            const columnIndex = table?.columns.findIndex(
              c => c.name === column.columnName
            );
            const columnData = table?.rows.map(row => row[columnIndex] ?? '');
            const sortedColumnData = [...new Set(columnData)].sort();
            const columnOptions = sortedColumnData.map(data => ({
              label: data,
              value: data,
            }));
            return (
              <>
                <Col span={6}>Column {index + 1}</Col>
                <Col span={6}>
                  <Input
                    readOnly
                    className="adaptify-read-only-field"
                    value={column.columnName}
                  />
                </Col>
                <Col span={6}>
                  <Input
                    readOnly
                    className="adaptify-read-only-field"
                    value={column.path}
                  />
                </Col>
                <Col span={6}>
                  <Select
                    style={{width: '100%'}}
                    value={column.override}
                    options={columnOptions}
                    onChange={value => {
                      const newColumns = structuredClone(
                        tableBindingFormValues.displayInputColumns
                      );
                      newColumns[index].override = value;
                      onFieldChange({
                        ...tableBindingFormValues,
                        displayInputColumns: newColumns,
                      });
                    }}
                  />
                </Col>
              </>
            );
          })}
        </Row>
        <Row gutter={[20, 20]}>
          <Col span={6}></Col>
          <Col span={6}>Output Column Name</Col>
          <Col span={12}></Col>
          <Col span={6}>Column 1</Col>
          <Col span={18}>
            <Select
              showSearch
              placeholder="Select a Column"
              options={outputTableColumnOptions}
              optionFilterProp="label"
              onChange={value => {
                onFieldChange({
                  ...tableBindingFormValues,
                  resultColumn: value,
                });
              }}
              style={{width: '100%'}}
              value={tableBindingFormValues.resultColumn}
            />
          </Col>
        </Row>
      </>
    ) : (
      <></>
    );
  return (
    <>
      <Row gutter={[20, 20]} style={{paddingBottom: '50px'}}>
        <Col span={6}>Table Name</Col>
        <Col span={18}>
          <Select
            style={{width: '100%'}}
            placeholder="Select a Table"
            options={tableNameOptions}
            showSearch
            optionFilterProp="label"
            onChange={value => {
              onFieldChange({
                ...tableBindingFormValues,
                tableId: value,
              });
              setInternalRefreshNumber(internalRefreshNumber + 1);
            }}
            value={tableBindingFormValues.tableId}
          />
        </Col>
      </Row>
      {columnOverrideControls}
    </>
  );
}
