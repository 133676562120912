import React, {useState} from 'react';
import {ProductService} from '../../product/service/ProductService';

import {Flex} from 'antd';
import {LobService} from '../../lob/service/LobService';
import {ValueListService} from '../../valuelist/service/ValueListService';
import {StudyProductVersionList} from '../control/StudyProductVersionList';
import {StudySummaryControl} from '../control/StudySummaryControl';
import {StudyTableComparisonList} from '../control/StudyTableComparisonList';
import {AnalysisService} from '../service/AnalysisService';

export interface AnalysisStudyDetailControlProps {
  analysisService: AnalysisService;
  productService: ProductService;
  valueListService: ValueListService;
  lobService: LobService;
  studyId: string;
  launchAddTableComparisonDialog: boolean;
}

export function AnalysisStudyDetailControl(
  props: AnalysisStudyDetailControlProps
) {
  const [modifyCount, setModifyCount] = useState<number>(0);
  const [selectedTableComparisonId, setSelectedTableComparisonId] = useState<
    string | undefined
  >(undefined);

  return (
    <Flex vertical gap={24}>
      <StudySummaryControl
        analysisService={props.analysisService}
        studyId={props.studyId}
      />
      <StudyProductVersionList
        analysisService={props.analysisService}
        productService={props.productService}
        valueListService={props.valueListService}
        lobService={props.lobService}
        studyId={props.studyId}
        modifyCount={modifyCount}
        setModifyCount={setModifyCount}
      />
      <StudyTableComparisonList
        analysisService={props.analysisService}
        productService={props.productService}
        studyId={props.studyId}
        modifyCount={modifyCount}
        setModifyCount={setModifyCount}
        selectedTableComparisonId={selectedTableComparisonId}
        setSelectedTableComparisonId={setSelectedTableComparisonId}
        launchAddTableComparisonDialog={props.launchAddTableComparisonDialog}
      />
    </Flex>
  );
}
