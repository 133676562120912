package com.adaptify.rating.admin.model.calculation.binding

import com.adaptify.rating.admin.model.ModelUtil
import com.adaptify.rating.admin.model.impl.util.DeepCopyable
import com.adaptify.rating.admin.model.type.PrimitiveDataType
import kotlinx.serialization.DeserializationStrategy
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.JsonContentPolymorphicSerializer
import kotlinx.serialization.json.JsonElement
import kotlin.js.JsExport

expect interface Literal {
    var dataType: String
    var value: String
}

expect fun createLiteral(dataType: String, value: String): Literal

@Serializable
class LiteralImpl(override var dataType: String,  override var value: String) :
    Literal{
}

object LiteralUtil : ModelUtil<Literal> {
    fun create(dataType: String, value: String): Literal {
        return createLiteral(dataType, value)
    }

    override fun deepCopy(obj: Literal): Literal {
        return create(obj.dataType, obj.value)
    }
}
