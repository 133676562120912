import React from 'react';

export function StartSvg(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="50px"
      height="50px"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3 2v12s6.333-2.833 10.666-6C9.333 4.833 3 2 3 2z"
        fill="green"
        overflow="visible"
        style={{marker: 'none'}}
        scale={0.001}
        color="#000000"
      />
    </svg>
  );
}

export function EndSvg(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="50px"
      height="50px"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 18a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2H6a2 2 0 0 0-2 2v12z"
        fill="red"
      />
    </svg>
  );
}

export function CalculationSvg(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="50px"
      height="50px"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      {...props}
    >
      <polygon points="14 8 10 8 10 4 8 4 8 8 4 8 4 10 8 10 8 14 10 14 10 10 14 10 14 8" />
      <rect x="4" y="19" width="10" height="2" />
      <rect x="4" y="24" width="10" height="2" />
      <rect x="18" y="8" width="10" height="2" />
      <polygon points="24.41 22 28 18.41 26.59 17 23 20.59 19.41 17 18 18.41 21.59 22 18 25.59 19.41 27 23 23.41 26.59 27 28 25.59 24.41 22" />
      <rect
        id="_Transparent_Rectangle_"
        data-name="&lt;Transparent Rectangle&gt;"
        style={{fill: 'none'}}
        width="32"
        height="32"
      />
    </svg>
  );
}

export function TableSvg(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="50px"
      height="50px"
      viewBox="0 0 1920 1920"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      {...props}
    >
      <path
        d="M1800 1320v420c0 33-27 60-60 60h-420v-480h480Zm-600 0v480H720v-480h480Zm-600 0v480H180c-33 0-60-27-60-60v-420h480Zm1200-600v480h-480V720h480Zm-600 0v480H720V720h480Zm-600 0v480H120V720h480Zm1140-600c33 0 60 27 60 60v420h-480V120h420Zm-540 0v480H720V120h480Zm-600 0v480H120V180c0-33 27-60 60-60h420ZM1740 0H180C80.76 0 0 80.76 0 180v1560c0 99.24 80.76 180 180 180h1560c99.24 0 180-80.76 180-180V180c0-99.24-80.76-180-180-180Z"
        fillRule="evenodd"
      />
    </svg>
  );
}

export function BranchSvg(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="50px"
      height="50px"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      {...props}
    >
      <path d="M21 18v-4h-5.042L13 11.042V8.95a3.5 3.5 0 1 0-1 0v2.074L9.024 14H4v4H2v5h5v-5H5v-3h3.958l3.532 3.533L16.024 15H20v3h-2v5h5v-5zM6 22H3v-3h3zm4-16.5A2.5 2.5 0 1 1 12.5 8 2.5 2.5 0 0 1 10 5.5zm2.485 11.633l-2.6-2.6 2.5-2.5h.2l2.5 2.5zM22 22h-3v-3h3z" />
      <path fill="none" d="M0 0h24v24H0z" />
    </svg>
  );
}

export function ConditionSvg(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="50px"
      height="50px"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      {...props}
    >
      <style type="text/css">{'}.linesandangles_een{fill:#111918;}'}</style>
      <g>
        <g>
          <path
            className="linesandangles_een"
            d="M10,20c-1.657,0-3,1.343-3,3s1.343,3,3,3s3-1.343,3-3S11.657,20,10,20z M10,24
			c-0.551,0-1-0.449-1-1s0.449-1,1-1s1,0.449,1,1S10.551,24,10,24z"
          />
          <circle className="linesandangles_een" cx="10" cy="16" r="3" />
          <path
            className="linesandangles_een"
            d="M10,6C8.343,6,7,7.343,7,9s1.343,3,3,3s3-1.343,3-3S11.657,6,10,6z M10,10
			c-0.551,0-1-0.449-1-1s0.449-1,1-1s1,0.449,1,1S10.551,10,10,10z"
          />
          <rect
            x="15"
            y="8"
            className="linesandangles_een"
            width="10"
            height="2"
          />
          <rect
            x="15"
            y="15"
            className="linesandangles_een"
            width="10"
            height="2"
          />
          <rect
            x="15"
            y="22"
            className="linesandangles_een"
            width="10"
            height="2"
          />
        </g>
        <g>
          <path
            className="linesandangles_een"
            d="M10,20c-1.657,0-3,1.343-3,3s1.343,3,3,3s3-1.343,3-3S11.657,20,10,20z M10,24
			c-0.551,0-1-0.449-1-1s0.449-1,1-1s1,0.449,1,1S10.551,24,10,24z"
          />
          <circle className="linesandangles_een" cx="10" cy="16" r="3" />
          <path
            className="linesandangles_een"
            d="M10,6C8.343,6,7,7.343,7,9s1.343,3,3,3s3-1.343,3-3S11.657,6,10,6z M10,10
			c-0.551,0-1-0.449-1-1s0.449-1,1-1s1,0.449,1,1S10.551,10,10,10z"
          />
          <rect
            x="15"
            y="8"
            className="linesandangles_een"
            width="10"
            height="2"
          />
          <rect
            x="15"
            y="15"
            className="linesandangles_een"
            width="10"
            height="2"
          />
          <rect
            x="15"
            y="22"
            className="linesandangles_een"
            width="10"
            height="2"
          />
        </g>
      </g>
    </svg>
  );
}

export function RightArrowHead(props: React.SVGProps<SVGSVGElement>) {
  <svg
    width="50px"
    height="50px"
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title>arrowhead-right-solid</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="invisible_box" data-name="invisible box">
        <rect width="48" height="48" fill="none" />
      </g>
      <g id="Q3_icons" data-name="Q3 icons">
        <path d="M19.5,37.4l11.9-12a1.9,1.9,0,0,0,0-2.8l-11.9-12A2,2,0,0,0,16,12h0V36h0a2,2,0,0,0,3.5,1.4Z" />
      </g>
    </g>
  </svg>;
}
