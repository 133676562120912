import {SERVER_BASE_URL} from '../../common/service/Constants';
import {
  CreateStandardHeaders,
  HandleFetchJson,
  HandleFetchText,
  HandleFetchVoid,
} from '../../common/service/Service';

import * as kot from 'adaptify-multi-module-rating-admin-model';
import {
  ExecuteTestCaseResult,
  ProductFlowSequenceUpdateRequest,
} from '../model/Rating';
import Table = kot.com.adaptify.rating.admin.model.table.Table;
import TestCaseLobItem = kot.com.adaptify.rating.admin.model.testcase.TestCaseLobItem;
import LineOfBusinessHierarchy = kot.com.adaptify.rating.admin.model.lob.LineOfBusinessHierarchy;
import GlobalVariable = kot.com.adaptify.rating.admin.model.calculation.util.GlobalVariable;

const SERVER_URL = SERVER_BASE_URL;

const EXPORT_TABLE_CSV_URL = (tableId: string) =>
  `${SERVER_URL}/admin/rating/table/${encodeURI(tableId)}/csv`;
const IMPORT_TABLE_CSV_URL = (id: string) =>
  `${SERVER_URL}/admin/rating/table/${encodeURI(id)}/csv/update`;

const GET_UTLIZED_LOB_HIERARCHY_BY_PRODUCT_VERSION_ID_URL = (id: string) =>
  `${SERVER_URL}/admin/rating/utilizedlobhierarchybyproductversionid/get/${id}`;
const GET_GLOBAL_VARIABLES_URL = (productVersionId: string) =>
  `${SERVER_URL}/admin/rating/globalvariables/get/${productVersionId}`;

const UPDATE_PREMIUM_ORCHESTRATION = `${SERVER_URL}/admin/rating/orchestration/update`;

const EXECUTE_TEST_CASE = `${SERVER_URL}/admin/rating/testcase/execute`;

export interface RatingService {
  ExportTableAsCsv(tableId: string): Promise<string>;
  UpdatePremiumOrchestration(
    request: ProductFlowSequenceUpdateRequest
  ): Promise<void>;
  ExecuteTestCase(testCaseId: string): Promise<ExecuteTestCaseResult>;
  GetUtilizedLobHierarchyForProductVersionId(
    productVersionId: string
  ): Promise<LineOfBusinessHierarchy>;
  GetGlobalVariables(productVersionId: string): Promise<GlobalVariable[]>;
}

export function GetImportTableAsCsvUrl(tableUUID: string) {
  return IMPORT_TABLE_CSV_URL(tableUUID);
}

export default function NewRatingService(authToken: string): RatingService {
  function createHeaders() {
    return CreateStandardHeaders(authToken);
  }
  async function ExportTableAsCsv(tableId: string): Promise<string> {
    return HandleFetchText(
      fetch(EXPORT_TABLE_CSV_URL(tableId), {
        method: 'GET',
        headers: createHeaders(),
      })
    );
  }

  async function UpdatePremiumOrchestration(
    request: ProductFlowSequenceUpdateRequest
  ) {
    return HandleFetchVoid(
      fetch(UPDATE_PREMIUM_ORCHESTRATION, {
        method: 'POST',
        headers: createHeaders(),
        body: JSON.stringify(request),
      })
    );
  }

  async function ExecuteTestCase(
    testCaseId: string
  ): Promise<ExecuteTestCaseResult> {
    return HandleFetchJson(
      fetch(EXECUTE_TEST_CASE, {
        method: 'POST',
        headers: createHeaders(),
        body: JSON.stringify({
          testCaseId: testCaseId,
        }),
      })
    );
  }

  async function GetUtilizedLobHierarchyForProductVersionId(
    productVersionId: string
  ): Promise<LineOfBusinessHierarchy> {
    const requestOptions = {
      method: 'GET',
      headers: createHeaders(),
    };

    return HandleFetchJson(
      fetch(
        GET_UTLIZED_LOB_HIERARCHY_BY_PRODUCT_VERSION_ID_URL(productVersionId),
        requestOptions
      )
    );
  }

  async function GetGlobalVariables(
    productVersionId: string
  ): Promise<GlobalVariable[]> {
    const requestOptions = {
      method: 'GET',
      headers: createHeaders(),
    };

    return HandleFetchJson(
      fetch(GET_GLOBAL_VARIABLES_URL(productVersionId), requestOptions)
    );
  }

  return {
    ExportTableAsCsv,
    UpdatePremiumOrchestration,
    ExecuteTestCase,
    GetUtilizedLobHierarchyForProductVersionId,
    GetGlobalVariables,
  };
}
