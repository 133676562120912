import * as kot from 'adaptify-multi-module-rating-admin-model';
import {Button, Flex} from 'antd';
import React, {useEffect, useMemo, useState} from 'react';
import {useHotkeys} from 'react-hotkeys-hook';
import {ButtonSettings} from '../../common/control/Common';
import {DraggableModal} from '../../common/control/DraggableModal';
import {LobService} from '../../lob/service/LobService';
import {ProductService} from '../../product/service/ProductService';
import {DirtyEditorConfirmationModal} from '../control/DirtyEditorConfirmationModal';
import {ProductVersionTableDetails} from '../control/table/ProductVersionTableDetails';
import {RatingService} from '../service/RatingService';
import Table = kot.com.adaptify.rating.admin.model.table.Table;
import LineOfBusinessHierarchy = kot.com.adaptify.rating.admin.model.lob.LineOfBusinessHierarchy;
import ScopedVariable = kot.com.adaptify.rating.admin.model.calculation.context.ScopedVariable;
import VariableType = kot.com.adaptify.rating.admin.model.calculation.context.VariableType;
import GlobalVariable = kot.com.adaptify.rating.admin.model.calculation.util.GlobalVariable;
import PrimitiveDataType = kot.com.adaptify.rating.admin.model.type.PrimitiveDataType;

export interface ProductVersionTableModalProps {
  lobService: LobService;
  productService: ProductService;
  ratingService: RatingService;
  productVersionId: string;
  tableId: string | undefined;
  authToken: string;
  open: boolean;
  onClose: () => void;
}

export function ProductVersionTableModal(props: ProductVersionTableModalProps) {
  const [modifyCount, setModifyCount] = useState<number>(0);

  const [lobHierarchy, setLobHierarchy] = useState<
    LineOfBusinessHierarchy | undefined
  >(undefined);

  const [table, setTable] = useState<Table | undefined>();
  // TODO limit the size of the stack so it can't grow indefinitely
  const [undoStack, setUndoStack] = useState<Table[]>([]);
  const [redoStack, setRedoStack] = useState<Table[]>([]);

  const [globalVariables, setGlobalVariables] = useState<GlobalVariable[]>([]);

  const [closeWindowConfirmOpen, setCloseWindowConfirmOpen] = useState(false);

  useEffect(() => {
    const eff = async () => {
      if (!props.productVersionId) {
        setLobHierarchy(undefined);
        return;
      }
      const hierarchyPromise =
        props.productService.GetLobHierarchyForProductVersionId(
          props.productVersionId
        );
      const globalVariablePromise = props.ratingService.GetGlobalVariables(
        props.productVersionId
      );
      const [lobs, globals] = await Promise.all([
        hierarchyPromise,
        globalVariablePromise,
      ]);

      setLobHierarchy(lobs);
      setGlobalVariables(globals);
    };
    eff();
  }, [props.productVersionId]);

  useEffect(() => {
    const eff = async () => {
      if (!props.tableId) {
        handleNewTableFromServer(undefined);
        return;
      }
      const table = await props.productService.GetProductVersionTable(
        props.tableId
      );
      handleNewTableFromServer(table);
    };
    eff();
  }, [props.tableId, modifyCount]);

  const globalScopedVariables = useMemo(() => {
    return globalVariables.map(gv => {
      return {
        name: gv.name,
        displayName: gv.name,
        type: VariableType.Variable.name,
        boundDataType: {
          primitive: gv.primitiveDataType
            ? PrimitiveDataType.valueOf(gv.primitiveDataType)
            : undefined,
          lobItem: undefined,
          isMany: false,
        },
      } as ScopedVariable;
    });
  }, [globalVariables]);

  async function handleNewTableFromServer(table?: Table) {
    setTable(table);
    setUndoStack([]);
    setRedoStack([]);
  }

  function onModified() {
    setModifyCount(modifyCount + 1);
  }

  function isDirty() {
    return undoStack.length > 0;
  }

  function onTableLocalChanged(updated: Table) {
    if (table) {
      setUndoStack([...undoStack, table]);
    }
    setTable(updated);
  }

  async function saveTable(tableToSave: Table): Promise<Table> {
    if (!tableToSave) {
      return tableToSave;
    }
    const updated = await props.productService.UpdateProductVersionTable(
      props.productVersionId,
      tableToSave
    );

    // need to go back to the server after saving because the list might need updating
    // so force reload (esp if it's saving the same table)
    setModifyCount(modifyCount + 1);
    return updated;
  }

  function canUndo() {
    return undoStack.length > 0;
  }

  function canRedo() {
    return redoStack.length > 0;
  }

  function undo() {
    if (undoStack.length === 0) {
      return;
    }
    const last = undoStack[undoStack.length - 1];
    if (table) {
      setRedoStack([...redoStack, table]);
    }
    setTable(last);
    setUndoStack(undoStack.slice(0, undoStack.length - 1));
  }

  function redo() {
    if (redoStack.length === 0) {
      return;
    }
    const last = redoStack[redoStack.length - 1];
    if (table) {
      setUndoStack([...undoStack, table]);
    }
    setTable(last);
    setRedoStack(redoStack.slice(0, redoStack.length - 1));
  }

  const closeWindowConfirm = closeWindowConfirmOpen ? (
    <DirtyEditorConfirmationModal
      open={closeWindowConfirmOpen}
      skipDialog={!isDirty()}
      onConfirm={async () => {
        setCloseWindowConfirmOpen(false);
        props.onClose();
      }}
      onCancel={() => setCloseWindowConfirmOpen(false)}
    />
  ) : (
    <></>
  );

  useHotkeys('mod+s', e => {
    e.preventDefault();
    if (table) {
      saveTable(table);
    }
  });

  useHotkeys('mod+z', e => {
    e.preventDefault();
    if (canUndo()) {
      undo();
    }
  });

  // mac os
  useHotkeys('mod+shift+z', e => {
    e.preventDefault();
    if (canRedo()) {
      redo();
    }
  });

  //windows
  useHotkeys('ctrl+y', e => {
    e.preventDefault();
    if (canRedo()) {
      redo();
    }
  });

  return (
    <>
      <DraggableModal
        className="adaptify-modal"
        title="Table Details"
        destroyOnClose={true}
        closable={false}
        open={props.open}
        okButtonProps={{style: {display: 'none'}}}
        cancelButtonProps={{style: {display: 'none'}}}
        width={'clamp(300px, 70svw, 800px)'}
        footer={null}
        height={'600px'}
      >
        <Flex vertical gap={24}>
          <ProductVersionTableDetails
            productVersionId={props.productVersionId}
            lobHierarchy={lobHierarchy}
            productVersionTable={table}
            productService={props.productService}
            ratingService={props.ratingService}
            authToken={props.authToken}
            onChanged={onModified}
            modifyCount={modifyCount}
            updateProductVersionTable={onTableLocalChanged}
            saveTable={saveTable}
            isDirty={isDirty()}
            canUndo={canUndo()}
            canRedo={canRedo()}
            undo={undo}
            redo={redo}
            globalVariables={globalScopedVariables}
            hideCreateAndDeleteButtons={true}
            readOnly={false}
            tableHeight="400px"
          />
        </Flex>
        <Flex align="center" justify="end" style={{padding: '20px'}}>
          <Button
            onClick={() => setCloseWindowConfirmOpen(true)}
            {...ButtonSettings}
          >
            Close Window
          </Button>
        </Flex>
      </DraggableModal>
      {closeWindowConfirm}
    </>
  );
}
