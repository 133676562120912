import * as AntIcons from '@ant-design/icons';
import {Handle, NodeProps, NodeResizer, Position} from '@xyflow/react';
import {NodeBase} from '@xyflow/system';
import {Flex, Tooltip, Typography} from 'antd';
import React, {memo} from 'react';
import {BranchSvg, CalculationSvg, ConditionSvg, TableSvg} from './FlowImages';
import {FlowSourceNodeHandle} from './FlowSourceNodeHandle';

function FlowNodeType(
  icon: React.JSX.Element,
  showSourceHandle: boolean,
  showTargetHandle: boolean,
  maxOutgoingConnections: number,
  backgroundColor: string | undefined,
  node: NodeProps
) {
  const bg = backgroundColor ? backgroundColor : 'white';
  // if the node isn't draggable we are saying it's also not resizable
  const resizer = node.draggable ? (
    <NodeResizer
      minWidth={100}
      minHeight={30}
      isVisible={node.selected}
      handleStyle={{width: 12, height: 12}}
    />
  ) : (
    <></>
  );

  const errorIcon = node.data.validationError ? (
    <Tooltip title={node.data.validationError}>
      <AntIcons.WarningOutlined
        style={{
          color: 'red',
          position: 'fixed',
          top: 10,
          right: 10,
          textAlign: 'right',
          fontSize: '2em',
          verticalAlign: 'top',
        }}
      />
    </Tooltip>
  ) : (
    <></>
  );
  // nopan class name is required by react-flow to support double clicking nodes when they are in non-dragable
  // read only mode
  return (
    <Flex
      justify="center"
      align="center"
      className="nopan"
      style={{backgroundColor: bg, width: '100%', height: '100%'}}
    >
      {resizer}
      {showTargetHandle ? (
        <Handle
          type="target"
          position={Position.Left}
          style={{width: 12, height: 12}}
          isConnectable={node.isConnectable}
        />
      ) : (
        <></>
      )}
      {icon}

      <div style={{padding: 10, textAlign: 'center'}}>
        <Typography.Text>{node.data.label as string}</Typography.Text>
      </div>
      {showSourceHandle ? (
        <FlowSourceNodeHandle
          maxConnections={maxOutgoingConnections}
          readOnly={!node.isConnectable}
        />
      ) : (
        <></>
      )}
      {errorIcon}
    </Flex>
  );
}

export const StartNodeType = memo((node: NodeBase) =>
  FlowNodeType(<></>, true, false, 1, '#ffff00', node)
);
export const EndNodeType = memo((node: NodeBase) =>
  FlowNodeType(<></>, false, true, 0, '#e99999', node)
);
export const CalculationNodeType = memo((node: NodeBase) =>
  FlowNodeType(<CalculationSvg />, true, true, 1, undefined, node)
);
export const TableNodeType = memo((node: NodeBase) =>
  FlowNodeType(<TableSvg />, true, true, 1, undefined, node)
);
export const BranchNodeType = memo((node: NodeBase) =>
  FlowNodeType(<BranchSvg />, true, true, 99, undefined, node)
);
export const ConditionNodeType = memo((node: NodeBase) =>
  FlowNodeType(<ConditionSvg />, true, true, 1, undefined, node)
);
