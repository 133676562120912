package com.adaptify.rating.admin.model.calculation.validation

import com.adaptify.rating.admin.model.calculation.BindingType
import com.adaptify.rating.admin.model.calculation.Calculation
import com.adaptify.rating.admin.model.calculation.CalculationFunction
import com.adaptify.rating.admin.model.calculation.context.CalculationEvalContext
import com.adaptify.rating.admin.model.calculation.context.ScopedVariable
import com.adaptify.rating.admin.model.calculation.env.MetadataProvider
import com.adaptify.rating.admin.model.calculation.util.CalculationFunctionVisitor
import com.adaptify.rating.admin.model.calculation.util.FunctionAcceptor
import com.adaptify.rating.admin.model.calculation.util.FunctionVisitor
import com.adaptify.rating.admin.model.calculation.util.GlobalVariable
import com.adaptify.rating.admin.model.flow.Flow
import com.adaptify.rating.admin.model.flow.Node
import com.adaptify.rating.admin.model.flow.NodeType
import com.adaptify.rating.admin.model.lob.LineOfBusinessHierarchy

object NodeValidator {

  // for first pass, just function validations, will change the signature to reflect the expanded scope
// this validation could presumably run on the client or server, we are going to start with the client for
// now as it should have the optimal response time, but we have the option to move expensive validations to the server
// if needed
  suspend fun validateNode(flow: Flow,
                           node: Node,
                           metadataProvider: MetadataProvider,
                           lineOfBusinessHierarchy: LineOfBusinessHierarchy,
                           globalVariables : Array<ScopedVariable>): NodeValidationResult {

    // TODO generic validations on nodes and edges
    val nodeType = NodeType.valueOf(node.nodeType);
    if (nodeType == NodeType.Calculation ||
      nodeType == NodeType.Branch) {
      val calculation = CalculationNodeAdapter(
        node.id, 0, flow.productVersionId,
        flow.lineOfBusinessItemId, node.functions
      )
      val ctx = CalculationEvalContext.Create(
        calculation, lineOfBusinessHierarchy,
        metadataProvider, globalVariables
      )
      val calculationMessages =
        CalculationValidator.validateCalculation(calculation, metadataProvider, lineOfBusinessHierarchy, ctx);
      val messages = mutableListOf<String>()

      if (nodeType == NodeType.Branch) {
        // check that the edges match the transitions
        // TODO find all edges and make sure they map to a defined transition
        val definedTransitions = getBranchTransitions(node);
        for (edge in flow.edges) {
          if (edge.sourceNode == node.id && !definedTransitions.contains(edge.sourceTransitionName)) {
            messages.add("Transition ${edge.sourceTransitionName} does not exist")
          }
        }
      }

      return NodeValidationResult(node.id, messages.toTypedArray(), calculationMessages);
    }
    if (nodeType == NodeType.Table) {
      if (node.tableId == null) {
        return NodeValidationResult(node.id, arrayOf("Table id is null"), emptyArray())
      }

      // check that the table is present
      val tableDef = metadataProvider.getTableDefinitionById(node.tableId!!).await();
      if (tableDef == null) {
        return NodeValidationResult(node.id, arrayOf("Referenced table not found"), emptyArray())
      }
    }
    return NodeValidationResult(node.id, emptyArray(), emptyArray())
  }

  private fun getBranchTransitions(node: Node) : Set<String> {
    val definedTransitions = mutableSetOf<String>()
    FunctionVisitor.visit(node.functions, object : FunctionAcceptor {
      override fun accept(function: CalculationFunction) {
        for (binding in function.bindings) {
          if (binding.bindingType == BindingType.TransitionDefinition.name &&
            binding.transitionDefinition != null
          ) {
            definedTransitions.add(binding.transitionDefinition!!);
          }
        }
      }
    })
    return definedTransitions;
  }

  class CalculationNodeAdapter(
    override var id: String,
    override var version: Int,
    override var productVersionId: String,
    override var lineOfBusinessItemId: String,
    override var functions: Array<CalculationFunction>
  ) : Calculation
}
