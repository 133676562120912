package com.adaptify.rating.admin.model.flow.descriptor

import kotlin.js.JsExport

@JsExport
enum class EmbeddedFunctionType {
  None,
  List,
  Predicate,
  Table
}
