import React from 'react';
import {useParams} from 'react-router-dom';
import {NavigationAware} from '../../NavigationAware';
import {RatingService} from '../../rating/service/RatingService';
import {AuthManager} from '../../user/util/AuthManager';
import {ValueListService} from '../../valuelist/service/ValueListService';
import {ProductService} from '../service/ProductService';
import {ProductVersionDetailControl} from './ProductVersionDetailControl';

export interface ProductVersionDetailPageProps extends NavigationAware {
  productService: ProductService;
  valueListService: ValueListService;
  ratingService: RatingService;
  authManager: AuthManager;
}

type ProductVersionDetailPageParams = {
  productId: string;
};

export function ProductVersionDetailPage(props: ProductVersionDetailPageProps) {
  const {productId} = useParams<ProductVersionDetailPageParams>();

  return (
    <ProductVersionDetailControl
      productService={props.productService}
      valueListService={props.valueListService}
      ratingService={props.ratingService}
      authManager={props.authManager}
      productId={productId}
    />
  );
}
