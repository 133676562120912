import * as kot from 'adaptify-multi-module-rating-admin-model';
import {v4 as uuidv4} from 'uuid';
import Flow = kot.com.adaptify.rating.admin.model.flow.Flow;
import Edge = kot.com.adaptify.rating.admin.model.flow.Edge;
import Node = kot.com.adaptify.rating.admin.model.flow.Node;
import NodeType = kot.com.adaptify.rating.admin.model.flow.NodeType;

export interface NodesAndEdges {
  nodes: Node[];
  edges: Edge[];
}

export function CreateDefaultNodesAndEdges(): NodesAndEdges {
  const nodes = [
    {
      id: uuidv4(),
      name: 'START',
      x: 100,
      y: 100,
      width: 200,
      height: 100,
      nodeType: NodeType.Start.name,
      functions: [],
    },
    {
      id: uuidv4(),
      name: 'END',
      x: 1200,
      y: 100,
      width: 200,
      height: 100,
      nodeType: NodeType.End.name,
      functions: [],
    },
  ];
  const edges = [
    {
      id: uuidv4(),
      sourceNode: nodes[0].id,
      targetNode: nodes[1].id,
      sequenceNumber: 0,
    },
  ];
  return {
    nodes: nodes,
    edges: edges,
  };
}
