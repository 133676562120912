import {ClientSideRowModelModule} from '@ag-grid-community/client-side-row-model';
import {
  ColDef,
  ColumnResizedEvent,
  GetRowIdParams,
  ModuleRegistry,
} from '@ag-grid-community/core';
import {AgGridReact} from '@ag-grid-community/react';
import '@ag-grid-community/styles/ag-grid.css'; // Core CSS
import '@ag-grid-community/styles/ag-theme-quartz.css';
import React, {useCallback, useEffect, useState} from 'react';
import {ProductService} from '../../product/service/ProductService';

ModuleRegistry.registerModules([ClientSideRowModelModule]);

interface ProductVersionTableGridProps {
  productService: ProductService;
  productVersionTableId: string;
}

interface RowData {
  id: string;
  data: String[];
}

// this is a read only grid just for analysis, it was simpler to create a new grid
// than to try to strip out all the edit functionality from the grid that's part of the table editor
export function ProductVersionTableGrid(props: ProductVersionTableGridProps) {
  const [productVersionTable, setProductVersionTable] = useState<
    Table | undefined
  >(props.productVersionTableId);
  const [colDefs, setColDefs] = useState([] as ColDef<RowData>[]);
  const [colWidths, setColWidths] = useState<Map<string, number>>(new Map());
  const [rows, setRows] = useState([] as RowData[]);

  useEffect(() => {
    const eff = async () => {
      if (!props.productVersionTableId) {
        setProductVersionTable(undefined);
        return;
      }
      const table = await props.productService.GetProductVersionTable(
        props.productVersionTableId
      );
      setProductVersionTable(table);
    };
    eff();
  }, [props.productVersionTableId]);

  useEffect(() => {
    // reload columns and rows when table changes
    setColDefs(getColumns());
    setRows(getTableRows());
  }, [productVersionTable]);

  function getColumns(): ColDef<RowData>[] {
    if (
      !productVersionTable ||
      !productVersionTable?.columns ||
      productVersionTable.columns.length === 0
    ) {
      return [];
    }

    const gridColumns: ColDef<RowData>[] = productVersionTable.columns.map(
      (col, index) => {
        const id = generateRowIdFromIndex(index);
        return {
          colId: id,
          headerName: col.name,
          width: colWidths[id] || 150,
          sortable: false,
          valueGetter: row => {
            return row.data?.data[index] || '';
          },
        } as ColDef<RowData>;
      }
    );
    return gridColumns;
  }

  function generateRowIdFromIndex(index: number) {
    return productVersionTable?.id + '_' + index;
  }

  function getTableRows(): RowData[] {
    if (
      !productVersionTable ||
      !productVersionTable.columns ||
      productVersionTable.columns.length === 0 ||
      !productVersionTable.rows
    ) {
      return [];
    }

    const tableRows = productVersionTable.rows.map((row, index) => {
      return {
        id: generateRowIdFromIndex(index),
        data: row.map(data => data || ''),
      };
    });
    // add a dummy row for editing convenience

    return tableRows;
  }

  const getRowId = useCallback(
    (params: GetRowIdParams<RowData>) => params.data.id,
    []
  );

  function onColumnResized(event: ColumnResizedEvent) {
    const newMap = structuredClone(colWidths);
    if (event.finished && event.column?.getColId()) {
      newMap[event.column.getColId()] = event.column.getActualWidth();
      setColWidths(newMap);
    }
  }

  return (
    <div className={'ag-theme-quartz'} style={{width: '100%', height: '100%'}}>
      <AgGridReact
        columnDefs={colDefs}
        rowData={rows}
        getRowId={getRowId}
        rowSelection="single"
        onColumnResized={onColumnResized}
      />
    </div>
  );
}
