package com.adaptify.rating.admin.model.calculation.descriptor.lib

import com.adaptify.rating.admin.model.calculation.BindingType
import com.adaptify.rating.admin.model.calculation.CalculationFunction
import com.adaptify.rating.admin.model.calculation.CalculationFunctionUtil
import com.adaptify.rating.admin.model.calculation.binding.BindingUtil
import com.adaptify.rating.admin.model.calculation.descriptor.BindingDescriptor
import com.adaptify.rating.admin.model.calculation.descriptor.BindingInputOutputType
import com.adaptify.rating.admin.model.calculation.descriptor.FunctionDescriptor
import com.adaptify.rating.admin.model.calculation.descriptor.FunctionType
import com.adaptify.rating.admin.model.calculation.util.TransitionUtil
import com.adaptify.rating.admin.model.generateUUID

// this is the old style if descriptor that has blocks under the if and else statement
// the new if statement will only be used flow nodes and has different options
object IfBranchDescriptor : FunctionDescriptor("IF", "If", FunctionType.If, "Logic") {

  val predicateDescriptor = object : BindingDescriptor(
    "predicate", "WHEN",
    BindingInputOutputType.Input, arrayOf(BindingType.Predicate),
    null
  ) {
    override fun getAllowedFunctionTypes(): Array<FunctionType> {
      return arrayOf(FunctionType.Predicate);
    }

    override fun shouldRenderBlockHeader(): Boolean {
      return false
    }
  }
  val thenDescriptor = object : BindingDescriptor(
    "then", "THEN", BindingInputOutputType.Input,
    arrayOf(BindingType.Block),
    null

  ) {
    override fun getAllowedFunctionTypes(): Array<FunctionType> {
      return arrayOf(FunctionType.If, FunctionType.Transition);
    }

    override fun isSingleFunctionBlock(): Boolean {
      return true
    }
  }
  val elseDescriptor = object : BindingDescriptor(
    "else", "ELSE", BindingInputOutputType.Input, arrayOf(BindingType.Block),
    null
  ) {
    override fun getAllowedFunctionTypes(): Array<FunctionType> {
      return arrayOf(FunctionType.If, FunctionType.Transition);
    }

    override fun isSingleFunctionBlock(): Boolean  = true

    override fun isOptional(): Boolean = true
  }

  override fun getBindingDescriptors(
  ): Array<BindingDescriptor> = arrayOf(predicateDescriptor, thenDescriptor, elseDescriptor)

  override fun getDisplayTemplate(
  ): String = "IF" // blocks have special non-configurable rendering
}
