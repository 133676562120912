import {DataGrid, GridColDef, GridRowSelectionModel} from '@mui/x-data-grid';
import {Button, Col, Flex, Modal, Popconfirm, Row} from 'antd';
import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {ButtonSettings, DataGridSettings} from '../../common/control/Common';
import {NewTenantAwareEntity} from '../../common/model/Common';
import {IdAndName} from '../../product/model/Product';
import {ProductService} from '../../product/service/ProductService';
import {StudyTableComparison} from '../model/ComparativeAnalysis';
import {AnalysisService} from '../service/AnalysisService';
import {CreateTableComparisonForm} from './CreateTableComparisonForm';

export interface StudyTableComparisonListProps {
  analysisService: AnalysisService;
  productService: ProductService;
  studyId: string;
  modifyCount: number;
  setModifyCount: (count: number) => void;
  selectedTableComparisonId: string | undefined;
  setSelectedTableComparisonId: (id: string | undefined) => void;
  launchAddTableComparisonDialog: boolean;
}

export function StudyTableComparisonList(props: StudyTableComparisonListProps) {
  const [tableComparisons, setTableComparisons] = useState<
    StudyTableComparison[]
  >([]);

  const [addTableComparisonOpen, setAddTableComparisonOpen] = useState<boolean>(
    props.launchAddTableComparisonDialog
  );

  useEffect(() => {
    const eff = async () => {
      if (!props.studyId || props.studyId === '') {
        setTableComparisons([]);
        return;
      }
      const tableComparisons =
        await props.analysisService.GetStudyTableComparisonsByStudyId(
          props.studyId
        );
      setTableComparisons(tableComparisons);
      if (!props.selectedTableComparisonId && tableComparisons.length > 0) {
        props.setSelectedTableComparisonId(tableComparisons[0].id);
      }
    };
    eff();
  }, [props.modifyCount]);

  function createNewTableComparison(): StudyTableComparison {
    return {
      ...NewTenantAwareEntity(),
      studyId: '',
      comparisonKey: '',
      name: '',
      comments: '',
    };
  }

  function onStudySelectionChanged(rowSelectionModel: GridRowSelectionModel) {
    if (rowSelectionModel.length > 0) {
      props.setSelectedTableComparisonId(rowSelectionModel[0] as string);
    } else {
      props.setSelectedTableComparisonId(undefined);
    }
  }

  function onDelete() {
    if (props.selectedTableComparisonId) {
      props.analysisService.DeleteStudyTableComparison(
        props.selectedTableComparisonId
      );
      props.setSelectedTableComparisonId(undefined);
      props.setModifyCount(props.modifyCount + 1);
    }
  }

  function onCancelAddTableComparison() {
    setAddTableComparisonOpen(false);
  }

  function openTableComparisonPopup() {
    setAddTableComparisonOpen(true);
  }

  const colDefs: GridColDef<IdAndName>[] = [
    {
      field: 'name',
      headerName: 'Table Name',
      flex: 200,
      renderCell: params => {
        return (
          <Link to={`/analysis/studytablecomparison/${params.row.id}/detail`}>
            {params.row.name}
          </Link>
        );
      },
    },
    {
      field: 'comments',
      headerName: 'Table Notes',
      flex: 200,
    },
  ];

  const editButtons = (
    <Flex justify="flex-end" align="flex-start">
      <Popconfirm
        title="Confirm deletion"
        description="Are you sure to want to delete?"
        onConfirm={onDelete}
        disabled={!props.selectedTableComparisonId}
        okText="Delete"
        cancelText="Cancel"
      >
        <Button {...ButtonSettings} disabled={!props.selectedTableComparisonId}>
          Delete
        </Button>
      </Popconfirm>
      <Button {...ButtonSettings} onClick={openTableComparisonPopup}>
        Add New Table Comparison
      </Button>
    </Flex>
  );

  const addTableComparisonModal = addTableComparisonOpen ? (
    <Modal
      className="adaptify-modal"
      title={'Add Table Comparison'}
      open={true}
      closable={false}
      okButtonProps={{style: {display: 'none'}}}
      cancelButtonProps={{style: {display: 'none'}}}
      width={'clamp(300px, 70svw, 1200px)'}
      footer={null}
    >
      <CreateTableComparisonForm
        analysisService={props.analysisService}
        productService={props.productService}
        studyId={props.studyId ?? ''}
        onCancel={onCancelAddTableComparison}
      />
    </Modal>
  ) : (
    <> </>
  );

  return (
    <>
      <Flex vertical>
        <Row>
          <Col span={18}>
            <div className="page-title">Table Comparisons</div>
          </Col>
          <Col span={6}>{editButtons}</Col>
          <Col span={24}>
            <DataGrid
              {...DataGridSettings}
              getRowId={r => r.id}
              columns={colDefs}
              filterMode="client"
              rowSelectionModel={
                props.selectedTableComparisonId
                  ? [props.selectedTableComparisonId]
                  : []
              }
              onRowSelectionModelChange={onStudySelectionChanged}
              rows={tableComparisons}
              sx={{
                '&.MuiDataGrid-root': {
                  borderRadius: '8px',
                  overflow: 'hidden',
                  borderColor: '#CCCCCC',
                },
              }}
            />
          </Col>
        </Row>
      </Flex>
      {addTableComparisonModal}
    </>
  );
}
