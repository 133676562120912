import React, {useEffect, useState} from 'react';
import {ButtonSettings} from '../../common/control/Common';
import {ProductService} from '../../product/service/ProductService';

import {Button, Col, Flex, Row} from 'antd';
import {ProductVersionTableGrid} from '../control/ProductVersionTableGrid';
import {TableComparisonSummaryForm} from '../control/TableComparisonSummaryForm';
import {TableDocumentMetadataDetail} from '../control/TableDocumentMetadataDetail';
import {
  StudyTableComparison,
  StudyTableComparisonTableSummaryInfo,
} from '../model/ComparativeAnalysis';
import {AnalysisService} from '../service/AnalysisService';
export interface TableComparisonDetailControlProps {
  analysisService: AnalysisService;
  productService: ProductService;
  studyTableComparisonId: string;
}

export function TableComparisonDetailControl(
  props: TableComparisonDetailControlProps
) {
  const [modifyCount, setModifyCount] = useState<number>(0);
  const [tableComparison, setTableComparison] = useState<
    StudyTableComparison | undefined
  >(undefined);
  const [tableComparisonSummaries, setTableComparisonSummaries] = useState<
    StudyTableComparisonTableSummaryInfo[]
  >([]);
  const [
    selectedTableComparisonSummaryId,
    setSelectedTableComparisonSummaryId,
  ] = useState<string | undefined>(undefined);

  useEffect(() => {
    const eff = async () => {
      if (props.studyTableComparisonId === '') {
        setTableComparisonSummaries([]);
        return;
      }

      const summaryPromise =
        await props.analysisService.GetStudyTableComparisonTableSummariesByTableComparisonId(
          props.studyTableComparisonId
        );
      // we need to figure out the study id, and then get the summary
      const tableComparisonPromise =
        await props.analysisService.GetStudyTableComparison(
          props.studyTableComparisonId
        );

      const [summary, loadedTableComparison] = await Promise.all([
        summaryPromise,
        tableComparisonPromise,
      ]);
      setTableComparisonSummaries(summary);
      setTableComparison(loadedTableComparison);
      if (summary && summary.length > 0) {
        setSelectedTableComparisonSummaryId(summary[0].tableComparisonTableId);
      }
    };
    eff();
  }, [props.studyTableComparisonId]);

  async function downloadTableAsXslx() {
    // to ensure JWT token is passed, make an API call to get the spreadsheet data
    // then hook the local data up to a download link
    if (!props.studyTableComparisonId) {
      return;
    }

    const contents = await props.analysisService.ExportStudyTableComparison(
      props.studyTableComparisonId
    );
    const objectUrl = window.URL.createObjectURL(contents);
    const anchor = document.createElement('a');
    document.body.appendChild(anchor);

    anchor.href = objectUrl;
    anchor.download = (tableComparison?.name ?? 'Comparison') + '.xlsx';
    anchor.click();

    window.URL.revokeObjectURL(objectUrl);
    anchor.remove();
  }

  function getSelectedTableComparisonSummary():
    | StudyTableComparisonTableSummaryInfo
    | undefined {
    if (!tableComparisonSummaries || !selectedTableComparisonSummaryId) {
      return undefined;
    }
    return tableComparisonSummaries.find(
      summary =>
        summary.tableComparisonTableId === selectedTableComparisonSummaryId
    );
  }

  const extraButtons: React.JSX.Element = (
    <>
      <Button {...ButtonSettings} onClick={downloadTableAsXslx}>
        Export
      </Button>
    </>
  );

  return (
    <Flex vertical gap={24}>
      <TableComparisonSummaryForm
        tableComparison={tableComparison}
        tableComparisonSummaries={tableComparisonSummaries}
        selectedTableComparisonTableId={selectedTableComparisonSummaryId}
        setSelectedTableComparisonTableId={setSelectedTableComparisonSummaryId}
      />
      <Row>
        <Col span={18}>
          <div className="page-title">Table Comparison Details</div>
        </Col>
        <Col span={6} style={{textAlign: 'right'}}>
          {extraButtons}
        </Col>
      </Row>
      <div
        style={{width: '100%', height: '600px'}}
        className={'ag-theme-quartz'}
      >
        <ProductVersionTableGrid
          productService={props.productService}
          productVersionTableId={
            getSelectedTableComparisonSummary()?.productVersionTableId || ''
          }
        />
      </div>
      <TableDocumentMetadataDetail
        productService={props.productService}
        productVersionTableId={
          getSelectedTableComparisonSummary()?.productVersionTableId
        }
      />
    </Flex>
  );
}
