import {
  DataGrid,
  GridCallbackDetails,
  GridColDef,
  GridRowSelectionModel,
} from '@mui/x-data-grid';
import {Button, Col, Flex, Row, Space} from 'antd';
import React, {useEffect, useState} from 'react';
import {ButtonSettings, DataGridSettings} from '../../common/control/Common';
import {DraggableModal} from '../../common/control/DraggableModal';
import {DateIsoStringToDisplayString} from '../../common/model/CommonUtils';
import {ValueListService} from '../../valuelist/service/ValueListService';
import {TenantModule} from '../model/Tenant';
import {TenantService} from '../service/TenantService';
import {AddTenantModuleForm} from './AddTenantModuleForm';
import {EditTenantModuleForm} from './EditTenantModuleForm';

export interface TenantModuleListProps {
  tenantService: TenantService;
  valueListService: ValueListService;
  readOnly?: boolean;
  tenantId: string | undefined;
  modifyCount: number;
  onSave: (tenantModule: TenantModule) => void;
}

export interface EditModuleTenantState {
  open: boolean;
  moduleTenantId?: string;
}

export function TenantModuleList(props: TenantModuleListProps) {
  const [editPopupState, setEditPopupState] = useState<EditModuleTenantState>({
    open: false,
  });
  const [modules, setModules] = useState<TenantModule[]>([]);
  const [selectedModuleId, setSelectedModuleId] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    if (!props.tenantId) {
      setModules([]);
      return;
    }
    const eff = async () => {
      const loaded = await props.tenantService.GetTenantModulesByTenantId(
        props.tenantId ?? ''
      );
      setModules(loaded);
      setSelectedModuleId(loaded.length > 0 ? loaded[0].id : undefined);
    };
    eff();
  }, [props.tenantId, props.modifyCount]);

  const colDefs: GridColDef<TenantModule>[] = [
    {
      field: 'moduleName',
      headerName: 'Module',
      flex: 300,
    },
    {
      field: 'endDate',
      headerName: 'End Date',
      flex: 300,
      valueGetter: (value, row) => {
        if (!value) {
          return '';
        }
        return DateIsoStringToDisplayString(value);
      },
    },
  ];

  function onAddModule() {
    setEditPopupState({open: true});
  }

  function onEditModule() {
    setEditPopupState({open: true, moduleTenantId: selectedModuleId});
  }

  function onSaveTenantModule(tenantModule: TenantModule) {
    setEditPopupState({open: false});
    props.onSave(tenantModule);
  }

  function onCancelTenantModule() {
    setEditPopupState({open: false});
  }

  function onTableSelectionChanged(
    rowSelectionModel: GridRowSelectionModel,
    details: GridCallbackDetails
  ) {
    if (rowSelectionModel.length > 0) {
      setSelectedModuleId(rowSelectionModel[0] as string);
    } else {
      setSelectedModuleId(undefined);
    }
  }

  const modifyButtons = props.readOnly ? (
    <></>
  ) : (
    <Flex justify="end">
      <Button
        {...ButtonSettings}
        onClick={onEditModule}
        disabled={!selectedModuleId}
      >
        Modify
      </Button>
      <Button {...ButtonSettings} onClick={onAddModule}>
        Add Module
      </Button>
    </Flex>
  );

  const addTenantModuleModal =
    editPopupState.open && !editPopupState.moduleTenantId ? (
      <DraggableModal
        className="adaptify-modal"
        title="Add Module"
        open={editPopupState.open}
        closable={false}
        okButtonProps={{style: {display: 'none'}}}
        cancelButtonProps={{style: {display: 'none'}}}
      >
        <AddTenantModuleForm
          tenantService={props.tenantService}
          valueListService={props.valueListService}
          tenantId={props.tenantId}
          onSaved={onSaveTenantModule}
          onCancel={onCancelTenantModule}
        />
      </DraggableModal>
    ) : (
      <></>
    );

  const editTenantModuleModal =
    editPopupState.open && editPopupState.moduleTenantId ? (
      <DraggableModal
        className="adaptify-modal"
        title="Edit Module"
        open={editPopupState.open}
        closable={false}
        okButtonProps={{style: {display: 'none'}}}
        cancelButtonProps={{style: {display: 'none'}}}
      >
        <EditTenantModuleForm
          tenantService={props.tenantService}
          valueListService={props.valueListService}
          tenantModuleId={editPopupState.moduleTenantId}
          onSaved={onSaveTenantModule}
          onCancel={onCancelTenantModule}
        />
      </DraggableModal>
    ) : (
      <></>
    );

  return (
    <>
      <Flex vertical>
        <Row>
          <Col span={18}></Col>
          <Col span={6}>{modifyButtons}</Col>
        </Row>
        <Space direction="vertical" style={{width: '100%'}}>
          <div style={{height: '100%', width: '100%'}}>
            <DataGrid
              {...DataGridSettings}
              columns={colDefs}
              rows={modules}
              rowSelectionModel={selectedModuleId ? [selectedModuleId] : []}
              onRowSelectionModelChange={onTableSelectionChanged}
            />
          </div>
        </Space>
      </Flex>
      {addTenantModuleModal}
      {editTenantModuleModal}
    </>
  );
}
