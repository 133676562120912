import {Button, Flex, Form, Input} from 'antd';
import {useForm} from 'antd/es/form/Form';
import React, {useEffect, useMemo, useState} from 'react';
import {ButtonSettings} from '../../../common/control/Common';

import * as kot from 'adaptify-multi-module-rating-admin-model';
import {DraggableModal} from '../../../common/control/DraggableModal';
import {IdAndName} from '../../../product/model/Product';
import {ProductService} from '../../../product/service/ProductService';
import {CalculationFunctionList} from '../calculation/CalculationFunctionList';
import Flow = kot.com.adaptify.rating.admin.model.flow.Flow;
import Node = kot.com.adaptify.rating.admin.model.flow.Node;
import MetadataProvider = kot.com.adaptify.rating.admin.model.util.MetadataProvider;
import LineOfBusinessHierarchy = kot.com.adaptify.rating.admin.model.lob.LineOfBusinessHierarchy;
import Calculation = kot.com.adaptify.rating.admin.model.calculation.Calculation;
import GlobalVariable = kot.com.adaptify.rating.admin.model.calculation.util.GlobalVariable;
import GlobalVariarableUtil = kot.com.adaptify.rating.admin.model.calculation.util.GlobalVariableUtil;
import ScopedVariable = kot.com.adaptify.rating.admin.model.calculation.context.ScopedVariable;
import VariableType = kot.com.adaptify.rating.admin.model.calculation.context.VariableType;
import PrimitiveDataType = kot.com.adaptify.rating.admin.model.type.PrimitiveDataType;

export interface CalculationNodeModalProps {
  productService: ProductService;
  allTableNames: IdAndName[];
  flow: Flow;
  node: Node;
  lobHierarchy: LineOfBusinessHierarchy;
  onSave: (node: Node) => void;
  onCancel: () => void;
  modifyCount: number;
  metadataProvider: MetadataProvider;
  globalVariables: GlobalVariable[];
  readOnly?: boolean;
}

export interface CalculationNodeModalValues {
  name: string;
}

export function CalculationNodeModal(props: CalculationNodeModalProps) {
  // we need to copy the node to support cancel on the popup, we will commit the changes in the calculation node to the copy
  // when the calculation is changed, then commit back to the flow when the ok button is pressed
  const [node, setNode] = useState<Node | undefined>(undefined);
  const [form] = useForm<CalculationNodeModalValues>();
  const [calculation, setCalculation] = useState<Calculation | undefined>(
    undefined
  );
  const [nodeChanged, setNodeChanged] = useState(false);

  useEffect(() => {
    // create a synthetic calculation from the node that will drive the calculation function list
    const calc: Calculation = {
      id: props.node.id,
      version: 0,
      productVersionId: props.flow.productVersionId,
      lineOfBusinessItemId: props.flow.lineOfBusinessItemId,
      functions: node?.functions || [],
    };
    setCalculation(calc);
  }, [node]);

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue({
      name: props.node.name ?? undefined,
    });
  }, [props.modifyCount, props.node]);

  useEffect(() => {
    // copy the source node when it changes
    const newNode = structuredClone(props.node);
    setNode(newNode);
    setNodeChanged(false);
  }, [props.node]);

  const globalScopedVariables = useMemo(() => {
    // construct global variables by getting the server variables,
    // replacing the variables for the flow with the local ones to handle unsaved flow changes
    // and removing any from this node itself so that we use the function editor to manage them
    const globalVariablesForOtherFlows = props.globalVariables.filter(
      v => v.flowId !== props.flow.id
    );
    const flowsForOtherNodes = GlobalVariarableUtil.getGlobalVariablesForFlow(
      props.flow
    ).filter(v => v.nodeId !== node?.id);

    return [...globalVariablesForOtherFlows, ...flowsForOtherNodes].map(v => {
      return {
        name: v.name,
        displayName: v.name,
        type: VariableType.Variable.name,
        boundDataType: {
          primitive: v.primitiveDataType
            ? PrimitiveDataType.valueOf(v.primitiveDataType)
            : undefined,
          lobItem: undefined,
          isMany: false,
        },
      } as ScopedVariable;
    });
  }, [props.globalVariables, props.flow, node]);

  function onFinish(values: CalculationNodeModalValues) {
    if (!node) {
      return;
    }

    if (node.name === values.name && !nodeChanged) {
      props.onCancel();
      return;
    }

    const newNode = structuredClone(node);
    newNode.name = values.name;
    props.onSave(newNode);
  }

  function onCalculationChanged(calculation: Calculation) {
    const newNode = structuredClone(props.node);
    newNode.functions = calculation.functions;
    setNode(newNode);
    setNodeChanged(true);
  }

  const buttons = props.readOnly ? (
    <Flex justify="end" style={{width: '100%'}} className="gap-2">
      <Button {...ButtonSettings} htmlType="button" onClick={props.onCancel}>
        Close
      </Button>
    </Flex>
  ) : (
    <Flex justify="end" style={{width: '100%'}} className="gap-2">
      <Button {...ButtonSettings} htmlType="button" onClick={props.onCancel}>
        Cancel
      </Button>
      <Button
        {...ButtonSettings}
        ghost={false}
        type="default"
        onClick={form.submit}
      >
        OK
      </Button>
    </Flex>
  );

  const readOnlyInputProps = props.readOnly
    ? {
        readOnly: true,
        className: 'adaptify-read-only-field',
      }
    : {};
  return (
    <DraggableModal
      className="adaptify-modal"
      title="Calculation"
      closable={false}
      open={true}
      okButtonProps={{style: {display: 'none'}}}
      cancelButtonProps={{style: {display: 'none'}}}
      width={'clamp(300px, 70svw, 800px)'}
      footer={null}
      height={'95%'}
    >
      <Form
        name="nodeNameForm"
        labelCol={{span: 24}}
        wrapperCol={{span: 24}}
        layout="vertical"
        size="large"
        style={{width: '100%', height: '100%'}}
        autoComplete="off"
        onFinish={onFinish}
        form={form}
      >
        <Form.Item name="name" label="Name" rules={[{required: true}]}>
          <Input {...readOnlyInputProps} />
        </Form.Item>

        <Flex vertical>
          <div
            style={{minHeight: '400px', maxHeight: '600px', overflow: 'auto'}}
          >
            <CalculationFunctionList
              productService={props.productService}
              lobHierarchy={props.lobHierarchy}
              calculation={calculation}
              allTableNames={props.allTableNames}
              onChanged={onCalculationChanged}
              metadataProvider={props.metadataProvider}
              singleFunctionMode={false}
              globalVariables={globalScopedVariables}
              currentLobItemId={props.flow.lineOfBusinessItemId}
              readOnly={props.readOnly}
            />
          </div>
        </Flex>
        {buttons}
      </Form>
    </DraggableModal>
  );
}
