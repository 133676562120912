package com.adaptify.rating.admin.model.table

import kotlin.js.JsExport

@JsExport
enum class ColumnEvaluationType(val value: String) {
  Equals("Equals"),
  GreaterThan("Greater Than"),
  GreaterThanOrEqualTo("Greater Than or Equal To"),
  LessThan("Less Than"),
  LessThanOrEqualTo("Less Than or Equal To");

  companion object {
    fun fromValue(value: String): ColumnEvaluationType {
      return ColumnEvaluationType.values().first { it.value == value }
    }
  }
}

