import React from 'react';
import {useParams, useSearchParams} from 'react-router-dom';
import {NavigationAware} from '../../NavigationAware';
import {LobService} from '../../lob/service/LobService';
import {ProductService} from '../../product/service/ProductService';
import {AuthManager} from '../../user/util/AuthManager';
import {RatingService} from '../service/RatingService';
import {ProductVersionLobCoverageFieldControl} from './ProductVersionLobCoverageFieldControl';

export interface ProductVersionLobCoverageFieldPageProps
  extends NavigationAware {
  lobService: LobService;
  productService: ProductService;
  ratingService: RatingService;
  authManager: AuthManager;
}

export function ProductVersionLobCoverageFieldPage(
  props: ProductVersionLobCoverageFieldPageProps
) {
  const {productVersionId} = useParams<string>();
  if (typeof productVersionId !== 'string') {
    return <></>;
  }

  const [searchParams, setSearchParams] = useSearchParams();
  const initialLobItemId = searchParams.get('coverageId');

  function onSetLobItemId(coverageId: string | undefined) {
    if (coverageId) {
      setSearchParams({coverageId: coverageId}, {replace: true});
    }
  }

  return (
    <ProductVersionLobCoverageFieldControl
      lobService={props.lobService}
      productService={props.productService}
      productVersionId={productVersionId as string}
      initialLobItemId={initialLobItemId ?? undefined}
      authManager={props.authManager}
      onSetLobItemId={onSetLobItemId}
    />
  );
}
