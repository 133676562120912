import {Button, Flex, Form, Select} from 'antd';
import {useForm} from 'antd/es/form/Form';
import React, {useEffect} from 'react';
import {ButtonSettings} from '../../../common/control/Common';

import * as kot from 'adaptify-multi-module-rating-admin-model';
import {DraggableModal} from '../../../common/control/DraggableModal';
import {LobService} from '../../../lob/service/LobService';
import {IdAndName} from '../../../product/model/Product';
import {ProductService} from '../../../product/service/ProductService';
import {ProductVersionTableModal} from '../../page/ProductVersionTableModal';
import {RatingService} from '../../service/RatingService';
import Node = kot.com.adaptify.rating.admin.model.flow.Node;
import TableDefinition = kot.com.adaptify.rating.admin.model.table.TableDefinition;

export interface TableNodeDetailsModalProps {
  lobService: LobService;
  productService: ProductService;
  ratingService: RatingService;
  productVersionId: string;
  authToken: string;
  allTableNames: IdAndName[];
  node: Node;
  onSave: (node: Node) => void;
  onCancel: () => void;
  modifyCount: number;
  readOnly?: boolean;
}

interface TableNodeModalValues {
  tableId: string;
  loadedTable?: TableDefinition;
}

export function TableNodeDetailsModal(props: TableNodeDetailsModalProps) {
  const [form] = useForm<TableNodeModalValues>();

  const [tableEditorModalOpen, setTableEditorModalOpen] = React.useState(false);

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue({
      tableId: props.node.tableId ?? undefined,
    });
  }, [props.modifyCount, props.node]);

  function onFinish(values: TableNodeModalValues) {
    const newNode = structuredClone(props.node);
    // set the node name equal to the table name
    newNode.name =
      props.allTableNames.find(v => v.id === values.tableId)?.name ?? 'Table';
    newNode.tableId = values.tableId;
    props.onSave(newNode);
  }

  function getTableIdOptions() {
    // right now we are referencing the table name, not the id, since we can't maintain a foreign key anyway
    // while we store the bindings in the json blob
    const options = props.allTableNames.map(v => ({
      label: v.name,
      value: v.id,
    }));
    options.unshift({label: '', value: ''});
    return options;
  }

  async function validateTableHasAllPathsDefined(rule: any, value: string) {
    if (!value) {
      return Promise.resolve();
    }

    //load the table and check the columns, we don't need the rows

    if (!value || value === '') {
      return Promise.resolve();
    }

    let table: TableDefinition | undefined = form.getFieldsValue().loadedTable;
    if (table?.id !== value) {
      table =
        await props.productService.GetProductVersionTableWithoutRows(value);
      form.setFieldsValue({
        ...form.getFieldsValue(),
        loadedTable: table,
      });
    }

    const columnsWithoutPaths =
      table?.columns.filter(col => !col.path || col.path === '') ?? [];
    if (columnsWithoutPaths.length > 0) {
      return Promise.reject(
        'In order to add this table to the canvas, please add Risk Item Fields for all columns in this table'
      );
    }

    return Promise.resolve();
  }

  const tableEditorModal = tableEditorModalOpen ? (
    <ProductVersionTableModal
      lobService={props.lobService}
      productService={props.productService}
      ratingService={props.ratingService}
      productVersionId={props.productVersionId}
      tableId={form.getFieldsValue().tableId}
      authToken={props.authToken}
      open={tableEditorModalOpen}
      onClose={() => {
        setTableEditorModalOpen(false);
        // clear the loaded table, so that we reload the columns
        form.setFieldValue({
          ...form.getFieldsValue(),
          loadedTable: undefined,
        });
        // retrigger valdiation
        form.validateFields(['tableId']);
        // TODO clear the id and names as well, since the table might been renamed in the dialog
      }}
    />
  ) : (
    <> </>
  );

  const buttons = props.readOnly ? (
    <Flex justify="end">
      <Button {...ButtonSettings} htmlType="button" onClick={props.onCancel}>
        Close
      </Button>
    </Flex>
  ) : (
    <Flex justify="end" className="w-full gap-2">
      <Button {...ButtonSettings} htmlType="button" onClick={props.onCancel}>
        Cancel
      </Button>
      <Button ghost={false} type="default" onClick={form.submit}>
        OK
      </Button>
    </Flex>
  );

  const readOnlyInputProps = props.readOnly
    ? {
        disabled: true,
        readOnly: true,
        className: 'adaptify-read-only-field',
      }
    : {};

  return (
    <>
      <DraggableModal
        className="adaptify-modal"
        open={true}
        title={'Table'}
        closable={false}
        okButtonProps={{style: {display: 'none'}}}
        cancelButtonProps={{style: {display: 'none'}}}
        width={'clamp(300px, 70svw, 800px)'}
        footer={null}
      >
        <Form
          name="tableName"
          labelCol={{span: 24}}
          wrapperCol={{span: 24}}
          layout="vertical"
          size="large"
          style={{width: '100%'}}
          autoComplete="off"
          onFinish={onFinish}
          form={form}
        >
          <Form.Item
            name="tableId"
            label="Table"
            dependencies={['loadedTable']}
            rules={[
              {required: true},
              {validator: validateTableHasAllPathsDefined},
            ]}
          >
            <Select
              showSearch
              optionFilterProp="label"
              placeholder="select a Table"
              options={getTableIdOptions()}
              {...readOnlyInputProps}
            />
          </Form.Item>
          {buttons}
        </Form>
      </DraggableModal>
      {tableEditorModal}
    </>
  );
}
