package com.adaptify.rating.admin.model.calculation.descriptor.lib

import com.adaptify.rating.admin.model.calculation.BindingType
import com.adaptify.rating.admin.model.calculation.CalculationFunction
import com.adaptify.rating.admin.model.calculation.binding.Binding
import com.adaptify.rating.admin.model.calculation.context.ScopedVariable
import com.adaptify.rating.admin.model.calculation.descriptor.*
import com.adaptify.rating.admin.model.calculation.env.MetadataProvider
import com.adaptify.rating.admin.model.util.BindingResolver


object ForEachDescriptor : FunctionDescriptor("FOR_EACH", "FOR_EACH", FunctionType.Function, "Logic") {

  val variableDescriptor = object : BindingDescriptor(
    "variable", "Variable", BindingInputOutputType.Output,
    arrayOf(BindingType.VariableDeclaration),
    null, true
  ) {

    override suspend fun getExpectedDataType(
      calculationFunction: CalculationFunction,
      functionDescriptor: FunctionDescriptor,
      bindingDescriptor: BindingDescriptor,
      binding: Binding,
      metadataProvider: MetadataProvider,
      scope: Array<ScopedVariable>
    ): BoundDataType? {
      // the data type of the list variable should match that of the list, but shouldn't be many
      val listBinding = calculationFunction.bindings.find { it.name == listDescriptor.name }
      if (listBinding == null) {
        return null;
      }

      val resolved = BindingResolver.ResolveDataTypeForBinding(
        calculationFunction,
        listBinding,
        metadataProvider,
        scope
      );
      // resolved should be a list, but since the variable is a single iteration of the for loop
      // it should be single
      return resolved?.let {
        BoundDataTypeImpl(it.primitive, it.lobItem, false)
      }
    }
  }

  val listDescriptor = object : BindingDescriptor(
    "list", "List", BindingInputOutputType.Input, arrayOf(BindingType.Path),
    null
  ) {
    override fun allowPrimitives(): Boolean {
      return false
    }

    override fun allowLobItems(): Boolean {
      return true
    }

    override fun allowMany(): Boolean {
      return true
    }
  }

  val whereDescriptor = object : BindingDescriptor(
    "where", "WHERE", BindingInputOutputType.Input,
    arrayOf(BindingType.Predicate),
    null
  ) {
    override fun getAllowedFunctionTypes(): Array<FunctionType> {
      return arrayOf(FunctionType.Predicate);
    }

    override fun isOptional(): Boolean = true
  }

  val doDescriptor = BindingDescriptor(
    "block", "DO", BindingInputOutputType.Input, arrayOf(BindingType.Block),
    null
  )

  override fun getBindingDescriptors(
  ): Array<BindingDescriptor> = arrayOf(listDescriptor,
    whereDescriptor, doDescriptor)

  override fun getDisplayTemplate(
  ): String = "ForEach \$list" // blocks have special non-configurable rendering
}
