import React from 'react';

import {Flex, Typography} from 'antd';

import * as kot from 'adaptify-multi-module-rating-admin-model';
import {FlowLog} from '../../model/Rating';
import {NodeLogControl} from './NodeLogControl';
import Table = kot.com.adaptify.rating.admin.model.table.Table;
import TestCase = kot.com.adaptify.rating.admin.model.testcase.TestCase;
import LineOfBusinessHierarchy = kot.com.adaptify.rating.admin.model.lob.LineOfBusinessHierarchy;
import TestCaseLobItem = kot.com.adaptify.rating.admin.model.testcase.TestCaseLobItem;
import NodeType = kot.com.adaptify.rating.admin.model.flow.NodeType;

export interface FlowLogControlProps {
  flowLog: FlowLog;
}

export function FlowLogControl(props: FlowLogControlProps) {
  const nodeControls = props.flowLog.nodes
    .filter(
      nodeLog =>
        nodeLog.nodeType !== NodeType.Start.name &&
        nodeLog.nodeType !== NodeType.End.name
    )
    .map(nodeLog => {
      return <NodeLogControl nodeLog={nodeLog} />;
    });

  const riskLabel =
    props.flowLog.lobItemType === 'Coverage' ? (
      <Typography.Title level={2} style={{color: 'black'}}>
        Risk - {props.flowLog.riskItemName}
      </Typography.Title>
    ) : (
      <></>
    );

  return (
    <div style={{height: '100%', width: '100%'}}>
      <Flex vertical>
        {riskLabel}
        <Typography.Title level={1} style={{color: 'black'}}>
          {props.flowLog.lobItemType} - {props.flowLog.lobItemName}
        </Typography.Title>
        <Typography.Title level={1} style={{color: 'black'}}>
          Flow - {props.flowLog.flowName}
        </Typography.Title>

        {nodeControls}
      </Flex>
    </div>
  );
}
