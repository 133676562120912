import React from 'react';
import {ProductService} from '../../product/service/ProductService';

import {Flex} from 'antd';
import {useParams} from 'react-router-dom';
import {CreateTableComparisonForm} from '../control/CreateTableComparisonForm';
import {StudySummaryControl} from '../control/StudySummaryControl';
import {AnalysisService} from '../service/AnalysisService';

export interface CreateTableComparisonPageProps {
  analysisService: AnalysisService;
  productService: ProductService;
}

export function CreateTableComparisonPage(
  props: CreateTableComparisonPageProps
) {
  const {studyId} = useParams<string>();
  if (typeof studyId !== 'string') {
    return <></>;
  }

  return (
    <Flex vertical gap={24}>
      <div style={{width: '100%', height: '100%'}}>
        <StudySummaryControl
          analysisService={props.analysisService}
          studyId={studyId}
        />
      </div>
      <CreateTableComparisonForm
        analysisService={props.analysisService}
        productService={props.productService}
        studyId={studyId}
      />
    </Flex>
  );
}
