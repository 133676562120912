import React from 'react';
import {NavigationAware} from '../../NavigationAware';
import {ValueListService} from '../../valuelist/service/ValueListService';
import {TenantService} from '../service/TenantService';
import {UserService} from '../service/UserService';
import {TenantSummaryControl} from './TenantSummaryControl';

export interface TenantSummaryPageProps extends NavigationAware {
  tenantService: TenantService;
  valueListService: ValueListService;
  userService: UserService;
  userTenantId: string | undefined;
}

export function TenantSummaryPage(props: TenantSummaryPageProps) {
  return (
    <TenantSummaryControl
      tenantService={props.tenantService}
      valueListService={props.valueListService}
      userService={props.userService}
      userTenantId={props.userTenantId}
    />
  );
}
