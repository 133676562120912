import {SERVER_BASE_URL} from '../../common/service/Constants';
import {
  ConvertToQueryParams,
  CreateStandardHeaders,
  HandleFetchJson,
  HandleFetchVoid,
} from '../../common/service/Service';
import {
  IdAndValue,
  ValueListDefinition,
  ValueListItem,
} from '../model/ValueList';

const SERVER_URL = SERVER_BASE_URL;

const QUERY_VALUE_LIST_DEFINITION_URL = `${SERVER_URL}/admin/valuelist/definition/all`;
const GET_VALUE_LIST_DEFINITION_URL = (id: string) =>
  `${SERVER_URL}/admin/valuelist/definition/get/${id}`;
const SAVE_VALUE_LIST_DEFINITION_URL = `${SERVER_URL}/admin/valuelist/definition/update`;
const DELETE_VALUE_LIST_DEFINITION_URL = (id: string) =>
  `${SERVER_URL}/admin/valuelist/definition/delete/${id}`;

const QUERY_VALUE_LIST_ITEM_BY_DEFINITION_ID_URL = (
  valueDefinitionId: string,
  parentValueItemId?: string
) =>
  `${SERVER_URL}/admin/valuelist/item/querybydefinitionid?${ConvertToQueryParams({valueDefinitionId, parentValueItemId})}`;
const QUERY_VALUE_LIST_ITEM_BY_DEFINITION_NAME_URL = (
  definitionName: string,
  parentValueItemId?: string
) =>
  `${SERVER_URL}/admin/valuelist/item/querybydefinitioncode?${ConvertToQueryParams({definitionName, parentValueItemId})}`;
const GET_VALUE_LIST_ITEM_URL = (id: string) =>
  `${SERVER_URL}/admin/valuelist/item/get/${id}`;
const SAVE_VALUE_LIST_ITEM_URL = `${SERVER_URL}/admin/valuelist/item/update`;
const DELETE_VALUE_LIST_ITEM_URL = (id: string) =>
  `${SERVER_URL}/admin/valuelist/item/delete/${id}`;

export interface ValueListService {
  GetAllValueListDefinitions(): Promise<ValueListDefinition[]>;
  GetValueListDefiniton(id: string): Promise<ValueListDefinition>;
  SaveValueListDefinition(
    definition: ValueListDefinition
  ): Promise<ValueListDefinition>;
  DeleteValueListDefinition(id: string): Promise<void>;

  GetValueListItemsByDefinitionId(
    id: string,
    parentValueItemId?: string
  ): Promise<ValueListItem[]>;
  GetValueListItemsByDefinitionName(
    name: string,
    parentValueItemId: string
  ): Promise<IdAndValue[]>;
  GetValueListItem(id: string): Promise<ValueListItem>;
  SaveValueListItem(item: ValueListItem): Promise<ValueListItem>;
  DeleteValueListItem(id: string): Promise<void>;

  // custom queries for for specific value list
  // these should get moved something else
  GetCarriers(): Promise<IdAndValue[]>;
  GetUnderwritingCompanies(
    carrierValueListItemId?: string
  ): Promise<IdAndValue[]>;
  GetInsuranceTypes(): Promise<IdAndValue[]>;
  GetLinesOfBusiness(insuranceTypeListItemId?: string): Promise<IdAndValue[]>;
  GetProductTypes(lineOfBusinessListItemId?: string): Promise<IdAndValue[]>;

  // GetLineOfBusinessCoveragesByParentRiskId(parentRiskId: string): Promise<LineOfBusinessItem[]>;
}

export function NewValueListService(authToken: string): ValueListService {
  function createHeaders() {
    return CreateStandardHeaders(authToken);
  }

  async function GetAllValueListDefinitions(): Promise<ValueListDefinition[]> {
    const requestOptions = {
      method: 'GET',
      headers: createHeaders(),
    };

    return HandleFetchJson(
      fetch(QUERY_VALUE_LIST_DEFINITION_URL, requestOptions)
    );
  }

  async function GetValueListDefiniton(
    id: string
  ): Promise<ValueListDefinition> {
    const requestOptions = {
      method: 'GET',
      headers: createHeaders(),
    };

    return HandleFetchJson(
      fetch(GET_VALUE_LIST_DEFINITION_URL(id), requestOptions)
    );
  }

  async function SaveValueListDefinition(
    definition: ValueListDefinition
  ): Promise<ValueListDefinition> {
    const requestOptions = {
      method: 'POST',
      headers: createHeaders(),
      body: JSON.stringify(definition),
    };

    return HandleFetchJson(
      fetch(SAVE_VALUE_LIST_DEFINITION_URL, requestOptions)
    );
  }

  async function DeleteValueListDefinition(id: string): Promise<void> {
    const requestOptions = {
      method: 'DELETE',
      headers: createHeaders(),
    };

    return HandleFetchVoid(
      fetch(DELETE_VALUE_LIST_DEFINITION_URL(id), requestOptions)
    );
  }

  async function GetValueListItemsByDefinitionId(
    id: string,
    parentValueItemId?: string
  ): Promise<ValueListItem[]> {
    const requestOptions = {
      method: 'GET',
      headers: createHeaders(),
    };

    return HandleFetchJson(
      fetch(
        QUERY_VALUE_LIST_ITEM_BY_DEFINITION_ID_URL(id, parentValueItemId),
        requestOptions
      )
    );
  }

  async function GetValueListItemsByDefinitionName(
    name: string,
    parentValueItemId?: string
  ): Promise<IdAndValue[]> {
    const requestOptions = {
      method: 'GET',
      headers: createHeaders(),
    };

    return HandleFetchJson(
      fetch(
        QUERY_VALUE_LIST_ITEM_BY_DEFINITION_NAME_URL(name, parentValueItemId),
        requestOptions
      )
    );
  }

  async function GetValueListItem(id: string): Promise<ValueListItem> {
    const requestOptions = {
      method: 'GET',
      headers: createHeaders(),
    };

    return HandleFetchJson(fetch(GET_VALUE_LIST_ITEM_URL(id), requestOptions));
  }

  async function SaveValueListItem(
    item: ValueListItem
  ): Promise<ValueListItem> {
    const requestOptions = {
      method: 'POST',
      headers: createHeaders(),
      body: JSON.stringify(item),
    };

    return HandleFetchJson(fetch(SAVE_VALUE_LIST_ITEM_URL, requestOptions));
  }

  async function DeleteValueListItem(id: string): Promise<void> {
    const requestOptions = {
      method: 'DELETE',
      headers: createHeaders(),
    };

    return HandleFetchVoid(
      fetch(DELETE_VALUE_LIST_ITEM_URL(id), requestOptions)
    );
  }

  async function GetCarriers(): Promise<IdAndValue[]> {
    return GetValueListItemsByDefinitionName('CARRIER');
  }
  async function GetUnderwritingCompanies(
    carrierValueListItemId?: string
  ): Promise<IdAndValue[]> {
    return GetValueListItemsByDefinitionName(
      'UNDERWRITING_COMPANY',
      carrierValueListItemId
    );
  }

  async function GetInsuranceTypes(): Promise<IdAndValue[]> {
    return GetValueListItemsByDefinitionName('INSURANCE_TYPE');
  }

  async function GetLinesOfBusiness(
    insuranceTypeListItemId?: string
  ): Promise<IdAndValue[]> {
    return GetValueListItemsByDefinitionName(
      'LINE_OF_BUSINESS',
      insuranceTypeListItemId
    );
  }

  async function GetProductTypes(
    lineOfBusinessListItemId?: string
  ): Promise<IdAndValue[]> {
    return GetValueListItemsByDefinitionName(
      'PRODUCT_TYPE',
      lineOfBusinessListItemId
    );
  }

  return {
    GetAllValueListDefinitions,
    GetValueListDefiniton,
    SaveValueListDefinition,
    DeleteValueListDefinition,

    GetValueListItemsByDefinitionId,
    GetValueListItemsByDefinitionName,
    GetValueListItem,
    SaveValueListItem,
    DeleteValueListItem,
    GetCarriers,
    GetUnderwritingCompanies,
    GetInsuranceTypes,
    GetLinesOfBusiness,
    GetProductTypes,
  };
}
