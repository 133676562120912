import React from 'react';
import {NavigationAware} from '../../NavigationAware';
import {UserService} from '../service/UserService';
import {ChangePasswordControl} from './ChangePasswordControl';

export interface ChangePasswordPageProps extends NavigationAware {
  userService: UserService;
  onPasswordChanged: () => void;
}

export function ChangePasswordPage(props: ChangePasswordPageProps) {
  return (
    <ChangePasswordControl
      userService={props.userService}
      onPasswordChanged={props.onPasswordChanged}
    />
  );
}
