import {BarsOutlined} from '@ant-design/icons';
import * as Ant from 'antd';
import {Flex, Layout} from 'antd';
import React, {useMemo} from 'react';
import {Link, Outlet, useOutlet} from 'react-router-dom';
import './App.scss';
import {DraggableModal} from './common/control/DraggableModal';
import {NavigationAware} from './NavigationAware';
import {LoginAvatar} from './user/login/LoginAvatar';
import {LoginForm} from './user/login/LoginForm';
import {TenantModuleName} from './user/model/Tenant';
import {AuthManager} from './user/util/AuthManager';

const headerStyle: React.CSSProperties = {
  textAlign: 'center',
  color: '#fff',
  height: 64,
  paddingInline: 48,
  lineHeight: '64px',
  backgroundColor: '#4096ff',
};

interface AppProps {
  authToken: string;
  logout: () => void;
  setAuthToken: (token: string) => void;
  title: string;
  authManager: AuthManager;
}

function App(props: AppProps) {
  const outlet = useOutlet();
  // search the prop hierarchy to find the navigation tab associated with this route
  function findCurrentNav(): string | undefined {
    let current = outlet?.props;
    while (current) {
      if ((current as NavigationAware)?.currentNavigation) {
        return (current as NavigationAware).currentNavigation;
      }
      current = current.children?.props;
    }
    return undefined;
  }

  const authorities = props.authManager.GetAuthorities();

  const [currentNav, setCurrentNav] = React.useState(findCurrentNav());

  const navItems = useMemo(() => {
    const authorities = props.authManager.GetAuthorities();

    // only show the items that are authorized
    const items: Ant.MenuProps['items'] = [];
    const auths = authorities ?? [];
    if (auths.includes(TenantModuleName.RateProductLibrary)) {
      items.push({
        key: 'Rating',
        label: (
          <Link to="/platform/product/summary/my">Rate Product Library</Link>
        ),
      });
    }
    if (auths.includes(TenantModuleName.RateProductLibrary)) {
      items.push({
        key: 'LOB',
        label: (
          <Link to="/platform/lob/hierarchy">LOB Product Maintenance</Link>
        ),
      });
    }
    if (auths.includes(TenantModuleName.AccountProvisioning)) {
      items.push({
        key: 'Users',
        label: <Link to="/platform/tenant/summary">Account Provisioning</Link>,
      });
    }
    if (auths.includes(TenantModuleName.Lists)) {
      items.push({
        key: 'Lists',
        label: <Link to="/platform/valuelist/summary">Lists</Link>,
      });
    }
    if (auths.includes(TenantModuleName.ComparativeAnalysis)) {
      items.push({
        key: 'Study',
        label: <Link to="/analysis/study/list">Comparative Analysis</Link>,
      });
    }
    if (auths.includes(TenantModuleName.RateProductAssistant)) {
      items.push({
        key: 'Chat',
        label: <Link to="/platform/chat">Rate Product Assistant</Link>,
      });
    }
    if (auths.includes(TenantModuleName.TrialAccounts)) {
      items.push({
        key: 'TrialAccount',
        label: <Link to="/platform/trialaccount/list">Trial Accounts</Link>,
      });
    }
    if (auths.includes(TenantModuleName.ClientRateProductLookup)) {
      items.push({
        key: 'ClientRateProductLookup',
        label: (
          <Link to="/platform/product/summary/all">
            Client Rate Product Lookup
          </Link>
        ),
      });
    }
    return items;
  }, [props.authManager]);

  function hasAuthToken() {
    return props.authToken !== '';
  }

  function onLogin(token: string) {
    props.setAuthToken(token);
  }

  function createMenuFromNavItems() {
    const dropdown = (
      <Ant.Dropdown
        menu={{items: navItems}}
        trigger={['click']}
        className="opacity-80 hover:opacity-100 focus:opacity-100"
      >
        <Ant.Button type="link">
          <BarsOutlined
            style={{fill: 'white', fontSize: '200%', color: 'white'}}
          />
        </Ant.Button>
      </Ant.Dropdown>
    );
    return dropdown;
  }

  const dropdownMenu = createMenuFromNavItems();

  return (
    <Ant.ConfigProvider>
      <Ant.App className="adaptify-layout">
        <Layout>
          <Layout.Header>
            <Ant.Row
              style={{
                width: '100%',
                padding: '4px 0px',
                height: '100%',
                alignItems: 'center',
              }}
            >
              <Ant.Col>
                <Flex
                  justify="flex-start"
                  align="center"
                  style={{height: '100%', width: '100%'}}
                >
                  {dropdownMenu}
                </Flex>
              </Ant.Col>

              <Ant.Col style={{display: 'flex', justifyContent: 'center'}}>
                <Flex justify="center" align="start">
                  <Ant.Typography.Title
                    level={2}
                    style={{margin: '0px', lineHeight: 1}}
                  >
                    {props.title}
                  </Ant.Typography.Title>
                </Flex>
              </Ant.Col>

              <Ant.Col span={4} style={{marginLeft: 'auto'}}>
                <Flex
                  justify="flex-end"
                  align="center"
                  style={{height: '100%'}}
                >
                  <LoginAvatar
                    authToken={props.authToken}
                    setAuthToken={props.setAuthToken}
                    logout={props.logout}
                    authManager={props.authManager}
                  />
                </Flex>
              </Ant.Col>
            </Ant.Row>
          </Layout.Header>
          <Layout>
            <Layout.Content className="w-full bg-white flex">
              <div className="py-6 px-8 lg:px-12 min-h-[800px] flex-1 min-w-0">
                <Outlet />
              </div>
            </Layout.Content>
          </Layout>
          <Layout.Footer
            style={{backgroundColor: 'white'}}
            className="flex items-center justify-between"
          >
            <p>2024 Adaptify, Inc. All Rights Reserved</p>
            <img src="/logo.svg" alt="Logo" className="max-w-32" />
          </Layout.Footer>
        </Layout>
        <DraggableModal
          open={!hasAuthToken()}
          closable={false}
          okButtonProps={{style: {display: 'none'}}}
          cancelButtonProps={{style: {display: 'none'}}}
        >
          <LoginForm onLogin={onLogin} />;
        </DraggableModal>
      </Ant.App>
    </Ant.ConfigProvider>
  );
}

export default App;
