import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import PublishOutlinedIcon from '@mui/icons-material/PublishOutlined';
import {
  DataGrid,
  GridCallbackDetails,
  GridColDef,
  GridRowSelectionModel,
} from '@mui/x-data-grid';
import {Button, Col, Flex, Popconfirm, Row} from 'antd';
import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {ButtonSettings, DataGridSettings} from '../../common/control/Common';
import {ConfirmationModal} from '../../common/control/ConfirmationModal';
import {DraggableModal} from '../../common/control/DraggableModal';
import {ValueListService} from '../../valuelist/service/ValueListService';
import {
  LineOfBusinessHierarchyDef,
  LobHierarchyInfo,
  LobStatus,
} from '../model/LineOfBusiness';
import {LobService} from '../service/LobService';
import {EditRiskHierarchyForm} from './EditRiskHierarchyForm';

export interface LobHierarchyListProps {
  lobService: LobService;
  valueListService: ValueListService;
  lobs: LobHierarchyInfo[];
  selectedLineOfBusinessDefId: string | undefined;
  setSelectedLineOfBusinessDefId: (selection: string | undefined) => void;
  onCreateNewLob?: (lobDef: LineOfBusinessHierarchyDef) => void;
  readOnly?: boolean;
  modifyCount: number;
  setModifyCount: (count: number) => void;
  currentTenantId: string | undefined;
  isAdaptifyUser: boolean;
}

export interface EditState {
  open: boolean;
  isNew: boolean;
  isCopying?: boolean;
  lobDefId?: string;
}

export function LobHierarchyList(props: LobHierarchyListProps) {
  const [editState, setEditState] = useState<EditState>({
    isNew: true,
    open: false,
  });
  const [publishConfirmOpen, setPublishConfirmOpen] = useState(false);

  const allColDefs: GridColDef<LobHierarchyInfo>[] = [
    {
      field: 'name',
      headerName: 'Hierarchy Name',
      flex: 300,
      renderCell: params => {
        return (
          <Link to={`/platform/lob/${params.row.id}/risk`}>
            {params.row.name}
          </Link>
        );
      },
    },
    {
      field: 'insuranceType',
      headerName: 'Insurance Type',
      flex: 300,
    },
    {
      field: 'lobName',
      headerName: 'LOB',
      flex: 300,
    },
    {
      field: 'hierarchySource',
      headerName: 'Hierarchy Source',
      flex: 300,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 300,
    },
  ];

  // remove the status column if not adaptify
  const colDefs = props.isAdaptifyUser
    ? allColDefs
    : allColDefs.filter(col => col.field !== 'status');

  function onOpenCreate() {
    setEditState({
      isNew: true,
      open: true,
    });
  }

  function onOpenModify(lobDefId: string | undefined) {
    if (lobDefId) {
      setEditState({
        isNew: false,
        open: true,
        lobDefId: lobDefId,
      });
    }
  }

  function onOpenCopy(lobDefId: string | undefined) {
    if (lobDefId) {
      setEditState({
        isNew: false,
        open: true,
        isCopying: true,
        lobDefId: lobDefId,
      });
    }
  }

  async function onDelete(lobDefId: string | undefined) {
    if (!lobDefId) {
      return;
    }
    await props.lobService.DeleteLineOfBusiness(lobDefId);
    props.setModifyCount(props.modifyCount + 1);
  }

  async function onSaveLineOfBusinessEntity(id: string) {
    setEditState({
      ...editState,
      open: false,
    });

    props.setModifyCount(props.modifyCount + 1);
    props.setSelectedLineOfBusinessDefId(id);
  }

  function onCancelLineOfBusinessEntity() {
    setEditState({
      ...editState,
      open: false,
    });
  }

  function onTableSelectionChanged(
    rowSelectionModel: GridRowSelectionModel,
    details: GridCallbackDetails
  ) {
    props.setSelectedLineOfBusinessDefId(
      rowSelectionModel.length > 0
        ? (rowSelectionModel[0] as string)
        : undefined
    );
  }

  function canPublishLob() {
    const lobId = props.selectedLineOfBusinessDefId;
    if (!lobId) {
      return false;
    }

    const lob = props.lobs.find(lob => lob.id === lobId);
    if (!lob) {
      return false;
    }
    if (lob.tenantId !== props.currentTenantId) {
      return false;
    }

    return lob.status !== LobStatus.Published;
  }

  async function onPublishLob() {
    const lobId = props.selectedLineOfBusinessDefId;
    if (!lobId) {
      return;
    }
    const result = await props.lobService.PublishLineOfBusiness(lobId ?? '');
    if (result) {
      props.setModifyCount(props.modifyCount + 1);
    }
  }

  function canModify() {
    if (!props.selectedLineOfBusinessDefId) {
      return false;
    }
    const lob = props.lobs.find(
      lob => lob.id === props.selectedLineOfBusinessDefId
    );

    if (!lob) {
      return false;
    }
    return (
      lob.tenantId === props.currentTenantId &&
      lob.status !== LobStatus.Published
    );
  }

  const deleteButton = (
    <Popconfirm
      title="Confirm deletion"
      description="Are you sure to want to delete?"
      onConfirm={() => onDelete(props.selectedLineOfBusinessDefId)}
      disabled={!props.setSelectedLineOfBusinessDefId}
      okText="Delete"
      cancelText="Cancel"
    >
      <Button
        {...ButtonSettings}
        disabled={!props.setSelectedLineOfBusinessDefId}
      >
        Delete
      </Button>
    </Popconfirm>
  );

  const publishButton = props.isAdaptifyUser ? (
    <Button
      {...ButtonSettings}
      icon={<PublishOutlinedIcon className="-m-1" />}
      disabled={!canPublishLob()}
      onClick={() => setPublishConfirmOpen(true)}
    >
      Publish
    </Button>
  ) : (
    <></>
  );
  const editButtons = (
    <Flex justify="flex-end" align="flex-start" className="gap-2">
      {publishButton}
      <Button
        {...ButtonSettings}
        icon={<ContentCopyOutlinedIcon className="-m-1" />}
        onClick={() => onOpenCopy(props.selectedLineOfBusinessDefId)}
        disabled={!props.selectedLineOfBusinessDefId}
      >
        Copy
      </Button>
      <Button
        {...ButtonSettings}
        icon={<DriveFileRenameOutlineOutlinedIcon className="-m-1" />}
        onClick={() => onOpenModify(props.selectedLineOfBusinessDefId)}
        disabled={!canModify()}
      >
        Modify
      </Button>
      <Button
        {...ButtonSettings}
        icon={<AddOutlinedIcon className="-m-1" />}
        onClick={onOpenCreate}
      >
        Create New LOB Hierarchy
      </Button>
    </Flex>
  );

  const buttons = props.readOnly ? <></> : editButtons;

  const editModal = editState.open ? (
    <DraggableModal
      className="adaptify-modal"
      title={
        editState.isNew
          ? 'Add Risk Hierarchy'
          : editState.isCopying
            ? 'Copy Risk Hierarchy'
            : 'Edit Risk Hierarchy'
      }
      open={editState.open}
      closable={false}
      okButtonProps={{style: {display: 'none'}}}
      cancelButtonProps={{style: {display: 'none'}}}
      width={'clamp(300px, 70svw, 800px)'}
      footer={null}
    >
      <EditRiskHierarchyForm
        lobService={props.lobService}
        valueListService={props.valueListService}
        lobDefId={editState.lobDefId}
        onSave={onSaveLineOfBusinessEntity}
        onCancel={onCancelLineOfBusinessEntity}
        copy={editState.isCopying}
      />
    </DraggableModal>
  ) : (
    <></>
  );

  const publishConfirmModal = publishConfirmOpen ? (
    <ConfirmationModal
      open={publishConfirmOpen}
      message="Please confirm that you would like to publish the selected LOB Hierarchy.
Please note that once you select Publish, then the LOB Hierarchy will be
available for all customer accounts to use."
      title="Publish LOB Hierarchy"
      onOk={() => {
        onPublishLob();
        setPublishConfirmOpen(false);
      }}
      onCancel={() => setPublishConfirmOpen(false)}
    />
  ) : (
    <> </>
  );

  return (
    <>
      <Flex vertical style={{width: '100%', height: '100%'}}>
        <Row>
          <Col span={18}>
            <div className="page-title">Hierarchy Summary</div>
          </Col>
          <Col span={6}>{buttons}</Col>
        </Row>
        <DataGrid
          style={{width: '100%', height: '100%'}}
          {...DataGridSettings}
          columns={colDefs}
          rows={props.lobs}
          getRowId={row => row.id}
          rowSelectionModel={
            props.selectedLineOfBusinessDefId
              ? [props.selectedLineOfBusinessDefId]
              : []
          }
          onRowSelectionModelChange={onTableSelectionChanged}
          sx={{
            '&.MuiDataGrid-root': {
              borderRadius: '8px',
              overflow: 'hidden',
              borderColor: '#CCCCCC',
            },
          }}
        />
      </Flex>
      {editModal}
      {publishConfirmModal}
    </>
  );
}
