import * as kot from 'adaptify-multi-module-rating-admin-model';
import {Flex} from 'antd';
import React, {useEffect, useState} from 'react';
import {LobService} from '../../lob/service/LobService';
import {ProductService} from '../../product/service/ProductService';
import {RatingNavigationBar} from '../control/RatingNavigationBar';

import {LobCoverageFieldList} from '../../lob/control/LobCoverageFieldList';
import {ProductVersion} from '../../product/model/Product';
import {AuthManager} from '../../user/util/AuthManager';
import {ProductVersionSummaryForm} from '../control/ProductVersionSummaryForm';
import {IsProductVersionReadOnly} from './ProductUtils';
import LineOfBusinessHierarchy = kot.com.adaptify.rating.admin.model.lob.LineOfBusinessHierarchy;

export interface ProductVersionLobCoverageFieldControlProps {
  lobService: LobService;
  initialLobItemId: string | undefined;
  onSetLobItemId: (lobItemId: string | undefined) => void;
  productService: ProductService;
  productVersionId: string;
  authManager: AuthManager;
}

export function ProductVersionLobCoverageFieldControl(
  props: ProductVersionLobCoverageFieldControlProps
) {
  const [productVersion, setProductVersion] = useState<
    ProductVersion | undefined
  >(undefined);
  const [lob, setLob] = useState<LineOfBusinessHierarchy | undefined>(
    undefined
  );
  const [selectedLobItemId, setSelectedLobItemId] = useState<
    string | undefined
  >(props.initialLobItemId);
  const [modifyCount, setModifyCount] = useState<number>(0);

  useEffect(() => {
    if (!props.productVersionId) {
      setProductVersion(undefined);
      return;
    }
    const eff = async () => {
      const lobHierarchyPromise =
        await props.productService.GetLobHierarchyForProductVersionId(
          props.productVersionId
        );

      const productVersionPromise = props.productService.GetProductVersion(
        props.productVersionId
      );

      const [lobHierarchy, productVersion] = await Promise.all([
        lobHierarchyPromise,
        productVersionPromise,
      ]);

      setLob(lobHierarchy);
      setProductVersion(productVersion);
    };
    eff();
  }, [props.productVersionId]);

  function isDirty() {
    return false;
  }

  function isReadOnly() {
    return (
      IsProductVersionReadOnly(productVersion, props.authManager) ||
      lob?.tenantId !== props.authManager.GetTenantId()
    );
  }

  function onSetSelectedCoverageId(lobItemId: string | undefined) {
    setSelectedLobItemId(lobItemId);
    props.onSetLobItemId(lobItemId);
  }

  // right now the premium orchestration is saved on every modification, so there's no concept of being dirty
  return (
    <Flex vertical gap={24}>
      <div style={{minHeight: '100%', width: '100%'}}>
        <ProductVersionSummaryForm
          productService={props.productService}
          productVersionId={props.productVersionId}
          isDirty={isDirty()}
          readOnly={isReadOnly()}
        />
      </div>
      <div style={{minHeight: '46px', width: '100%'}}>
        <RatingNavigationBar
          productVersionId={props.productVersionId}
          isDirty={isDirty()}
          selection="LOB"
        />
      </div>
      <Flex vertical gap={24}>
        <LobCoverageFieldList
          lobService={props.lobService}
          lobHierarchyId={lob?.id}
          selectedCoverageId={selectedLobItemId}
          setSelectedCoverageId={onSetSelectedCoverageId}
          readOnly={isReadOnly()}
        />
      </Flex>
    </Flex>
  );
}
