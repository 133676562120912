import {
  DataGrid,
  GridCallbackDetails,
  GridColDef,
  GridRowSelectionModel,
} from '@mui/x-data-grid';
import {Button, Col, Flex, Row, Space} from 'antd';
import React, {useEffect, useState} from 'react';
import {ButtonSettings, DataGridSettings} from '../../common/control/Common';
import {ConfirmationModal} from '../../common/control/ConfirmationModal';
import {DraggableModal} from '../../common/control/DraggableModal';
import {User} from '../model/User';
import {UserService} from '../service/UserService';
import {CreateUserForm} from './CreateUserForm';

export interface UserListProps {
  userService: UserService;
  readOnly?: boolean;
  tenantId: string | undefined;
}

export interface EditUserState {
  open: boolean;
  userId?: string;
}

export function UserList(props: UserListProps) {
  const [createPopupState, setCreatePopupState] = useState<EditUserState>({
    open: false,
  });
  const [activateConfirmOpen, setActivateConfirmOpen] =
    useState<boolean>(false);
  const [inactivateConfirmOpen, setInactivateConfirmOpen] =
    useState<boolean>(false);

  const [modifyCount, setModifyCount] = React.useState<number>(0);
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUserId, setSelectedUserId] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    if (!props.tenantId) {
      setUsers([]);
      return;
    }
    const eff = async () => {
      const loaded = await props.userService.QueryUser({
        tenantId: props.tenantId,
      });
      setUsers(loaded);
      setSelectedUserId(loaded.length > 0 ? loaded[0].id : undefined);
    };
    eff();
  }, [props.tenantId, modifyCount]);

  const colDefs: GridColDef<User>[] = [
    {
      field: 'fullName',
      headerName: 'Full Name',
      flex: 300,
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 300,
    },
    {
      field: 'disabled',
      headerName: 'Status',
      flex: 300,
      valueFormatter: params => {
        return params ? 'Inactive' : 'Active';
      },
    },
  ];

  function onCreateNew() {
    setCreatePopupState({open: true});
  }

  function onModify() {
    setCreatePopupState({open: true, userId: selectedUserId});
  }

  function onTableSelectionChanged(
    rowSelectionModel: GridRowSelectionModel,
    details: GridCallbackDetails
  ) {
    if (rowSelectionModel.length > 0) {
      setSelectedUserId(rowSelectionModel[0] as string);
    } else {
      setSelectedUserId(undefined);
    }
  }

  function onUserSaved() {
    // up the modify count to trigger a refresh
    setModifyCount(modifyCount + 1);
    setCreatePopupState({open: false});
  }

  function onUserCancelled() {
    setCreatePopupState({open: false});
  }

  const selectedUser = users.find(u => u.id === selectedUserId);

  async function onResetPassword() {
    if (!selectedUserId) {
      return;
    }
    await props.userService.ResetPassword(selectedUser?.email ?? '');
    setModifyCount(modifyCount + 1);
  }

  async function onInactivateUser() {
    if (!selectedUserId) {
      return;
    }
    await props.userService.InactivateUser(selectedUser?.email ?? '');
    setInactivateConfirmOpen(false);
    setModifyCount(modifyCount + 1);
  }

  async function onActivateUser() {
    if (!selectedUserId) {
      return;
    }
    await props.userService.ActivateUser(selectedUser?.email ?? '');
    setActivateConfirmOpen(false);
    setModifyCount(modifyCount + 1);
  }

  /*
    Product doesn't want a reset password button, so it's removed for now
    <Button
        {...ButtonSettings}
        onClick={onResetPassword}
        disabled={!selectedUser}
      >
        Reset Password
      </Button>
      */

  const modifyButtons = props.readOnly ? (
    <></>
  ) : (
    <Flex justify="end">
      <Button {...ButtonSettings} onClick={onCreateNew}>
        Add User
      </Button>
      <Button {...ButtonSettings} onClick={onModify}>
        Modify User
      </Button>

      <Button
        {...ButtonSettings}
        onClick={() => {
          setActivateConfirmOpen(true);
        }}
        disabled={!selectedUser || !selectedUser?.disabled}
      >
        Activate User
      </Button>
      <Button
        {...ButtonSettings}
        onClick={() => {
          setInactivateConfirmOpen(true);
        }}
        disabled={!selectedUser || selectedUser?.disabled}
      >
        Deactivate User
      </Button>
    </Flex>
  );

  const createUserModal = createPopupState.open ? (
    <DraggableModal
      className="adaptify-modal"
      title={createPopupState.userId ? 'Modify User' : 'Create User'}
      open={createPopupState.open}
      closable={false}
      okButtonProps={{style: {display: 'none'}}}
      cancelButtonProps={{style: {display: 'none'}}}
    >
      <CreateUserForm
        userService={props.userService}
        tenantId={props.tenantId ?? ''}
        onFinish={onUserSaved}
        onCancel={onUserCancelled}
        userId={createPopupState.userId}
      />
    </DraggableModal>
  ) : (
    <></>
  );

  const activateConfirmModal = activateConfirmOpen ? (
    <ConfirmationModal
      open={activateConfirmOpen}
      title="Activate user"
      message="Are you sure you would like to activate this user? Please note, the user can now log into the
platform once activated."
      okText="Yes"
      cancelText="No"
      onOk={onActivateUser}
      onCancel={() => setActivateConfirmOpen(false)}
    />
  ) : (
    <></>
  );

  const inactivateConfirmModal = inactivateConfirmOpen ? (
    <ConfirmationModal
      open={inactivateConfirmOpen}
      title="Deactivate user"
      message="Are you sure you would like to deactivate this user? Please note, the user cannot log into the
platform once deactivated"
      okText="Yes"
      cancelText="No"
      onOk={onInactivateUser}
      onCancel={() => setInactivateConfirmOpen(false)}
    />
  ) : (
    <></>
  );

  return (
    <>
      <Flex vertical>
        <Row>
          <Col span={18}>
            <div className="page-title">Users</div>
          </Col>
          <Col span={6}>{modifyButtons}</Col>
        </Row>
        <Space direction="vertical" style={{width: '100%'}}>
          <div style={{height: '100%', width: '100%'}}>
            <DataGrid
              {...DataGridSettings}
              columns={colDefs}
              rows={users}
              rowSelectionModel={selectedUserId ? [selectedUserId] : []}
              onRowSelectionModelChange={onTableSelectionChanged}
            />
          </div>
        </Space>
      </Flex>
      {createUserModal}
      {activateConfirmModal}
      {inactivateConfirmModal}
    </>
  );
}
