import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Flex,
  Form,
  Input,
  Row,
  Select,
  Upload,
} from 'antd';
import {BaseOptionType} from 'antd/es/select';
import dayjs from 'dayjs';
import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {
  ButtonSettings,
  DateDataFormats,
  StateCodeOptions,
} from '../../common/control/Common';
import {NewTenantAwareEntity} from '../../common/model/Common';
import {DayJsToIsoString} from '../../common/model/CommonUtils';
import {CreateAuthHeader} from '../../common/service/Service';
import {LobHierarchyInfo} from '../../lob/model/LineOfBusiness';
import {LobService} from '../../lob/service/LobService';
import {IdAndValue} from '../../valuelist/model/ValueList';
import {ValueListService} from '../../valuelist/service/ValueListService';
import {Product, ProductVersion} from '../model/Product';
import {
  ProductService,
  UPLOAD_ANALYSIS_DOCUMENT_URL,
} from '../service/ProductService';

export interface CreateProductFormProps {
  lobService: LobService;
  productService: ProductService;
  valueListService: ValueListService;
  existingProductId: string | undefined;
  refreshFormCount: number;
  authToken: string;
  onSaved: (product: Product) => void;
  onCancel: () => void;
}

interface CreateProductFormArgs {
  carrierValueListId: string;
  underwritingCompanyValueListItemId: string;
  insuranceTypeValueListItemId: string;
  lobNameValueListItemId: string;
  productTypeValueListItemId: string;
  lobDefId: string;
  stateCode: string;
  effectiveDate: dayjs.Dayjs;
  description: string;
  importOptions: string[];
}

export function CreateProductForm(props: CreateProductFormProps) {
  const [form] = Form.useForm<CreateProductFormArgs>();
  const [lobs, setLobs] = React.useState<LobHierarchyInfo[]>([]);
  const [uploadedDocumentId, setUploadedDocumentId] = React.useState('');
  const [productTypes, setProductTypes] = React.useState<IdAndValue[]>([]);
  const [existingProductVersionId, setExistingProductVersionId] = useState<
    string | undefined
  >(undefined);

  // using this to refresh form dependencies, since I cannot figure out how to use ant forms shouldUpdate correctly
  const [internalRefreshNum, setInternalRefreshNum] = React.useState(0);
  const [carrierValueItems, setCarrierValueItems] = useState<IdAndValue[]>([]);
  const [underwritingCompanyValueItems, setUnderwritingCompanyValueItems] =
    useState<IdAndValue[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const eff = async () => {
      if (!props.existingProductId) {
        setExistingProductVersionId(undefined);
        return;
      }

      const product = await props.productService.GetProduct(
        props.existingProductId
      );
      // just get the last product version based on the parent chain.  This could be done on the server, but just doing it here
      // until product decides on requirements that make more sense
      const productVersions =
        await props.productService.QueryProductVersionSummaries({
          productId: product.id,
        });

      const parentToChild = new Set<string>();
      for (const v of productVersions) {
        if (v.parentProductVersionId) {
          parentToChild.add(v.parentProductVersionId);
        }
      }

      const lastVersion = productVersions.find(v => !parentToChild.has(v.id));

      if (!lastVersion) {
        return;
      }

      setExistingProductVersionId(lastVersion.id);
      form.setFieldsValue({
        ...form.getFieldsValue(),
        carrierValueListId: lastVersion.carrierValueListItemId,
        underwritingCompanyValueListItemId:
          lastVersion.underwritingCompanyValueListItemId,
        insuranceTypeValueListItemId:
          lastVersion.lineOfBusinessInsuranceTypeValueListItemId,
        lobNameValueListItemId: lastVersion.lineOfBusinessNameValueListItemId,
        productTypeValueListItemId: lastVersion.productTypeValueListItemId,
        lobDefId: lastVersion.lineOfBusinessDefId,
        stateCode: lastVersion.stateCode,
        effectiveDate: dayjs(lastVersion.effectiveDate),
        description: lastVersion.description,
      });
      setInternalRefreshNum(internalRefreshNum + 1);
    };
    eff();
  }, [props.existingProductId]);

  useEffect(() => {
    const eff = async () => {
      const configured = await props.lobService.GetConfiguredLobDefInfo();
      setLobs(configured);
    };
    eff();
    form.resetFields();
    setUploadedDocumentId('');
  }, [props.refreshFormCount]);

  // whenever the lob is picked, we need to load the appropriate product types
  useEffect(() => {
    const eff = async () => {
      const lobNameValueListItemId = form.getFieldValue(
        'lobNameValueListItemId'
      );
      if (!lobNameValueListItemId || lobNameValueListItemId === '') {
        return;
      }
      const productTypes = await props.valueListService.GetProductTypes(
        lobNameValueListItemId
      );
      setProductTypes(productTypes);
    };
    eff();
  }, [form.getFieldValue('lobNameValueListItemId'), internalRefreshNum]);

  useEffect(() => {
    const eff = async () => {
      const loaded = await props.valueListService.GetCarriers();
      setCarrierValueItems(loaded);
    };
    eff();
  }, []);

  useEffect(() => {
    const eff = async () => {
      const carrierValueListId = form.getFieldsValue().carrierValueListId;
      if (!carrierValueListId || carrierValueListId === '') {
        setUnderwritingCompanyValueItems([]);
        return;
      }
      // we need to know whether the lob requires the form to be set, so we need to load it here
      const loaded = await props.valueListService.GetUnderwritingCompanies(
        form.getFieldsValue().carrierValueListId
      );
      setUnderwritingCompanyValueItems(loaded);
    };
    eff();
  }, [form.getFieldsValue().carrierValueListId, internalRefreshNum]);

  function getCarrierNameOptions(): BaseOptionType[] {
    return carrierValueItems.map(v => ({
      label: v.value,
      value: v.id,
    }));
  }

  function getUnderwritingCompanyOptions(): BaseOptionType[] {
    return underwritingCompanyValueItems.map(v => ({
      label: v.value,
      value: v.id,
    }));
  }

  function getLobCategoryOptions(): BaseOptionType[] {
    // get the unique categories
    const insuranceTypeIds = new Set();
    return lobs
      .map(v => {
        if (insuranceTypeIds.has(v.insuranceTypeValueListItemId)) {
          return;
        }
        insuranceTypeIds.add(v.insuranceTypeValueListItemId);
        return {
          label: v.insuranceType,
          value: v.insuranceTypeValueListItemId,
        };
      })
      .filter(v => v !== undefined) as BaseOptionType[];
  }

  function getLobNameOptions(): BaseOptionType[] {
    const insuranceTypeValueListItemId =
      form.getFieldsValue().insuranceTypeValueListItemId;
    if (insuranceTypeValueListItemId === '') {
      return [];
    }
    const lobNameIds = new Set();
    return lobs
      .filter(
        v => v.insuranceTypeValueListItemId === insuranceTypeValueListItemId
      )
      .map(v => {
        if (lobNameIds.has(v.lobNameValueListItemId)) {
          return;
        }
        lobNameIds.add(v.lobNameValueListItemId);
        return {
          label: v.lobName,
          value: v.lobNameValueListItemId,
        };
      })
      .filter(v => v !== undefined) as BaseOptionType[];
  }

  function getHierarchyNameOptions(): BaseOptionType[] {
    const lobName = form.getFieldsValue().lobNameValueListItemId;
    if (lobName === '') {
      return [];
    }

    const filteredLobs = lobs.filter(v => v.lobNameValueListItemId === lobName);
    const sourceToId = new Map<string, string>();
    for (const l of filteredLobs) {
      sourceToId.set(l.id, l.name);
    }

    const lobIds = lobs
      .filter(v => v.lobNameValueListItemId === lobName)
      .map(v => v.id)
      .sort();

    // label should be hierarchy name, value should be the underlying lob id
    return Array.from(
      new Set(
        lobIds.map(v => ({
          label: sourceToId.get(v),
          value: v,
        }))
      )
    ).sort((a, b) => (a.label as string).localeCompare(b.label as string));
  }
  async function onSave(value: CreateProductFormArgs) {
    if (props.existingProductId) {
      return await onModify(value);
    }
    return await onCreate(value);
  }

  async function onModify(value: CreateProductFormArgs) {
    const updated = await props.productService.UpdateProductAndVersion(
      props.existingProductId ?? '',
      existingProductVersionId ?? '',
      value.underwritingCompanyValueListItemId,
      value.productTypeValueListItemId,
      value.lobDefId,
      value.stateCode,
      DayJsToIsoString(value.effectiveDate),
      value.description
    );
    props.onSaved(updated);
  }

  async function onCreate(value: CreateProductFormArgs) {
    if ((value.importOptions ?? []).find(v => v === 'tables') !== undefined) {
      if (uploadedDocumentId === '') {
        form.setFields([
          {
            name: 'importOptions',
            value: value.importOptions,
            errors: ['Upload a document when an import option is selected'],
          },
        ]);
        return;
      }
    }

    const effectiveDateStr = value.effectiveDate.format('YYYY-MM-DD');
    const product: Product = {
      ...NewTenantAwareEntity(),
      underwritingCompanyValueListItemId:
        value.underwritingCompanyValueListItemId,
      productTypeValueListItemId: value.productTypeValueListItemId,
      lineOfBusinessId: value.lobDefId,
      stateCode: value.stateCode,
      source: 'Adaptify',
    };

    const version: ProductVersion = {
      ...NewTenantAwareEntity(),
      versionNumber: 1,
      productId: product.id,
      parentProductVersionId: undefined,
      source: 'Created',
      status: 'Draft',
      effectiveDate: effectiveDateStr,
      description: value.description,
    };

    const updated = await props.productService.CreateProduct(
      product,
      version,
      [],
      value.importOptions?.find(v => v === 'tables') !== undefined,
      uploadedDocumentId
    );

    // merge values in the form with the existing object
    props.onSaved(updated);

    // navigate to the rating screen
    navigate(`/rating/product/version/${version.id}/table`);
  }

  function onFormValuesChange(
    changedValues: CreateProductFormArgs,
    allValues: CreateProductFormArgs
  ) {
    if (changedValues.insuranceTypeValueListItemId) {
      // reset the dependent list of values
      form.setFieldsValue({
        ...allValues,
        lobNameValueListItemId: '',
        lobDefId: '',
        productTypeValueListItemId: '',
      });

      setInternalRefreshNum(internalRefreshNum + 1);
    }
    if (changedValues.lobNameValueListItemId) {
      form.setFieldsValue({
        ...allValues,
        lobDefId: '',
        productTypeValueListItemId: '',
      });
      setInternalRefreshNum(internalRefreshNum + 1);
    }

    if (changedValues.carrierValueListId) {
      // refresh the underwriting company list
      form.setFieldsValue({
        ...allValues,
        underwritingCompanyValueListItemId: '',
      });
      setInternalRefreshNum(internalRefreshNum + 1);
    }
    if (changedValues.importOptions) {
      // refresh whether the import button is present
      setInternalRefreshNum(internalRefreshNum + 1);
    }
  }

  function getProductTypeOptions(): BaseOptionType[] {
    return productTypes.map(v => ({
      label: v.value,
      value: v.id,
    }));
  }

  function isImportEnabled(value: CreateProductFormArgs): boolean {
    return value.importOptions?.find(v => v === 'tables') !== undefined;
  }

  const productTypeOptions = getProductTypeOptions();
  const productTypeControl =
    productTypeOptions?.length > 0 ? (
      <Form.Item
        name="productTypeValueListItemId"
        label="Policy Form"
        rules={[{required: true}]}
      >
        <Select
          showSearch
          optionFilterProp="label"
          placeholder="select a Line of Business"
          options={productTypeOptions}
        />
      </Form.Item>
    ) : (
      <></>
    );

  return (
    <Form
      layout="vertical"
      size="large"
      name="edit_risk_hierarchy"
      labelCol={{span: 24}}
      wrapperCol={{span: 24}}
      style={{width: '100%'}}
      onValuesChange={onFormValuesChange}
      onFinish={onSave}
      autoComplete="off"
      form={form}
    >
      <Form.Item
        name="carrierValueListId"
        label="Carrier Name"
        rules={[{required: true}]}
      >
        <Select
          showSearch
          optionFilterProp="label"
          placeholder="select a Carrier"
          options={getCarrierNameOptions()}
        />
      </Form.Item>
      <Form.Item
        name="underwritingCompanyValueListItemId"
        label="Underwriting Company"
        rules={[{required: true}]}
      >
        <Select
          showSearch
          optionFilterProp="label"
          placeholder="select an Underwriting Company"
          options={getUnderwritingCompanyOptions()}
        />
      </Form.Item>
      <Row gutter={16}>
        <Col xs={24} lg={12}>
          <Form.Item
            name="insuranceTypeValueListItemId"
            label="Insurance Type"
            shouldUpdate
            rules={[{required: true}]}
          >
            <Select
              showSearch
              optionFilterProp="label"
              placeholder="select an Insurance Type"
              options={getLobCategoryOptions()}
            />
          </Form.Item>
        </Col>
        <Col xs={24} lg={12}>
          <Form.Item
            name="lobNameValueListItemId"
            label="Line of Business"
            shouldUpdate
            rules={[{required: true}]}
          >
            <Select
              showSearch
              optionFilterProp="label"
              placeholder="select a Line of Business"
              options={getLobNameOptions()}
            />
          </Form.Item>
        </Col>
      </Row>

      {productTypeControl}
      <Form.Item
        name="lobDefId"
        label="Hierarchy Name"
        rules={[{required: true}]}
      >
        <Select
          showSearch
          optionFilterProp="label"
          placeholder="select a LOB Hierachy Source"
          options={getHierarchyNameOptions()}
        />
      </Form.Item>
      <Row gutter={16}>
        <Col xs={24} lg={12}>
          <Form.Item name="stateCode" label="State" rules={[{required: true}]}>
            <Select
              showSearch
              optionFilterProp="label"
              placeholder="select a State"
              options={StateCodeOptions}
            />
          </Form.Item>
        </Col>
        <Col xs={24} lg={12}>
          <Form.Item
            name="effectiveDate"
            label="Effective Date"
            rules={[{required: true}]}
          >
            <DatePicker className="w-full" format={DateDataFormats} />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        name="description"
        label="Description"
        rules={[{required: false}]}
      >
        <Input.TextArea />
      </Form.Item>

      <Row gutter={16}>
        <Col xs={24} lg={12}>
          <Form.Item
            layout="horizontal"
            name="importOptions"
            hidden={props.existingProductId !== undefined}
          >
            <Checkbox.Group>
              <Row>
                <Col span={24} className="flex gap-2 items-center">
                  <Checkbox
                    name="importOptions"
                    value="tables"
                    style={{lineHeight: '32px'}}
                  />
                  <label htmlFor="importOptions" className="leading-none">
                    Import Tables
                  </label>
                </Col>
              </Row>
            </Checkbox.Group>
          </Form.Item>
        </Col>
        <Col xs={24} lg={12}>
          <Form.Item
            wrapperCol={{offset: 6}}
            layout="horizontal"
            hidden={!isImportEnabled(form.getFieldsValue())}
          >
            <div style={{textAlign: 'right'}}>
              <Upload
                action={UPLOAD_ANALYSIS_DOCUMENT_URL}
                accept={'application/pdf'}
                showUploadList={true}
                method="put"
                headers={CreateAuthHeader(props.authToken)}
                maxCount={1}
                onChange={info => {
                  if (info.file.status === 'done') {
                    const documentId = info.file.response;
                    setUploadedDocumentId(documentId);
                  }
                }}
              >
                <Button {...ButtonSettings}>Upload File</Button>
              </Upload>
            </div>
          </Form.Item>
        </Col>
      </Row>

      <Flex justify="end" align="flex-start" className="gap-2">
        <Button {...ButtonSettings} htmlType="button" onClick={props.onCancel}>
          Cancel
        </Button>
        <Button ghost={false} type="default" htmlType="submit">
          {props.existingProductId ? 'Modify' : 'Create'}
        </Button>
      </Flex>
    </Form>
  );
}
