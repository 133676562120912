import React from 'react';
import {useParams, useSearchParams} from 'react-router-dom';
import {NavigationAware} from '../../NavigationAware';
import {LobService} from '../../lob/service/LobService';
import {ProductService} from '../../product/service/ProductService';
import {AuthManager} from '../../user/util/AuthManager';
import {RatingService} from '../service/RatingService';
import {ProductVersionCoverageControl} from './ProductVersionCoverageControl';

export interface ProductVersionCoveragePageProps extends NavigationAware {
  lobService: LobService;
  productService: ProductService;
  ratingService: RatingService;
  authToken: string;
  authManager: AuthManager;
}

export function ProductVersionCoveragePage(
  props: ProductVersionCoveragePageProps
) {
  const {productVersionId} = useParams<string>();
  const [searchParams, setSearchParams] = useSearchParams();

  if (typeof productVersionId !== 'string') {
    return <></>;
  }

  function onCoverageSelected(coverageId: string | undefined) {
    if (coverageId) {
      setSearchParams({coverageId: coverageId}, {replace: true});
    }
  }

  return (
    <ProductVersionCoverageControl
      lobService={props.lobService}
      productService={props.productService}
      ratingService={props.ratingService}
      productVersionId={productVersionId as string}
      initialSelectedCoverageId={searchParams.get('coverageId') || undefined}
      authToken={props.authToken}
      onCoverageSelected={onCoverageSelected}
      authManager={props.authManager}
    />
  );
}
