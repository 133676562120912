import {DataGrid, GridColDef, GridRowSelectionModel} from '@mui/x-data-grid';
import {Flex} from 'antd';
import React from 'react';
import {DataGridSettings} from '../../common/control/Common';
import {StreamState} from '../hook/useStream';
import {
  Conversation,
  Message,
  SelectedConversation,
  SendMessageArgs,
} from '../model/Chat';

interface ChatConversationListProps {
  selectedConversation: SelectedConversation;
  setSelectedConversation: React.Dispatch<
    React.SetStateAction<SelectedConversation>
  >;
  loading: boolean;
  conversations: Conversation[];
  setMessages: React.Dispatch<React.SetStateAction<Message[]>>;
  conversationState: StreamState<SendMessageArgs>;
}

const columnDefs: GridColDef<Conversation>[] = [
  {
    field: 'first_message',
    headerName: 'First Message',
    flex: 1,
  },
];

export const ChatConversationList: React.FC<ChatConversationListProps> = ({
  selectedConversation,
  setSelectedConversation,
  loading,
  conversations,
  setMessages,
  conversationState,
}: ChatConversationListProps) => {
  const onConversationSelectionChanged = (selection: GridRowSelectionModel) => {
    if (selection.length > 0) {
      const id = selection[0] as string;
      setSelectedConversation({id, new: false});
    } else {
      conversationState.stopStream();
      setSelectedConversation(null);
      setMessages([]);
    }
  };

  return (
    <Flex vertical className="w-[300px] h-full">
      <h1 className="page-title mb-4">Conversation History</h1>
      <Flex vertical flex={1} className="min-h-0">
        <DataGrid
          {...DataGridSettings}
          loading={loading}
          rows={conversations || []}
          columns={columnDefs}
          rowSelectionModel={
            selectedConversation ? [selectedConversation.id] : []
          }
          onRowSelectionModelChange={onConversationSelectionChanged}
          slots={{columnHeaders: () => null}}
          pageSizeOptions={[12]}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 12,
              },
            },
          }}
          className="ag-theme-quartz"
          sx={{
            '&.MuiDataGrid-root': {
              borderRadius: '8px',
              overflow: 'hidden',
              borderColor: '#CCCCCC',
            },
            '& .MuiDataGrid-cell': {
              border: 0,
            },
          }}
        />
      </Flex>
    </Flex>
  );
};
