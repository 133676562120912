package com.adaptify.rating.admin.model.calculation.descriptor

import com.adaptify.rating.admin.model.calculation.BindingType
import com.adaptify.rating.admin.model.calculation.CalculationFunction
import com.adaptify.rating.admin.model.calculation.binding.Binding
import com.adaptify.rating.admin.model.calculation.context.ScopedVariable
import com.adaptify.rating.admin.model.calculation.env.MetadataProvider
import com.adaptify.rating.admin.model.lob.LineOfBusinessHierarchyItem
import com.adaptify.rating.admin.model.type.PrimitiveDataType
import kotlin.js.JsExport


@JsExport
open class BindingDescriptor(val name :String,
                                 val displayName : String,
                                 val inputOutputType: BindingInputOutputType,
                                 val allowedBindingTypes :  Array<BindingType>,
                                 val allowedPrimitiveDataTypes : Array<PrimitiveDataType>? = null,
                                 val scopedToChildOnly: Boolean = false) {
    @JsExport.Ignore
    open suspend fun getExpectedDataType(
      calculationFunction: CalculationFunction,
      functionDescriptor: FunctionDescriptor,
      bindingDescriptor: BindingDescriptor,
      binding: Binding,
      metadataProvider: MetadataProvider,
      scope : Array<ScopedVariable>
    ): BoundDataType?{
        val dt = allowedPrimitiveDataTypes
        if (dt != null && dt.size == 1) {
            return BoundDataTypeImpl.Primitive(dt[0], false)
        }
        return null
    }

  open fun allowPrimitives(): Boolean {
    return true;
  }

  open fun allowLobItems(): Boolean {
    return false;
  }

  open fun allowMany(): Boolean {
    // many is only allowed right now for roeach binding, particularly as we don't
    // support variables (and therefore we don't support assignment of variables to lists)
    return false;
  }

  open fun getAllowedFunctionTypes(): Array<FunctionType> {
    // only applicable for blocks, it decides which functions
    // are allowed within the function block
    return arrayOf(FunctionType.Function);
  }

  open fun getAllowedConfigurationValues() : Array<ConfigurationValue> {
    return arrayOf();
  }

  open fun isSingleFunctionBlock(): Boolean {
    // only applicable for blocks, decides whether the block
    // should be a single function or many
    return false;
  }

  open fun shouldRenderBlockHeader(): Boolean {
    return true;
  }

  open fun isOptional(): Boolean {
    return false;
  }

  @JsExport.Ignore
  open suspend fun getPreferredPrimitiveDataType(
    calculationFunction: CalculationFunction,
    metadataProvider: MetadataProvider,
    scope: Array<ScopedVariable>
  ): PrimitiveDataType? {
    return null
  }

  companion object {
    val standardInputBindingTypes = arrayOf(BindingType.Path, BindingType.DynamicVariable, BindingType.PrimitiveLiteral)
    val standardOutputBindingTypes = arrayOf(BindingType.DynamicVariableDeclaration, BindingType.Path)

    val stringDataType = arrayOf(PrimitiveDataType.String)
    val numberDataType = arrayOf(PrimitiveDataType.Number)
    val booleanDataType = arrayOf(PrimitiveDataType.Boolean)
    val dateDataType = arrayOf(PrimitiveDataType.Date)
  }

}


@JsExport
interface BoundDataType {
  val primitive: PrimitiveDataType?
  val lobItem: LineOfBusinessHierarchyItem?
  val isMany: Boolean
}

@JsExport
data class ConfigurationValue(val name : Any, val displayName: String)

data class BoundDataTypeImpl(override val primitive: PrimitiveDataType?, override val lobItem: LineOfBusinessHierarchyItem?, override val isMany: Boolean) : BoundDataType {

  companion object {
    fun LobItem(lobItem: LineOfBusinessHierarchyItem, isMany: Boolean): BoundDataType {
      return BoundDataTypeImpl(null, lobItem, isMany)
    }

    fun Primitive(primitive: PrimitiveDataType, isMany: Boolean): BoundDataType {
      return BoundDataTypeImpl(primitive, null, isMany)
    }
  }
}



