import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import {Button, Col, Flex, Row, Select, Typography} from 'antd';
import {BaseOptionType} from 'antd/es/select';
import React, {useEffect, useState} from 'react';
import {ButtonSettings, StateCodeOptions} from '../../common/control/Common';
import {IdAndValue} from '../../valuelist/model/ValueList';
import {ValueListService} from '../../valuelist/service/ValueListService';
import {StudyInfoQuery} from '../model/ComparativeAnalysis';

export interface StudyQueryFormProps {
  valueListService: ValueListService;
  studyInfoQuery: StudyInfoQuery;
  setStudyInfoQuery: (studyInfoQuery: StudyInfoQuery) => void;
  onQuery: () => void;
}

export function StudyQueryForm(props: StudyQueryFormProps) {
  const [insuranceTypes, setInsuranceTypes] = useState<IdAndValue[]>([]);
  const [linesOfBusiness, setLinesOfBusiness] = useState<IdAndValue[]>([]);
  const [productTypes, setProductTypes] = useState<IdAndValue[]>([]);
  const [selectedInsuranceTypeId, setSelectedInsuranceTypeId] = useState<
    string | undefined
  >(props.studyInfoQuery.insuranceType);
  const [selectedLineOfBusinessId, setSelectedLineOfBusinessId] = useState<
    string | undefined
  >(props.studyInfoQuery.insuranceType);
  const [lobName, setLobName] = useState<string | undefined>(
    props.studyInfoQuery.lobName
  );

  useEffect(() => {
    const eff = async () => {
      // we need to know whether the lob requires the form to be set, so we need to load it here
      const loaded = await props.valueListService.GetInsuranceTypes();
      setInsuranceTypes(loaded);
    };
    eff();
  }, []);

  useEffect(() => {
    const eff = async () => {
      // we need to know whether the lob requires the form to be set, so we need to load it here
      const loaded = await props.valueListService.GetLinesOfBusiness(
        selectedInsuranceTypeId
      );
      setLinesOfBusiness(loaded);
    };
    eff();
  }, [selectedInsuranceTypeId]);

  useEffect(() => {
    const eff = async () => {
      // we need to know whether the lob requires the form to be set, so we need to load it here
      const loaded = await props.valueListService.GetProductTypes(
        selectedLineOfBusinessId
      );
      setProductTypes(loaded);
    };
    eff();
  }, [selectedLineOfBusinessId]);

  function getInsuranceTypeOptions(): BaseOptionType[] {
    return insuranceTypes.map(v => ({
      label: v.value,
      value: v.id,
    }));
  }

  function getLineOfBusinessOptions(): BaseOptionType[] {
    return linesOfBusiness.map(v => ({
      label: v.value,
      value: v.id,
    }));
  }

  function getProductTypeOptions(): BaseOptionType[] {
    return productTypes.map(v => ({
      label: v.value,
      value: v.id,
    }));
  }

  function onInsuranceTypeChange(value: string) {
    setSelectedInsuranceTypeId(value);
    props.setStudyInfoQuery({
      ...props.studyInfoQuery,
      insuranceType: insuranceTypes.find(v => v.id === value)?.value,
    });
  }

  function onLineOfBusinessChange(value: string) {
    setSelectedLineOfBusinessId(value);
    props.setStudyInfoQuery({
      ...props.studyInfoQuery,
      lobName: linesOfBusiness.find(v => v.id === value)?.value,
    });
  }

  function onStateCodeChange(value: string) {
    props.setStudyInfoQuery({...props.studyInfoQuery, stateCode: value});
  }

  function onProductTypeChange(value: string) {
    props.setStudyInfoQuery({
      ...props.studyInfoQuery,
      productType: productTypes.find(v => v.id === value)?.value,
    });
  }

  const buttons = (
    <Flex justify="flex-end" align="flex-start">
      <Button
        {...ButtonSettings}
        icon={<SearchOutlinedIcon className="-m-1" />}
        onClick={props.onQuery}
      >
        Search
      </Button>
    </Flex>
  );

  return (
    <Flex vertical>
      <Row gutter={[20, 10]}>
        <Col span={24}>
          <div className="page-title">Carrier Study Lookup</div>
        </Col>
      </Row>
      <div
        style={{
          border: 'solid',
          borderWidth: '1px',
          borderRadius: '8px',
          borderColor: '#CCCCCC',
          backgroundColor: '#F2F7FE',
        }}
      >
        <div
          style={{
            marginLeft: '16px',
            marginRight: '16px',
            marginTop: '16px',
            marginBottom: '16px',
          }}
        >
          <Row gutter={[20, 16]}>
            <Col span={3} style={{textAlign: 'right'}}>
              <Typography.Text>Insurance Type:</Typography.Text>
            </Col>
            <Col span={9}>
              <Select
                showSearch
                allowClear
                optionFilterProp="label"
                options={getInsuranceTypeOptions()}
                onChange={value => onInsuranceTypeChange(value)}
                style={{width: '100%'}}
              ></Select>
            </Col>
            <Col span={3} style={{textAlign: 'right'}}>
              <Typography.Text>Line of Business:</Typography.Text>
            </Col>
            <Col span={9}>
              <Select
                showSearch
                allowClear
                optionFilterProp="label"
                options={getLineOfBusinessOptions()}
                onChange={value => onLineOfBusinessChange(value)}
                style={{width: '100%'}}
              ></Select>
            </Col>
            <Col span={3} style={{textAlign: 'right'}}>
              <Typography.Text>State:</Typography.Text>
            </Col>
            <Col span={3} style={{textAlign: 'right'}}>
              <Select
                showSearch
                allowClear
                optionFilterProp="label"
                options={StateCodeOptions}
                onChange={value => onStateCodeChange(value)}
                style={{width: '100%'}}
              ></Select>
            </Col>
            <Col span={3} style={{textAlign: 'right'}}>
              <Typography.Text>Product Type:</Typography.Text>
            </Col>
            <Col span={3} style={{textAlign: 'right'}}>
              <Select
                showSearch
                allowClear
                optionFilterProp="label"
                options={getProductTypeOptions()}
                onChange={value => onProductTypeChange(value)}
                style={{width: '100%'}}
              ></Select>
            </Col>
            <Col span={12}>
              <Flex justify="flex-end" align="flex-start">
                <Button
                  {...ButtonSettings}
                  icon={<SearchOutlinedIcon className="-m-1" />}
                  onClick={props.onQuery}
                >
                  Search
                </Button>
              </Flex>
            </Col>
          </Row>
        </div>
      </div>
    </Flex>
  );
}
