package com.adaptify.rating.admin.model.calculation.descriptor.lib

import com.adaptify.rating.admin.model.calculation.BindingType
import com.adaptify.rating.admin.model.calculation.CalculationFunction
import com.adaptify.rating.admin.model.calculation.descriptor.BindingDescriptor
import com.adaptify.rating.admin.model.calculation.descriptor.BindingInputOutputType
import com.adaptify.rating.admin.model.calculation.descriptor.FunctionDescriptor
import com.adaptify.rating.admin.model.calculation.descriptor.FunctionType


// Condition functions are only useful in workflows, as they contain a predicate
// but don't set a result or have any side effects, the runtime needs to invoke the predicate
// and then decide whether to move to the next node or not
object ConditionDescriptor : FunctionDescriptor("CONDITION", "Condition", FunctionType.Gate, "Special") {

  val predicateDescriptor = object : BindingDescriptor(
    "predicate", "WHEN", BindingInputOutputType.Input, arrayOf(BindingType.Predicate),
    null
  ) {
    override fun getAllowedFunctionTypes(): Array<FunctionType> {
      return arrayOf(FunctionType.Predicate);
    }
  }

  override fun getBindingDescriptors(
  ): Array<BindingDescriptor> = arrayOf(predicateDescriptor)

  override fun getDisplayTemplate(
  ): String = "WHEN" // blocks have special non-configurable rendering
}
