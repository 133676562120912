import {
  ProductVersion,
  ProductVersionStatus,
} from '../../product/model/Product';
import {UserAuthorities} from '../../user/model/User';
import {AuthManager} from '../../user/util/AuthManager';

export function IsProductVersionReadOnly(
  productVersion: ProductVersion | undefined,
  authManager: AuthManager
): boolean {
  if (!productVersion) {
    return true;
  }
  return (
    productVersion?.status === ProductVersionStatus.Published ||
    (productVersion?.tenantId !== authManager.GetTenantId() &&
      !authManager.HasAuthority(UserAuthorities.CanEditClientRating))
  );
}
