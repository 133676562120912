import {SERVER_BASE_URL} from '../../common/service/Constants';
import {
  ConvertToQueryParams,
  CreateStandardHeaders,
  HandleFetchJson,
} from '../../common/service/Service';
import {
  Tenant,
  TenantHistory,
  TenantInfo,
  TenantModule,
  TenantQuery,
  TenantSummary,
} from '../model/Tenant';

const QUERY_TENANT_URL = (query: TenantQuery) =>
  `${SERVER_BASE_URL}/admin/tenant/query?${ConvertToQueryParams(query)}`;
const GET_TENANT_URL = (id: string) =>
  `${SERVER_BASE_URL}/admin/tenant/get/${id}`;

const GET_TENANT_INFO_URL = (id: string) =>
  `${SERVER_BASE_URL}/admin/tenant/getinfo/${id}`;

const GET_TENANT_MODULES_URL = (id: string) =>
  `${SERVER_BASE_URL}/admin/tenant/module/querybytenantid/${id}`;

const GET_TENANT_MODULE_BY_ID_URL = (id: string) =>
  `${SERVER_BASE_URL}/admin/tenant/module/get/${id}`;

const GET_TENANT_HISTORIES_URL = (id: string) =>
  `${SERVER_BASE_URL}/admin/tenant/history/querybytenantid/${id}`;

const SAVE_TENANT_URL = `${SERVER_BASE_URL}/admin/tenant/update`;
const SAVE_TENANT_MODULE_URL = `${SERVER_BASE_URL}/admin/tenant/module/update`;

export interface TenantService {
  QueryTenants(query: TenantQuery): Promise<TenantSummary[]>;
  GetTenant(id: string): Promise<Tenant>;
  GetTenantInfo(id: string): Promise<TenantInfo>;
  GetTenantModulesByTenantId(id: string): Promise<TenantModule[]>;
  GetTenantModuleById(id: string): Promise<TenantModule>;
  GetTenantHistoriesByTenantId(id: string): Promise<TenantHistory[]>;
  SaveTenant(
    tenant: Tenant,
    states: string[],
    lobIds: string[]
  ): Promise<Tenant>;
  SaveTenantModule(module: TenantModule): Promise<TenantModule>;
}

export default function NewTenantService(authToken: string): TenantService {
  function createHeaders() {
    return CreateStandardHeaders(authToken);
  }

  async function QueryTenants(query: TenantQuery): Promise<TenantSummary[]> {
    const requestOptions = {
      method: 'GET',
      headers: createHeaders(),
    };

    return HandleFetchJson(fetch(QUERY_TENANT_URL(query), requestOptions));
  }

  async function GetTenant(id: string): Promise<Tenant> {
    const requestOptions = {
      method: 'GET',
      headers: createHeaders(),
    };

    return HandleFetchJson(fetch(GET_TENANT_URL(id), requestOptions));
  }

  async function GetTenantInfo(id: string): Promise<TenantInfo> {
    const requestOptions = {
      method: 'GET',
      headers: createHeaders(),
    };

    return HandleFetchJson(fetch(GET_TENANT_INFO_URL(id), requestOptions));
  }

  async function GetTenantModulesByTenantId(
    id: string
  ): Promise<TenantModule[]> {
    const requestOptions = {
      method: 'GET',
      headers: createHeaders(),
    };

    return HandleFetchJson(fetch(GET_TENANT_MODULES_URL(id), requestOptions));
  }

  async function GetTenantModuleById(id: string): Promise<TenantModule> {
    const requestOptions = {
      method: 'GET',
      headers: createHeaders(),
    };

    return HandleFetchJson(
      fetch(GET_TENANT_MODULE_BY_ID_URL(id), requestOptions)
    );
  }

  async function GetTenantHistoriesByTenantId(
    id: string
  ): Promise<TenantHistory[]> {
    const requestOptions = {
      method: 'GET',
      headers: createHeaders(),
    };

    return HandleFetchJson(fetch(GET_TENANT_HISTORIES_URL(id), requestOptions));
  }

  async function SaveTenant(
    tenant: Tenant,
    states: string[],
    lobIds: string[]
  ): Promise<Tenant> {
    const requestOptions = {
      method: 'POST',
      headers: createHeaders(),
      body: JSON.stringify({
        tenant: tenant,
        writtenInStateCodes: states,
        lineOfBusinessValueListItemIds: lobIds,
      }),
    };

    return HandleFetchJson(fetch(SAVE_TENANT_URL, requestOptions));
  }

  async function SaveTenantModule(
    tenantModule: TenantModule
  ): Promise<TenantModule> {
    const requestOptions = {
      method: 'POST',
      headers: createHeaders(),
      body: JSON.stringify(tenantModule),
    };

    return HandleFetchJson(fetch(SAVE_TENANT_MODULE_URL, requestOptions));
  }

  return {
    QueryTenants,
    GetTenant,
    GetTenantInfo,
    GetTenantModulesByTenantId,
    GetTenantModuleById,
    GetTenantHistoriesByTenantId,
    SaveTenant,
    SaveTenantModule,
  };
}
