package com.adaptify.rating.admin.model.calculation.util

import com.adaptify.rating.admin.model.calculation.BindingType
import com.adaptify.rating.admin.model.calculation.binding.Binding
import com.adaptify.rating.admin.model.calculation.binding.ConjunctionType
import com.adaptify.rating.admin.model.calculation.context.CalculationEvalContext
import com.adaptify.rating.admin.model.calculation.descriptor.BindingDescriptor
import com.adaptify.rating.admin.model.calculation.env.MetadataNameProvider
import com.adaptify.rating.admin.model.type.PrimitiveDataType
import kotlinx.datetime.LocalDate
import kotlinx.datetime.format
import kotlinx.datetime.format.*
import kotlin.js.JsExport

@JsExport
object BindingHelper {

  val displayDateFormat = LocalDate.Format {
    monthNumber(padding = Padding.ZERO)
    char('/')
    dayOfMonth(padding = Padding.ZERO)
    char('/')
    year()
  }

  fun GetBindingDisplayString(
    parentFunctionId: String,
    bindingDescriptor: BindingDescriptor,
    binding: Binding,
    nameProvider: MetadataNameProvider,
    context: CalculationEvalContext
  ) : String {
    if (binding.bindingType == null) {
      return ""
    }

    if (binding.bindingType == BindingType.PrimitiveLiteral.name) {
      if (binding.literal?.dataType == PrimitiveDataType.Date.name &&
        binding.literal?.value != null) {
        try {
        return LocalDate.parse(binding.literal?.value!!).format(
          displayDateFormat)
        } catch (e: Exception) {
          return "<Invalid Date>"
        }
      }
      if (binding.literal?.dataType == PrimitiveDataType.Boolean.name &&
        binding.literal?.value != null) {
        return binding.literal?.value!!.uppercase();
      }
      return binding.literal?.value ?: "<Provide Value>";
    }

    val stepMapper = { functionId: String ->
      context.functionContexts[functionId]?.stepName ?: "<Invalid Step>"
    }

    if (binding.bindingType == BindingType.DynamicVariable.name) {
      return stepMapper(binding.dynamicVariableFunctionId ?: "<Invalid Variable>")
    }

    if (binding.bindingType == BindingType.DynamicVariableDeclaration.name) {
      return stepMapper(parentFunctionId)
    }

    if (binding.bindingType == BindingType.Path.name) {
      return binding.path ?: "<Pick Path>"
    }

    if (binding.bindingType == BindingType.VariableDeclaration.name) {
      return binding.variableDeclaration?.name ?: "<Pick Variable Name>"
    }
    if (binding.bindingType == BindingType.TableBinding.name) {
      val tableId = binding.tableBinding?.tableId ?: "<Pick Table>"
      val table = nameProvider.getTableNameById(tableId)
      val tableName = table ?: "<Invalid Table>";
      return tableName;
    }
    if (binding.bindingType == BindingType.TransitionDefinition.name) {
      return binding.transitionDefinition ?: "<Define Transition>"
    }
    if (binding.bindingType == BindingType.Predicate.name) {
      if (binding.predicate?.conjunctionType == null) {
        return "<Define Predicate>"
      }
      return ConjunctionType.valueOf(binding.predicate?.conjunctionType!!).displayName
    }
    if (binding.bindingType == "ConfigurationValue") {
      if (binding.configurationValue == null) {
        return "<Provide Value>"
      }

      val allowedValues = bindingDescriptor.getAllowedConfigurationValues();
      val value = allowedValues.find { it.name == binding.configurationValue }
      return value?.displayName ?: "<Invalid Value>"
    }
    if (binding.bindingType == BindingType.ConfigurationValue.name) {
      if (binding.configurationValue == null) {
        return "<Provide Value>"
      }

      val allowedValues = bindingDescriptor.getAllowedConfigurationValues();
      val value = allowedValues.find { it.name == binding.configurationValue }
      return value?.displayName ?: "<Invalid Value>"
    }

    return ""
  }
}
