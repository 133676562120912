import {ReactFlowProvider} from '@xyflow/react';
import * as kot from 'adaptify-multi-module-rating-admin-model';
import {Button, Col, Flex, Row} from 'antd';
import React, {useEffect, useState} from 'react';
import {useHotkeys} from 'react-hotkeys-hook';
import {v4 as uuidv4} from 'uuid';
import {ButtonSettings} from '../../common/control/Common';
import {LobService} from '../../lob/service/LobService';
import {ProductService} from '../../product/service/ProductService';
import {RatingNavigationBar} from '../control/RatingNavigationBar';
import {ProductVersionFlowControl} from '../control/flow/ProductVersionFlowControl';
import {CreateDefaultNodesAndEdges} from '../model/FlowUtils';
import {RatingService} from '../service/RatingService';
import Flow = kot.com.adaptify.rating.admin.model.flow.Flow;

import {ConfirmationModal} from '../../common/control/ConfirmationModal';
import {DraggableModal} from '../../common/control/DraggableModal';
import {HttpError} from '../../common/service/Service';
import {ProductVersion} from '../../product/model/Product';
import {AuthManager} from '../../user/util/AuthManager';
import {DirtyEditorConfirmationModal} from '../control/DirtyEditorConfirmationModal';
import {ProductVersionSummaryForm} from '../control/ProductVersionSummaryForm';
import {CopyRiskFlowModal} from '../control/flow/CopyRiskFlowModal';
import {EditRiskFlowForm} from '../control/flow/EditRiskFlowForm';
import {RiskFlowSelectionForm} from '../control/flow/RiskFlowSelectionForm';
import {IsProductVersionReadOnly} from './ProductUtils';
export interface ProductVersionRiskFlowControlProps {
  lobService: LobService;
  productService: ProductService;
  ratingService: RatingService;
  productVersionId: string;
  authToken: string;
  initialSelectedRiskId: string | undefined;
  initialSelectedFlowId: string | undefined;
  onFlowSelected?: (
    riskId: string | undefined,
    flowId: string | undefined
  ) => void;
  authManager: AuthManager;
}

interface RiskFlowEditState {
  open: boolean;
  isNew: boolean;
}

interface SelectedFlowConfirmState {
  open: boolean;
  flowId?: string;
  riskId?: string;
}

export function ProductVersionRiskFlowControl(
  props: ProductVersionRiskFlowControlProps
) {
  const [refreshFromServerCount, setRefreshFromServerCount] =
    useState<number>(0);

  const [selectedFlowId, setSelectedFlowId] = useState<string | undefined>(
    props.initialSelectedFlowId
  );

  const [selectedRiskId, setSelectedRiskId] = useState<string | undefined>(
    props.initialSelectedRiskId
  );

  const [riskFlowEditState, setRiskFlowEditState] = useState<RiskFlowEditState>(
    {
      open: false,
      isNew: false,
    }
  );

  const [flow, setFlow] = useState<Flow | undefined>();
  const [productVersion, setProductVersion] = useState<
    ProductVersion | undefined
  >(undefined);

  // TODO limit the size of the stack so it can't grow indefinitely
  const [undoStack, setUndoStack] = useState<Flow[]>([]);
  const [redoStack, setRedoStack] = useState<Flow[]>([]);

  const [selectedFlowConfirmState, setSelectedFlowConfirmState] =
    useState<SelectedFlowConfirmState>({
      open: false,
    });

  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);

  const [copyRiskFlowModalOpen, setCopyRiskFlowModalOpen] = useState(false);

  const [errorMsg, setErrorMsg] = useState<string>();

  useEffect(() => {
    const eff = async () => {
      if (!props.productVersionId) {
        setProductVersion(undefined);
        return;
      }
      const productVersion = await props.productService.GetProductVersion(
        props.productVersionId
      );
      setProductVersion(productVersion);
    };
    eff();
    setErrorMsg(undefined);
  }, [props.productVersionId]);

  useEffect(() => {
    const eff = async () => {
      if (
        props.productVersionId === '' ||
        !selectedFlowId ||
        selectedFlowId === ''
      ) {
        handleNewFlowFromServer(undefined);
        return;
      }
      // if there's no flow, create an empty one
      const loadedFlow =
        await props.productService.GetProductVersionFlow(selectedFlowId);
      handleNewFlowFromServer(loadedFlow);
    };
    eff();
    setErrorMsg(undefined);
  }, [props.productVersionId, selectedFlowId, refreshFromServerCount]);

  async function handleNewFlowFromServer(flow?: Flow) {
    setFlow(flow);
    setUndoStack([]);
    setRedoStack([]);
  }

  function doSetSelectedFlowId(id: string | undefined) {
    setSelectedFlowId(id);
    if (props.onFlowSelected) {
      props.onFlowSelected(selectedRiskId, id);
    }
  }

  function isDirty() {
    return undoStack.length > 0;
  }

  function onFlowLocalChanged(updated: Flow | undefined) {
    if (flow) {
      setUndoStack([...undoStack, flow]);
    }
    setFlow(updated);
  }

  async function saveFlow(flowToSave: Flow): Promise<Flow> {
    if (!flowToSave) {
      return flowToSave;
    }

    const updated =
      await props.productService.UpdateProductVersionFlow(flowToSave);
    // need to go back to the server after saving because the list might need updating
    // so force reload (esp if it's saving the same table)
    setRefreshFromServerCount(refreshFromServerCount + 1);

    // remove the undo stack on save for now, so we don't need to figure out how to manage server conflicts
    setUndoStack([]);
    setRedoStack([]);

    doSetSelectedFlowId(updated.id);
    return updated;
  }

  async function deleteFlow(flowId: string): Promise<void> {
    await props.productService.DeleteProductVersionFlow(flowId);
    // clear out the flow from the selection
    doSetSelectedFlowId(undefined);
    setRefreshFromServerCount(refreshFromServerCount + 1);
  }

  function canUndo() {
    return undoStack.length > 0;
  }

  function canRedo() {
    return redoStack.length > 0;
  }

  function undo() {
    if (undoStack.length === 0) {
      return;
    }
    const last = undoStack[undoStack.length - 1];
    if (flow) {
      setRedoStack([...redoStack, flow]);
    }
    setFlow(last);
    setUndoStack(undoStack.slice(0, undoStack.length - 1));
    // undo pushes an external change to the model this triggers a graph refresh
  }

  function redo() {
    if (redoStack.length === 0) {
      return;
    }
    const last = redoStack[redoStack.length - 1];
    if (flow) {
      setUndoStack([...undoStack, flow]);
    }
    setFlow(last);
    setRedoStack(redoStack.slice(0, redoStack.length - 1));
    // redo pushes an external change to the model this triggers a graph refresh
  }

  function isReadOnly() {
    return IsProductVersionReadOnly(productVersion, props.authManager);
  }

  useHotkeys('mod+s', e => {
    if (isReadOnly()) {
      return;
    }
    e.preventDefault();
    if (flow) {
      saveFlow(flow);
    }
  });

  useHotkeys('mod+z', e => {
    if (isReadOnly()) {
      return;
    }
    e.preventDefault();
    if (canUndo()) {
      undo();
    }
  });

  // mac os
  useHotkeys('mod+shift+z', e => {
    if (isReadOnly()) {
      return;
    }
    e.preventDefault();
    if (canRedo()) {
      redo();
    }
  });

  //windows
  useHotkeys('ctrl+y', e => {
    if (isReadOnly()) {
      return;
    }
    e.preventDefault();
    if (canRedo()) {
      redo();
    }
  });

  function openCreateCoverageDialog() {
    setRiskFlowEditState({open: true, isNew: true});
  }

  function onCreateFlowCancel() {
    setRiskFlowEditState({open: false, isNew: false});
  }

  async function onDeleteFlow() {
    if (!flow) {
      return;
    }

    await deleteFlow(flow.id);
  }

  async function onCreateFlowSave(
    name: string,
    lobRiskItemId: string,
    lobRiskItemTenantId: string
  ) {
    if (riskFlowEditState.isNew) {
      const nodesAndEdges = CreateDefaultNodesAndEdges();
      const newFlow: Flow = {
        id: uuidv4(),
        name: name,
        version: 0,
        edges: nodesAndEdges.edges,
        nodes: nodesAndEdges.nodes,
        productVersionId: props.productVersionId || '',
        lineOfBusinessItemId: lobRiskItemId || '',
        lineOfBusinessItemTenantId: lobRiskItemTenantId || '',
        sequenceAfterChildren: false,
        isDefaultRiskFlow: false,
      };
      const updated = await saveFlow(newFlow);
      setSelectedRiskId(lobRiskItemId);
      doSetSelectedFlowId(updated.id);
      setRiskFlowEditState({open: false, isNew: false});
      setRefreshFromServerCount(refreshFromServerCount + 1);
      return;
    }
    // should this save or update locally?  requirements were not clear
    // current requirements remove edit completely
    if (!flow) {
      return;
    }
    saveFlow({...flow, name: name});
    setRiskFlowEditState({open: false, isNew: false});
  }
  const editButtons = isReadOnly() ? (
    <> </>
  ) : (
    <Flex justify="flex-end" align="flex-start" className="gap-2">
      <Button
        {...ButtonSettings}
        onClick={openCreateCoverageDialog}
        disabled={!props.productVersionId}
      >
        Create New
      </Button>
      <Button
        {...ButtonSettings}
        disabled={!flow || flow.isDefaultRiskFlow}
        onClick={() => setDeleteConfirmOpen(true)}
      >
        Delete
      </Button>
      <Button
        {...ButtonSettings}
        disabled={!flow}
        onClick={onClickCopyFlowButton}
      >
        Copy
      </Button>
    </Flex>
  );

  function onClickCopyFlowButton() {
    if (isDirty()) {
      setSelectedFlowConfirmState({
        open: true,
      });
    } else {
      setCopyRiskFlowModalOpen(true);
    }
  }

  const addFlowModal = riskFlowEditState.open ? (
    <DraggableModal
      className="adaptify-modal"
      open={riskFlowEditState.open}
      closable={false}
      okButtonProps={{style: {display: 'none'}}}
      cancelButtonProps={{style: {display: 'none'}}}
      title="Create Risk Item Calculation"
      width={'clamp(300px, 70svw, 800px)'}
      footer={null}
    >
      <EditRiskFlowForm
        productService={props.productService}
        productVersionId={props.productVersionId}
        initialName={riskFlowEditState.isNew || !flow?.name ? '' : flow.name}
        onSave={onCreateFlowSave}
        onCancel={onCreateFlowCancel}
        isNew={riskFlowEditState.isNew}
      />
    </DraggableModal>
  ) : (
    <> </>
  );

  const navigateConfirmModal = selectedFlowConfirmState.open ? (
    <DirtyEditorConfirmationModal
      open={selectedFlowConfirmState.open}
      skipDialog={!isDirty()}
      onCancel={() => {
        setSelectedFlowConfirmState({open: false});
        // do nothing
      }}
      onConfirm={async () => {
        if (selectedFlowConfirmState.riskId) {
          setSelectedRiskId(selectedFlowConfirmState.riskId);
          setSelectedFlowId(undefined);
        }
        if (selectedFlowConfirmState.flowId) {
          doSetSelectedFlowId(selectedFlowConfirmState.flowId);
        }
        setSelectedFlowConfirmState({open: false});
      }}
    />
  ) : (
    <></>
  );

  const deleteConfirmModal = deleteConfirmOpen ? (
    <ConfirmationModal
      open={deleteConfirmOpen}
      message="Are you sure you would like to delete this Risk Item Calculation?"
      title="Delete Risk Item Calculation"
      onOk={() => {
        onDeleteFlow();
        setDeleteConfirmOpen(false);
      }}
      onCancel={() => setDeleteConfirmOpen(false)}
    />
  ) : (
    <>/</>
  );

  async function onCopyFlow(sourceFlowId: string, newFlowName: string) {
    if (!flow) {
      return;
    }

    const newFlowId = await props.productService
      .CopyProductVersionRiskFlow(sourceFlowId, newFlowName)
      .catch((e: HttpError) => {
        setErrorMsg(e.message);
        return undefined;
      });

    setCopyRiskFlowModalOpen(false);
    if (newFlowId) {
      setRefreshFromServerCount(refreshFromServerCount + 1);
      doSetSelectedFlowId(newFlowId);
    }
  }

  const copyRiskFlowModal = copyRiskFlowModalOpen ? (
    <CopyRiskFlowModal
      sourceFlowId={!flow?.id ? '' : flow.id}
      sourceFlowName={!flow?.name ? '' : flow.name}
      onCopy={onCopyFlow}
      onCancel={() => setCopyRiskFlowModalOpen(false)}
    />
  ) : (
    <>/</>
  );

  return (
    <>
      <b style={{color: 'red'}}>{errorMsg}</b>
      <Flex vertical gap={24}>
        <div style={{height: '100%', width: '100%'}}>
          <ProductVersionSummaryForm
            productService={props.productService}
            productVersionId={props.productVersionId}
            isDirty={isDirty()}
            readOnly={isReadOnly()}
          />
        </div>
        <div style={{minHeight: '46px', width: '100%'}}>
          <RatingNavigationBar
            productVersionId={props.productVersionId}
            isDirty={isDirty()}
            selection="Risk"
          />
        </div>
        <div
          style={{
            border: 'solid',
            borderWidth: '1px',
            borderRadius: '8px',
            borderColor: '#CCCCCC',
            backgroundColor: '#F2F7FE',
          }}
        >
          <div
            style={{
              marginLeft: '16px',
              marginRight: '16px',
              marginTop: '16px',
              marginBottom: '16px',
            }}
          >
            <Row gutter={10}>
              <Col span={16}>
                <RiskFlowSelectionForm
                  productService={props.productService}
                  productVersionId={props.productVersionId}
                  selectedRiskId={selectedRiskId}
                  setSelectedRiskId={id => {
                    if (selectedRiskId === id) {
                      return;
                    }
                    if (!selectedRiskId) {
                      setSelectedRiskId(id);
                      return;
                    }
                    // check if we need to confirm navigation
                    setSelectedFlowConfirmState({
                      open: true,
                      riskId: id,
                    });
                  }}
                  selectedRiskFlowId={selectedFlowId}
                  setSelectedRiskFlowId={id => {
                    if (selectedFlowId === id) {
                      return;
                    }
                    if (!selectedFlowId) {
                      doSetSelectedFlowId(id);
                      return;
                    }

                    // check if we need to confirm navigation
                    setSelectedFlowConfirmState({
                      open: true,
                      flowId: id,
                    });
                  }}
                  modifyCount={refreshFromServerCount}
                />
              </Col>
              <Col span={8}>{editButtons}</Col>
            </Row>
          </div>
        </div>
        <div style={{width: '100%', height: 'clamp(550px, 60svh, 800px)'}}>
          <ReactFlowProvider>
            <ProductVersionFlowControl
              productService={props.productService}
              ratingService={props.ratingService}
              lobService={props.lobService}
              authToken={props.authToken}
              productVersionId={props.productVersionId}
              flow={flow}
              updateFlow={onFlowLocalChanged}
              saveFlow={saveFlow}
              deleteFlow={deleteFlow}
              isDirty={isDirty()}
              canUndo={canUndo()}
              canRedo={canRedo()}
              undo={undo}
              redo={redo}
              refreshFromServerCount={refreshFromServerCount}
              readOnly={isReadOnly()}
            />
          </ReactFlowProvider>
        </div>
      </Flex>
      {addFlowModal}
      {navigateConfirmModal}
      {deleteConfirmModal}
      {copyRiskFlowModal}
    </>
  );
}
