import {App, Button, Flex, Form, Input} from 'antd';
import React, {useState} from 'react';
import {ButtonSettings} from '../../common/control/Common';
import {HTTP_FORBIDDEN} from '../../common/service/Constants';
import {HttpError} from '../../common/service/Service';
import {UserService} from '../service/UserService';

export interface ChangePasswordInfo {
  password: string;
  newPassword: string;
  confirmNewPassword: string;
}

interface ChangePasswordFormProps {
  userService: UserService;
  onSubmit: (info: ChangePasswordInfo) => void;
  onCancel: () => void;
  requireOldPassword?: boolean;
  hideCancel?: boolean;
}

export function ChangePasswordForm(props: ChangePasswordFormProps) {
  const [loginError, setLoginError] = useState('');
  const [form] = Form.useForm();

  const modal = App.useApp().modal;
  async function onFinish(info: ChangePasswordInfo) {
    try {
      await props.userService.ChangePassword(info.password, info.newPassword);
      form.resetFields();
      props.onSubmit(info);
    } catch (e) {
      const error = e as HttpError;
      if (error.statusCode === HTTP_FORBIDDEN) {
        setLoginError('Invalid password');
        form.setFields([
          {
            name: 'password',
            errors: ['Invalid login or password'],
          },
        ]);
      } else {
        modal.error({
          title: 'Internal Error',
          content: e.message + '\n' + (e.stack || ''),
        });
      }
    }
  }

  function onValidateLogin() {
    if (loginError !== '') {
      return Promise.reject(loginError);
    }

    return Promise.resolve(loginError);
  }

  function cancel() {
    form.resetFields();
    props.onCancel();
  }

  function onValidationPasswordConfirm(object, value) {
    if (value !== form.getFieldsValue().newPassword) {
      return Promise.reject('Passwords do not match');
    }

    return Promise.resolve('');
  }

  function onFormFieldsChange() {
    // reset the login message after a failed login
    setLoginError('');
  }

  const buttons = props.hideCancel
    ? []
    : [
        <Button {...ButtonSettings} htmlType="button" onClick={cancel}>
          Cancel
        </Button>,
      ];

  buttons.push(
    <Button {...ButtonSettings} type="primary" htmlType="submit">
      OK
    </Button>
  );

  const passwordForm = props.requireOldPassword ? (
    <Form.Item<ChangePasswordInfo>
      label="Password"
      name="password"
      validateStatus={loginError !== '' ? 'error' : ''}
      rules={[
        {required: true, message: 'Please input your password'},
        {validator: onValidateLogin, validateTrigger: ['onChange']},
      ]}
    >
      <Input.Password />
    </Form.Item>
  ) : (
    <></>
  );

  return (
    <Form
      name="change_password"
      labelCol={{span: 8}}
      wrapperCol={{span: 16}}
      style={{maxWidth: 600}}
      initialValues={{remember: true}}
      onFieldsChange={onFormFieldsChange}
      onFinish={onFinish}
      autoComplete="off"
      form={form}
    >
      {passwordForm}

      <Form.Item<ChangePasswordInfo>
        label="New Password"
        name="newPassword"
        dependencies={['password']}
        validateStatus={loginError !== '' ? 'error' : ''}
        rules={[
          {
            required: true,
            message: 'Please input your new password',
          },
          {
            min: 8,
            message: 'Password should contain at least 8 letters and numbers',
          },
        ]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item<ChangePasswordInfo>
        label="Confirm Password"
        name="confirmNewPassword"
        dependencies={['password']}
        validateStatus={loginError !== '' ? 'error' : ''}
        rules={[
          {
            required: true,
            message: 'Please input your password confirmation',
          },
          {
            validator: onValidationPasswordConfirm,
            validateTrigger: ['onChange'],
          },
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Flex justify="end">{buttons}</Flex>
    </Form>
  );
}
