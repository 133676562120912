import {Flex} from 'antd';
import React, {useState} from 'react';
import {ValueListChildTabControl} from '../control/ValueListChildTabControl';
import {ValueListItemList} from '../control/ValueListItemList';
import {ValueListService} from '../service/ValueListService';

export interface ValueListItemControlProps {
  valueListService: ValueListService;
  valueListDefinitionId: string | undefined;
}
export function ValueListItemControl(props: ValueListItemControlProps) {
  const [selectedItemId, setSelectedItemId] = useState<string | undefined>(
    undefined
  );

  // controlled by the tab control
  const [selectedChildDefinitionId, setSelectedChildDefinitionId] = useState<
    string | undefined
  >(undefined);

  // controlled by the 2nd level item tab
  const [selectedChildItemId, setSelectedChildItemId] = useState<
    string | undefined
  >(undefined);

  const [selectedGrandchildDefinitionId, setSelectedGrandchildDefinitionId] =
    useState<string | undefined>(undefined);

  // controlled by the 2nd level item tab
  const [selectedGrandchildItemId, setSelectedGrandchildItemId] = useState<
    string | undefined
  >(undefined);

  const childListItem = selectedChildDefinitionId ? (
    <ValueListItemList
      valueListService={props.valueListService}
      valueListDefinitionId={selectedChildDefinitionId}
      selectedValueItemId={selectedChildItemId}
      setSelectedValueItemId={setSelectedChildItemId}
      parentValueListItemId={selectedItemId}
      childList
    />
  ) : (
    <></>
  );

  const grandchildTab = selectedChildDefinitionId ? (
    <ValueListChildTabControl
      valueListService={props.valueListService}
      parentValueListDefinitionId={selectedChildDefinitionId}
      valueListDefinitionId={selectedGrandchildDefinitionId}
      setValueListDefinitionId={setSelectedGrandchildDefinitionId}
    />
  ) : (
    <></>
  );

  const grandchildListItem = selectedGrandchildDefinitionId ? (
    <ValueListItemList
      valueListService={props.valueListService}
      valueListDefinitionId={selectedGrandchildDefinitionId}
      selectedValueItemId={selectedGrandchildItemId}
      setSelectedValueItemId={setSelectedGrandchildItemId}
      parentValueListItemId={selectedChildItemId}
      childList
    />
  ) : (
    <></>
  );

  return (
    <Flex vertical gap={24}>
      <ValueListItemList
        valueListService={props.valueListService}
        valueListDefinitionId={props.valueListDefinitionId}
        selectedValueItemId={selectedItemId}
        setSelectedValueItemId={setSelectedItemId}
        parentValueListItemId={undefined}
      />
      <ValueListChildTabControl
        valueListService={props.valueListService}
        parentValueListDefinitionId={props.valueListDefinitionId}
        valueListDefinitionId={selectedChildDefinitionId}
        setValueListDefinitionId={setSelectedChildDefinitionId}
      />
      {childListItem}
      {grandchildTab}
      {grandchildListItem}
    </Flex>
  );
}
