package com.adaptify.rating.admin.model.calculation.descriptor.lib

import com.adaptify.rating.admin.model.calculation.BindingType
import com.adaptify.rating.admin.model.calculation.CalculationFunction
import com.adaptify.rating.admin.model.calculation.descriptor.*
import com.adaptify.rating.admin.model.type.PrimitiveDataType

object RoundUpDescriptor : FunctionDescriptor("ROUND_UP", "ROUND_UP", FunctionType.Function, "Math") {

  val assignToDescriptor = BindingDescriptor(
    "assignTo", "Assign To", BindingInputOutputType.Output, BindingDescriptor.standardOutputBindingTypes,
    BindingDescriptor.numberDataType
  )
  val valueDescriptor = BindingDescriptor(
    "value", "Value", BindingInputOutputType.Input,
    BindingDescriptor.standardInputBindingTypes,
    BindingDescriptor.numberDataType
  )
  val numberOfDigitsDescriptor = object : BindingDescriptor(
    "numberOfDigits", "Number of Digits", BindingInputOutputType.Input,
    arrayOf(BindingType.ConfigurationValue),
    BindingDescriptor.stringDataType


  ) {

    val roundConfigurationValues = arrayOf(
      ConfigurationValue("-3","1000"),
      ConfigurationValue("-2","100"),
      ConfigurationValue("-1","10"),
      ConfigurationValue("0","0"),
    )


    override fun getAllowedConfigurationValues(): Array<ConfigurationValue> =
      roundConfigurationValues
  }

  override fun getBindingDescriptors(
  ): Array<BindingDescriptor> = arrayOf(assignToDescriptor, valueDescriptor, numberOfDigitsDescriptor)

  override fun getDisplayTemplate(
  ): String = "\$assignTo = ROUND_UP(\$value, \$numberOfDigits)"
}
