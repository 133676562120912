import {Flex} from 'antd';
import React, {useEffect, useState} from 'react';
import {LobCoverageFieldList} from '../control/LobCoverageFieldList';
import {LobSummaryControl} from '../control/LobSummaryControl';
import {LobStatus} from '../model/LineOfBusiness';
import {LobService} from '../service/LobService';

export interface LobCoverageFieldControlProps {
  lobHierarchyId: string | undefined;
  lobService: LobService;
  initialCoverageId: string | undefined;
  onSetLobItemId: (lobItemId: string | undefined) => void;
  currentTenantId: string;
}

export function LobCoverageFieldControl(props: LobCoverageFieldControlProps) {
  const [selectedLobItemId, setSelectedLobItemId] = useState<
    string | undefined
  >(props.initialCoverageId);
  const [lobTenantId, setLobTenantId] = useState<string | undefined>(undefined);
  const [isPublished, setIsPublished] = useState<boolean>(false);

  function onSetSelectedCoverageId(lobItemId: string | undefined) {
    setSelectedLobItemId(lobItemId);
    props.onSetLobItemId(lobItemId);
  }

  useEffect(() => {
    if (!props.lobHierarchyId) {
      return;
    }
    const eff = async () => {
      const lob = await props.lobService.GetLineOfBusiness(
        props.lobHierarchyId ?? ''
      );
      setLobTenantId(lob?.tenantId);
      setIsPublished(lob.status === LobStatus.Published);
    };
    eff();
  }, [props.lobHierarchyId, props.currentTenantId]);

  const readOnly = lobTenantId !== props.currentTenantId;

  return (
    <Flex vertical gap={24}>
      <LobSummaryControl
        lobService={props.lobService}
        lobHierarchyId={props.lobHierarchyId}
      />
      <LobCoverageFieldList
        lobService={props.lobService}
        lobHierarchyId={props.lobHierarchyId}
        selectedCoverageId={selectedLobItemId}
        setSelectedCoverageId={onSetSelectedCoverageId}
        existingItemsReadOnly={isPublished}
        readOnly={readOnly}
      />
    </Flex>
  );
}
