import {Form, Input} from 'antd';
import React, {useEffect} from 'react';
import {ButtonSettings} from '../../../common/control/Common';
import {DraggableModal} from '../../../common/control/DraggableModal';

export interface CopyRiskFlowModalProps {
  sourceFlowId: string;
  sourceFlowName: string;

  onCopy: (sourceFlowId: string, newFlowName: string) => void;
  onCancel: () => void;
}

interface CopyRiskFlowFormValues {
  newFlowName: string;
}

export function CopyRiskFlowModal(props: CopyRiskFlowModalProps) {
  const [form] = Form.useForm<CopyRiskFlowFormValues>();

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue({newFlowName: props.sourceFlowName + ' Copy'});
  }, [props.sourceFlowName]);

  function onCopy(values: CopyRiskFlowFormValues) {
    props.onCopy(props.sourceFlowId, values.newFlowName);
  }

  return (
    <DraggableModal
      className="adaptify-modal"
      width={'clamp(300px, 70svw, 1200px)'}
      open={true}
      title={'Copy Risk Flow'}
      onCancel={props.onCancel}
      cancelButtonProps={{...ButtonSettings}}
      okButtonProps={{...ButtonSettings, ghost: false, type: 'default'}}
      onOk={form.submit}
    >
      <Form
        name="copy_flow_name"
        labelWrap={true}
        layout="vertical"
        size="large"
        labelCol={{span: 24}}
        wrapperCol={{span: 24}}
        onFinish={onCopy}
        autoComplete="off"
        form={form}
      >
        <Form.Item
          name="newFlowName"
          label="New Flow Name"
          rules={[{required: true}]}
        >
          <Input></Input>
        </Form.Item>
      </Form>
    </DraggableModal>
  );
}
