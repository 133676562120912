import {BaseOptionType} from 'antd/es/select';
import {SystemFieldsEntity, TenantAwareEntity} from '../../common/model/Common';

export type AuthToken = string;

export interface Tenant extends SystemFieldsEntity {
  name: string;
  accountNumber?: string | undefined;
  accountType: string;
  address: string;
  url: string | undefined;
  description?: string | undefined;
  directWrittenPremium?: string | undefined;
  primaryContactName: string;
  primaryContactJobTitle: string;
  primaryContactEmail: string;
  primaryContactPhone: string;
}

export interface TenantQuery {
  id?: string | undefined;
  name?: string | undefined;
  accountType?: string | undefined;
  accountNumber?: string | undefined;
}

export interface TenantSummary {
  id: string;
  name: string;
  accountType: string;
  accountNumber: string;
}

export interface TenantInfo {
  id: string;
  version: number;
  name: string;
  accountType: string;
  accountNumber: string;
  address: string;
  url: string;
  description: string;
  directWrittenPremium: string;
  primaryContactName: string;
  primaryContactJobTitle: string;
  primaryContactEmail: string;
  primaryContactPhone: string;
  statesWrittenIn: string[];
  lineOfBusinessValueListItemIds: IdAndName[];
}

export interface IdAndName {
  id: string;
  name: string;
}

export interface TenantModule extends TenantAwareEntity {
  moduleName: string;
  endDate?: string | undefined;
}

export interface TenantHistory extends TenantAwareEntity {
  description: string;
}

export const ClientTypeOptions: BaseOptionType[] = [
  {label: 'Adaptify', value: 'Adaptify'},
  {label: 'Broker', value: 'Broker'},
  {label: 'Carrier', value: 'Carrier'},
];

export const WrittenByStateCodeOptions: BaseOptionType[] = [
  {label: 'All', value: 'All'},
  {label: 'Alabama', value: 'AL'},
  {label: 'Alaska', value: 'AK'},
  {label: 'Arizona', value: 'AZ'},
  {label: 'Arkansas', value: 'AR'},
  {label: 'California', value: 'CA'},
  {label: 'Colorado', value: 'CO'},
  {label: 'Connecticut', value: 'CT'},
  {label: 'Delaware', value: 'DE'},
  {label: 'Florida', value: 'FL'},
  {label: 'Georgia', value: 'GA'},
  {label: 'Hawaii', value: 'HI'},
  {label: 'Idaho', value: 'ID'},
  {label: 'Illinois', value: 'IL'},
  {label: 'Indiana', value: 'IN'},
  {label: 'Iowa', value: 'IA'},
  {label: 'Kansas', value: 'KS'},
  {label: 'Kentucky', value: 'KY'},
  {label: 'Louisiana', value: 'LA'},
  {label: 'Maine', value: 'ME'},
  {label: 'Maryland', value: 'MD'},
  {label: 'Massachusetts', value: 'MA'},
  {label: 'Michigan', value: 'MI'},
  {label: 'Minnesota', value: 'MN'},
  {label: 'Mississippi', value: 'MS'},
  {label: 'Missouri', value: 'MO'},
  {label: 'Montana', value: 'MT'},
  {label: 'Nebraska', value: 'NE'},
  {label: 'Nevada', value: 'NV'},
  {label: 'New Hampshire', value: 'NH'},
  {label: 'New Jersey', value: 'NJ'},
  {label: 'New Mexico', value: 'NM'},
  {label: 'New York', value: 'NY'},
  {label: 'North Carolina', value: 'NC'},
  {label: 'North Dakota', value: 'ND'},
  {label: 'Ohio', value: 'OH'},
  {label: 'Oklahoma', value: 'OK'},
  {label: 'Oregon', value: 'OR'},
  {label: 'Pennsylvania', value: 'PA'},
  {label: 'Rhode Island', value: 'RI'},
  {label: 'South Carolina', value: 'SC'},
  {label: 'South Dakota', value: 'SD'},
  {label: 'Tennessee', value: 'TN'},
  {label: 'Texas', value: 'TX'},
  {label: 'Utah', value: 'UT'},
  {label: 'Vermont', value: 'VT'},
  {label: 'Virginia', value: 'VA'},
  {label: 'Washington', value: 'WA'},
  {label: 'West Virginia', value: 'WV'},
  {label: 'Wisconsin', value: 'WI'},
  {label: 'Wyoming', value: 'WY'},
];

export enum TenantModuleName {
  RateProductLibrary = 'Rate Product Library',
  Lists = 'Lists',
  ComparativeAnalysis = 'Comparative Analysis',
  RateProductAssistant = 'Rate Product Assistant',
  AccountProvisioning = 'Account Provisioning',
  TrialAccounts = 'Trial Accounts',
  ClientRateProductLookup = 'Client Rate Product Lookup',
}

export const TenantModuleNameOptions: BaseOptionType[] = [
  {
    label: '',
    value: '',
  },
  {
    label: TenantModuleName.RateProductLibrary,
    value: TenantModuleName.RateProductLibrary,
  },
  {label: TenantModuleName.Lists, value: TenantModuleName.Lists},
  {
    label: TenantModuleName.ComparativeAnalysis,
    value: TenantModuleName.ComparativeAnalysis,
  },
  {
    label: TenantModuleName.RateProductAssistant,
    value: TenantModuleName.RateProductAssistant,
  },
  {
    label: TenantModuleName.AccountProvisioning,
    value: TenantModuleName.AccountProvisioning,
  },
  {
    label: TenantModuleName.TrialAccounts,
    value: TenantModuleName.TrialAccounts,
  },
];
