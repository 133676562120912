package com.adaptify.rating.admin.model

import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import kotlin.js.JsExport

expect fun generateUUID(): String;

expect fun <T> modelToJson(obj : T): String

expect fun <T> modelArrayToJson(util : ModelUtil<T>, obj : Array<T>): String
expect fun <T> jsonToModel(util : ModelUtil<T>, str : String): T

expect fun <T> jsonToModelArray(util : ModelUtil<T>, str : String) : Array<T>

expect fun jsonToStringArrayArray(str : String) : Array<Array<String?>>

interface ModelUtil<T> {
    fun deepCopy(obj : T): T
    fun deepCopyOpt(obj : T?): T? {
        return obj?.let { deepCopy(it) }
    }
    fun toJson(obj : Any): String {
      return modelToJson(obj);
    }

    fun fromJson(json : String): T {
        return jsonToModel(this, json)
    }

  fun toJsonArray(obj : Array<T>): String {
    return modelArrayToJson(this, obj)
  }

  fun fromJsonArray(str : String) : Array<T> {
    return jsonToModelArray(this, str)
  }

  fun fromJsonStringArrayArray(str : String) : Array<Array<String?>> {
    return jsonToStringArrayArray(str);
  }
}
