import {Button, Col, Flex, Input, Row, Typography} from 'antd';
import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';

import {ButtonSettings} from '../../common/control/Common';
import {LobHierarchyInfo} from '../model/LineOfBusiness';
import {LobService} from '../service/LobService';

export interface LobSummaryControlProps {
  lobService: LobService;
  lobHierarchyId: string | undefined;
  supressRiskItemsAndCoverageNavigation?: boolean;
}

export function LobSummaryControl(props: LobSummaryControlProps) {
  const [lobHierarchyInfo, setLobHierarchyInfo] = useState<
    LobHierarchyInfo | undefined
  >(undefined);

  useEffect(() => {
    const eff = async () => {
      if (!props.lobHierarchyId) {
        setLobHierarchyInfo(undefined);
        return;
      }
      const loaded = await props.lobService.QueryLinesOfBusinesses({
        id: props.lobHierarchyId,
      });
      if (!loaded || loaded.length === 0) {
        setLobHierarchyInfo(undefined);
        return;
      }
      setLobHierarchyInfo(loaded[0]);
    };
    eff();
  }, [props.lobHierarchyId]);

  const readOnlyStyle = {backgroundColor: '#F0F0F0'};

  const riskItemNav = props.supressRiskItemsAndCoverageNavigation ? (
    <></>
  ) : (
    <Link to={`/platform/lob/${props.lobHierarchyId}/risk/`}>
      <Button {...ButtonSettings}>Risk Items and Coverages</Button>
    </Link>
  );

  return (
    <Flex vertical style={{width: '100%', height: '100%'}}>
      <Row>
        <Col span={18}>
          <div className="page-title">LOB Hierarchy</div>
        </Col>
        <Col span={6}>
          <Flex align="end" justify="end" className="gap-2">
            <Link to={'/platform/lob/hierarchy'}>
              <Button {...ButtonSettings}>Hierarchy Summary</Button>
            </Link>
            {riskItemNav}
          </Flex>
        </Col>
      </Row>
      <div
        style={{
          border: 'solid',
          borderWidth: '1px',
          borderRadius: '8px',
          borderColor: '#CCCCCC',
          backgroundColor: '#F2F7FE',
        }}
      >
        <div
          style={{
            marginLeft: '16px',
            marginRight: '16px',
            marginTop: '16px',
            marginBottom: '16px',
          }}
        >
          <Row gutter={[20, 16]}>
            <Col span={3} style={{textAlign: 'right'}}>
              <Typography.Text>Insurance Type:</Typography.Text>
            </Col>
            <Col span={9}>
              <Input
                readOnly
                style={readOnlyStyle}
                value={lobHierarchyInfo?.insuranceType}
              />
            </Col>
            <Col span={3} style={{textAlign: 'right'}}>
              <Typography.Text>Line of Business:</Typography.Text>
            </Col>
            <Col span={9}>
              <Input
                readOnly
                style={readOnlyStyle}
                value={lobHierarchyInfo?.lobName}
              />
            </Col>
            <Col span={3} style={{textAlign: 'right'}}>
              <Typography.Text>Hierarchy Source:</Typography.Text>
            </Col>
            <Col span={9}>
              <Input
                readOnly
                style={readOnlyStyle}
                value={lobHierarchyInfo?.hierarchySource}
              />
            </Col>
            <Col span={3} style={{textAlign: 'right'}}>
              <Typography.Text>Hierarchy Name:</Typography.Text>
            </Col>
            <Col span={9}>
              <Input
                readOnly
                style={readOnlyStyle}
                value={lobHierarchyInfo?.name}
              />
            </Col>
          </Row>
        </div>
      </div>
    </Flex>
  );
}
