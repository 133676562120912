import {Typography} from 'antd';
import React from 'react';

import * as kot from 'adaptify-multi-module-rating-admin-model';
import {NodeLog} from '../../model/Rating';
import {FunctionLogsControl} from './FunctionLogsControl';
import {TableLogControl} from './TableLogControl';
import Table = kot.com.adaptify.rating.admin.model.table.Table;
import TestCase = kot.com.adaptify.rating.admin.model.testcase.TestCase;
import LineOfBusinessHierarchy = kot.com.adaptify.rating.admin.model.lob.LineOfBusinessHierarchy;
import TestCaseLobItem = kot.com.adaptify.rating.admin.model.testcase.TestCaseLobItem;
import NodeType = kot.com.adaptify.rating.admin.model.flow.NodeType;

export interface NodeLogControlProps {
  nodeLog: NodeLog;
}

function createChildControl(nodeLog: NodeLog) {
  switch (nodeLog.nodeType) {
    case NodeType.Table.name:
      if (nodeLog.table) {
        return <TableLogControl tableLog={nodeLog.table} />;
      }
      return <></>;
    case NodeType.Calculation.name:
    case NodeType.Branch.name:
      return <FunctionLogsControl functionLog={nodeLog.functions} />;
    default:
      return <></>;
  }
}

export function NodeLogControl(props: NodeLogControlProps) {
  const errorLog = props.nodeLog.error ? (
    <Typography.Title level={3} style={{color: 'red'}}>
      {props.nodeLog.error}
    </Typography.Title>
  ) : (
    <></>
  );

  return (
    <div style={{height: '100%', width: '100%'}}>
      <Typography.Title level={2} style={{color: 'black'}}>
        {props.nodeLog.nodeType} - {props.nodeLog.nodeName}
      </Typography.Title>
      {errorLog}
      {createChildControl(props.nodeLog)}
    </div>
  );
}
