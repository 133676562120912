package com.adaptify.rating.admin.model.calculation.binding

import com.adaptify.rating.admin.model.ModelUtil
import com.adaptify.rating.admin.model.calculation.CalculationFunction
import com.adaptify.rating.admin.model.calculation.CalculationFunctionUtil
import kotlinx.serialization.Serializable
import kotlin.js.JsExport

expect interface Predicate {
    var conjunctionType: String
    var functions: Array<CalculationFunction>
}

expect fun createPredicate(conjunctionType: String, functions: Array<CalculationFunction>): Predicate

@Serializable
class PredicateImpl(override var conjunctionType: String,
                            override var functions: Array<CalculationFunction>) : Predicate

object PredicateUtil : ModelUtil<Predicate> {
    fun create(conjunctionType: String, functions: Array<CalculationFunction>): Predicate {
        return createPredicate(conjunctionType, functions)
    }

    override fun deepCopy(obj: Predicate): Predicate {
        return create(obj.conjunctionType, obj.functions.map { CalculationFunctionUtil.deepCopy(it) }.toTypedArray())
    }
}
