export interface ErrorDetails {
  message: string;
  details: string;
}

export class HttpError extends Error {
  statusCode: number;
  fullMessage: string;
  details: ErrorDetails | undefined;

  constructor(statusCode: number, fullMessage: string, details?: ErrorDetails) {
    super(fullMessage);
    this.statusCode = statusCode;
    this.fullMessage = fullMessage;
    this.details = details;
  }
}

export async function HandleFetchJson<T>(
  responsePromise: Promise<Response>
): Promise<T> {
  const response = await responsePromise;
  if (!response.ok) {
    return handleErrorResponse(response);
  }
  return (await response.json()) as T;
}

export async function HandleFetchText(
  responsePromise: Promise<Response>
): Promise<string> {
  const response = await responsePromise;
  if (!response.ok) {
    return handleErrorResponse(response);
  }
  return await response.text();
}

export async function HandleFetchVoid(
  responsePromise: Promise<Response>
): Promise<void> {
  const response = await responsePromise;
  if (!response.ok) {
    return handleErrorResponse(response);
  }
}

async function handleErrorResponse(response: Response) {
  const textResponse = await response.text();
  let jsonResponse: any | undefined = undefined;
  try {
    jsonResponse = JSON.parse(textResponse);
  } catch (e) {
    // try to parse as json, but ignor if we can't
  }
  const transformedJsonResponse = {
    message: jsonResponse?.message ?? jsonResponse?.exception ?? textResponse,
    details: jsonResponse?.details ?? jsonResponse?.stackTrace,
  };
  return Promise.reject(
    new HttpError(response.status, textResponse, transformedJsonResponse)
  );
}

export function CreateStandardHeaders(authToken: string) {
  return {...CreateAuthHeader(authToken), 'Content-Type': 'application/json'};
}

export function CreateAuthHeader(authToken: string) {
  return {
    Authorization: 'Bearer ' + authToken,
  };
}

export function ConvertToQueryParams(params: any): string {
  return Object.keys(params)
    .filter(key => params[key] !== undefined)
    .map(key => key + '=' + encodeURI(params[key]))
    .join('&');
}
