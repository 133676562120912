import '@ag-grid-community/styles/ag-grid.css'; // Core CSS
import '@ag-grid-community/styles/ag-theme-quartz.css';
import {Flex, Typography} from 'antd';
import React, {useEffect, useState} from 'react';
import Markdown from 'react-markdown';
import {ProductVersionTableMetadataSummary} from '../../product/model/Product';
import {ProductService} from '../../product/service/ProductService';

export interface TableDocumentMetadataDetailProps {
  productService: ProductService;
  productVersionTableId: string | undefined;
}

export function TableDocumentMetadataDetail(
  props: TableDocumentMetadataDetailProps
) {
  const [tableMetadata, setTableMetadata] = useState<
    ProductVersionTableMetadataSummary[]
  >([]);

  useEffect(() => {
    const eff = async () => {
      if (!props.productVersionTableId || props.productVersionTableId === '') {
        setTableMetadata([]);
        return;
      }

      const loaded =
        await props.productService.GetProductVersionTableDocumentTableMetadataByProductVersionTableId(
          props.productVersionTableId
        );
      setTableMetadata(loaded);
    };
    eff();
  }, [props.productVersionTableId]);

  const text = tableMetadata.reduce((text, meta) => {
    return text + meta.documentTableSummary;
  }, '');
  if (text.length === 0) {
    return <> </>;
  }
  return (
    <div style={{width: '100%'}}>
      <Flex vertical>
        <Typography.Text strong>
          Rules and Relevant Text around Tables
        </Typography.Text>
        <Markdown>{text}</Markdown>
      </Flex>
    </div>
  );
}
