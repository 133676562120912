import {Flex} from 'antd';
import React, {useEffect} from 'react';
import {ADAPTIFY_TENANT_ID} from '../../common/service/Constants';
import {ValueListService} from '../../valuelist/service/ValueListService';
import {TenantList} from '../control/TenantList';
import {TenantQueryForm} from '../control/TenantQuery';
import {TenantQuery, TenantSummary} from '../model/Tenant';
import {TenantService} from '../service/TenantService';
import {UserService} from '../service/UserService';

export interface TenantSummaryControlProps {
  tenantService: TenantService;
  valueListService: ValueListService;
  userService: UserService;
  userTenantId: string | undefined;
}

export function TenantSummaryControl(props: TenantSummaryControlProps) {
  const [selectedTenantId, setSelectedTenantId] = React.useState<
    string | undefined
  >(undefined);
  const [tenantQuery, setTenantQuery] = React.useState<TenantQuery>(
    {} as TenantQuery
  );
  const [tenants, setTenants] = React.useState<TenantSummary[]>([]);
  const [modifyCount, setModifyCount] = React.useState<number>(0);

  useEffect(() => {
    onQueryTenant();
  }, [modifyCount]);

  async function onQueryTenant() {
    const loaded = await props.tenantService.QueryTenants(tenantQuery);
    setTenants(loaded);
  }

  const isAdaptify = ADAPTIFY_TENANT_ID === props.userTenantId;

  const queryPanel = isAdaptify ? (
    <TenantQueryForm
      tenantService={props.tenantService}
      tenantQuery={tenantQuery}
      setTenantQuery={setTenantQuery}
      onQuery={onQueryTenant}
    />
  ) : (
    <></>
  );

  return (
    <Flex vertical gap={24}>
      {queryPanel}
      <TenantList
        tenantService={props.tenantService}
        valueListService={props.valueListService}
        tenants={tenants}
        selectedTenantId={selectedTenantId}
        setSelectedTenantId={setSelectedTenantId}
        modifyCount={modifyCount}
        setModifyCount={setModifyCount}
        readOnly={!isAdaptify}
      />
    </Flex>
  );
}
