import {useCallback, useEffect, useRef, useState} from 'react';
import {StreamOrchestrator} from '../model/Chat';

export interface StreamState<T> {
  startStream: (args: T) => void;
  stopStream: () => void;
  isLoading: boolean;
  error: Error | null;
  isActive: boolean;
}

export interface UseStreamOptions {
  onMessage: (event: string, message: any) => void;
  onError: (error: Error) => void;
  onClose?: () => void;
}

export const useStream = <T>(
  orchestrator: StreamOrchestrator<T>,
  options: UseStreamOptions
): StreamState<T> => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const controllerRef = useRef<AbortController | null>(null);
  const optionsRef = useRef(options);
  const stoppedDuringCallbackRef = useRef(false);

  optionsRef.current = options;

  useEffect(() => {
    return () => {
      if (controllerRef.current) {
        controllerRef.current.abort();
        controllerRef.current = null;
      }
    };
  }, []);

  const stopStream = useCallback(() => {
    if (controllerRef.current) {
      controllerRef.current.abort();
      controllerRef.current = null;
      setIsLoading(false);
      stoppedDuringCallbackRef.current = true;
    }
  }, []);

  const startStream = useCallback(
    async (args: T) => {
      if (controllerRef.current) {
        controllerRef.current.abort();
      }

      setError(null);
      setIsLoading(true);
      stoppedDuringCallbackRef.current = false;

      const newController = new AbortController();
      controllerRef.current = newController;

      try {
        await orchestrator(
          args,
          {
            onMessage: (event: string, data: any) => {
              if (!newController.signal.aborted) {
                optionsRef.current.onMessage?.(event, data);
                if (stoppedDuringCallbackRef.current) {
                  newController.abort();
                  setIsLoading(false);
                }
              }
            },
            onClose: () => {
              if (!newController.signal.aborted) {
                optionsRef.current.onClose?.();
                if (!stoppedDuringCallbackRef.current) {
                  setIsLoading(false);
                  controllerRef.current = null;
                }
              }
            },
            onError: (err: Error) => {
              if (!newController.signal.aborted) {
                setError(err);
                optionsRef.current.onError?.(err);
                if (!stoppedDuringCallbackRef.current) {
                  setIsLoading(false);
                  controllerRef.current = null;
                }
              }
            },
          },
          newController
        );
      } catch (err) {
        if (!newController.signal.aborted && err instanceof Error) {
          setError(err);
          optionsRef.current.onError?.(err);
        }
      } finally {
        if (
          !newController.signal.aborted &&
          !stoppedDuringCallbackRef.current
        ) {
          setIsLoading(false);
          controllerRef.current = null;
        }
      }
    },
    [orchestrator]
  );

  return {
    startStream,
    stopStream,
    isLoading,
    error,
    isActive: !!controllerRef.current,
  };
};
