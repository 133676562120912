package com.adaptify.rating.admin.model.calculation.binding

@JsExport
external actual interface TableBinding {
    actual var tableId: String?
    actual var inputOverrides: Array<KeyedInputEntry>?
    actual var resultColumn: String?
}

actual fun createTableBinding(
  tableId: String?,
  inputOverrides: Array<KeyedInputEntry>?,
  resultColumn: String?,
): TableBinding {
  return js("({tableId: tableId, inputOverrides: inputOverrides, resultColumn: resultColumn})")
}

