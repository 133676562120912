import React from 'react';
import {useParams, useSearchParams} from 'react-router-dom';
import {NavigationAware} from '../../NavigationAware';
import {LobService} from '../service/LobService';
import {LobRiskFieldControl} from './LobRiskFieldControl';

type LobRiskPageParams = {
  lobHierarchyId: string;
};

export interface LobRiskFieldPageProps extends NavigationAware {
  lobService: LobService;
  currentTenantId: string | undefined;
}
export function LobRiskFieldPage(props: LobRiskFieldPageProps) {
  const {lobHierarchyId} = useParams<LobRiskPageParams>();

  const [searchParams, setSearchParams] = useSearchParams();
  const initialLobItemId = searchParams.get('lobItemId');

  function onSetLobItemId(lobItemId: string | undefined) {
    if (lobItemId) {
      setSearchParams({lobItemId: lobItemId}, {replace: true});
    }
  }

  return (
    <LobRiskFieldControl
      lobService={props.lobService}
      lobHierarchyId={lobHierarchyId}
      initialLobItemId={initialLobItemId ?? undefined}
      onSetLobItemId={onSetLobItemId}
      currentTenantId={props.currentTenantId}
    />
  );
}
