package com.adaptify.rating.admin.model.calculation.binding

import com.adaptify.rating.admin.model.ModelUtil
import com.adaptify.rating.admin.model.impl.util.DeepCopyable
import kotlinx.serialization.DeserializationStrategy
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.JsonContentPolymorphicSerializer
import kotlinx.serialization.json.JsonElement
import kotlin.js.JsExport

expect interface TableBinding {
    var tableId: String?

    var inputOverrides: Array<KeyedInputEntry>?  // this will replace match columns after migrating old data
    var resultColumn: String?
}

expect fun createTableBinding(
    tableId: String? = null,
    inputOverrides: Array<KeyedInputEntry>? = null,
    resultColumn: String? = null,
): TableBinding

@Serializable
class TableBindingImpl(
    override var tableId: String? = null,
    override var inputOverrides: Array<KeyedInputEntry>? = null,
    override var resultColumn: String? = null,
) : TableBinding

object TableBindingUtil : ModelUtil<TableBinding> {
    fun create(
        tableId: String? = null,
        inputOverrides: Array<KeyedInputEntry>? = null,
        resultColumn: String? = null,
    ): TableBinding {
        return createTableBinding(tableId, inputOverrides, resultColumn)
    }

    override fun deepCopy(obj: TableBinding): TableBinding {
        return create(
            obj.tableId,
            obj.inputOverrides?.map { KeyedInputEntryUtil.deepCopy(it) }?.toTypedArray(),
            obj.resultColumn,
        )
    }
}
