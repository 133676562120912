package com.adaptify.rating.admin.model.util

import com.adaptify.rating.admin.model.calculation.Calculation
import com.adaptify.rating.admin.model.calculation.CalculationFunction
import com.adaptify.rating.admin.model.calculation.context.CalculationEvalContext
import com.adaptify.rating.admin.model.calculation.context.ScopedVariable
import com.adaptify.rating.admin.model.calculation.descriptor.BindingDescriptor
import com.adaptify.rating.admin.model.calculation.validation.CalculationValidator
import com.adaptify.rating.admin.model.calculation.validation.FlowValidationResult
import com.adaptify.rating.admin.model.calculation.validation.FlowValidator
import com.adaptify.rating.admin.model.calculation.validation.FunctionValidationMessage
import com.adaptify.rating.admin.model.flow.Flow
import com.adaptify.rating.admin.model.lob.LineOfBusinessHierarchy
import com.adaptify.rating.admin.model.type.PrimitiveDataType
import kotlinx.coroutines.DelicateCoroutinesApi
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.promise
import kotlin.js.Promise

// this is mostly used to convert between deferred and promises


@JsExport
@JsName("GetCalculationEvalContext")
@DelicateCoroutinesApi
fun GetCalculationEvalContextJs(
  calculation: Calculation,
  lobDef: LineOfBusinessHierarchy,
  metadataProvider: MetadataProviderJs,
  globalVariables: Array<ScopedVariable>
): Promise<CalculationEvalContext?> {
  return GlobalScope.promise {
    CalculationEvalContext.Create(calculation, lobDef, MetadataProviderConverter(metadataProvider), globalVariables)
  }
}

@JsExport
@JsName("GetFunctionStepNameMap")
@DelicateCoroutinesApi
fun GetFunctionStepNameMapJs(calculation: Calculation,
                             lobDef: LineOfBusinessHierarchy,
                             metadataProvider: MetadataProviderJs): Promise<Map<String, String>> {
   return GlobalScope.promise {
     BindingResolver.GetFunctionStepNameMap(calculation, lobDef, MetadataProviderConverter(metadataProvider))
   }
 }

@JsExport
@JsName("ValidateCalculation")
@DelicateCoroutinesApi
fun ValidateCalculationJs(
  calculation: Calculation,
  metadataProvider: MetadataProviderJs,
  evalContext: CalculationEvalContext
): Promise<Array<FunctionValidationMessage>> {
  return GlobalScope.promise {
    CalculationValidator.validateCalculation(calculation, MetadataProviderConverter(metadataProvider), null, evalContext)
  }
}

@JsExport
@JsName("ValidateFlow")
@DelicateCoroutinesApi
fun ValidationFlowJs(
  flow: Flow,
  metadataProvider: MetadataProviderJs,
  lobDef: LineOfBusinessHierarchy,
  globalVariables: Array<ScopedVariable>
): Promise<FlowValidationResult> {
  return GlobalScope.promise {
    FlowValidator.validateFlow(flow, MetadataProviderConverter(metadataProvider), lobDef, globalVariables)
  }
}

@JsExport
@JsName("GetPreferredBindingPrimitiveDataType")
@DelicateCoroutinesApi
fun GetPreferredBindingPrimitiveDataTypeJs(
  bindingDescriptor: BindingDescriptor,
  function: CalculationFunction,
  metadataProvider: MetadataProviderJs,
  evalContext: CalculationEvalContext,
): Promise<PrimitiveDataType?> {

  return GlobalScope.promise {
    val functionScope = evalContext.functionContexts.get(function.id)?.variables
    if (functionScope == null) {
      return@promise null
    }

    return@promise bindingDescriptor.getPreferredPrimitiveDataType(function,
      MetadataProviderConverter(metadataProvider), functionScope);
  }
}
