package com.adaptify.rating.admin.model.calculation.binding

@JsExport
external actual interface KeyedInputEntry {
  actual var key: String?
  actual var value: String?
}

actual fun createKeyedInputEntry(key: String?, value: String?): KeyedInputEntry {
  return js("({key: key, value: value})")
}
