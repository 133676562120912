import {Flex} from 'antd';
import React, {useEffect, useState} from 'react';
import {LobService} from '../../lob/service/LobService';
import {ProductVersion} from '../../product/model/Product';
import {ProductService} from '../../product/service/ProductService';
import {AuthManager} from '../../user/util/AuthManager';
import {ProductVersionSummaryForm} from '../control/ProductVersionSummaryForm';
import {RatingNavigationBar} from '../control/RatingNavigationBar';
import {TestCaseList} from '../control/testcase/TestCaseList';
import {RatingService} from '../service/RatingService';
import {IsProductVersionReadOnly} from './ProductUtils';

export interface ProductVersionTestCaseControlProps {
  lobService: LobService;
  productService: ProductService;
  ratingService: RatingService;
  productVersionId: string;
  authToken: string;
  authManager: AuthManager;
}

export function ProductVersionTestCaseControl(
  props: ProductVersionTestCaseControlProps
) {
  const [productVersion, setProductVersion] = useState<
    ProductVersion | undefined
  >(undefined);

  useEffect(() => {
    const eff = async () => {
      if (!props.productVersionId) {
        setProductVersion(undefined);
        return;
      }
      const productVersion = await props.productService.GetProductVersion(
        props.productVersionId
      );
      setProductVersion(productVersion);
    };
    eff();
  }, [props.productVersionId]);

  const [modifyCount, setModifyCount] = useState(0);
  const [selectedTestCaseId, setSelectedTestCaseId] = useState<
    string | undefined
  >(undefined);

  function isDirty() {
    return false;
  }

  function isReadOnly() {
    return IsProductVersionReadOnly(productVersion, props.authManager);
  }

  return (
    <Flex vertical gap={24}>
      <div style={{height: '100%', width: '100%'}}>
        <ProductVersionSummaryForm
          productService={props.productService}
          productVersionId={props.productVersionId}
          isDirty={isDirty()}
          readOnly={isReadOnly()}
        />
      </div>
      <div style={{minHeight: '46px', width: '100%'}}>
        <RatingNavigationBar
          productVersionId={props.productVersionId}
          isDirty={isDirty()}
          selection="TestSuite"
        />
      </div>
      <div>
        <TestCaseList
          productService={props.productService}
          ratingService={props.ratingService}
          modifyCount={modifyCount}
          setModifyCount={setModifyCount}
          selectedTestCaseId={selectedTestCaseId}
          setSelectedTestCaseId={setSelectedTestCaseId}
          productVersionId={props.productVersionId}
          isReadOnly={isReadOnly()}
        />
      </div>
    </Flex>
  );
}
