import {Col, Flex, Input, Row, Typography} from 'antd';
import React, {useEffect, useState} from 'react';
import {StudyInfo} from '../model/ComparativeAnalysis';
import {AnalysisService} from '../service/AnalysisService';
export interface StudySummaryControlProps {
  analysisService: AnalysisService;
  studyId: string;
}

export function StudySummaryControl(props: StudySummaryControlProps) {
  // used to trigger a refresh on the UI on change
  const [study, setStudy] = useState<StudyInfo | undefined>(undefined);
  useEffect(() => {
    const eff = async () => {
      if (props.studyId === '') {
        setStudy(undefined);
        return;
      }
      const loaded = await props.analysisService.GetStudyInfoById(
        props.studyId
      );
      setStudy(loaded);
    };
    eff();
  }, [props.studyId]);

  const inputStyle = {background: '#EEEEEE'};

  return (
    <Flex vertical>
      <div
        style={{
          border: 'solid',
          borderWidth: '1px',
          borderRadius: '8px',
          borderColor: '#CCCCCC',
          backgroundColor: '#F2F7FE',
        }}
      >
        <div
          style={{
            marginLeft: '16px',
            marginRight: '16px',
            marginTop: '16px',
            marginBottom: '16px',
          }}
        >
          <Row gutter={[20, 16]} style={{paddingBottom: '10px'}}>
            <Col span={3} style={{textAlign: 'right'}}>
              <Typography.Text>Study Name:</Typography.Text>
            </Col>
            <Col span={9}>
              <Input value={study?.name} readOnly style={inputStyle}></Input>
            </Col>
            <Col span={3} style={{textAlign: 'right'}}>
              <Typography.Text>Description:</Typography.Text>
            </Col>
            <Col span={9}>
              <Input
                value={study?.comments}
                style={inputStyle}
                readOnly
              ></Input>
            </Col>
          </Row>
        </div>
      </div>
    </Flex>
  );
}
