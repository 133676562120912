import React from 'react';
import {NavigationAware} from '../../NavigationAware';
import {TrialAccountList} from '../control/TrialAccountList';
import {TrialAccountService} from '../service/TrialAccountService';

export interface TrialAccountListPageProps extends NavigationAware {
  trialAccountService: TrialAccountService;
}

export function TrialAccountListPage(props: TrialAccountListPageProps) {
  return <TrialAccountList trialAccountService={props.trialAccountService} />;
}
