import React from 'react';
import {useParams, useSearchParams} from 'react-router-dom';
import {NavigationAware} from '../../NavigationAware';
import {LobService} from '../../lob/service/LobService';
import {ProductService} from '../../product/service/ProductService';
import {AuthManager} from '../../user/util/AuthManager';
import {RatingService} from '../service/RatingService';
import {ProductVersionLobRiskFieldControl} from './ProductVersionLobRiskFieldControl';

export interface ProductVersionLobRiskFieldPageProps extends NavigationAware {
  lobService: LobService;
  productService: ProductService;
  ratingService: RatingService;
  authManager: AuthManager;
}
export function ProductVersionLobRiskFieldPage(
  props: ProductVersionLobRiskFieldPageProps
) {
  const {productVersionId} = useParams<string>();
  if (typeof productVersionId !== 'string') {
    return <></>;
  }

  const [searchParams, setSearchParams] = useSearchParams();
  const initialLobItemId = searchParams.get('lobItemId');

  function onSetLobItemId(lobItemId: string | undefined) {
    if (lobItemId) {
      setSearchParams({lobItemId: lobItemId}, {replace: true});
    }
  }

  return (
    <ProductVersionLobRiskFieldControl
      lobService={props.lobService}
      productService={props.productService}
      productVersionId={productVersionId as string}
      initialLobItemId={initialLobItemId ?? undefined}
      authManager={props.authManager}
      onSetLobItemId={onSetLobItemId}
    />
  );
}
