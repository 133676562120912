import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import {
  DataGrid,
  GridCallbackDetails,
  GridColDef,
  GridRowSelectionModel,
} from '@mui/x-data-grid';
import {
  Button,
  Col,
  Flex,
  Popconfirm,
  Row,
  Select,
  Space,
  Typography,
} from 'antd';
import React, {useEffect, useState} from 'react';
import {ButtonSettings, DataGridSettings} from '../../common/control/Common';
import {DraggableModal} from '../../common/control/DraggableModal';
import {NewTenantAwareEntity} from '../../common/model/Common';
import {
  LineOfBusinessItemField,
  LobItemInfo,
  ReservedCoverageFieldNames,
} from '../model/LineOfBusiness';
import {LobService} from '../service/LobService';
import {EditLobItemFieldForm} from './EditLobItemFieldForm';

export interface LobCoverageFieldListProps {
  lobHierarchyId: string | undefined;
  selectedCoverageId: string | undefined;
  setSelectedCoverageId: (coverageId: string | undefined) => void;
  lobService: LobService;
  existingItemsReadOnly?: boolean;
  readOnly?: boolean;
}

export interface EditState {
  isNew: boolean;
  isEditing: boolean;
  lobItemField?: LineOfBusinessItemField;
}

export function LobCoverageFieldList(props: LobCoverageFieldListProps) {
  const [editState, setEditState] = useState<EditState>({
    isNew: true,
    isEditing: false,
  });
  // used to trigger a refresh on the UI on change
  const [modifyCount, setModifyCount] = useState(0);
  const [selectedRiskId, setSelectedRiskId] = useState<string | undefined>();

  const [allRisks, setAllRisks] = useState<LobItemInfo[]>([]);
  const [allCoveragesForRisk, setAllCoveragesForRisk] = useState<LobItemInfo[]>(
    []
  );

  const [lobItemFields, setLobItemFields] = useState<LineOfBusinessItemField[]>(
    []
  );
  const [selectedRow, setSelectedRow] = useState<
    LineOfBusinessItemField | undefined
  >(undefined);

  useEffect(() => {
    if (!props.selectedCoverageId) {
      return;
    }

    const eff = async () => {
      // we only ask for the initial selected coverage, but we use that info to find the parent risk
      // to automatically select that as well
      const coverage = await props.lobService.GetLineOfBusinessItem(
        props.selectedCoverageId ?? ''
      );
      if (coverage) {
        setSelectedRiskId(coverage.parentLineOfBusinessItemId);
        props.setSelectedCoverageId(coverage.id);
      }
    };
    eff();
  }, [props.selectedCoverageId]);

  useEffect(() => {
    if (!props.lobHierarchyId) {
      setAllRisks([]);
      setAllCoveragesForRisk([]);
      props.setSelectedCoverageId(undefined);
      return;
    }
    const eff = async () => {
      const risks = await props.lobService.GetLineOfBusinessRisks(
        props.lobHierarchyId ?? ''
      );
      setAllRisks(risks);
      if (!props.selectedCoverageId && risks.length > 0) {
        setSelectedRiskId(risks[0].id);
      }
    };
    eff();
  }, [props.lobHierarchyId]);

  useEffect(() => {
    if (!selectedRiskId) {
      setAllCoveragesForRisk([]);
      return;
    }
    const eff = async () => {
      const coverages =
        await props.lobService.GetLineOfBusinessCoveragesByParentRiskId(
          selectedRiskId ?? ''
        );
      setAllCoveragesForRisk(coverages);
      if (!props.selectedCoverageId && coverages.length > 0) {
        props.setSelectedCoverageId(coverages[0].id);
      }
    };
    eff();
  }, [selectedRiskId]);

  useEffect(() => {
    if (props.selectedCoverageId === '') {
      setLobItemFields([]);
      return;
    }
    const eff = async () => {
      const fields = await props.lobService.GetLineOfBusinessItemFields(
        props.selectedCoverageId ?? ''
      );
      setLobItemFields(fields);
      if (fields.length > 0) {
        if (selectedRow) {
          const found = fields.find(f => f.id === selectedRow.id);
          if (found) {
            setSelectedRow(found);
            return;
          } else {
            setSelectedRow(fields[0]);
          }
        } else {
          setSelectedRow(fields[0]);
        }
      } else {
        setSelectedRow(undefined);
      }
    };
    eff();
  }, [props.selectedCoverageId, modifyCount]);

  function createLobHierarchyItemField(): LineOfBusinessItemField {
    return {
      ...NewTenantAwareEntity(),
      name: '',
      dataType: '',
      lineOfBusinessItemId: props.selectedCoverageId ?? '',
    };
  }

  const colDefs: GridColDef<LineOfBusinessItemField>[] = [
    {
      field: 'name',
      headerName: 'Field',
      flex: 300,
    },
    {
      field: 'dataType',
      headerName: 'Data Type',
      flex: 300,
    },
  ];

  function onOpenCreateDialog() {
    setEditState({
      isNew: true,
      isEditing: true,
      lobItemField: createLobHierarchyItemField(),
    });
  }

  function onOpenEditDialog() {
    if (!selectedRow) {
      return;
    }
    setEditState({
      isNew: true,
      isEditing: true,
      lobItemField: selectedRow,
    });
  }

  async function onDelete() {
    if (!selectedRow) {
      return;
    }
    await props.lobService.DeleteLineOfBusinessItemField(selectedRow.id);
    setModifyCount(modifyCount + 1);
  }

  async function onSaveLineOfBusinessItemField(value: LineOfBusinessItemField) {
    setEditState({
      ...editState,
      isEditing: false,
    });
    setSelectedRow(value);
    setModifyCount(modifyCount + 1);
  }

  function onCancelLineOfBusinessItemField() {
    setEditState({
      ...editState,
      isEditing: false,
    });
  }

  function onTableSelectionChanged(
    rowSelectionModel: GridRowSelectionModel,
    details: GridCallbackDetails
  ) {
    let selection: LineOfBusinessItemField | undefined = undefined;
    if (rowSelectionModel.length > 0) {
      selection = lobItemFields.find(lob => lob.id === rowSelectionModel[0]);
    }
    setSelectedRow(selection);
  }

  const riskOptions = allRisks.map(lob => {
    return {
      label: lob.name,
      value: lob.id,
    };
  });

  const coverageOptions = allCoveragesForRisk.map(lob => {
    return {
      label: lob.name,
      value: lob.id,
    };
  });

  const selectSection = (
    <Flex gap="middle" align="center">
      <Typography.Text>Risk:</Typography.Text>
      <Select
        showSearch
        optionFilterProp="label"
        style={{width: '300px'}}
        value={selectedRiskId}
        options={riskOptions}
        onChange={value => {
          setSelectedRiskId(value);
          props.setSelectedCoverageId(undefined);
        }}
      />

      <Typography.Text>Coverage:</Typography.Text>
      <Select
        showSearch
        optionFilterProp="label"
        style={{width: '300px'}}
        value={props.selectedCoverageId}
        options={coverageOptions}
        onChange={value => props.setSelectedCoverageId(value)}
      />
    </Flex>
  );

  // current requriements are to remove the delete button
  // but keeping the code for it around as I expect this to be revisited
  const deleteButton = (
    <Popconfirm
      title="Confirm deletion"
      description="Are you sure to want to delete?"
      onConfirm={onDelete}
      disabled={!selectedRow}
      okText="Delete"
      cancelText="Cancel"
    >
      <Button {...ButtonSettings} disabled={!selectedRow}>
        Delete
      </Button>
    </Popconfirm>
  );

  const editButtons = (
    <Flex justify="flex-end" align="flex-start" className="gap-2">
      <Button
        {...ButtonSettings}
        icon={<DriveFileRenameOutlineOutlinedIcon className="-m-1" />}
        onClick={onOpenEditDialog}
        disabled={
          props.existingItemsReadOnly ||
          props.readOnly ||
          !selectedRow ||
          selectedRow.copiedToClientFromAdaptify
        }
      >
        Modify
      </Button>
      <Button
        {...ButtonSettings}
        icon={<AddOutlinedIcon className="-m-1" />}
        onClick={onOpenCreateDialog}
        disabled={props.selectedCoverageId === ''}
      >
        Add Field
      </Button>
    </Flex>
  );

  const buttons = props.readOnly ? <></> : editButtons;

  const fieldModal = editState.isEditing ? (
    <DraggableModal
      className="adaptify-modal"
      title={editState.isNew ? 'Add Coverage Field' : 'Edit Coverage Field'}
      open={editState.isEditing}
      closable={false}
      okButtonProps={{style: {display: 'none'}}}
      cancelButtonProps={{style: {display: 'none'}}}
      width={800}
    >
      <EditLobItemFieldForm
        lobService={props.lobService}
        lobItemField={editState.lobItemField ?? createLobHierarchyItemField()}
        allLobItemsFields={lobItemFields}
        onSave={onSaveLineOfBusinessItemField}
        onCancel={onCancelLineOfBusinessItemField}
        reservedFieldNames={ReservedCoverageFieldNames}
      />
    </DraggableModal>
  ) : (
    <></>
  );

  return (
    <>
      <Flex vertical style={{width: '100%', height: '100%'}}>
        <Row>
          <Col span={6}>
            <div className="page-title">Fields</div>
          </Col>
          <Col span={12}>
            <Flex justify="center" align="flex-start">
              {selectSection}
            </Flex>
          </Col>
          <Col span={6}>{buttons}</Col>
        </Row>
        <Space direction="vertical" style={{width: '100%', height: '100%'}}>
          <div style={{height: '500px', width: '100%'}}>
            <DataGrid
              {...DataGridSettings}
              columns={colDefs}
              rows={lobItemFields}
              rowSelectionModel={selectedRow ? [selectedRow.id] : []}
              onRowSelectionModelChange={onTableSelectionChanged}
              sx={{
                '&.MuiDataGrid-root': {
                  borderRadius: '8px',
                  overflow: 'hidden',
                  borderColor: '#CCCCCC',
                },
              }}
            />
          </div>
        </Space>
      </Flex>
      {fieldModal}
    </>
  );
}
