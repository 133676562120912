package com.adaptify.rating.admin.model.calculation.descriptor

import kotlin.js.JsExport

@JsExport
enum class FunctionType(val displayName: String) {
  Function("function"),
  Predicate("predicate"),
  Gate("gate"),
  Branch("branch"),
  Transition("transition"),
  If("if"),
}
