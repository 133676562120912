import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import {Button, Col, Flex, Input, Row, Select, Typography} from 'antd';
import React from 'react';
import {ButtonSettings} from '../../common/control/Common';
import {ClientTypeOptions, TenantQuery} from '../model/Tenant';
import {TenantService} from '../service/TenantService';

export interface TenantQueryFormProps {
  tenantService: TenantService;
  tenantQuery: TenantQuery;
  setTenantQuery: (tenantQuery: TenantQuery) => void;
  onQuery: () => void;
}

export function TenantQueryForm(props: TenantQueryFormProps) {
  function onNameChange(value: string) {
    props.setTenantQuery({
      ...props.tenantQuery,
      name: value,
    });
  }

  function onAccountNumberChange(value: string) {
    props.setTenantQuery({
      ...props.tenantQuery,
      accountNumber: value,
    });
  }

  function onAccountTypeChange(value: string) {
    props.setTenantQuery({
      ...props.tenantQuery,
      accountType: value,
    });
  }

  return (
    <Flex vertical>
      <Row>
        <Col span={24}>
          <div className="page-title">Account Lookup</div>
        </Col>
      </Row>
      <div
        style={{
          border: 'solid',
          borderWidth: '1px',
          borderRadius: '8px',
          borderColor: '#CCCCCC',
          backgroundColor: '#F2F7FE',
        }}
      >
        <div
          style={{
            marginLeft: '16px',
            marginRight: '16px',
            marginTop: '16px',
            marginBottom: '16px',
          }}
        >
          <Row gutter={[10, 16]}>
            <Col span={3} className="adaptify-label-column">
              <Typography.Text>Account Name</Typography.Text>
            </Col>
            <Col span={9}>
              <Input
                allowClear
                onChange={event => onNameChange(event.target.value)}
                style={{width: '100%'}}
                value={props.tenantQuery.name}
              />
            </Col>
            <Col span={3} className="adaptify-label-column">
              <Typography.Text>Account ID:</Typography.Text>
            </Col>
            <Col span={9}>
              <Input
                allowClear
                onChange={event => onAccountNumberChange(event.target.value)}
                style={{width: '100%'}}
                value={props.tenantQuery.accountNumber}
              />
            </Col>
            <Col span={3} className="adaptify-label-column">
              <Typography.Text>Account Type:</Typography.Text>
            </Col>
            <Col span={9}>
              <Select
                showSearch
                allowClear
                options={ClientTypeOptions}
                onChange={value => onAccountTypeChange(value)}
                style={{width: '100%'}}
              ></Select>
            </Col>
            <Col span={12} />
            <Col span={24}>
              <Flex justify="flex-end" align="flex-start">
                <Button
                  {...ButtonSettings}
                  icon={<SearchOutlinedIcon className="-m-1" />}
                  onClick={props.onQuery}
                >
                  Search
                </Button>
              </Flex>
            </Col>
          </Row>
        </div>
      </div>
    </Flex>
  );
}
