import {
  Button,
  Flex,
  Form,
  Input,
  Select,
  TreeDataNode,
  TreeSelect,
} from 'antd';
import React, {useEffect, useState} from 'react';
import {ButtonSettings} from '../../common/control/Common';
import {NewTenantAwareEntity} from '../../common/model/Common';
import {IdAndValue} from '../../valuelist/model/ValueList';
import {ValueListService} from '../../valuelist/service/ValueListService';
import {
  ClientTypeOptions,
  Tenant,
  WrittenByStateCodeOptions,
} from '../model/Tenant';
import {TenantService} from '../service/TenantService';

interface CreateTenantFormProps {
  tenantService: TenantService;
  valueListService: ValueListService;
  existingTenantId?: string;
  onSaved: (tenant: Tenant) => void;
  onCancel: () => void;
}

interface CreateTenantFormArgs {
  accountType: string;
  name: string;
  address: string;
  url: string;
  description: string;
  lineOfBusinessValueListItemIds: string[];
  statesWrittenIn: string[];
  directWrittenPremium: string;
  primaryContactName: string;
  primaryContactJobTitle: string;
  primaryContactEmail: string;
  primaryContactPhone: string;
}

export function CreateTenantForm(props: CreateTenantFormProps) {
  const [form] = Form.useForm<CreateTenantFormArgs>();

  // using this to refresh form dependencies, since I cannot figure out how to use ant forms shouldUpdate correctly
  const [internalRefreshNum, setInternalRefreshNum] = React.useState(0);
  const [tenant, setTenant] = useState<Tenant | undefined>(undefined);
  const [lobTreeData, setLobTreeData] = useState<TreeDataNode[]>();

  useEffect(() => {
    if (!props.existingTenantId) {
      setTenant(undefined);
      return;
    }
    const eff = async () => {
      const tenant = await props.tenantService.GetTenant(
        props.existingTenantId ?? ''
      );
      setTenant(tenant);
    };

    eff();
  }, [props.existingTenantId]);

  useEffect(() => {
    form.resetFields();
    if (tenant) {
      form.setFieldsValue({
        name: tenant.name,
      });
    }
  }, [tenant]);

  useEffect(() => {
    const eff = async () => {
      // get the insurance type and the lines of business and use them to form the tree
      const insuranceTypes = await props.valueListService.GetInsuranceTypes();

      // unfortunately we aren't returning back the parent id when querying children
      // so query for each insurance type separately to build the tree
      // since there shouldn't be more than 5 of these ever (and 2 right now) performance should be managable

      const lobPromiseMap = new Map<String, Promise<IdAndValue[]>>();
      insuranceTypes.forEach(async insuranceType => {
        lobPromiseMap.set(
          insuranceType.id,
          props.valueListService.GetLinesOfBusiness(insuranceType.id)
        );
      });

      // wait for all the promises to finish concurrently
      await Promise.all(lobPromiseMap.values());

      // now build the map of insurance type to lob, await here should be free since we already awaited the promises above
      const insuranceTypeIdToCarrierMap = new Map<String, IdAndValue[]>();
      for (const [insuranceTypeId, lobPromise] of lobPromiseMap.entries()) {
        const lobs = await lobPromise;
        insuranceTypeIdToCarrierMap.set(insuranceTypeId, lobs);
      }

      // now build the tree
      const treeData: TreeDataNode[] = insuranceTypes.map(insuranceType => {
        return {
          title: insuranceType.value,
          value: insuranceType.id,
          checkable: false,
          children: insuranceTypeIdToCarrierMap
            .get(insuranceType.id)
            ?.map(lob => {
              return {
                title: lob.value,
                value: lob.id,
              };
            }),
        };
      });
      setLobTreeData(treeData);
    };
    eff();
  }, [internalRefreshNum]);

  async function onSave(value: CreateTenantFormArgs) {
    return await onCreate(value);
  }

  async function onCreate(value: CreateTenantFormArgs) {
    const toCreate = {
      ...NewTenantAwareEntity(),
      name: value.name,
      accountType: value.accountType,
      address: value.address,
      url: value.url,
      description: value.description,
      directWrittenPremium: value.directWrittenPremium,
      primaryContactName: value.primaryContactName,
      primaryContactJobTitle: value.primaryContactJobTitle,
      primaryContactEmail: value.primaryContactEmail,
      primaryContactPhone: value.primaryContactPhone,
    } as Tenant;

    const created = await props.tenantService.SaveTenant(
      toCreate,
      value.statesWrittenIn,
      value.lineOfBusinessValueListItemIds
    );
    props.onSaved(created);
  }

  return (
    <Form
      name="edit_tenant"
      labelCol={{span: 8}}
      wrapperCol={{span: 16}}
      style={{maxWidth: 1200}}
      onFinish={onSave}
      autoComplete="off"
      size="large"
      form={form}
    >
      <Form.Item
        name="accountType"
        label="Account Type"
        rules={[{required: true}]}
      >
        <Select options={ClientTypeOptions} />
      </Form.Item>
      <Form.Item name="name" label="Name" rules={[{required: true}]}>
        <Input />
      </Form.Item>
      <Form.Item name="address" label="Company Address">
        <Input />
      </Form.Item>
      <Form.Item name="url" label="Company URL" rules={[{type: 'url'}]}>
        <Input />
      </Form.Item>
      <Form.Item name="description" label="Company Description">
        <Input />
      </Form.Item>
      <Form.Item
        name="lineOfBusinessValueListItemIds"
        label="Lines of Business"
      >
        <TreeSelect
          treeData={lobTreeData}
          treeCheckable={true}
          showCheckedStrategy={TreeSelect.SHOW_PARENT}
          placeholder="Please select"
        />
      </Form.Item>
      <Form.Item name="statesWrittenIn" label="States Written In">
        <Select
          options={WrittenByStateCodeOptions}
          placeholder="Please select"
          mode="multiple"
        />
      </Form.Item>
      <Form.Item name="directWrittenPremium" label="DWP">
        <Input />
      </Form.Item>
      <Form.Item
        name="primaryContactName"
        label="Primary Contact Name"
        rules={[{required: true}]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="primaryContactJobTitle"
        label="Primary Contact Job Title"
        rules={[{required: true}]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="primaryContactEmail"
        label="Primary Contact Email Address"
        rules={[{required: true}, {type: 'email'}]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="primaryContactPhone"
        label="Primary Contact Phone Number"
        rules={[{required: true}]}
      >
        <Input />
      </Form.Item>

      <Flex justify="end" style={{width: '100%'}} className="gap-2">
        <Button {...ButtonSettings} htmlType="button" onClick={props.onCancel}>
          Cancel
        </Button>
        <Button {...ButtonSettings} type="primary" htmlType="submit">
          {props.existingTenantId ? 'Modify' : 'Create'}
        </Button>
      </Flex>
    </Form>
  );
}
