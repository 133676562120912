import * as kot from 'adaptify-multi-module-rating-admin-model';

import Calculation = kot.com.adaptify.rating.admin.model.calculation.Calculation;
import CalculationFunction = kot.com.adaptify.rating.admin.model.calculation.CalculationFunction;
import Binding = kot.com.adaptify.rating.admin.model.calculation.binding.Binding;
import BindingDescriptor = kot.com.adaptify.rating.admin.model.calculation.descriptor.BindingDescriptor;
import BindingType = kot.com.adaptify.rating.admin.model.calculation.BindingType;
import FunctionDescriptorMap = kot.com.adaptify.rating.admin.model.calculation.descriptor.FunctionDescriptorMap;
import CalculationEvalContext = kot.com.adaptify.rating.admin.model.calculation.context.CalculationEvalContext;
import BindingHelper = kot.com.adaptify.rating.admin.model.calculation.util.BindingHelper;
import FunctionDescriptor = kot.com.adaptify.rating.admin.model.calculation.descriptor.FunctionDescriptor;

export type FunctionPath = RootFunctionPath | ChildFunctionPath | VirtualPath;

export interface HasFunction {
  calculationFunction: CalculationFunction;
}
type RootFunctionPath = HasFunction;

export interface ChildPath {
  parents: BindingPart[];
}

export interface BindingPart {
  calculationFunction: CalculationFunction;
  bindingDesc: BindingDescriptor;
}

export interface ChildFunctionPath extends ChildPath, HasFunction {}

export enum VirtualPathType {
  BlockHeader,
  FunctionPlaceholder,
  PredicatePlaceholder,
}
export interface VirtualPath extends ChildPath {
  type: VirtualPathType;
}

export interface FunctionListModel {
  // leaf is index 0
  path: FunctionPath;
  dynamicFunctionName: string;
  bindingDescriptors: BindingDescriptor[];
}

/*
Example display
func1 = 1 + 2         <- Calculation Function
if (predicate) then
  func2 = func1 + 3   <- Binding Path w/ function
else
  <enter calculation here>  <- Binding Path w/o function
*/

export async function GenerateCalculationFunctionListModel(
  calculation: Calculation,
  stepNameMapper: (id: string) => string
): Promise<FunctionListModel[]> {
  const models: FunctionListModel[] = [];

  for (const calculationFunction of calculation.functions || []) {
    appendCalculationFunctionListModels(
      calculationFunction,
      models,
      stepNameMapper
    );
  }
  return models;
}

function appendCalculationFunctionListModels(
  calculationFunction: CalculationFunction,
  models: FunctionListModel[],
  stepNameMapper: (id: string) => string,
  parentBindingPaths?: BindingPart[]
) {
  const currentPath: FunctionPath = parentBindingPaths
    ? {
        calculationFunction: calculationFunction,
        parents: [...parentBindingPaths],
      }
    : {
        calculationFunction: calculationFunction,
      };

  const funcDef = FunctionDescriptorMap.asJsReadonlyMapView().get(
    calculationFunction.name || ''
  );
  let bindingDescs: BindingDescriptor[] = [];
  if (funcDef !== undefined) {
    bindingDescs = funcDef.getBindingDescriptors();
  }
  models.push({
    path: currentPath,
    dynamicFunctionName: stepNameMapper(calculationFunction.id),
    bindingDescriptors: bindingDescs,
  });

  const childFunctionBindingDescs = bindingDescs.filter(
    b =>
      b.allowedBindingTypes.indexOf(BindingType.Block) !== -1 ||
      b.allowedBindingTypes.indexOf(BindingType.Predicate) !== -1
  );

  for (const bindingDesc of childFunctionBindingDescs) {
    const isBlock =
      bindingDesc.allowedBindingTypes.indexOf(BindingType.Block) !== -1;

    const childBindingPart: BindingPart[] = [
      {
        calculationFunction: calculationFunction,
        bindingDesc: bindingDesc,
      },
      ...(parentBindingPaths || []),
    ];

    // block bindings start with the binding definition itself in the list
    // then either the bound functions or a placeholder
    // predicates right now don't have headers as that just happens to look nicer on the UI
    if (bindingDesc.shouldRenderBlockHeader()) {
      // create a placeholder
      models.push({
        path: {
          type: VirtualPathType.BlockHeader,
          parents: childBindingPart,
        },
        dynamicFunctionName: '',
        bindingDescriptors: [],
      });
    }
    const binding = calculationFunction.bindings.find(
      b => b.name === bindingDesc.name
    );

    const children = binding?.block || binding?.predicate?.functions || [];
    if (children.length > 0) {
      for (const childFunction of children) {
        // this can be parallelized but we need to preserve the order
        appendCalculationFunctionListModels(
          childFunction,
          models,
          stepNameMapper,
          childBindingPart
        );
      }
    } else {
      // create a placeholder

      models.push({
        path: {
          type: isBlock
            ? VirtualPathType.FunctionPlaceholder
            : VirtualPathType.PredicatePlaceholder,
          parents: childBindingPart,
        },
        dynamicFunctionName: '',
        bindingDescriptors: [],
      });
    }
  }
}

export function FindBinding(
  calculationFunction: CalculationFunction,
  bindingDesc: BindingDescriptor
): Binding | undefined {
  return calculationFunction.bindings.find(
    binding => binding.name === bindingDesc.name
  );
}

export function FindBindingIndex(
  calculationFunction: CalculationFunction,
  bindingDesc: BindingDescriptor
): number {
  return calculationFunction.bindings.findIndex(
    binding => binding.name === bindingDesc.name
  );
}

export function GetIndexInModel(
  path: FunctionPath,
  calculation: Calculation
): number {
  const functionHolder = path as HasFunction;
  if (!functionHolder.calculationFunction) {
    // only real functions have an in indexs
    return -1;
  }
  if ((path as ChildPath).parents) {
    const childPath = path as ChildPath;

    const leafPath = childPath.parents[0];
    const binding = FindBinding(
      leafPath.calculationFunction,
      leafPath.bindingDesc
    );
    if (!binding) {
      return -1;
    }

    if (binding.bindingType === BindingType.Block.name && binding.block) {
      return binding.block.findIndex(
        f => f.id === functionHolder.calculationFunction.id
      );
    }

    if (
      binding?.bindingType === BindingType.Predicate.name &&
      binding?.predicate?.functions
    ) {
      return binding?.predicate?.functions.findIndex(
        f => f.id === functionHolder.calculationFunction.id
      );
    }

    return -1;
  }

  // root function logic
  return (calculation.functions || []).findIndex(
    f => f.id === functionHolder.calculationFunction.id
  );
}

export function FindFunctionByUUID(
  calculation: Calculation,
  uuid: string
): CalculationFunction | undefined {
  return FindFunctionInListByUUID(calculation.functions || [], uuid);
}

export function FindFunctionInListByUUID(
  calculationFunctions: CalculationFunction[],
  uuid: string
): CalculationFunction | undefined {
  for (const func of calculationFunctions) {
    if (func.id === uuid) {
      return func;
    }
    for (const binding of func.bindings) {
      if (binding.bindingType === BindingType.Block.name && binding.block) {
        const found = FindFunctionInListByUUID(binding.block, uuid);
        if (found) {
          return found;
        }
      }
      if (
        binding.bindingType === BindingType.Predicate.name &&
        binding.predicate?.functions
      ) {
        const found = FindFunctionInListByUUID(
          binding.predicate.functions,
          uuid
        );
        if (found) {
          return found;
        }
      }
    }
  }
  return undefined;
}

export function CopyCalculationReplaceBinding(
  calculation: Calculation,
  calculationFunction: CalculationFunction,
  bindingDesc: BindingDescriptor,
  binding?: Binding
) {
  const updated = structuredClone(calculation);
  const updatedFunction = FindFunctionByUUID(
    updated,
    calculationFunction.id || ''
  );
  if (!updatedFunction) {
    return updated;
  }

  updatedFunction.bindings = updatedFunction.bindings.filter(
    b => b.name !== bindingDesc.name
  );

  if (binding) {
    updatedFunction.bindings.push(binding);
  }
  // sort the bindings so that the content stays as close to the same as possible
  // this will be useful if we ever export the metadata to source control or provide
  // a diff view
  updatedFunction.bindings.sort((a, b) => a.name.localeCompare(b.name));
  return updated;
}

export function ProcessFunctionDisplayTemplate(
  desc: FunctionDescriptor,
  handleNonBinding: (chunk: string) => void,
  handleBinding: (bindingName: string) => void
) {
  // regular expressions API isn't so robust so just iterating through the string
  let currentSectionName = '';
  let isInBinding = false;

  // iterate through the characters one by one, replacing the non-templated pieces of the string
  // with spans and the teplated pieces with the current binding as a hyperlink
  const template = desc.getDisplayTemplate() || '';
  for (let i = 0; i < template.length; i++) {
    const c = template[i];
    if (c === '$') {
      // if we have any characters accumulated add them
      if (currentSectionName.length > 0) {
        handleNonBinding(currentSectionName);
        currentSectionName = '';
      }

      // we are in a binding now
      isInBinding = true;
      continue;
    }
    const isLetter = c >= 'A' && c <= 'z';
    const isDigit = c >= '0' && c <= '9';
    if (isInBinding && !(isLetter || isDigit)) {
      // end the binding
      handleBinding(currentSectionName);
      isInBinding = false;
      currentSectionName = c;
      continue;
    }

    currentSectionName += c;
  }
  if (currentSectionName.length > 0) {
    if (isInBinding) {
      handleBinding(currentSectionName);
    } else {
      handleNonBinding(currentSectionName);
    }
  }
}
