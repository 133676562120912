import {Button, Col, Menu, MenuProps, Row} from 'antd';
import React, {useState} from 'react';

import {useNavigate} from 'react-router-dom';
import {DirtyEditorConfirmationModal} from './DirtyEditorConfirmationModal';

export interface RatingNavigationBarProps {
  selection: string;
  productVersionId: string;
  isDirty: boolean;
}

interface NavigateConfirmState {
  open: boolean;
  location?: string;
}

export function RatingNavigationBar(props: RatingNavigationBarProps) {
  const navigate = useNavigate();
  const [navigateConfirmState, setNavigateConfirmState] =
    useState<NavigateConfirmState>({
      open: false,
    });
  const navItems: MenuProps['items'] = [
    {
      key: 'Table',
      label: (
        <Button
          type="text"
          onClick={() =>
            setNavigateConfirmState({
              open: true,
              location: `/rating/product/version/${props.productVersionId}/table`,
            })
          }
        >
          Tables
        </Button>
      ),
    },
    {
      key: 'Risk',
      label: (
        <Button
          type="text"
          onClick={() =>
            setNavigateConfirmState({
              open: true,
              location: `/rating/product/version/${props.productVersionId}/risk`,
            })
          }
        >
          Risk Item Calculations
        </Button>
      ),
    },
    {
      key: 'Coverage',
      label: (
        <Button
          type="text"
          onClick={() =>
            setNavigateConfirmState({
              open: true,
              location: `/rating/product/version/${props.productVersionId}/coverage`,
            })
          }
        >
          Coverages
        </Button>
      ),
    },
    {
      key: 'Orchestration',
      label: (
        <Button
          type="text"
          onClick={() =>
            setNavigateConfirmState({
              open: true,
              location: `/rating/product/version/${props.productVersionId}/orchestration`,
            })
          }
        >
          Premium Orchestration
        </Button>
      ),
    },
    {
      key: 'LOB',
      label: (
        <Button
          type="text"
          onClick={() =>
            setNavigateConfirmState({
              open: true,
              location: `/rating/product/version/${props.productVersionId}/lob`,
            })
          }
        >
          LOB Hierarchy
        </Button>
      ),
    },
    {
      key: 'TestSuite',
      label: (
        <Button
          type="text"
          onClick={() =>
            setNavigateConfirmState({
              open: true,
              location: `/rating/product/version/${props.productVersionId}/testcase`,
            })
          }
        >
          Test Suite
        </Button>
      ),
    },
  ];

  const navigateConfirmModal = navigateConfirmState.open ? (
    <DirtyEditorConfirmationModal
      open={navigateConfirmState.open}
      skipDialog={!props.isDirty}
      onCancel={() => {
        setNavigateConfirmState({open: false});
        // restore the previous navigation state
      }}
      onConfirm={async () => {
        navigate(navigateConfirmState.location ?? '');
      }}
    />
  ) : (
    <></>
  );

  return (
    <>
      <Row>
        <Col span="24">
          <Menu
            mode="horizontal"
            selectedKeys={[props.selection]}
            items={navItems}
          />
        </Col>
      </Row>
      {navigateConfirmModal}
    </>
  );
}
