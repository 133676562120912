export const SERVER_BASE_URL = process.env.REACT_APP_PRODUCT_DEV_USE_HOST_NAME
  ? window.location.origin + '/api'
  : process.env.REACT_APP_PRODUCT_DEV_SERVER_URL || 'http://localhost:8050/api';
export const HTTP_BAD_REQUEST = 400;
export const HTTP_UNAUTHORIZED = 401;
export const HTTP_FORBIDDEN = 403;
export const HTTP_NOT_FOUND = 404;

export const HTTP_UNPROCESSABLE_ENTITY = 422;

export const ADAPTIFY_TENANT_ID = '38b4c27f-f46a-4949-b6a3-e59a0983178f';
