package com.adaptify.rating.admin.model.calculation

import kotlinx.serialization.Serializable
import kotlin.js.JsExport

@JsExport
@Serializable
enum class BindingType(val code : String, val displayName : String) {
    None("None", "None"),
    PrimitiveLiteral("PrimitiveLiteral","Value"),
    DynamicVariableDeclaration("DynamicVariableDeclaration", "New Step"),
    DynamicVariable("DynamicVariable", "Step"),
    VariableDeclaration("VariableDeclaration", "New Variable"),
    Path("Path", "Path"),
    Block("Block","Block"),
    TableName("TableName","Table Name"),
    TableBinding("TableBinding","Table Binding"),
    Predicate("Predicate","Predicate"),
    TransitionDefinition("TransitionDefinition","Transition"),
    ConfigurationValue("ConfigurationValue","Configuration Value"),
}
