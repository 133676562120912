package com.adaptify.rating.admin.model.lob

import com.adaptify.rating.admin.model.MetadataObject

@JsExport
external actual interface LineOfBusinessHierarchyItemField : MetadataObject {
  actual var parentLineOfBusinessItemId : String
  actual var dataType: String
  actual var isRatingInput: Boolean
}

actual fun createLineOfBusinessHierarchyItemField(
  id: String,
  name: String,
  version: Int,
  parentLineOfBusinessItemId : String,
  dataType: String,
  isRatingInput: Boolean,
): LineOfBusinessHierarchyItemField {
  return js("{id: id, name: name, version: version, parentLineOfBusinessItemId: parentLineOfBusinessItemId, dataType: dataType, isRatingInput: isRatingInput}")
}
