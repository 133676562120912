import {DataGrid, GridColDef} from '@mui/x-data-grid';
import {Button, Col, Flex, Row, Space} from 'antd';
import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {ButtonSettings, DataGridSettings} from '../../common/control/Common';
import {DraggableModal} from '../../common/control/DraggableModal';
import {DateIsoStringToDisplayString} from '../../common/model/CommonUtils';
import {TrialAccountInfo} from '../model/TrialAccount';
import {TrialAccountService} from '../service/TrialAccountService';
import {CreateTrialAccountForm} from './CreateTrialAccountForm';

export interface TrialAccountListProps {
  trialAccountService: TrialAccountService;
}

export function TrialAccountList(props: TrialAccountListProps) {
  const [trialAccounts, setTrialAccounts] = useState<TrialAccountInfo[]>([]);
  const [createAccountOpen, setCreateAccountOpen] = useState<boolean>(false);
  const [modifyCount, setModifyCount] = React.useState<number>(0);

  useEffect(() => {
    const eff = async () => {
      const loaded = await props.trialAccountService.GetAllTrialAccounts();
      setTrialAccounts(loaded);
    };
    eff();
  }, [modifyCount]);

  function onFinishCreate() {
    setCreateAccountOpen(false);
    setModifyCount(modifyCount + 1);
  }

  function onCancelCreate() {
    setCreateAccountOpen(false);
  }

  const colDefs: GridColDef<TrialAccountInfo>[] = [
    {
      field: 'accountName',
      headerName: 'Account Name',
      flex: 300,
      renderCell: params => {
        return (
          <Link to={`/platform/trialaccount/detail/${params.row.id}`}>
            {params.row.accountName}
          </Link>
        );
      },
    },
    {
      field: 'createdAt',
      headerName: 'Created Date',
      flex: 300,
      valueGetter: (value, row) => {
        if (!row.createdAt) {
          return '';
        }
        return DateIsoStringToDisplayString(row.createdAt);
      },
    },
  ];

  const createAccountModal = createAccountOpen ? (
    <DraggableModal
      className="adaptify-modal"
      title="Get Access"
      open={createAccountOpen}
      closable={false}
      okButtonProps={{style: {display: 'none'}}}
      cancelButtonProps={{style: {display: 'none'}}}
      width={'clamp(300px, 70svw, 800px)'}
      footer={null}
    >
      <CreateTrialAccountForm
        trialAccountService={props.trialAccountService}
        onFinish={onFinishCreate}
        onCancel={onCancelCreate}
      />
    </DraggableModal>
  ) : (
    <></>
  );

  return (
    <>
      <Flex vertical>
        <Row>
          <Col span={18}>
            <div className="page-title">Trial Accounts</div>
          </Col>
          <Col span={6}>
            <Flex justify="end">
              <Button
                {...ButtonSettings}
                onClick={() => setCreateAccountOpen(true)}
              >
                Create Account
              </Button>
            </Flex>
          </Col>
        </Row>
        <Space direction="vertical" style={{width: '100%'}}>
          <div style={{height: '100%', width: '100%'}}>
            <DataGrid
              {...DataGridSettings}
              columns={colDefs}
              rows={trialAccounts}
            />
          </div>
        </Space>
      </Flex>
      {createAccountModal}
    </>
  );
}
