package com.adaptify.rating.admin.model.calculation.descriptor.lib.predicates

import com.adaptify.rating.admin.model.calculation.CalculationFunction
import com.adaptify.rating.admin.model.calculation.descriptor.BindingDescriptor
import com.adaptify.rating.admin.model.calculation.descriptor.BindingInputOutputType
import com.adaptify.rating.admin.model.calculation.descriptor.FunctionDescriptor
import com.adaptify.rating.admin.model.calculation.descriptor.FunctionType

object LessThanPredicateDescriptor : FunctionDescriptor("LESS_THAN", "<", FunctionType.Predicate, "Predicate") {

  val leftDescriptor = BindingDescriptor(
    "left", "Left", BindingInputOutputType.Input, BindingDescriptor.standardInputBindingTypes,
    BindingDescriptor.numberDataType
  )
  val rightDescriptor = BindingDescriptor(
    "right", "Right", BindingInputOutputType.Input, BindingDescriptor.standardInputBindingTypes,
    BindingDescriptor.numberDataType
  )

  override fun getBindingDescriptors(
  ): Array<BindingDescriptor> = arrayOf(leftDescriptor, rightDescriptor)

  override fun getDisplayTemplate(
  ): String = "\$left < \$right"
}
