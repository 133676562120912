import {
  DataGrid,
  GridCallbackDetails,
  GridColDef,
  GridRowSelectionModel,
} from '@mui/x-data-grid';
import {Flex, Space} from 'antd';
import React, {useEffect, useState} from 'react';
import {DataGridSettings} from '../../common/control/Common';
import {DateIsoStringToDisplayString} from '../../common/model/CommonUtils';
import {TenantHistory} from '../model/Tenant';
import {TenantService} from '../service/TenantService';

export interface TenantHistoryListProps {
  tenantService: TenantService;
  readOnly?: boolean;
  tenantId: string | undefined;
}

export interface EditModuleTenantState {
  open: boolean;
  moduleTenantId?: string;
}

export function TenantHistoryList(props: TenantHistoryListProps) {
  const [histories, setHistories] = useState<TenantHistory[]>([]);
  const [selectedHistoryId, setSelectedHistoryId] = useState<
    string | undefined
  >(undefined);

  useEffect(() => {
    if (!props.tenantId) {
      setHistories([]);
      return;
    }
    const eff = async () => {
      const loaded = await props.tenantService.GetTenantHistoriesByTenantId(
        props.tenantId ?? ''
      );
      setHistories(loaded);
      setSelectedHistoryId(loaded.length > 0 ? loaded[0].id : undefined);
    };
    eff();
  }, [props.tenantId]);

  const colDefs: GridColDef<TenantHistory>[] = [
    {
      field: 'createdBy',
      headerName: 'Username',
      flex: 200,
    },
    {
      field: 'createdAt',
      headerName: 'Date',
      flex: 200,
      valueGetter: (value, row) => {
        if (!value) {
          return '';
        }
        return DateIsoStringToDisplayString(value);
      },
    },
    {
      field: 'description',
      headerName: 'Account History Description',
      flex: 400,
    },
  ];

  function onTableSelectionChanged(
    rowSelectionModel: GridRowSelectionModel,
    details: GridCallbackDetails
  ) {
    if (rowSelectionModel.length > 0) {
      setSelectedHistoryId(rowSelectionModel[0] as string);
    } else {
      setSelectedHistoryId(undefined);
    }
  }

  return (
    <>
      <Flex vertical>
        <Space direction="vertical" style={{width: '100%'}}>
          <div style={{height: '100%', width: '100%'}}>
            <DataGrid
              {...DataGridSettings}
              columns={colDefs}
              rows={histories}
              rowSelectionModel={selectedHistoryId ? [selectedHistoryId] : []}
              onRowSelectionModelChange={onTableSelectionChanged}
            />
          </div>
        </Space>
      </Flex>
    </>
  );
}
