import '@ag-grid-community/styles/ag-grid.css'; // Core CSS
import '@ag-grid-community/styles/ag-theme-quartz.css';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import {
  DataGrid,
  GridCallbackDetails,
  GridColDef,
  GridRowSelectionModel,
} from '@mui/x-data-grid';
import {Button, Card, Flex, Popconfirm, Space} from 'antd';
import React, {useEffect, useState} from 'react';
import {ButtonSettings, DataGridSettings} from '../../common/control/Common';
import {DraggableModal} from '../../common/control/DraggableModal';
import {NewTenantAwareEntity} from '../../common/model/Common';
import {ValueListDefinition, ValueListItem} from '../model/ValueList';
import {ValueListService} from '../service/ValueListService';
import {EditValueListItemForm} from './EditValueListItemForm';

export interface ValueListItemListProps {
  valueListService: ValueListService;
  readonly?: boolean;
  valueListDefinitionId: string | undefined;
  selectedValueItemId: string | undefined;
  setSelectedValueItemId: (selection: string | undefined) => void;
  parentValueListItemId: string | undefined;
  childList?: boolean;
}

export interface EditState {
  isNew: boolean;
  open: boolean;
  valueListItem: ValueListItem;
}

export function ValueListItemList(props: ValueListItemListProps) {
  const [valueListDefinition, setValueListDefinition] = useState<
    ValueListDefinition | undefined
  >(undefined);
  const [editState, setEditState] = useState<EditState>({
    isNew: true,
    open: false,
    valueListItem: createValueListItem(),
  });
  // used to trigger a refresh on the UI on change
  const [modifyCount, setModifyCount] = useState(0);
  const [valueListItems, setValueListItems] = useState<ValueListItem[]>([]);
  useEffect(() => {
    if (!props.valueListDefinitionId) {
      setValueListItems([]);
      return;
    }
    if (props.childList && !props.parentValueListItemId) {
      setValueListItems([]);
      return;
    }
    const eff = async () => {
      const items =
        await props.valueListService.GetValueListItemsByDefinitionId(
          props.valueListDefinitionId ?? '',
          props.parentValueListItemId
        );
      setValueListItems(items);
      // we might preserve selection from navgiation from the parents, but not for the children
      if (props.childList || !props.selectedValueItemId) {
        props.setSelectedValueItemId(
          items.length > 0 ? items[0].id : undefined
        );
      }
    };
    eff();
  }, [props.parentValueListItemId, modifyCount]);

  useEffect(() => {
    if (!props.valueListDefinitionId) {
      setValueListDefinition(undefined);
      return;
    }
    const eff = async () => {
      const definition = await props.valueListService.GetValueListDefiniton(
        props.valueListDefinitionId ?? ''
      );
      setValueListDefinition(definition);
    };
    eff();
  }, [props.valueListDefinitionId]);

  const colDefs: GridColDef<ValueListItem>[] = [
    {
      field: 'value',
      headerName: 'Value',
      flex: 300,
    },
  ];

  function createValueListItem(): ValueListItem {
    return {
      ...NewTenantAwareEntity(),
      valueListDefinitionId: props.valueListDefinitionId || '',
      parentValueListItemId: props.parentValueListItemId,
      value: '',
    };
  }

  function onOpenCreateDialog() {
    setEditState({
      isNew: true,
      open: true,
      valueListItem: createValueListItem(),
    });
  }

  function onOpenEditDialog() {
    if (!props.selectedValueItemId) {
      return;
    }

    const valueListItem = valueListItems.find(
      item => item.id === props.selectedValueItemId
    );
    if (!valueListItem) {
      return;
    }
    setEditState({
      isNew: false,
      open: true,
      valueListItem: valueListItem,
    });
  }

  async function onDelete() {
    const sel = props.selectedValueItemId;
    if (!props.selectedValueItemId) {
      return;
    }
    await props.valueListService.DeleteValueListItem(props.selectedValueItemId);
    setModifyCount(modifyCount + 1);
  }

  async function onSavePopup(value: ValueListItem) {
    await props.valueListService.SaveValueListItem(value);
    setEditState({
      ...editState,
      open: false,
    });
    props.setSelectedValueItemId(value.id);
    setModifyCount(modifyCount + 1);
  }

  function onCancelPopup() {
    setEditState({
      ...editState,
      open: false,
    });
  }

  const editButtons = (
    <Flex justify="flex-end" align="flex-start" className="gap-2">
      <Button
        {...ButtonSettings}
        onClick={onOpenCreateDialog}
        disabled={
          props.childList &&
          (!props.valueListDefinitionId || !props.parentValueListItemId)
        }
      >
        <AddOutlinedIcon className="-ml-1" />
        Create
      </Button>
      <Button
        {...ButtonSettings}
        onClick={onOpenEditDialog}
        disabled={!props.selectedValueItemId}
      >
        <DriveFileRenameOutlineOutlinedIcon className="-ml-1" />
        Modify
      </Button>
      <Popconfirm
        title="Confirm deletion"
        description="Are you sure to want to delete?"
        onConfirm={onDelete}
        disabled={!props.selectedValueItemId}
        okText="Delete"
        cancelText="Cancel"
      >
        <Button {...ButtonSettings} disabled={!props.selectedValueItemId}>
          <DeleteOutlinedIcon className="-ml-1" />
          Delete
        </Button>
      </Popconfirm>
    </Flex>
  );

  const buttons = props.readonly ? <></> : editButtons;

  function onTableSelectionChanged(
    rowSelectionModel: GridRowSelectionModel,
    details: GridCallbackDetails
  ) {
    const selection: ValueListDefinition | undefined = undefined;
    if (rowSelectionModel.length !== 0) {
      props.setSelectedValueItemId(rowSelectionModel[0] as string);
    } else {
      props.setSelectedValueItemId(undefined);
    }
  }

  return (
    <>
      <Card
        title={valueListDefinition?.name || 'Values'}
        extra={buttons}
        style={{width: '100%', height: '100%'}}
      >
        <Space direction="vertical" style={{width: '100%', height: '100%'}}>
          <div style={{width: '100%', height: '100%'}}>
            <DataGrid
              {...DataGridSettings}
              columns={colDefs}
              rows={valueListItems}
              rowSelectionModel={
                props.selectedValueItemId ? [props.selectedValueItemId] : []
              }
              onRowSelectionModelChange={onTableSelectionChanged}
              sx={{
                '&.MuiDataGrid-root': {
                  borderRadius: '8px',
                  overflow: 'hidden',
                  borderColor: '#CCCCCC',
                },
              }}
            />
          </div>
        </Space>
      </Card>
      <DraggableModal
        className="adaptify-modal"
        title={editState.isNew ? 'Add Value' : 'Edit Value'}
        open={editState.open}
        closable={false}
        okButtonProps={{style: {display: 'none'}}}
        cancelButtonProps={{style: {display: 'none'}}}
        width={'clamp(300px, 70svw, 800px)'}
        footer={null}
      >
        <EditValueListItemForm
          valueListItem={editState.valueListItem}
          parentValueItemId={props.parentValueListItemId}
          onSave={onSavePopup}
          onCancel={onCancelPopup}
        />
      </DraggableModal>
    </>
  );
}
