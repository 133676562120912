import {Col, Input, Row, Typography} from 'antd';
import React, {useState} from 'react';

import * as kot from 'adaptify-multi-module-rating-admin-model';
import TestCase = kot.com.adaptify.rating.admin.model.testcase.TestCase;

export interface TestCaseSummaryFormProps {
  testCase: TestCase | undefined;
  actualPremium: string | undefined;
  status: string | undefined;
  error: string | undefined;
  executeTestCase: () => void;
}

export function TestCaseSummaryForm(props: TestCaseSummaryFormProps) {
  const [testCase, setTestCase] = useState<TestCase | undefined>(undefined);
  const inputStyle = {background: '#EEEEEE'};

  const error = props.error ? (
    <>
      <Col span={3}>
        <Typography.Text strong>Error:</Typography.Text>
      </Col>
      <Col span={21}>
        <Input.TextArea
          readOnly
          className="adaptify-read-only-field"
          value={props.error}
        />
      </Col>
    </>
  ) : (
    <> </>
  );
  return (
    <>
      <Row gutter={[10, 10]} style={{paddingBottom: '10px'}}>
        <Col span={4} className="adaptify-label-column">
          <Typography.Text strong>Test Case Number:</Typography.Text>
        </Col>
        <Col span={3}>
          <Input
            value={`Test Case ${props.testCase?.sequenceNumber}`}
            readOnly
            style={inputStyle}
          ></Input>
        </Col>
        <Col span={4} className="adaptify-label-column">
          <Typography.Text strong>Expected Premium:</Typography.Text>
        </Col>
        <Col span={3}>
          <Input
            value={props.testCase?.expectedPremium}
            style={inputStyle}
            readOnly
          ></Input>
        </Col>
        <Col span={3} className="adaptify-label-column">
          <Typography.Text strong>Actual Premium:</Typography.Text>
        </Col>
        <Col span={3}>
          <Input
            value={props.actualPremium}
            style={inputStyle}
            readOnly
          ></Input>
        </Col>
        <Col span={2} className="adaptify-label-column">
          <Typography.Text strong>Status:</Typography.Text>
        </Col>
        <Col span={2}>
          <Input value={props.status} style={inputStyle} readOnly></Input>
        </Col>
        {error}
      </Row>
    </>
  );
}
