package com.adaptify.rating.admin.model.calculation

import com.adaptify.rating.admin.model.ModelUtil
import com.adaptify.rating.admin.model.calculation.binding.Binding
import com.adaptify.rating.admin.model.calculation.binding.BindingUtil
import kotlinx.serialization.Serializable
import kotlin.js.JsExport


expect interface CalculationFunction {
    var id: String
    var name: String
    var bindings: Array<Binding>
}

expect fun createCalculationFunction (id: String, name: String, bindings: Array<Binding> = emptyArray()): CalculationFunction

@Serializable
class CalculationFunctionImpl(override var id: String,
                              override var name: String,
                              override var bindings: Array<Binding> = emptyArray()) :
  CalculationFunction

object CalculationFunctionUtil : ModelUtil<CalculationFunction> {
    fun create(id: String, name: String, bindings: Array<Binding> = emptyArray()): CalculationFunction {
        return createCalculationFunction(id, name, bindings)
    }

    override fun deepCopy(obj: CalculationFunction): CalculationFunction {
        return create(obj.id, obj.name, obj.bindings.map { BindingUtil.deepCopy(it) }.toTypedArray())
    }
}
