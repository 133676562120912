import {Modal, ModalProps} from 'antd';
import React, {useRef, useState} from 'react';
import Draggable, {DraggableData, DraggableEvent} from 'react-draggable';

interface Bounds {
  left: number;
  top: number;
  bottom: number;
  right: number;
}

export function DraggableModal(props: ModalProps) {
  const [disabled, setDisabled] = useState(true);
  const [bounds, setBounds] = useState<Bounds>({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const draggableRef = useRef<HTMLDivElement>(null);

  function onStart(_event: DraggableEvent, uiData: DraggableData) {
    const {clientWidth, clientHeight} = window.document.documentElement;
    const targetRect = draggableRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  }

  return (
    <Modal
      {...props}
      title={
        <div
          style={{width: '100%', cursor: 'move'}}
          onMouseOver={() => {
            if (disabled) {
              setDisabled(false);
            }
          }}
          onMouseOut={() => {
            setDisabled(true);
          }}
          onFocus={() => {}}
          onBlur={() => {}}
          // end
        >
          {props.title ?? ''}
        </div>
      }
      modalRender={modal => (
        <Draggable
          disabled={disabled}
          bounds={bounds}
          nodeRef={draggableRef}
          onStart={(event, uiData) => onStart(event, uiData)}
        >
          <div ref={draggableRef}>{modal}</div>
        </Draggable>
      )}
    />
  );
}
