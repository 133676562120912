import React from 'react';
import {useParams} from 'react-router-dom';
import {NavigationAware} from '../../NavigationAware';
import {TrialAccountDetailForm} from '../control/TrialAccountDetailForm';
import {TrialAccountService} from '../service/TrialAccountService';

export interface TrialAccountDetailPageProps extends NavigationAware {
  trialAccountService: TrialAccountService;
}

type TrialAccountDetailPageParams = {
  trialAccountId: string;
};

export function TrialAccountDetailPage(props: TrialAccountDetailPageProps) {
  const {trialAccountId} = useParams<TrialAccountDetailPageParams>();
  return (
    <TrialAccountDetailForm
      trialAccountService={props.trialAccountService}
      trialAccountId={trialAccountId}
    />
  );
}
