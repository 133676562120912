import {Button, Col, Flex, Input, Row, Typography} from 'antd';
import React, {useEffect, useState} from 'react';
import {ButtonSettings} from '../../common/control/Common';

import {Link} from 'react-router-dom';
import {ProductSummary} from '../model/Product';
import {ProductService} from '../service/ProductService';

export interface ProductSummaryFormProps {
  productService: ProductService;
  productId: string | undefined;
}

export function ProductSummaryForm(props: ProductSummaryFormProps) {
  const [product, setProduct] = useState<ProductSummary | undefined>(undefined);

  useEffect(() => {
    const eff = async () => {
      if (!props.productId) {
        setProduct(undefined);
        return;
      }
      const loaded = await props.productService.QueryProduct({
        id: props.productId,
      });
      if (loaded.length === 0) {
        setProduct(undefined);
      }
      setProduct(loaded[0]);
    };
    eff();
  }, [props.productId]);

  const inputStyle = {background: '#EEEEEE'};

  return (
    <Flex vertical>
      <Row>
        <Col span={18}>
          <div className="page-title">Rate Product</div>
        </Col>
        <Col span={6}>
          <Flex align="end" justify="end" className="gap-2">
            <Link to={'/platform/product/summary/my'}>
              <Button {...ButtonSettings}>Rate Product Library</Button>
            </Link>
          </Flex>
        </Col>
      </Row>
      <div
        style={{
          border: 'solid',
          borderWidth: '1px',
          borderRadius: '8px',
          borderColor: '#CCCCCC',
          backgroundColor: '#F2F7FE',
        }}
      >
        <div
          style={{
            marginLeft: '16px',
            marginRight: '16px',
            marginTop: '16px',
            marginBottom: '16px',
          }}
        >
          <Row gutter={[10, 16]}>
            <Col span={3} className="adaptify-label-column">
              <Typography.Text>Carrier Name:</Typography.Text>
            </Col>
            <Col span={9}>
              <Input
                value={product?.carrierName}
                readOnly
                style={inputStyle}
              ></Input>
            </Col>
            <Col span={3} className="adaptify-label-column">
              <Typography.Text>Underwriting Company:</Typography.Text>
            </Col>
            <Col span={9}>
              <Input
                value={product?.underwritingCompany}
                style={inputStyle}
                readOnly
              ></Input>
            </Col>
            <Col span={3} className="adaptify-label-column">
              <Typography.Text>Insurance Type:</Typography.Text>
            </Col>
            <Col span={3} className="adaptify-label-column">
              <Input
                value={product?.insuranceType}
                style={inputStyle}
                readOnly
              ></Input>
            </Col>
            <Col span={3} className="adaptify-label-column">
              <Typography.Text>Line of Business:</Typography.Text>
            </Col>
            <Col span={3} className="adaptify-label-column">
              <Input
                value={product?.lineOfBusinessName}
                style={inputStyle}
                readOnly
              ></Input>
            </Col>
            <Col span={3} className="adaptify-label-column">
              <Typography.Text>State:</Typography.Text>
            </Col>
            <Col span={3} className="adaptify-label-column">
              <Input
                value={product?.stateCode}
                style={inputStyle}
                readOnly
              ></Input>
            </Col>
            <Col span={3} className="adaptify-label-column">
              <Typography.Text>Product Type:</Typography.Text>
            </Col>
            <Col span={3} className="adaptify-label-column">
              <Input
                value={product?.productType}
                style={inputStyle}
                readOnly
              ></Input>
            </Col>
          </Row>
        </div>
      </div>
    </Flex>
  );
}
