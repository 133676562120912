import * as kot from 'adaptify-multi-module-rating-admin-model';
import {Flex} from 'antd';
import React, {useEffect, useState} from 'react';
import {LobService} from '../../lob/service/LobService';
import {ProductService} from '../../product/service/ProductService';
import {RatingNavigationBar} from '../control/RatingNavigationBar';

import {LobCoverageList} from '../../lob/control/LobCoverageList';
import {LobRiskList} from '../../lob/control/LobRiskList';
import {ProductVersion} from '../../product/model/Product';
import {AuthManager} from '../../user/util/AuthManager';
import {ProductVersionSummaryForm} from '../control/ProductVersionSummaryForm';
import {IsProductVersionReadOnly} from './ProductUtils';
import LineOfBusinessHierarchy = kot.com.adaptify.rating.admin.model.lob.LineOfBusinessHierarchy;
export interface ProductVersionLobItemControlProps {
  lobService: LobService;
  productService: ProductService;
  productVersionId: string;
  authManager: AuthManager;
}

export function ProductVersionLobItemControl(
  props: ProductVersionLobItemControlProps
) {
  const [productVersion, setProductVersion] = useState<
    ProductVersion | undefined
  >(undefined);
  const [lob, setLob] = useState<LineOfBusinessHierarchy | undefined>(
    undefined
  );
  const [selectedRiskId, setSelectedRiskId] = useState<string | undefined>(
    undefined
  );
  const [selectedCoverageId, setSelectedCoverageId] = useState<
    string | undefined
  >(undefined);
  const [modifyCount, setModifyCount] = useState<number>(0);

  useEffect(() => {
    if (!props.productVersionId) {
      setProductVersion(undefined);
      return;
    }
    const eff = async () => {
      const lobHierarchyPromise =
        await props.productService.GetLobHierarchyForProductVersionId(
          props.productVersionId
        );

      const productVersionPromise = props.productService.GetProductVersion(
        props.productVersionId
      );

      const [lobHierarchy, productVersion] = await Promise.all([
        lobHierarchyPromise,
        productVersionPromise,
      ]);

      setLob(lobHierarchy);
      setProductVersion(productVersion);
    };
    eff();
  }, [props.productVersionId]);

  function isDirty() {
    return false;
  }

  function isReadOnly() {
    return (
      IsProductVersionReadOnly(productVersion, props.authManager) ||
      lob?.tenantId !== props.authManager.GetTenantId()
    );
  }

  // right now the premium orchestration is saved on every modification, so there's no concept of being dirty
  return (
    <Flex vertical gap={24}>
      <div style={{minHeight: '100%', width: '100%'}}>
        <ProductVersionSummaryForm
          productService={props.productService}
          productVersionId={props.productVersionId}
          isDirty={isDirty()}
          readOnly={isReadOnly()}
        />
      </div>
      <div style={{minHeight: '46px', width: '100%'}}>
        <RatingNavigationBar
          productVersionId={props.productVersionId}
          isDirty={isDirty()}
          selection="LOB"
        />
      </div>
      <Flex vertical gap={24}>
        <LobRiskList
          lobService={props.lobService}
          lobDefId={lob?.id}
          readOnly={isReadOnly()}
          selection={selectedRiskId}
          selectionChanged={setSelectedRiskId}
          modifyCount={modifyCount}
          setModifyCount={setModifyCount}
          getDrilldownUrl={(lobItemId: string) =>
            `/rating/product/version/${props.productVersionId}/lob/risk/?lobItemId=${lobItemId}`
          }
        />
        <LobCoverageList
          lobService={props.lobService}
          lobHierarchyId={lob?.id}
          parentLobItemId={selectedRiskId ?? ''}
          selection={selectedCoverageId}
          selectionChanged={setSelectedCoverageId}
          modifyCount={modifyCount}
          setModifyCount={setModifyCount}
          readOnly={isReadOnly()}
          getDrilldownUrl={(coverageId: string) =>
            `/rating/product/version/${props.productVersionId}/lob/coverage?coverageId=${coverageId}`
          }
        />
      </Flex>
    </Flex>
  );
}
