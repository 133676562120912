package com.adaptify.rating.admin.model.calculation.descriptor.lib

import com.adaptify.rating.admin.model.calculation.BindingType
import com.adaptify.rating.admin.model.calculation.CalculationFunction
import com.adaptify.rating.admin.model.calculation.binding.Binding
import com.adaptify.rating.admin.model.calculation.binding.BindingUtil
import com.adaptify.rating.admin.model.calculation.context.ScopedVariable
import com.adaptify.rating.admin.model.calculation.descriptor.*
import com.adaptify.rating.admin.model.calculation.env.MetadataProvider
import com.adaptify.rating.admin.model.type.PrimitiveDataType
import com.adaptify.rating.admin.model.util.BindingResolver

object TableDescriptor : FunctionDescriptor("TABLE_LOOKUP", "TABLE_LOOKUP", FunctionType.Function, "Logic") {

  val assignToDescriptor = object : BindingDescriptor(
    "assignTo", "Assign To", BindingInputOutputType.Output,
    BindingDescriptor.standardOutputBindingTypes, null
  ) {
    override suspend fun getExpectedDataType(
      calculationFunction: CalculationFunction,
      functionDescriptor: FunctionDescriptor,
      bindingDescriptor: BindingDescriptor,
      binding: Binding,
      metadataProvider: MetadataProvider,
      scope: Array<ScopedVariable>
    ): BoundDataType? {

      val table = calculationFunction.bindings.find {
        it.name === "table"
      }
      val resultColumn = table?.tableBinding?.resultColumn
      val tableId = table?.tableBinding?.tableId
      if (resultColumn != null && tableId != null) {
        // figure out the type of the result column
        val table = metadataProvider.getTableDefinitionById(tableId).await()
        val resolvedColumn = table?.columns?.find {
          it.name == resultColumn
        }
        if (resolvedColumn != null && resolvedColumn.path != null) {
          return null;
        }

        val boundDataType =  BindingResolver.ResolveDataTypeForBinding(
          calculationFunction,
          BindingUtil.fromPath("any", resolvedColumn?.path ?: ""),
          metadataProvider,
          scope
        );
        if (boundDataType == null || boundDataType.primitive == null) {
          return null;
        }
        // even if many cardinality was traversed data type is always one
        return BoundDataTypeImpl.Primitive(boundDataType.primitive ?: PrimitiveDataType.String, false);
      }
      return null;
    }
  }

  val tableDescriptor = BindingDescriptor(
    "table", "Table", BindingInputOutputType.Input,
    arrayOf(BindingType.TableBinding), null
  )

  override fun getBindingDescriptors(
  ): Array<BindingDescriptor> =  arrayOf(assignToDescriptor, tableDescriptor);

  override fun getDisplayTemplate(
  ): String = "\$assignTo = TABLE_LOOKUP(\$table)"
}
